export const group_g2_segments =[
 [
  {
   "color": "#057e40ff",
   "pois": [{"id": "centroid", "point": [917, 379]}],
   "polygons": [
    [
     [989, 333],
     [987, 651],
     [988, 678],
     [937, 678],
     [925, 705],
     [925, 757],
     [919, 758],
     [919, 673],
     [917, 667],
     [849, 666],
     [843, 94],
     [853, 94],
     [856, 79],
     [943, 80],
     [951, 78],
     [957, 80],
     [965, 80],
     [973, 78],
     [979, 83],
     [979, 89],
     [989, 98]
    ],
    [[887, 684], [863, 683], [863, 670], [887, 671]],
    [[914, 671], [907, 684], [892, 684], [892, 671]]
   ],
   "box": [843, 78, 989, 758]
  },
  {
   "color": "#05a168ff",
   "pois": [{"id": "centroid", "point": [965, 835]}],
   "polygons": [
    [
     [1129, 838],
     [1128, 847],
     [1122, 848],
     [1116, 851],
     [1114, 854],
     [1103, 859],
     [1099, 856],
     [1097, 852],
     [1092, 849],
     [1084, 847],
     [1073, 848],
     [1071, 851],
     [1067, 851],
     [1064, 853],
     [1060, 858],
     [1057, 866],
     [1045, 875],
     [984, 873],
     [980, 871],
     [979, 857],
     [968, 852],
     [812, 846],
     [797, 846],
     [796, 849],
     [793, 849],
     [783, 845],
     [782, 807],
     [792, 791],
     [792, 809],
     [821, 811],
     [822, 821],
     [830, 823],
     [881, 825],
     [910, 824],
     [919, 797],
     [919, 759],
     [925, 758],
     [925, 809],
     [929, 813],
     [1058, 818],
     [1091, 818],
     [1115, 820],
     [1129, 817],
     [1129, 807]
    ]
   ],
   "box": [782, 758, 1129, 875]
  }
 ],
 [
  {
   "color": "#057e40ff",
   "pois": [{"id": "centroid", "point": [925, 373]}],
   "polygons": [
    [
     [1001, 339],
     [1000, 345],
     [993, 348],
     [991, 351],
     [992, 401],
     [990, 679],
     [967, 678],
     [965, 679],
     [965, 682],
     [965, 680],
     [961, 678],
     [933, 677],
     [915, 696],
     [915, 670],
     [913, 666],
     [856, 663],
     [851, 95],
     [861, 95],
     [863, 80],
     [967, 80],
     [979, 78],
     [990, 82],
     [990, 93],
     [1001, 98]
    ],
    [[884, 682], [862, 681], [862, 668], [884, 669]],
    [[909, 670], [896, 683], [889, 683], [889, 669]],
    [[911, 684], [911, 674]],
    [[907, 684], [905, 684], [904, 682], [907, 678]]
   ],
   "box": [851, 78, 1001, 696]
  },
  {
   "color": "#05a168ff",
   "pois": [{"id": "centroid", "point": [962, 837]}],
   "polygons": [
    [
     [1124, 854],
     [1121, 858],
     [1115, 859],
     [1109, 855],
     [1094, 853],
     [1089, 856],
     [1086, 860],
     [1075, 863],
     [1073, 858],
     [1068, 855],
     [1067, 852],
     [1053, 851],
     [1049, 852],
     [1045, 857],
     [1038, 858],
     [1035, 865],
     [1031, 867],
     [1029, 871],
     [1020, 876],
     [956, 872],
     [954, 856],
     [947, 852],
     [791, 841],
     [781, 839],
     [781, 800],
     [788, 793],
     [789, 803],
     [791, 805],
     [813, 806],
     [813, 815],
     [815, 818],
     [839, 820],
     [892, 823],
     [900, 821],
     [912, 805],
     [912, 809],
     [917, 812],
     [926, 813],
     [1030, 819],
     [1085, 824],
     [1105, 824],
     [1114, 823],
     [1124, 798]
    ]
   ],
   "box": [781, 793, 1124, 876]
  }
 ],
 [
  {
   "color": "#057e40ff",
   "pois": [{"id": "centroid", "point": [928, 358]}],
   "polygons": [
    [
     [1013, 323],
     [1012, 330],
     [999, 332],
     [992, 336],
     [992, 345],
     [977, 349],
     [977, 679],
     [931, 676],
     [913, 688],
     [913, 665],
     [864, 661],
     [859, 96],
     [871, 95],
     [872, 81],
     [927, 81],
     [935, 79],
     [986, 78],
     [1001, 82],
     [1001, 93],
     [1013, 97]
    ],
    [[984, 362], [983, 354]],
    [[990, 360], [987, 360], [988, 354], [990, 355]],
    [[883, 681], [866, 680], [864, 678], [864, 667], [882, 667]],
    [[905, 669], [888, 680], [888, 667]],
    [[908, 683], [905, 683], [905, 674], [907, 672]],
    [[901, 682], [897, 682], [897, 679], [901, 676]]
   ],
   "box": [859, 78, 1013, 688]
  },
  {
   "color": "#05a168ff",
   "pois": [{"id": "centroid", "point": [958, 838]}],
   "polygons": [
    [
     [1118, 813],
     [1117, 860],
     [1110, 867],
     [1108, 865],
     [1088, 864],
     [1075, 858],
     [1062, 860],
     [1059, 863],
     [1052, 863],
     [1050, 867],
     [1047, 861],
     [1042, 858],
     [1034, 855],
     [1025, 854],
     [1022, 855],
     [1020, 859],
     [1012, 860],
     [1010, 863],
     [1003, 867],
     [1002, 873],
     [992, 877],
     [932, 870],
     [929, 854],
     [917, 850],
     [795, 837],
     [781, 833],
     [781, 794],
     [787, 790],
     [788, 797],
     [806, 801],
     [806, 806],
     [809, 813],
     [884, 821],
     [892, 818],
     [902, 809],
     [905, 811],
     [916, 811],
     [953, 816],
     [964, 816],
     [983, 819],
     [1090, 829],
     [1100, 827],
     [1102, 822],
     [1117, 804]
    ]
   ],
   "box": [781, 790, 1118, 877]
  }
 ]
]