import { getBoundingBox } from './mathFunctions'

export const getBinaryDataMatrix = (pixels) => {
  const box = getBoundingBox(pixels)
  const boxWidth = box[2] - box[0] + 1
  const boxHeight = box[3] - box[1] + 1
  const binaryDataMatrix = Array(boxHeight)
  for (let i = 0; i < boxHeight; ++i) {
    binaryDataMatrix[i] = new Uint8ClampedArray(boxWidth)
  }

  for (let pixel of pixels) {
    binaryDataMatrix[pixel[1] - box[1]][pixel[0] - box[0]] = 1
  }
  return { binaryDataMatrix, box }
}

export const dilation = (binaryDataMatrix) => {
  const neighbours = [
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1]
    // [0, 0]
  ]
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  const newBinaryDataMatrix = Array(height)
  for (let i = 0; i < height; ++i) {
    newBinaryDataMatrix[i] = new Uint8ClampedArray(width)
  }

  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      if (binaryDataMatrix[y][x] === 0) continue

      for (let neighbour of neighbours) {
        const nbX = x + neighbour[0]
        const nbY = y + neighbour[1]
        if (nbX < 0 || nbY < 0 || nbX > width - 1 || nbY > height - 1) {
          //Vecino cae fuera de la imagen
          continue
        }
        newBinaryDataMatrix[nbY][nbX] = 1
      }
    }
  }
  return newBinaryDataMatrix
}

export const erosion = (binaryDataMatrix) => {
  const neighbours = [
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1]
  ]
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  const newBinaryDataMatrix = Array(height)
  for (let i = 0; i < height; ++i) {
    newBinaryDataMatrix[i] = new Uint8ClampedArray(width)
  }

  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      if (binaryDataMatrix[y][x] === 0) continue

      let keep = true
      for (let neighbour of neighbours) {
        const nbX = x + neighbour[0]
        const nbY = y + neighbour[1]
        if (nbX < 0 || nbY < 0 || nbX > width - 1 || nbY > height - 1) {
          //Vecino cae fuera de la imagen
          continue
        }
        if (binaryDataMatrix[nbY][nbX] === 0) {
          keep = false
          break
        }
      }
      newBinaryDataMatrix[y][x] = keep ? 1 : 0
    }
  }
  return newBinaryDataMatrix
}

export const modeFilter = (binaryDataMatrix) => {
  const neighbours = [
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1],
    [0, 0]
  ]
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  const newBinaryDataMatrix = Array(height)
  for (let i = 0; i < height; ++i) {
    newBinaryDataMatrix[i] = new Uint8ClampedArray(width)
  }
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      let cnt = 0
      for (let neighbour of neighbours) {
        const nbX = x + neighbour[0]
        const nbY = y + neighbour[1]
        if (nbX < 0 || nbY < 0 || nbX > width - 1 || nbY > height - 1) {
          //Vecino cae fuera de la imagen
          continue
        }
        binaryDataMatrix[nbY][nbX] !== 0 ? cnt++ : cnt--
      }
      newBinaryDataMatrix[y][x] = cnt >= 0 ? 1 : 0
    }
  }
  return newBinaryDataMatrix
}
