import { combineReducers } from 'redux'
import reducerMaster from './reducerMaster'
import reducerHouses from './reducerHouses'
import reducerGroups from './reducerGroups'
import reducerRotator from './reducerRotator'
import reducerShare from './reducerShare'
import reducerSegmentGenerator from './SegmentGenerator/reducerSegmentGenerator'
import reducerSGEditor from './SegmentGenerator/reducerSGEditor'
import reducerSGVisualization from './SegmentGenerator/reducerSGVisualization'
import reducerSGMaskImage from './SegmentGenerator/reducerSGMaskImage'
import reducerSGParameters from './SegmentGenerator/reducerSGParameters'

export default combineReducers({
  master: reducerMaster,
  houses: reducerHouses,
  groups: reducerGroups,
  rotator: reducerRotator,
  share: reducerShare,
  generator: reducerSegmentGenerator,
  areaEditor: reducerSGEditor,
  visualization: reducerSGVisualization,
  maskImage: reducerSGMaskImage,
  parameters: reducerSGParameters
})
