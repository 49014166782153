import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { mouseOverSegment } from '../../modules/reducerShare'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '200px',
    height: '150px',
    padding: '5px'
  },
  header: { padding: '5px' },
  inner: {
    width: '100%',
    height: '100%',

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: '1px',
    top: '1px',
    color: 'black',
    '&:hover': { backgroundColor: 'transparent' }
  }
}))

const RTooltipContent = ({ houseId, master }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    if (master) {
      dispatch(mouseOverSegment(''))
    }
  }, [dispatch, master])

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        action={
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>Contenido</CardContent>
      <CardActions></CardActions>
    </Card>
  )
}
export default RTooltipContent
