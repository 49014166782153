import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { outputText } from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'

import { currentGroupSelector } from '../../../../modules/reducerGroups'

const useStyles = makeStyles((theme) => ({
  button: { width: '100%', height: '40px' }
}))

const RCGSaveButton = () => {
  const classes = useStyles()
  const output = useSelector(outputText)
  const currentGroup = useSelector(currentGroupSelector)
  // const dispatch = useDispatch()

  const handleSave = useCallback(() => {
    var a = document.createElement('a')
    var file = new Blob([output.text], { type: 'application/js' })
    a.href = URL.createObjectURL(file)
    a.download = output.fileName
    a.click()
  }, [output])

  return currentGroup !== '' ? (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      disableFocusRipple
      disableRipple
      onClick={handleSave}
    >
      Save
    </Button>
  ) : null
}
export default RCGSaveButton
