export const group_LLDH_0_segments =[
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [316, 732]}],
   "box": [131, 648, 511, 805],
   "polygons": [
    [
     [304, 649],
     [163, 684],
     [131, 696],
     [150, 739],
     [190, 801],
     [204, 805],
     [244, 804],
     [469, 789],
     [511, 782],
     [507, 748],
     [481, 738],
     [464, 694],
     [425, 691],
     [417, 652],
     [362, 665],
     [340, 661],
     [314, 648]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [748, 693]}],
   "box": [594, 621, 915, 777],
   "polygons": [
    [
     [794, 622],
     [699, 626],
     [601, 653],
     [597, 664],
     [594, 702],
     [607, 774],
     [612, 777],
     [624, 776],
     [721, 761],
     [856, 748],
     [867, 745],
     [898, 727],
     [909, 716],
     [915, 696],
     [912, 664],
     [904, 642],
     [890, 635],
     [828, 621]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [958, 586]}],
   "box": [854, 539, 1068, 631],
   "polygons": [
    [
     [1057, 540],
     [870, 591],
     [854, 597],
     [854, 604],
     [862, 616],
     [876, 624],
     [897, 631],
     [910, 630],
     [1057, 571],
     [1065, 565],
     [1068, 558],
     [1065, 541],
     [1060, 539]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1151, 546]}],
   "box": [1074, 514, 1231, 568],
   "polygons": [
    [
     [1168, 515],
     [1076, 535],
     [1074, 538],
     [1079, 568],
     [1197, 568],
     [1221, 563],
     [1231, 556],
     [1230, 549],
     [1222, 541],
     [1194, 521],
     [1178, 514]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1575, 540]}],
   "box": [1450, 494, 1715, 579],
   "polygons": [
    [
     [1523, 495],
     [1504, 500],
     [1476, 530],
     [1450, 550],
     [1451, 555],
     [1483, 578],
     [1494, 576],
     [1519, 563],
     [1567, 557],
     [1587, 560],
     [1642, 579],
     [1697, 569],
     [1715, 552],
     [1713, 548],
     [1703, 543],
     [1643, 520],
     [1528, 494]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1740, 542]}],
   "box": [1531, 454, 1871, 670],
   "polygons": [
    [
     [1543, 455],
     [1538, 458],
     [1531, 472],
     [1537, 489],
     [1699, 535],
     [1761, 564],
     [1769, 572],
     [1777, 615],
     [1819, 666],
     [1826, 670],
     [1871, 667],
     [1846, 593],
     [1847, 566],
     [1807, 536],
     [1821, 509],
     [1811, 471],
     [1777, 463],
     [1770, 465],
     [1745, 508],
     [1738, 512],
     [1581, 454]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1885, 588]}],
   "box": [1822, 500, 1920, 726],
   "polygons": [
    [
     [1862, 501],
     [1832, 526],
     [1822, 537],
     [1823, 542],
     [1875, 580],
     [1870, 593],
     [1875, 619],
     [1907, 681],
     [1906, 689],
     [1894, 706],
     [1920, 726],
     [1919, 636],
     [1908, 617],
     [1909, 613],
     [1920, 607],
     [1917, 575],
     [1913, 569],
     [1882, 550],
     [1883, 546],
     [1906, 534],
     [1897, 513],
     [1865, 500]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1120, 653]}],
   "box": [945, 573, 1337, 750],
   "polygons": [
    [
     [1100, 574],
     [1049, 585],
     [953, 624],
     [949, 628],
     [945, 641],
     [947, 722],
     [951, 735],
     [1028, 750],
     [1060, 749],
     [1091, 743],
     [1163, 718],
     [1294, 664],
     [1324, 649],
     [1337, 637],
     [1336, 630],
     [1314, 590],
     [1298, 580],
     [1138, 573]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [529, 693]}],
   "box": [428, 630, 624, 783],
   "polygons": [
    [
     [599, 631],
     [449, 638],
     [441, 640],
     [428, 650],
     [440, 680],
     [467, 688],
     [487, 707],
     [494, 734],
     [519, 758],
     [523, 783],
     [536, 783],
     [594, 771],
     [583, 719],
     [583, 677],
     [600, 647],
     [624, 631],
     [621, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [316, 731]}],
   "box": [132, 647, 510, 804],
   "polygons": [
    [
     [307, 648],
     [156, 685],
     [132, 695],
     [152, 740],
     [190, 799],
     [213, 804],
     [250, 802],
     [466, 788],
     [510, 781],
     [506, 747],
     [480, 736],
     [463, 693],
     [425, 690],
     [417, 651],
     [365, 664],
     [342, 661],
     [313, 647]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [747, 693]}],
   "box": [593, 621, 913, 775],
   "polygons": [
    [
     [798, 622],
     [698, 626],
     [614, 647],
     [600, 653],
     [597, 659],
     [593, 704],
     [606, 774],
     [624, 775],
     [723, 760],
     [852, 748],
     [865, 745],
     [895, 728],
     [906, 718],
     [913, 701],
     [911, 665],
     [902, 641],
     [888, 635],
     [825, 621]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [958, 587]}],
   "box": [852, 540, 1067, 631],
   "polygons": [
    [
     [1054, 541],
     [883, 587],
     [852, 598],
     [853, 604],
     [859, 614],
     [870, 622],
     [895, 631],
     [910, 630],
     [1057, 571],
     [1067, 560],
     [1066, 547],
     [1061, 540]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1150, 547]}],
   "box": [1074, 515, 1231, 569],
   "polygons": [
    [
     [1167, 516],
     [1074, 536],
     [1078, 569],
     [1194, 569],
     [1214, 566],
     [1230, 557],
     [1231, 554],
     [1225, 545],
     [1195, 523],
     [1178, 515]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1575, 541]}],
   "box": [1449, 496, 1714, 580],
   "polygons": [
    [
     [1519, 497],
     [1501, 503],
     [1477, 530],
     [1449, 552],
     [1450, 556],
     [1484, 580],
     [1519, 564],
     [1570, 558],
     [1639, 580],
     [1695, 571],
     [1714, 554],
     [1708, 547],
     [1641, 521],
     [1532, 496]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1739, 543]}],
   "box": [1531, 455, 1870, 672],
   "polygons": [
    [
     [1546, 456],
     [1538, 459],
     [1531, 472],
     [1536, 490],
     [1697, 536],
     [1761, 566],
     [1769, 576],
     [1777, 618],
     [1821, 670],
     [1827, 672],
     [1870, 668],
     [1845, 593],
     [1846, 567],
     [1807, 539],
     [1808, 532],
     [1821, 510],
     [1810, 472],
     [1779, 465],
     [1771, 466],
     [1740, 513],
     [1724, 510],
     [1577, 455]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1885, 591]}],
   "box": [1821, 502, 1920, 729],
   "polygons": [
    [
     [1862, 503],
     [1838, 522],
     [1821, 541],
     [1874, 581],
     [1869, 595],
     [1874, 620],
     [1906, 683],
     [1903, 693],
     [1893, 706],
     [1896, 712],
     [1920, 729],
     [1919, 639],
     [1907, 617],
     [1919, 610],
     [1916, 575],
     [1881, 551],
     [1906, 536],
     [1898, 516],
     [1866, 502]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1118, 654]}],
   "box": [943, 574, 1336, 750],
   "polygons": [
    [
     [1094, 575],
     [1044, 587],
     [948, 628],
     [943, 648],
     [946, 727],
     [951, 736],
     [1025, 750],
     [1059, 749],
     [1090, 743],
     [1157, 720],
     [1294, 664],
     [1324, 649],
     [1336, 637],
     [1315, 594],
     [1309, 587],
     [1297, 581],
     [1144, 574]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [529, 693]}],
   "box": [428, 630, 623, 783],
   "polygons": [
    [
     [593, 631],
     [455, 637],
     [443, 639],
     [428, 649],
     [439, 679],
     [465, 686],
     [485, 704],
     [492, 731],
     [518, 756],
     [523, 783],
     [593, 770],
     [582, 717],
     [582, 678],
     [598, 649],
     [623, 632],
     [621, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [316, 730]}],
   "box": [133, 647, 509, 802],
   "polygons": [
    [
     [303, 648],
     [161, 683],
     [133, 694],
     [153, 739],
     [191, 798],
     [206, 802],
     [243, 801],
     [477, 786],
     [509, 780],
     [505, 746],
     [479, 735],
     [464, 693],
     [425, 690],
     [418, 651],
     [359, 664],
     [344, 661],
     [316, 647]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [746, 693]}],
   "box": [593, 622, 912, 775],
   "polygons": [
    [
     [761, 623],
     [697, 626],
     [602, 651],
     [596, 661],
     [593, 693],
     [594, 718],
     [604, 772],
     [608, 775],
     [625, 774],
     [718, 760],
     [849, 748],
     [863, 745],
     [890, 730],
     [905, 717],
     [912, 697],
     [910, 667],
     [902, 643],
     [886, 635],
     [831, 622]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [957, 587]}],
   "box": [851, 541, 1066, 632],
   "polygons": [
    [
     [1052, 542],
     [887, 586],
     [851, 600],
     [858, 614],
     [865, 620],
     [897, 632],
     [910, 630],
     [1054, 573],
     [1066, 561],
     [1065, 547],
     [1061, 541]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1149, 547]}],
   "box": [1073, 516, 1230, 570],
   "polygons": [
    [
     [1165, 517],
     [1123, 528],
     [1075, 536],
     [1073, 539],
     [1077, 569],
     [1191, 570],
     [1216, 566],
     [1229, 558],
     [1230, 555],
     [1223, 545],
     [1196, 525],
     [1177, 516]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1574, 543]}],
   "box": [1448, 497, 1714, 582],
   "polygons": [
    [
     [1520, 498],
     [1502, 503],
     [1474, 533],
     [1448, 553],
     [1449, 557],
     [1482, 581],
     [1519, 565],
     [1561, 560],
     [1583, 562],
     [1641, 582],
     [1695, 572],
     [1714, 554],
     [1704, 547],
     [1639, 522],
     [1529, 497]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1739, 545]}],
   "box": [1530, 457, 1868, 673],
   "polygons": [
    [
     [1542, 458],
     [1536, 463],
     [1530, 477],
     [1536, 492],
     [1703, 540],
     [1761, 568],
     [1768, 577],
     [1776, 620],
     [1819, 671],
     [1824, 673],
     [1868, 670],
     [1867, 660],
     [1845, 599],
     [1846, 571],
     [1806, 539],
     [1821, 510],
     [1810, 474],
     [1781, 467],
     [1769, 469],
     [1743, 513],
     [1738, 515],
     [1581, 457]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1885, 594]}],
   "box": [1821, 503, 1919, 731],
   "polygons": [
    [
     [1863, 504],
     [1836, 525],
     [1821, 542],
     [1825, 548],
     [1874, 583],
     [1868, 597],
     [1872, 618],
     [1904, 683],
     [1903, 692],
     [1891, 708],
     [1895, 714],
     [1919, 731],
     [1919, 643],
     [1906, 619],
     [1918, 612],
     [1916, 579],
     [1913, 573],
     [1881, 554],
     [1883, 549],
     [1906, 536],
     [1895, 515],
     [1863, 503]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1117, 654]}],
   "box": [942, 574, 1334, 750],
   "polygons": [
    [
     [1105, 575],
     [1071, 579],
     [1042, 588],
     [947, 628],
     [942, 645],
     [944, 726],
     [949, 736],
     [1022, 750],
     [1059, 749],
     [1089, 743],
     [1143, 725],
     [1285, 668],
     [1317, 653],
     [1334, 639],
     [1333, 631],
     [1317, 600],
     [1308, 588],
     [1296, 582],
     [1123, 574]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [528, 692]}],
   "box": [428, 630, 621, 783],
   "polygons": [
    [
     [590, 631],
     [452, 637],
     [442, 639],
     [428, 649],
     [440, 679],
     [465, 686],
     [486, 705],
     [491, 729],
     [517, 755],
     [521, 781],
     [524, 783],
     [592, 769],
     [581, 715],
     [581, 680],
     [598, 648],
     [621, 633],
     [621, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [316, 729]}],
   "box": [134, 646, 508, 801],
   "polygons": [
    [
     [308, 647],
     [155, 684],
     [134, 693],
     [152, 735],
     [189, 795],
     [211, 801],
     [254, 799],
     [477, 785],
     [508, 779],
     [504, 745],
     [478, 734],
     [464, 693],
     [425, 690],
     [418, 651],
     [366, 663],
     [346, 661],
     [313, 646]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [744, 693]}],
   "box": [592, 622, 910, 775],
   "polygons": [
    [
     [772, 623],
     [697, 626],
     [600, 652],
     [595, 662],
     [592, 693],
     [593, 718],
     [603, 772],
     [610, 775],
     [713, 760],
     [853, 747],
     [893, 727],
     [906, 713],
     [910, 700],
     [908, 664],
     [899, 641],
     [828, 622]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [956, 588]}],
   "box": [850, 542, 1065, 632],
   "polygons": [
    [
     [1050, 543],
     [881, 588],
     [850, 599],
     [851, 604],
     [862, 619],
     [873, 625],
     [894, 632],
     [907, 631],
     [1056, 572],
     [1065, 562],
     [1064, 547],
     [1060, 542]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1148, 548]}],
   "box": [1072, 517, 1229, 571],
   "polygons": [
    [
     [1165, 518],
     [1074, 537],
     [1072, 540],
     [1076, 570],
     [1189, 571],
     [1211, 568],
     [1228, 559],
     [1229, 556],
     [1222, 546],
     [1195, 526],
     [1176, 517]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1573, 544]}],
   "box": [1448, 498, 1713, 583],
   "polygons": [
    [
     [1523, 499],
     [1502, 504],
     [1475, 533],
     [1449, 552],
     [1448, 558],
     [1481, 582],
     [1519, 566],
     [1564, 561],
     [1584, 564],
     [1638, 583],
     [1693, 574],
     [1713, 556],
     [1704, 549],
     [1643, 525],
     [1525, 498]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1738, 546]}],
   "box": [1530, 458, 1867, 675],
   "polygons": [
    [
     [1545, 459],
     [1538, 461],
     [1530, 476],
     [1535, 493],
     [1698, 540],
     [1761, 570],
     [1767, 579],
     [1771, 612],
     [1775, 622],
     [1814, 670],
     [1823, 675],
     [1867, 671],
     [1844, 601],
     [1845, 572],
     [1806, 542],
     [1820, 514],
     [1815, 487],
     [1809, 475],
     [1776, 468],
     [1770, 470],
     [1743, 514],
     [1736, 517],
     [1576, 458]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1884, 597]}],
   "box": [1821, 505, 1919, 734],
   "polygons": [
    [
     [1862, 506],
     [1832, 530],
     [1821, 543],
     [1825, 550],
     [1873, 585],
     [1867, 599],
     [1871, 620],
     [1903, 686],
     [1900, 696],
     [1890, 708],
     [1891, 713],
     [1919, 734],
     [1919, 647],
     [1906, 624],
     [1907, 618],
     [1917, 613],
     [1915, 579],
     [1910, 573],
     [1880, 555],
     [1882, 551],
     [1903, 541],
     [1904, 533],
     [1895, 517],
     [1864, 505]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1115, 655]}],
   "box": [940, 575, 1333, 751],
   "polygons": [
    [
     [1100, 576],
     [1069, 580],
     [1040, 589],
     [945, 629],
     [940, 653],
     [942, 725],
     [947, 736],
     [991, 746],
     [1031, 751],
     [1066, 748],
     [1092, 742],
     [1158, 719],
     [1278, 671],
     [1317, 653],
     [1333, 639],
     [1315, 600],
     [1298, 584],
     [1281, 581],
     [1127, 575]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 692]}],
   "box": [428, 630, 622, 782],
   "polygons": [
    [
     [590, 631],
     [450, 637],
     [440, 640],
     [428, 649],
     [439, 678],
     [464, 685],
     [485, 704],
     [491, 730],
     [516, 754],
     [522, 782],
     [591, 768],
     [580, 714],
     [581, 677],
     [596, 650],
     [622, 632],
     [620, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [316, 728]}],
   "box": [135, 646, 507, 799],
   "polygons": [
    [
     [305, 647],
     [153, 684],
     [136, 692],
     [135, 695],
     [155, 737],
     [190, 794],
     [207, 799],
     [245, 798],
     [476, 784],
     [507, 778],
     [504, 745],
     [478, 734],
     [463, 692],
     [425, 689],
     [417, 650],
     [362, 663],
     [342, 659],
     [317, 646]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [743, 693]}],
   "box": [591, 622, 909, 773],
   "polygons": [
    [
     [793, 623],
     [698, 626],
     [599, 652],
     [595, 659],
     [591, 696],
     [593, 724],
     [602, 772],
     [621, 773],
     [708, 760],
     [851, 747],
     [890, 728],
     [905, 712],
     [909, 696],
     [907, 666],
     [899, 643],
     [884, 636],
     [821, 622]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [955, 589]}],
   "box": [849, 543, 1065, 632],
   "polygons": [
    [
     [1050, 544],
     [886, 587],
     [849, 601],
     [856, 615],
     [863, 621],
     [891, 632],
     [908, 631],
     [1055, 573],
     [1063, 566],
     [1065, 559],
     [1063, 547],
     [1059, 543]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1147, 550]}],
   "box": [1072, 518, 1228, 572],
   "polygons": [
    [
     [1167, 519],
     [1072, 539],
     [1075, 571],
     [1191, 572],
     [1217, 567],
     [1228, 559],
     [1224, 550],
     [1212, 540],
     [1184, 521],
     [1174, 518]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1572, 546]}],
   "box": [1447, 500, 1712, 585],
   "polygons": [
    [
     [1520, 501],
     [1500, 507],
     [1474, 535],
     [1447, 555],
     [1448, 561],
     [1481, 584],
     [1517, 568],
     [1560, 563],
     [1584, 566],
     [1638, 585],
     [1691, 576],
     [1712, 558],
     [1699, 549],
     [1643, 527],
     [1527, 500]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1737, 548]}],
   "box": [1530, 460, 1866, 677],
   "polygons": [
    [
     [1544, 461],
     [1537, 464],
     [1530, 477],
     [1535, 495],
     [1695, 541],
     [1757, 570],
     [1766, 580],
     [1770, 614],
     [1774, 624],
     [1815, 674],
     [1824, 677],
     [1866, 672],
     [1843, 602],
     [1844, 573],
     [1805, 543],
     [1820, 516],
     [1814, 485],
     [1810, 478],
     [1781, 471],
     [1770, 472],
     [1743, 516],
     [1736, 519],
     [1577, 460]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1884, 600]}],
   "box": [1820, 507, 1920, 738],
   "polygons": [
    [
     [1863, 508],
     [1837, 528],
     [1820, 546],
     [1827, 554],
     [1872, 586],
     [1866, 603],
     [1871, 625],
     [1902, 690],
     [1888, 711],
     [1891, 717],
     [1920, 738],
     [1919, 650],
     [1905, 625],
     [1906, 620],
     [1917, 613],
     [1915, 583],
     [1912, 577],
     [1880, 557],
     [1882, 553],
     [1903, 543],
     [1904, 536],
     [1895, 519],
     [1863, 507]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1114, 656]}],
   "box": [939, 576, 1331, 751],
   "polygons": [
    [
     [1099, 577],
     [1053, 585],
     [945, 628],
     [939, 649],
     [940, 722],
     [946, 737],
     [1023, 751],
     [1072, 747],
     [1142, 725],
     [1274, 673],
     [1312, 656],
     [1331, 641],
     [1329, 631],
     [1311, 597],
     [1305, 590],
     [1292, 584],
     [1124, 576]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 691]}],
   "box": [428, 630, 622, 781],
   "polygons": [
    [
     [593, 631],
     [450, 637],
     [442, 639],
     [428, 649],
     [440, 678],
     [465, 685],
     [485, 704],
     [491, 730],
     [516, 755],
     [520, 781],
     [581, 771],
     [590, 767],
     [580, 719],
     [580, 679],
     [597, 648],
     [622, 631],
     [619, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [317, 727]}],
   "box": [137, 646, 507, 798],
   "polygons": [
    [
     [304, 647],
     [163, 681],
     [137, 691],
     [156, 736],
     [191, 793],
     [209, 798],
     [243, 797],
     [478, 783],
     [500, 780],
     [507, 776],
     [503, 744],
     [478, 734],
     [464, 693],
     [425, 689],
     [418, 650],
     [369, 662],
     [347, 660],
     [318, 646]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [742, 693]}],
   "box": [590, 623, 907, 774],
   "polygons": [
    [
     [755, 624],
     [693, 627],
     [599, 652],
     [594, 662],
     [590, 699],
     [600, 770],
     [608, 774],
     [713, 759],
     [842, 748],
     [860, 744],
     [884, 731],
     [900, 718],
     [907, 702],
     [906, 667],
     [897, 642],
     [827, 623]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [954, 589]}],
   "box": [849, 544, 1064, 633],
   "polygons": [
    [
     [1049, 545],
     [877, 590],
     [849, 600],
     [850, 606],
     [855, 615],
     [869, 625],
     [892, 633],
     [909, 631],
     [1054, 574],
     [1064, 562],
     [1062, 547],
     [1059, 544]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1147, 551]}],
   "box": [1071, 519, 1227, 574],
   "polygons": [
    [
     [1167, 520],
     [1071, 540],
     [1074, 571],
     [1090, 574],
     [1189, 573],
     [1216, 568],
     [1227, 560],
     [1224, 552],
     [1210, 540],
     [1185, 523],
     [1173, 519]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1572, 547]}],
   "box": [1446, 502, 1711, 586],
   "polygons": [
    [
     [1517, 503],
     [1500, 508],
     [1476, 534],
     [1446, 557],
     [1447, 562],
     [1479, 585],
     [1518, 569],
     [1564, 564],
     [1582, 567],
     [1635, 586],
     [1692, 577],
     [1711, 560],
     [1710, 556],
     [1704, 553],
     [1641, 528],
     [1530, 502]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1737, 550]}],
   "box": [1529, 462, 1864, 678],
   "polygons": [
    [
     [1541, 463],
     [1529, 481],
     [1534, 496],
     [1696, 543],
     [1757, 572],
     [1765, 580],
     [1771, 622],
     [1812, 674],
     [1819, 678],
     [1864, 675],
     [1842, 602],
     [1844, 576],
     [1805, 545],
     [1820, 517],
     [1811, 480],
     [1778, 472],
     [1770, 474],
     [1743, 517],
     [1738, 520],
     [1726, 518],
     [1580, 462]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1884, 603]}],
   "box": [1820, 509, 1920, 740],
   "polygons": [
    [
     [1862, 510],
     [1855, 518],
     [1835, 531],
     [1820, 547],
     [1826, 555],
     [1871, 587],
     [1866, 602],
     [1870, 626],
     [1900, 688],
     [1899, 697],
     [1887, 711],
     [1888, 716],
     [1920, 740],
     [1919, 654],
     [1904, 626],
     [1906, 621],
     [1916, 617],
     [1914, 582],
     [1880, 559],
     [1883, 554],
     [1905, 542],
     [1894, 520],
     [1864, 509]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1113, 656]}],
   "box": [938, 577, 1330, 751],
   "polygons": [
    [
     [1095, 578],
     [1066, 582],
     [1037, 591],
     [943, 630],
     [938, 648],
     [939, 728],
     [944, 737],
     [1018, 751],
     [1053, 750],
     [1076, 746],
     [1139, 726],
     [1277, 672],
     [1314, 655],
     [1330, 642],
     [1310, 598],
     [1304, 591],
     [1294, 586],
     [1127, 577]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 691]}],
   "box": [429, 630, 622, 780],
   "polygons": [
    [
     [598, 631],
     [450, 637],
     [442, 639],
     [429, 649],
     [440, 678],
     [465, 685],
     [485, 704],
     [491, 730],
     [515, 754],
     [519, 780],
     [531, 779],
     [582, 770],
     [589, 766],
     [579, 716],
     [580, 677],
     [596, 649],
     [622, 631],
     [618, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [317, 726]}],
   "box": [138, 645, 505, 797],
   "polygons": [
    [
     [311, 646],
     [158, 682],
     [138, 691],
     [159, 739],
     [193, 793],
     [211, 797],
     [260, 795],
     [468, 783],
     [505, 777],
     [502, 743],
     [477, 733],
     [462, 691],
     [425, 689],
     [418, 650],
     [368, 662],
     [348, 660],
     [315, 645]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [741, 693]}],
   "box": [589, 623, 906, 773],
   "polygons": [
    [
     [781, 624],
     [694, 627],
     [611, 647],
     [597, 653],
     [589, 701],
     [599, 770],
     [603, 773],
     [620, 772],
     [710, 759],
     [841, 748],
     [861, 743],
     [886, 729],
     [900, 716],
     [906, 699],
     [904, 664],
     [896, 643],
     [867, 633],
     [820, 623]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [953, 590]}],
   "box": [849, 545, 1063, 634],
   "polygons": [
    [
     [1048, 546],
     [875, 591],
     [849, 600],
     [849, 606],
     [853, 614],
     [869, 626],
     [893, 634],
     [906, 632],
     [1053, 575],
     [1063, 564],
     [1061, 547],
     [1058, 545]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1146, 552]}],
   "box": [1070, 521, 1225, 575],
   "polygons": [
    [
     [1160, 522],
     [1122, 532],
     [1072, 540],
     [1070, 543],
     [1073, 572],
     [1089, 575],
     [1190, 574],
     [1210, 571],
     [1225, 563],
     [1225, 556],
     [1219, 549],
     [1189, 527],
     [1176, 521]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1571, 549]}],
   "box": [1446, 503, 1711, 588],
   "polygons": [
    [
     [1520, 504],
     [1499, 510],
     [1476, 535],
     [1447, 556],
     [1446, 563],
     [1477, 586],
     [1488, 584],
     [1515, 571],
     [1558, 566],
     [1579, 568],
     [1636, 588],
     [1690, 579],
     [1711, 561],
     [1697, 552],
     [1638, 529],
     [1526, 503]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1736, 552]}],
   "box": [1529, 463, 1863, 680],
   "polygons": [
    [
     [1545, 464],
     [1536, 468],
     [1529, 481],
     [1534, 498],
     [1696, 545],
     [1755, 573],
     [1764, 582],
     [1770, 624],
     [1812, 677],
     [1820, 680],
     [1863, 676],
     [1841, 604],
     [1843, 577],
     [1804, 546],
     [1820, 518],
     [1811, 482],
     [1778, 474],
     [1770, 476],
     [1744, 517],
     [1737, 522],
     [1619, 480],
     [1585, 465],
     [1572, 463]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1884, 606]}],
   "box": [1820, 511, 1920, 743],
   "polygons": [
    [
     [1862, 512],
     [1836, 532],
     [1820, 548],
     [1821, 553],
     [1871, 590],
     [1865, 605],
     [1870, 631],
     [1899, 691],
     [1898, 698],
     [1885, 715],
     [1889, 721],
     [1920, 743],
     [1919, 657],
     [1903, 628],
     [1905, 623],
     [1915, 619],
     [1914, 587],
     [1909, 579],
     [1879, 560],
     [1905, 544],
     [1895, 523],
     [1864, 511]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1111, 657]}],
   "box": [936, 578, 1329, 752],
   "polygons": [
    [
     [1093, 579],
     [1047, 588],
     [942, 630],
     [936, 658],
     [937, 726],
     [942, 737],
     [1024, 752],
     [1072, 747],
     [1139, 726],
     [1285, 669],
     [1318, 653],
     [1329, 642],
     [1307, 597],
     [1292, 587],
     [1125, 578]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 691]}],
   "box": [429, 630, 621, 780],
   "polygons": [
    [
     [606, 631],
     [450, 637],
     [429, 649],
     [439, 677],
     [465, 685],
     [485, 704],
     [489, 727],
     [513, 751],
     [520, 780],
     [579, 770],
     [588, 766],
     [578, 713],
     [579, 679],
     [595, 650],
     [621, 632],
     [615, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [317, 725]}],
   "box": [139, 645, 505, 795],
   "polygons": [
    [
     [307, 646],
     [163, 680],
     [139, 690],
     [159, 736],
     [193, 791],
     [206, 795],
     [252, 794],
     [467, 782],
     [495, 779],
     [505, 775],
     [502, 743],
     [477, 733],
     [463, 691],
     [425, 688],
     [419, 650],
     [362, 662],
     [344, 658],
     [319, 645]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [740, 693]}],
   "box": [589, 624, 905, 772],
   "polygons": [
    [
     [726, 625],
     [684, 629],
     [607, 648],
     [595, 655],
     [589, 695],
     [590, 718],
     [597, 767],
     [601, 772],
     [715, 758],
     [846, 747],
     [883, 730],
     [898, 717],
     [905, 697],
     [904, 670],
     [895, 643],
     [827, 624]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [953, 591]}],
   "box": [847, 546, 1063, 634],
   "polygons": [
    [
     [1046, 547],
     [883, 589],
     [854, 598],
     [847, 603],
     [853, 615],
     [870, 627],
     [891, 634],
     [907, 632],
     [1052, 576],
     [1062, 566],
     [1063, 560],
     [1058, 546]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1145, 553]}],
   "box": [1070, 522, 1225, 575],
   "polygons": [
    [
     [1160, 523],
     [1070, 542],
     [1073, 574],
     [1189, 575],
     [1209, 572],
     [1225, 563],
     [1223, 555],
     [1217, 549],
     [1190, 529],
     [1175, 522]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1570, 550]}],
   "box": [1444, 505, 1710, 590],
   "polygons": [
    [
     [1517, 506],
     [1499, 511],
     [1476, 536],
     [1447, 557],
     [1444, 560],
     [1445, 564],
     [1478, 588],
     [1516, 572],
     [1563, 567],
     [1577, 569],
     [1638, 590],
     [1691, 580],
     [1710, 563],
     [1702, 556],
     [1639, 531],
     [1529, 505]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1736, 553]}],
   "box": [1529, 465, 1862, 681],
   "polygons": [
    [
     [1542, 466],
     [1535, 471],
     [1529, 482],
     [1533, 499],
     [1699, 548],
     [1755, 575],
     [1764, 585],
     [1767, 618],
     [1771, 629],
     [1810, 678],
     [1816, 681],
     [1862, 677],
     [1841, 609],
     [1842, 578],
     [1804, 549],
     [1820, 520],
     [1810, 483],
     [1779, 476],
     [1770, 478],
     [1743, 520],
     [1736, 524],
     [1578, 465]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1883, 609]}],
   "box": [1819, 513, 1919, 745],
   "polygons": [
    [
     [1862, 514],
     [1833, 536],
     [1819, 552],
     [1871, 592],
     [1864, 608],
     [1869, 632],
     [1898, 694],
     [1896, 701],
     [1885, 713],
     [1885, 720],
     [1919, 745],
     [1919, 661],
     [1902, 628],
     [1914, 621],
     [1913, 586],
     [1879, 563],
     [1882, 558],
     [1905, 546],
     [1894, 524],
     [1865, 513]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1110, 658]}],
   "box": [935, 579, 1327, 752],
   "polygons": [
    [
     [1090, 580],
     [1067, 583],
     [1034, 593],
     [941, 631],
     [935, 657],
     [936, 729],
     [940, 737],
     [1019, 752],
     [1076, 746],
     [1155, 720],
     [1276, 673],
     [1313, 656],
     [1327, 645],
     [1326, 636],
     [1310, 604],
     [1300, 592],
     [1291, 588],
     [1128, 579]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 691]}],
   "box": [429, 630, 621, 779],
   "polygons": [
    [
     [604, 631],
     [448, 637],
     [429, 650],
     [440, 677],
     [466, 685],
     [483, 701],
     [490, 729],
     [514, 753],
     [519, 779],
     [579, 769],
     [587, 765],
     [578, 718],
     [579, 677],
     [597, 647],
     [621, 632],
     [616, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [318, 724]}],
   "box": [141, 644, 504, 794],
   "polygons": [
    [
     [311, 645],
     [157, 681],
     [141, 688],
     [162, 738],
     [193, 789],
     [215, 794],
     [260, 792],
     [464, 781],
     [494, 778],
     [504, 774],
     [502, 744],
     [499, 740],
     [476, 731],
     [463, 691],
     [425, 687],
     [418, 649],
     [368, 661],
     [344, 657],
     [318, 644]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [739, 693]}],
   "box": [588, 624, 903, 771],
   "polygons": [
    [
     [731, 625],
     [679, 630],
     [609, 647],
     [595, 654],
     [588, 698],
     [598, 770],
     [615, 771],
     [708, 758],
     [843, 747],
     [855, 744],
     [881, 730],
     [894, 720],
     [903, 701],
     [903, 673],
     [895, 645],
     [878, 637],
     [824, 624]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [952, 591]}],
   "box": [846, 546, 1062, 634],
   "polygons": [
    [
     [1052, 547],
     [884, 589],
     [849, 600],
     [846, 604],
     [854, 618],
     [866, 626],
     [888, 634],
     [907, 632],
     [1049, 578],
     [1059, 571],
     [1062, 564],
     [1059, 547],
     [1054, 546]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1144, 554]}],
   "box": [1069, 523, 1225, 577],
   "polygons": [
    [
     [1160, 524],
     [1069, 543],
     [1072, 574],
     [1109, 577],
     [1186, 576],
     [1211, 572],
     [1224, 564],
     [1225, 561],
     [1215, 549],
     [1191, 531],
     [1175, 523]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1570, 552]}],
   "box": [1444, 506, 1709, 591],
   "polygons": [
    [
     [1519, 507],
     [1498, 513],
     [1478, 535],
     [1444, 560],
     [1444, 565],
     [1476, 589],
     [1512, 574],
     [1555, 569],
     [1578, 571],
     [1634, 591],
     [1689, 582],
     [1709, 565],
     [1708, 561],
     [1700, 557],
     [1640, 533],
     [1525, 506]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1735, 555]}],
   "box": [1528, 466, 1861, 683],
   "polygons": [
    [
     [1546, 467],
     [1539, 468],
     [1529, 483],
     [1528, 488],
     [1533, 501],
     [1697, 549],
     [1755, 577],
     [1763, 586],
     [1769, 629],
     [1808, 679],
     [1815, 683],
     [1860, 680],
     [1861, 676],
     [1840, 610],
     [1842, 581],
     [1804, 551],
     [1806, 543],
     [1820, 521],
     [1812, 486],
     [1781, 478],
     [1771, 479],
     [1738, 525],
     [1722, 522],
     [1582, 467],
     [1558, 466]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1883, 612]}],
   "box": [1819, 515, 1920, 748],
   "polygons": [
    [
     [1862, 516],
     [1834, 537],
     [1819, 553],
     [1827, 562],
     [1870, 593],
     [1864, 608],
     [1868, 633],
     [1896, 693],
     [1895, 702],
     [1882, 718],
     [1892, 729],
     [1920, 748],
     [1919, 664],
     [1902, 632],
     [1903, 627],
     [1914, 622],
     [1913, 589],
     [1910, 584],
     [1879, 565],
     [1880, 561],
     [1905, 548],
     [1896, 527],
     [1865, 515]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1109, 658]}],
   "box": [934, 580, 1326, 752],
   "polygons": [
    [
     [1086, 581],
     [1065, 584],
     [1029, 595],
     [940, 631],
     [934, 652],
     [934, 727],
     [937, 736],
     [966, 744],
     [1016, 752],
     [1066, 748],
     [1135, 727],
     [1256, 681],
     [1313, 656],
     [1326, 645],
     [1310, 607],
     [1305, 599],
     [1293, 590],
     [1265, 586],
     [1131, 580]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 690]}],
   "box": [430, 630, 621, 778],
   "polygons": [
    [
     [600, 631],
     [455, 636],
     [444, 638],
     [430, 649],
     [441, 677],
     [464, 683],
     [484, 702],
     [489, 727],
     [513, 752],
     [518, 778],
     [579, 768],
     [586, 764],
     [577, 715],
     [578, 680],
     [595, 649],
     [621, 632],
     [617, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [318, 724]}],
   "box": [142, 644, 503, 792],
   "polygons": [
    [
     [309, 645],
     [159, 680],
     [142, 688],
     [159, 730],
     [194, 788],
     [207, 792],
     [256, 791],
     [465, 780],
     [503, 774],
     [501, 743],
     [476, 731],
     [463, 691],
     [425, 687],
     [419, 649],
     [364, 661],
     [345, 657],
     [320, 644]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [738, 693]}],
   "box": [587, 624, 901, 771],
   "polygons": [
    [
     [753, 625],
     [689, 628],
     [605, 648],
     [595, 653],
     [587, 699],
     [596, 768],
     [600, 771],
     [617, 770],
     [704, 758],
     [841, 747],
     [853, 744],
     [878, 731],
     [895, 717],
     [901, 704],
     [901, 668],
     [893, 644],
     [868, 635],
     [819, 624]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [951, 592]}],
   "box": [847, 547, 1061, 635],
   "polygons": [
    [
     [1049, 548],
     [871, 593],
     [847, 601],
     [847, 608],
     [852, 617],
     [864, 626],
     [890, 635],
     [904, 633],
     [1053, 576],
     [1061, 566],
     [1060, 553],
     [1055, 547]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1144, 555]}],
   "box": [1068, 524, 1223, 578],
   "polygons": [
    [
     [1159, 525],
     [1070, 543],
     [1068, 546],
     [1071, 575],
     [1116, 578],
     [1184, 577],
     [1210, 573],
     [1223, 565],
     [1222, 558],
     [1213, 549],
     [1174, 524]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1569, 553]}],
   "box": [1444, 508, 1708, 592],
   "polygons": [
    [
     [1515, 509],
     [1498, 514],
     [1472, 541],
     [1445, 560],
     [1444, 567],
     [1475, 590],
     [1513, 575],
     [1558, 570],
     [1576, 572],
     [1631, 592],
     [1683, 585],
     [1692, 581],
     [1708, 567],
     [1706, 562],
     [1698, 558],
     [1638, 534],
     [1528, 508]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1735, 556]}],
   "box": [1528, 468, 1859, 684],
   "polygons": [
    [
     [1541, 469],
     [1536, 472],
     [1528, 486],
     [1534, 503],
     [1695, 550],
     [1755, 579],
     [1762, 587],
     [1767, 629],
     [1807, 681],
     [1812, 684],
     [1859, 681],
     [1839, 611],
     [1841, 582],
     [1803, 551],
     [1820, 522],
     [1811, 487],
     [1776, 479],
     [1769, 482],
     [1742, 524],
     [1736, 527],
     [1578, 468]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1883, 615]}],
   "box": [1819, 517, 1920, 750],
   "polygons": [
    [
     [1862, 518],
     [1831, 541],
     [1819, 554],
     [1822, 560],
     [1870, 596],
     [1863, 610],
     [1867, 634],
     [1895, 695],
     [1894, 703],
     [1881, 718],
     [1882, 723],
     [1920, 750],
     [1919, 668],
     [1901, 634],
     [1903, 628],
     [1913, 624],
     [1912, 589],
     [1879, 567],
     [1881, 562],
     [1905, 549],
     [1896, 529],
     [1866, 517]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1107, 659]}],
   "box": [931, 580, 1325, 752],
   "polygons": [
    [
     [1099, 581],
     [1051, 588],
     [940, 630],
     [934, 644],
     [932, 667],
     [931, 721],
     [934, 734],
     [945, 740],
     [1011, 752],
     [1047, 751],
     [1070, 747],
     [1143, 724],
     [1284, 670],
     [1313, 656],
     [1325, 644],
     [1307, 605],
     [1299, 595],
     [1289, 590],
     [1108, 580]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 690]}],
   "box": [430, 630, 620, 776],
   "polygons": [
    [
     [603, 631],
     [453, 636],
     [444, 638],
     [430, 649],
     [440, 676],
     [466, 684],
     [484, 702],
     [489, 727],
     [512, 750],
     [515, 776],
     [528, 776],
     [585, 764],
     [577, 720],
     [578, 677],
     [594, 650],
     [620, 632],
     [615, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [318, 723]}],
   "box": [143, 644, 503, 791],
   "polygons": [
    [
     [307, 645],
     [161, 679],
     [143, 687],
     [160, 729],
     [192, 785],
     [210, 791],
     [250, 790],
     [466, 779],
     [494, 776],
     [503, 772],
     [500, 741],
     [476, 731],
     [463, 691],
     [425, 686],
     [420, 649],
     [370, 660],
     [350, 658],
     [322, 644]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [737, 693]}],
   "box": [586, 624, 901, 771],
   "polygons": [
    [
     [778, 625],
     [695, 627],
     [596, 652],
     [592, 659],
     [586, 702],
     [595, 768],
     [602, 771],
     [708, 757],
     [838, 747],
     [856, 742],
     [883, 727],
     [895, 715],
     [901, 696],
     [900, 669],
     [893, 646],
     [889, 642],
     [862, 634],
     [812, 624]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [950, 592]}],
   "box": [845, 548, 1061, 635],
   "polygons": [
    [
     [1047, 549],
     [883, 590],
     [850, 600],
     [845, 604],
     [851, 617],
     [867, 628],
     [887, 635],
     [905, 633],
     [1050, 578],
     [1060, 568],
     [1061, 560],
     [1055, 548]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1143, 555]}],
   "box": [1067, 525, 1223, 579],
   "polygons": [
    [
     [1158, 526],
     [1069, 544],
     [1067, 548],
     [1070, 576],
     [1123, 579],
     [1181, 578],
     [1209, 574],
     [1223, 565],
     [1219, 556],
     [1211, 549],
     [1174, 525]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1568, 554]}],
   "box": [1443, 509, 1708, 594],
   "polygons": [
    [
     [1517, 510],
     [1498, 515],
     [1474, 540],
     [1443, 562],
     [1443, 568],
     [1476, 592],
     [1513, 576],
     [1564, 571],
     [1633, 594],
     [1687, 585],
     [1708, 567],
     [1703, 562],
     [1642, 537],
     [1526, 509]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1734, 558]}],
   "box": [1529, 469, 1857, 686],
   "polygons": [
    [
     [1545, 470],
     [1537, 472],
     [1529, 484],
     [1529, 497],
     [1533, 504],
     [1693, 551],
     [1755, 581],
     [1761, 588],
     [1765, 628],
     [1800, 676],
     [1813, 686],
     [1857, 683],
     [1856, 671],
     [1838, 612],
     [1840, 583],
     [1803, 554],
     [1819, 526],
     [1811, 489],
     [1779, 481],
     [1770, 483],
     [1742, 525],
     [1734, 529],
     [1587, 472],
     [1557, 469]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1883, 618]}],
   "box": [1818, 518, 1920, 751],
   "polygons": [
    [
     [1863, 519],
     [1834, 540],
     [1818, 557],
     [1869, 597],
     [1862, 612],
     [1866, 635],
     [1894, 698],
     [1891, 707],
     [1879, 721],
     [1885, 729],
     [1916, 751],
     [1920, 750],
     [1919, 672],
     [1900, 635],
     [1901, 630],
     [1912, 626],
     [1912, 593],
     [1907, 586],
     [1878, 568],
     [1880, 564],
     [1902, 554],
     [1903, 546],
     [1895, 530],
     [1863, 518]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1106, 659]}],
   "box": [931, 581, 1323, 753],
   "polygons": [
    [
     [1092, 582],
     [1049, 589],
     [941, 629],
     [933, 643],
     [931, 662],
     [933, 735],
     [947, 741],
     [1007, 752],
     [1023, 753],
     [1065, 748],
     [1137, 726],
     [1267, 677],
     [1308, 659],
     [1323, 647],
     [1322, 639],
     [1306, 606],
     [1298, 596],
     [1288, 591],
     [1115, 581]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 689]}],
   "box": [430, 630, 620, 777],
   "polygons": [
    [
     [604, 631],
     [452, 636],
     [444, 638],
     [430, 649],
     [441, 676],
     [465, 683],
     [484, 702],
     [488, 725],
     [511, 749],
     [514, 773],
     [519, 777],
     [576, 767],
     [584, 763],
     [576, 718],
     [578, 675],
     [595, 648],
     [620, 632],
     [614, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [318, 722]}],
   "box": [144, 643, 502, 790],
   "polygons": [
    [
     [311, 644],
     [162, 678],
     [144, 686],
     [159, 724],
     [193, 784],
     [218, 790],
     [465, 778],
     [493, 775],
     [502, 771],
     [500, 742],
     [497, 738],
     [475, 730],
     [462, 689],
     [425, 686],
     [419, 648],
     [365, 660],
     [346, 656],
     [321, 643]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [736, 693]}],
   "box": [586, 625, 899, 769],
   "polygons": [
    [
     [708, 626],
     [623, 643],
     [594, 653],
     [586, 695],
     [594, 768],
     [613, 769],
     [713, 756],
     [835, 747],
     [852, 743],
     [878, 729],
     [892, 717],
     [899, 700],
     [899, 671],
     [891, 645],
     [878, 639],
     [822, 625]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [950, 593]}],
   "box": [844, 549, 1059, 636],
   "polygons": [
    [
     [1045, 550],
     [880, 591],
     [844, 604],
     [850, 617],
     [861, 626],
     [890, 636],
     [902, 634],
     [1047, 580],
     [1059, 569],
     [1059, 557],
     [1055, 549]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1142, 556]}],
   "box": [1068, 526, 1222, 579],
   "polygons": [
    [
     [1158, 527],
     [1068, 545],
     [1069, 576],
     [1089, 579],
     [1177, 579],
     [1208, 575],
     [1222, 566],
     [1219, 558],
     [1209, 549],
     [1173, 526]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1568, 556]}],
   "box": [1441, 510, 1707, 595],
   "polygons": [
    [
     [1520, 511],
     [1498, 516],
     [1476, 539],
     [1444, 562],
     [1441, 565],
     [1442, 569],
     [1474, 593],
     [1510, 578],
     [1553, 573],
     [1575, 575],
     [1630, 595],
     [1685, 587],
     [1707, 569],
     [1701, 563],
     [1633, 536],
     [1521, 510]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1733, 560]}],
   "box": [1527, 471, 1856, 687],
   "polygons": [
    [
     [1541, 472],
     [1534, 477],
     [1527, 491],
     [1533, 506],
     [1696, 554],
     [1753, 582],
     [1760, 589],
     [1764, 630],
     [1803, 683],
     [1809, 687],
     [1856, 684],
     [1837, 613],
     [1840, 586],
     [1802, 555],
     [1819, 527],
     [1814, 497],
     [1810, 490],
     [1780, 483],
     [1770, 485],
     [1743, 525],
     [1736, 530],
     [1721, 527],
     [1576, 471]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1882, 621]}],
   "box": [1818, 520, 1920, 753],
   "polygons": [
    [
     [1862, 521],
     [1831, 544],
     [1818, 558],
     [1823, 565],
     [1868, 598],
     [1861, 615],
     [1865, 637],
     [1892, 698],
     [1890, 708],
     [1878, 721],
     [1879, 726],
     [1916, 753],
     [1920, 752],
     [1919, 676],
     [1899, 636],
     [1900, 632],
     [1911, 628],
     [1912, 625],
     [1911, 593],
     [1878, 570],
     [1881, 565],
     [1904, 553],
     [1895, 532],
     [1864, 520]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1105, 660]}],
   "box": [928, 582, 1322, 753],
   "polygons": [
    [
     [1086, 583],
     [1047, 590],
     [939, 630],
     [934, 637],
     [930, 657],
     [928, 715],
     [932, 736],
     [954, 743],
     [1016, 753],
     [1069, 747],
     [1142, 724],
     [1270, 676],
     [1308, 659],
     [1322, 647],
     [1304, 606],
     [1297, 597],
     [1287, 592],
     [1119, 582]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 689]}],
   "box": [431, 630, 620, 776],
   "polygons": [
    [
     [600, 631],
     [450, 636],
     [431, 647],
     [439, 674],
     [464, 682],
     [483, 700],
     [488, 726],
     [510, 748],
     [514, 775],
     [517, 776],
     [583, 763],
     [575, 715],
     [577, 677],
     [593, 650],
     [620, 632],
     [615, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [319, 721]}],
   "box": [146, 643, 501, 788],
   "polygons": [
    [
     [308, 644],
     [164, 677],
     [146, 685],
     [169, 739],
     [196, 784],
     [212, 788],
     [253, 787],
     [464, 777],
     [501, 771],
     [499, 739],
     [475, 729],
     [461, 688],
     [426, 686],
     [420, 648],
     [371, 659],
     [346, 655],
     [324, 643]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [735, 692]}],
   "box": [585, 625, 898, 768],
   "polygons": [
    [
     [712, 626],
     [689, 628],
     [614, 645],
     [593, 654],
     [585, 700],
     [594, 768],
     [840, 746],
     [880, 727],
     [894, 712],
     [898, 699],
     [897, 665],
     [890, 645],
     [869, 637],
     [818, 625]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [949, 594]}],
   "box": [844, 550, 1059, 636],
   "polygons": [
    [
     [1044, 551],
     [864, 596],
     [844, 603],
     [845, 609],
     [855, 623],
     [866, 629],
     [886, 636],
     [902, 634],
     [1048, 580],
     [1059, 568],
     [1058, 555],
     [1054, 550]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1141, 557]}],
   "box": [1067, 527, 1220, 580],
   "polygons": [
    [
     [1158, 528],
     [1067, 546],
     [1069, 578],
     [1177, 580],
     [1207, 576],
     [1220, 569],
     [1220, 562],
     [1212, 553],
     [1182, 531],
     [1172, 527]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1567, 557]}],
   "box": [1440, 512, 1706, 597],
   "polygons": [
    [
     [1516, 513],
     [1496, 519],
     [1474, 542],
     [1444, 563],
     [1440, 567],
     [1442, 572],
     [1472, 594],
     [1511, 579],
     [1562, 574],
     [1631, 597],
     [1686, 588],
     [1706, 571],
     [1701, 565],
     [1637, 539],
     [1525, 512]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1733, 562]}],
   "box": [1527, 473, 1855, 689],
   "polygons": [
    [
     [1540, 474],
     [1531, 483],
     [1527, 492],
     [1532, 507],
     [1691, 554],
     [1753, 584],
     [1760, 593],
     [1764, 634],
     [1802, 685],
     [1809, 689],
     [1855, 686],
     [1836, 613],
     [1839, 586],
     [1802, 557],
     [1819, 529],
     [1812, 493],
     [1780, 485],
     [1770, 487],
     [1742, 528],
     [1736, 532],
     [1724, 530],
     [1579, 473]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1882, 623]}],
   "box": [1818, 522, 1920, 755],
   "polygons": [
    [
     [1863, 523],
     [1831, 546],
     [1818, 560],
     [1823, 567],
     [1868, 601],
     [1861, 615],
     [1864, 637],
     [1891, 700],
     [1890, 708],
     [1876, 725],
     [1889, 738],
     [1915, 755],
     [1920, 753],
     [1919, 680],
     [1899, 640],
     [1900, 634],
     [1911, 629],
     [1911, 596],
     [1908, 591],
     [1878, 572],
     [1881, 567],
     [1903, 557],
     [1903, 550],
     [1895, 534],
     [1864, 522]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1103, 661]}],
   "box": [927, 583, 1321, 753],
   "polygons": [
    [
     [1084, 584],
     [1039, 593],
     [936, 632],
     [929, 656],
     [927, 704],
     [930, 736],
     [961, 745],
     [1011, 753],
     [1064, 748],
     [1139, 725],
     [1263, 679],
     [1303, 662],
     [1316, 654],
     [1321, 647],
     [1303, 607],
     [1297, 599],
     [1285, 593],
     [1120, 583]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 689]}],
   "box": [431, 630, 620, 775],
   "polygons": [
    [
     [600, 631],
     [449, 636],
     [431, 648],
     [440, 674],
     [465, 682],
     [484, 701],
     [487, 723],
     [509, 746],
     [513, 774],
     [516, 775],
     [582, 762],
     [575, 718],
     [577, 676],
     [595, 647],
     [620, 632],
     [614, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [319, 720]}],
   "box": [148, 642, 500, 787],
   "polygons": [
    [
     [316, 643],
     [175, 674],
     [148, 683],
     [161, 721],
     [195, 782],
     [212, 787],
     [254, 786],
     [469, 776],
     [500, 771],
     [498, 738],
     [474, 728],
     [462, 688],
     [425, 684],
     [420, 648],
     [370, 659],
     [347, 655],
     [321, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [734, 693]}],
   "box": [584, 625, 898, 768],
   "polygons": [
    [
     [764, 626],
     [690, 628],
     [606, 647],
     [593, 653],
     [584, 701],
     [592, 767],
     [611, 768],
     [705, 756],
     [839, 746],
     [884, 723],
     [894, 709],
     [898, 683],
     [895, 662],
     [889, 646],
     [828, 627],
     [803, 625]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [948, 594]}],
   "box": [843, 551, 1058, 637],
   "polygons": [
    [
     [1044, 552],
     [876, 593],
     [849, 601],
     [843, 604],
     [844, 609],
     [853, 623],
     [866, 630],
     [887, 637],
     [900, 635],
     [1047, 581],
     [1058, 569],
     [1057, 555],
     [1053, 551]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1141, 558]}],
   "box": [1065, 528, 1220, 581],
   "polygons": [
    [
     [1159, 529],
     [1067, 547],
     [1065, 551],
     [1068, 579],
     [1179, 581],
     [1207, 577],
     [1220, 569],
     [1219, 563],
     [1212, 555],
     [1181, 532],
     [1171, 528]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1566, 559]}],
   "box": [1441, 514, 1706, 599],
   "polygons": [
    [
     [1513, 515],
     [1496, 520],
     [1476, 541],
     [1441, 566],
     [1441, 573],
     [1472, 596],
     [1512, 580],
     [1553, 576],
     [1573, 578],
     [1633, 599],
     [1684, 590],
     [1706, 572],
     [1694, 564],
     [1634, 540],
     [1526, 514]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1732, 563]}],
   "box": [1527, 474, 1853, 691],
   "polygons": [
    [
     [1543, 475],
     [1534, 480],
     [1527, 492],
     [1532, 509],
     [1691, 556],
     [1749, 584],
     [1759, 594],
     [1762, 634],
     [1799, 685],
     [1809, 691],
     [1853, 688],
     [1835, 615],
     [1838, 588],
     [1802, 560],
     [1804, 552],
     [1819, 530],
     [1812, 495],
     [1780, 487],
     [1770, 489],
     [1743, 528],
     [1735, 534],
     [1609, 488],
     [1584, 476],
     [1561, 474]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1882, 626]}],
   "box": [1817, 524, 1919, 755],
   "polygons": [
    [
     [1862, 525],
     [1853, 534],
     [1833, 546],
     [1817, 562],
     [1825, 571],
     [1867, 602],
     [1860, 617],
     [1863, 639],
     [1890, 704],
     [1874, 727],
     [1906, 754],
     [1918, 755],
     [1919, 684],
     [1898, 642],
     [1900, 635],
     [1910, 631],
     [1910, 597],
     [1877, 574],
     [1880, 569],
     [1904, 557],
     [1895, 536],
     [1864, 524]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1102, 661]}],
   "box": [925, 584, 1319, 753],
   "polygons": [
    [
     [1082, 585],
     [1044, 592],
     [937, 631],
     [931, 640],
     [928, 654],
     [925, 717],
     [929, 737],
     [1004, 753],
     [1042, 752],
     [1065, 748],
     [1122, 731],
     [1264, 679],
     [1307, 660],
     [1319, 650],
     [1304, 612],
     [1299, 604],
     [1287, 595],
     [1119, 584]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 689]}],
   "box": [432, 631, 618, 774],
   "polygons": [
    [
     [566, 632],
     [450, 636],
     [432, 646],
     [440, 674],
     [465, 682],
     [483, 700],
     [487, 724],
     [510, 748],
     [513, 774],
     [581, 762],
     [574, 715],
     [576, 678],
     [594, 648],
     [618, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [320, 719]}],
   "box": [149, 642, 500, 786],
   "polygons": [
    [
     [312, 643],
     [177, 673],
     [149, 683],
     [163, 722],
     [196, 781],
     [217, 786],
     [247, 785],
     [470, 775],
     [500, 769],
     [498, 739],
     [474, 728],
     [462, 688],
     [426, 685],
     [421, 648],
     [375, 658],
     [355, 657],
     [324, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [734, 693]}],
   "box": [584, 626, 895, 769],
   "polygons": [
    [
     [701, 627],
     [609, 646],
     [592, 654],
     [584, 696],
     [589, 759],
     [592, 767],
     [599, 769],
     [700, 756],
     [837, 746],
     [876, 728],
     [889, 716],
     [895, 702],
     [895, 668],
     [888, 646],
     [878, 641],
     [818, 626]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [947, 595]}],
   "box": [842, 552, 1058, 637],
   "polygons": [
    [
     [1042, 553],
     [881, 592],
     [847, 602],
     [842, 606],
     [849, 620],
     [860, 628],
     [885, 637],
     [901, 635],
     [1049, 580],
     [1056, 574],
     [1058, 567],
     [1056, 554],
     [1053, 552]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1140, 559]}],
   "box": [1066, 529, 1219, 582],
   "polygons": [
    [
     [1158, 530],
     [1066, 548],
     [1067, 579],
     [1100, 582],
     [1175, 582],
     [1206, 578],
     [1219, 570],
     [1217, 562],
     [1209, 554],
     [1182, 534],
     [1170, 529]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1566, 560]}],
   "box": [1439, 515, 1705, 600],
   "polygons": [
    [
     [1515, 516],
     [1495, 522],
     [1470, 547],
     [1442, 566],
     [1439, 569],
     [1440, 574],
     [1471, 597],
     [1508, 582],
     [1558, 577],
     [1571, 579],
     [1629, 600],
     [1682, 592],
     [1705, 574],
     [1697, 567],
     [1632, 541],
     [1524, 515]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1732, 565]}],
   "box": [1526, 476, 1852, 692],
   "polygons": [
    [
     [1540, 477],
     [1527, 493],
     [1526, 498],
     [1531, 510],
     [1692, 558],
     [1751, 587],
     [1758, 594],
     [1761, 635],
     [1799, 688],
     [1805, 692],
     [1852, 689],
     [1835, 620],
     [1838, 591],
     [1801, 561],
     [1819, 532],
     [1811, 496],
     [1782, 489],
     [1770, 491],
     [1741, 532],
     [1732, 536],
     [1577, 476]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1881, 629]}],
   "box": [1817, 526, 1920, 758],
   "polygons": [
    [
     [1862, 527],
     [1829, 551],
     [1817, 564],
     [1866, 603],
     [1866, 609],
     [1859, 621],
     [1863, 644],
     [1888, 702],
     [1886, 713],
     [1873, 728],
     [1901, 753],
     [1910, 758],
     [1916, 757],
     [1920, 755],
     [1919, 688],
     [1897, 643],
     [1898, 638],
     [1908, 634],
     [1910, 630],
     [1910, 600],
     [1906, 594],
     [1877, 576],
     [1878, 572],
     [1904, 559],
     [1895, 538],
     [1865, 526]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1101, 662]}],
   "box": [925, 585, 1318, 753],
   "polygons": [
    [
     [1078, 586],
     [1039, 594],
     [934, 633],
     [928, 648],
     [925, 687],
     [925, 731],
     [929, 738],
     [1001, 753],
     [1031, 753],
     [1060, 749],
     [1139, 725],
     [1262, 680],
     [1302, 663],
     [1318, 650],
     [1303, 613],
     [1298, 605],
     [1286, 596],
     [1122, 585]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [432, 631, 618, 774],
   "polygons": [
    [
     [564, 632],
     [457, 635],
     [446, 637],
     [432, 646],
     [440, 673],
     [466, 682],
     [484, 701],
     [487, 724],
     [508, 745],
     [514, 774],
     [574, 764],
     [581, 760],
     [574, 719],
     [576, 676],
     [593, 649],
     [618, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [320, 719]}],
   "box": [150, 642, 499, 785],
   "polygons": [
    [
     [311, 643],
     [184, 671],
     [150, 682],
     [162, 717],
     [197, 780],
     [219, 785],
     [458, 775],
     [499, 769],
     [497, 737],
     [474, 728],
     [462, 688],
     [426, 685],
     [421, 648],
     [373, 658],
     [353, 656],
     [325, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [733, 693]}],
   "box": [583, 626, 894, 767],
   "polygons": [
    [
     [706, 627],
     [609, 646],
     [591, 655],
     [583, 698],
     [588, 760],
     [591, 767],
     [836, 746],
     [873, 729],
     [887, 717],
     [894, 699],
     [894, 670],
     [887, 647],
     [861, 637],
     [811, 626]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [947, 596]}],
   "box": [843, 553, 1057, 638],
   "polygons": [
    [
     [1041, 554],
     [854, 600],
     [843, 604],
     [843, 611],
     [853, 625],
     [867, 632],
     [886, 638],
     [902, 635],
     [1048, 581],
     [1057, 569],
     [1053, 553]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1139, 560]}],
   "box": [1065, 530, 1219, 584],
   "polygons": [
    [
     [1159, 531],
     [1065, 549],
     [1066, 580],
     [1135, 584],
     [1176, 583],
     [1202, 580],
     [1219, 570],
     [1216, 563],
     [1198, 547],
     [1176, 532],
     [1168, 530]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1565, 562]}],
   "box": [1439, 517, 1704, 602],
   "polygons": [
    [
     [1513, 518],
     [1495, 523],
     [1470, 548],
     [1439, 569],
     [1439, 575],
     [1469, 598],
     [1509, 583],
     [1550, 579],
     [1571, 581],
     [1631, 602],
     [1683, 593],
     [1704, 576],
     [1702, 572],
     [1690, 566],
     [1629, 542],
     [1525, 517]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1731, 567]}],
   "box": [1527, 477, 1851, 694],
   "polygons": [
    [
     [1545, 478],
     [1538, 479],
     [1527, 493],
     [1527, 504],
     [1531, 512],
     [1692, 560],
     [1749, 588],
     [1757, 596],
     [1761, 640],
     [1796, 688],
     [1805, 694],
     [1851, 690],
     [1834, 622],
     [1837, 592],
     [1801, 564],
     [1802, 557],
     [1818, 535],
     [1811, 498],
     [1782, 491],
     [1771, 492],
     [1742, 532],
     [1735, 537],
     [1712, 531],
     [1580, 478],
     [1551, 477]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1881, 632]}],
   "box": [1817, 528, 1920, 760],
   "polygons": [
    [
     [1862, 529],
     [1832, 550],
     [1817, 565],
     [1820, 571],
     [1866, 606],
     [1859, 620],
     [1862, 646],
     [1887, 705],
     [1885, 714],
     [1873, 726],
     [1873, 733],
     [1901, 756],
     [1910, 760],
     [1920, 756],
     [1919, 692],
     [1896, 645],
     [1897, 639],
     [1909, 633],
     [1909, 600],
     [1877, 578],
     [1879, 573],
     [1903, 562],
     [1902, 555],
     [1895, 540],
     [1865, 528]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1099, 663]}],
   "box": [922, 586, 1317, 754],
   "polygons": [
    [
     [1077, 587],
     [1044, 593],
     [935, 632],
     [928, 643],
     [925, 662],
     [922, 717],
     [925, 736],
     [934, 741],
     [1005, 754],
     [1060, 749],
     [1142, 724],
     [1263, 680],
     [1306, 661],
     [1317, 650],
     [1300, 611],
     [1292, 601],
     [1284, 597],
     [1121, 586]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [432, 631, 618, 773],
   "polygons": [
    [
     [571, 632],
     [458, 635],
     [446, 637],
     [432, 647],
     [440, 673],
     [466, 682],
     [482, 698],
     [488, 726],
     [509, 748],
     [512, 773],
     [579, 761],
     [573, 717],
     [576, 675],
     [592, 650],
     [618, 633],
     [616, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [320, 718]}],
   "box": [151, 642, 498, 784],
   "polygons": [
    [
     [313, 643],
     [180, 672],
     [158, 678],
     [151, 683],
     [166, 722],
     [198, 779],
     [220, 784],
     [466, 774],
     [498, 769],
     [497, 738],
     [474, 728],
     [463, 689],
     [426, 684],
     [422, 648],
     [374, 658],
     [354, 656],
     [326, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [732, 693]}],
   "box": [582, 627, 893, 767],
   "polygons": [
    [
     [699, 628],
     [611, 646],
     [597, 650],
     [591, 655],
     [582, 705],
     [587, 758],
     [591, 767],
     [696, 756],
     [830, 747],
     [843, 744],
     [871, 730],
     [886, 717],
     [893, 699],
     [893, 670],
     [886, 647],
     [850, 635],
     [813, 627]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [946, 597]}],
   "box": [841, 554, 1057, 639],
   "polygons": [
    [
     [1043, 555],
     [875, 595],
     [841, 607],
     [848, 622],
     [864, 632],
     [886, 639],
     [901, 636],
     [1048, 582],
     [1054, 577],
     [1057, 567],
     [1055, 557],
     [1051, 554]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1139, 562]}],
   "box": [1065, 532, 1218, 585],
   "polygons": [
    [
     [1155, 533],
     [1065, 550],
     [1066, 582],
     [1119, 585],
     [1184, 584],
     [1206, 580],
     [1218, 572],
     [1208, 557],
     [1183, 538],
     [1170, 532]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1564, 564]}],
   "box": [1437, 519, 1704, 604],
   "polygons": [
    [
     [1512, 520],
     [1496, 524],
     [1473, 547],
     [1441, 569],
     [1437, 573],
     [1439, 578],
     [1468, 600],
     [1508, 585],
     [1549, 581],
     [1571, 583],
     [1629, 604],
     [1682, 595],
     [1704, 578],
     [1696, 571],
     [1635, 546],
     [1525, 519]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1731, 569]}],
   "box": [1526, 480, 1850, 696],
   "polygons": [
    [
     [1539, 481],
     [1526, 498],
     [1531, 514],
     [1691, 562],
     [1748, 590],
     [1757, 600],
     [1759, 639],
     [1793, 688],
     [1803, 696],
     [1850, 692],
     [1833, 622],
     [1837, 595],
     [1800, 564],
     [1819, 534],
     [1812, 501],
     [1781, 493],
     [1772, 494],
     [1743, 533],
     [1732, 540],
     [1578, 480]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1881, 635]}],
   "box": [1817, 530, 1920, 762],
   "polygons": [
    [
     [1863, 531],
     [1834, 551],
     [1817, 567],
     [1823, 576],
     [1866, 609],
     [1858, 623],
     [1861, 646],
     [1886, 708],
     [1884, 716],
     [1870, 732],
     [1880, 743],
     [1908, 762],
     [1920, 758],
     [1919, 696],
     [1895, 645],
     [1896, 642],
     [1908, 637],
     [1909, 604],
     [1905, 598],
     [1876, 579],
     [1904, 562],
     [1896, 543],
     [1863, 530]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1098, 664]}],
   "box": [921, 587, 1315, 754],
   "polygons": [
    [
     [1080, 588],
     [1031, 598],
     [934, 633],
     [929, 639],
     [924, 663],
     [921, 711],
     [923, 736],
     [939, 743],
     [997, 754],
     [1053, 751],
     [1095, 740],
     [1257, 683],
     [1302, 664],
     [1315, 653],
     [1298, 611],
     [1290, 602],
     [1281, 598],
     [1113, 587]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [433, 631, 619, 773],
   "polygons": [
    [
     [598, 632],
     [452, 636],
     [433, 647],
     [441, 674],
     [465, 681],
     [484, 701],
     [486, 722],
     [508, 746],
     [510, 768],
     [513, 773],
     [570, 764],
     [579, 760],
     [573, 720],
     [576, 675],
     [596, 646],
     [619, 633],
     [612, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [321, 718]}],
   "box": [153, 642, 498, 783],
   "polygons": [
    [
     [313, 643],
     [184, 671],
     [161, 677],
     [153, 682],
     [166, 718],
     [199, 778],
     [220, 783],
     [251, 782],
     [455, 774],
     [498, 768],
     [497, 738],
     [474, 728],
     [462, 687],
     [426, 683],
     [423, 648],
     [374, 658],
     [352, 655],
     [328, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [731, 693]}],
   "box": [582, 627, 893, 768],
   "polygons": [
    [
     [707, 628],
     [685, 630],
     [612, 646],
     [591, 655],
     [582, 701],
     [589, 766],
     [595, 768],
     [706, 755],
     [830, 747],
     [845, 743],
     [879, 724],
     [889, 711],
     [893, 693],
     [892, 669],
     [886, 649],
     [872, 642],
     [824, 629],
     [793, 627]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [946, 598]}],
   "box": [842, 555, 1056, 639],
   "polygons": [
    [
     [1044, 556],
     [870, 597],
     [842, 606],
     [842, 612],
     [848, 623],
     [860, 631],
     [882, 639],
     [899, 637],
     [1046, 584],
     [1053, 579],
     [1056, 572],
     [1054, 556],
     [1050, 555]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1138, 563]}],
   "box": [1065, 533, 1217, 586],
   "polygons": [
    [
     [1157, 534],
     [1065, 551],
     [1065, 582],
     [1116, 586],
     [1186, 585],
     [1206, 581],
     [1217, 574],
     [1216, 567],
     [1206, 557],
     [1179, 537],
     [1169, 533]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1564, 566]}],
   "box": [1437, 520, 1703, 605],
   "polygons": [
    [
     [1516, 521],
     [1493, 528],
     [1470, 551],
     [1440, 571],
     [1437, 574],
     [1439, 580],
     [1469, 602],
     [1506, 587],
     [1544, 583],
     [1572, 585],
     [1625, 605],
     [1678, 598],
     [1703, 580],
     [1694, 572],
     [1629, 546],
     [1521, 520]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1731, 571]}],
   "box": [1527, 481, 1849, 698],
   "polygons": [
    [
     [1543, 482],
     [1538, 483],
     [1527, 497],
     [1527, 508],
     [1531, 516],
     [1686, 562],
     [1748, 592],
     [1756, 600],
     [1760, 645],
     [1794, 692],
     [1803, 698],
     [1849, 694],
     [1833, 626],
     [1838, 600],
     [1834, 593],
     [1801, 568],
     [1802, 561],
     [1819, 538],
     [1815, 510],
     [1811, 502],
     [1781, 495],
     [1771, 497],
     [1743, 535],
     [1735, 541],
     [1722, 539],
     [1584, 483],
     [1557, 481]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1881, 637]}],
   "box": [1817, 532, 1920, 764],
   "polygons": [
    [
     [1864, 533],
     [1833, 554],
     [1817, 569],
     [1822, 577],
     [1866, 611],
     [1858, 625],
     [1861, 650],
     [1885, 709],
     [1883, 718],
     [1869, 734],
     [1897, 759],
     [1908, 764],
     [1920, 759],
     [1919, 700],
     [1895, 648],
     [1897, 643],
     [1908, 638],
     [1909, 606],
     [1905, 600],
     [1877, 582],
     [1878, 578],
     [1904, 565],
     [1895, 544],
     [1864, 532]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1097, 665]}],
   "box": [920, 588, 1314, 755],
   "polygons": [
    [
     [1079, 589],
     [1040, 596],
     [933, 634],
     [926, 646],
     [923, 666],
     [920, 711],
     [923, 738],
     [949, 746],
     [1001, 755],
     [1059, 750],
     [1150, 722],
     [1256, 684],
     [1291, 670],
     [1314, 655],
     [1300, 617],
     [1295, 609],
     [1283, 600],
     [1112, 588]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [434, 632, 618, 772],
   "polygons": [
    [
     [559, 633],
     [453, 636],
     [434, 646],
     [441, 673],
     [467, 682],
     [483, 699],
     [487, 724],
     [508, 746],
     [511, 772],
     [578, 760],
     [573, 721],
     [576, 675],
     [594, 648],
     [618, 634],
     [617, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [322, 717]}],
   "box": [154, 642, 497, 782],
   "polygons": [
    [
     [313, 643],
     [179, 672],
     [161, 677],
     [154, 682],
     [167, 717],
     [199, 777],
     [219, 782],
     [255, 781],
     [463, 773],
     [497, 768],
     [496, 736],
     [473, 726],
     [462, 687],
     [426, 683],
     [423, 648],
     [375, 658],
     [353, 655],
     [329, 642]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [730, 694]}],
   "box": [582, 628, 891, 767],
   "polygons": [
    [
     [697, 629],
     [608, 647],
     [591, 655],
     [582, 697],
     [586, 761],
     [590, 767],
     [693, 756],
     [830, 747],
     [842, 744],
     [871, 729],
     [883, 719],
     [891, 700],
     [891, 670],
     [885, 649],
     [837, 633],
     [809, 628]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [945, 599]}],
   "box": [840, 556, 1056, 640],
   "polygons": [
    [
     [1044, 557],
     [865, 599],
     [840, 609],
     [846, 622],
     [862, 633],
     [883, 640],
     [901, 637],
     [1047, 584],
     [1053, 579],
     [1056, 570],
     [1054, 559],
     [1049, 556]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1138, 564]}],
   "box": [1064, 534, 1216, 588],
   "polygons": [
    [
     [1159, 535],
     [1065, 552],
     [1064, 583],
     [1151, 588],
     [1194, 585],
     [1216, 576],
     [1215, 568],
     [1205, 558],
     [1178, 538],
     [1166, 534]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1564, 567]}],
   "box": [1436, 522, 1703, 607],
   "polygons": [
    [
     [1514, 523],
     [1496, 527],
     [1470, 552],
     [1440, 572],
     [1436, 576],
     [1437, 580],
     [1467, 603],
     [1508, 588],
     [1551, 584],
     [1569, 586],
     [1626, 607],
     [1680, 599],
     [1703, 581],
     [1696, 575],
     [1636, 550],
     [1523, 522]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1730, 572]}],
   "box": [1526, 483, 1847, 700],
   "polygons": [
    [
     [1541, 484],
     [1535, 487],
     [1526, 501],
     [1530, 517],
     [1686, 564],
     [1744, 592],
     [1751, 596],
     [1756, 604],
     [1758, 644],
     [1792, 693],
     [1803, 700],
     [1847, 697],
     [1832, 627],
     [1836, 598],
     [1800, 569],
     [1819, 539],
     [1813, 505],
     [1781, 497],
     [1773, 498],
     [1742, 538],
     [1735, 543],
     [1717, 539],
     [1584, 485],
     [1568, 483]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1880, 640]}],
   "box": [1816, 535, 1920, 764],
   "polygons": [
    [
     [1862, 536],
     [1829, 559],
     [1816, 572],
     [1865, 612],
     [1857, 628],
     [1860, 651],
     [1884, 712],
     [1881, 721],
     [1868, 734],
     [1869, 739],
     [1900, 764],
     [1911, 764],
     [1920, 761],
     [1919, 704],
     [1894, 650],
     [1896, 645],
     [1907, 641],
     [1908, 606],
     [1876, 584],
     [1878, 580],
     [1904, 567],
     [1896, 547],
     [1867, 535]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1096, 666]}],
   "box": [918, 589, 1313, 755],
   "polygons": [
    [
     [1079, 590],
     [1036, 598],
     [932, 635],
     [926, 644],
     [922, 665],
     [918, 722],
     [921, 738],
     [928, 742],
     [994, 755],
     [1028, 755],
     [1060, 750],
     [1145, 724],
     [1263, 682],
     [1302, 665],
     [1313, 655],
     [1297, 615],
     [1289, 605],
     [1281, 601],
     [1109, 589]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [434, 632, 619, 772],
   "polygons": [
    [
     [575, 633],
     [455, 636],
     [434, 647],
     [442, 674],
     [464, 680],
     [483, 699],
     [487, 724],
     [508, 747],
     [511, 772],
     [570, 763],
     [578, 759],
     [572, 718],
     [575, 677],
     [591, 652],
     [619, 633],
     [616, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [322, 717]}],
   "box": [156, 641, 497, 781],
   "polygons": [
    [
     [321, 642],
     [182, 671],
     [156, 680],
     [170, 721],
     [200, 776],
     [220, 781],
     [255, 780],
     [468, 772],
     [497, 767],
     [496, 737],
     [473, 726],
     [462, 687],
     [427, 684],
     [423, 648],
     [373, 658],
     [354, 655],
     [326, 641]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [730, 694]}],
   "box": [581, 628, 891, 766],
   "polygons": [
    [
     [700, 629],
     [608, 647],
     [592, 653],
     [581, 699],
     [585, 760],
     [588, 766],
     [702, 755],
     [829, 747],
     [843, 743],
     [877, 724],
     [887, 710],
     [891, 690],
     [889, 665],
     [884, 650],
     [879, 646],
     [827, 631],
     [790, 628]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [944, 599]}],
   "box": [841, 557, 1055, 640],
   "polygons": [
    [
     [1043, 558],
     [863, 600],
     [841, 607],
     [841, 614],
     [845, 622],
     [858, 632],
     [880, 640],
     [895, 639],
     [1046, 585],
     [1055, 573],
     [1053, 559],
     [1049, 557]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1137, 565]}],
   "box": [1064, 535, 1216, 589],
   "polygons": [
    [
     [1160, 536],
     [1064, 553],
     [1064, 585],
     [1150, 589],
     [1186, 587],
     [1205, 583],
     [1216, 576],
     [1214, 569],
     [1193, 550],
     [1177, 539],
     [1164, 535]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1563, 569]}],
   "box": [1436, 524, 1702, 608],
   "polygons": [
    [
     [1511, 525],
     [1495, 529],
     [1470, 553],
     [1437, 575],
     [1436, 581],
     [1468, 605],
     [1504, 590],
     [1544, 586],
     [1570, 588],
     [1623, 608],
     [1677, 601],
     [1702, 583],
     [1696, 577],
     [1639, 553],
     [1525, 524]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1730, 574]}],
   "box": [1525, 485, 1846, 701],
   "polygons": [
    [
     [1539, 486],
     [1525, 504],
     [1530, 519],
     [1689, 567],
     [1742, 593],
     [1755, 605],
     [1756, 644],
     [1790, 694],
     [1799, 701],
     [1846, 698],
     [1831, 628],
     [1835, 600],
     [1800, 572],
     [1801, 565],
     [1818, 543],
     [1813, 507],
     [1782, 499],
     [1772, 500],
     [1743, 538],
     [1734, 545],
     [1720, 542],
     [1576, 485]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1880, 642]}],
   "box": [1816, 536, 1920, 766],
   "polygons": [
    [
     [1864, 537],
     [1835, 556],
     [1816, 573],
     [1824, 583],
     [1864, 614],
     [1856, 630],
     [1859, 653],
     [1882, 711],
     [1880, 722],
     [1866, 736],
     [1867, 740],
     [1899, 766],
     [1908, 766],
     [1920, 762],
     [1919, 708],
     [1893, 651],
     [1895, 647],
     [1906, 643],
     [1908, 610],
     [1904, 604],
     [1876, 586],
     [1877, 582],
     [1902, 571],
     [1902, 564],
     [1896, 549],
     [1864, 536]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1095, 666]}],
   "box": [917, 590, 1312, 756],
   "polygons": [
    [
     [1077, 591],
     [1031, 600],
     [930, 636],
     [924, 647],
     [921, 664],
     [917, 715],
     [920, 739],
     [956, 749],
     [999, 756],
     [1051, 752],
     [1106, 737],
     [1253, 686],
     [1302, 665],
     [1312, 656],
     [1294, 613],
     [1279, 602],
     [1110, 590]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [435, 632, 618, 771],
   "polygons": [
    [
     [583, 633],
     [455, 636],
     [435, 646],
     [441, 672],
     [466, 681],
     [483, 699],
     [487, 724],
     [507, 745],
     [509, 771],
     [567, 763],
     [577, 759],
     [572, 722],
     [575, 676],
     [594, 648],
     [618, 634],
     [614, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [323, 716]}],
   "box": [157, 641, 496, 780],
   "polygons": [
    [
     [319, 642],
     [185, 670],
     [157, 680],
     [170, 717],
     [201, 775],
     [227, 780],
     [471, 771],
     [496, 766],
     [495, 735],
     [473, 726],
     [463, 688],
     [427, 683],
     [424, 648],
     [380, 657],
     [359, 656],
     [329, 641]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [729, 694]}],
   "box": [580, 628, 890, 767],
   "polygons": [
    [
     [709, 629],
     [664, 635],
     [604, 648],
     [591, 654],
     [580, 704],
     [586, 765],
     [593, 767],
     [697, 755],
     [833, 746],
     [869, 729],
     [884, 714],
     [890, 687],
     [888, 666],
     [881, 648],
     [819, 630],
     [736, 628]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [944, 600]}],
   "box": [840, 558, 1054, 641],
   "polygons": [
    [
     [1043, 559],
     [861, 601],
     [840, 608],
     [840, 614],
     [847, 626],
     [863, 635],
     [881, 641],
     [896, 639],
     [1047, 585],
     [1054, 575],
     [1052, 559],
     [1048, 558]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1136, 566]}],
   "box": [1063, 537, 1215, 589],
   "polygons": [
    [
     [1153, 538],
     [1064, 554],
     [1063, 586],
     [1171, 589],
     [1202, 585],
     [1215, 577],
     [1213, 570],
     [1202, 559],
     [1178, 541],
     [1168, 537]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1562, 570]}],
   "box": [1435, 525, 1701, 610],
   "polygons": [
    [
     [1514, 526],
     [1494, 531],
     [1470, 554],
     [1437, 576],
     [1435, 582],
     [1465, 606],
     [1506, 591],
     [1551, 587],
     [1570, 590],
     [1623, 610],
     [1679, 602],
     [1701, 585],
     [1699, 581],
     [1689, 576],
     [1639, 555],
     [1521, 525]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1729, 576]}],
   "box": [1526, 486, 1844, 703],
   "polygons": [
    [
     [1544, 487],
     [1536, 489],
     [1526, 502],
     [1526, 514],
     [1530, 521],
     [1689, 569],
     [1747, 598],
     [1754, 606],
     [1756, 649],
     [1788, 695],
     [1798, 703],
     [1844, 700],
     [1830, 630],
     [1836, 605],
     [1830, 597],
     [1799, 573],
     [1802, 565],
     [1818, 545],
     [1813, 509],
     [1782, 501],
     [1773, 502],
     [1741, 542],
     [1733, 547],
     [1615, 503],
     [1583, 488],
     [1552, 486]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1879, 645]}],
   "box": [1816, 539, 1920, 769],
   "polygons": [
    [
     [1862, 540],
     [1832, 560],
     [1816, 575],
     [1820, 582],
     [1864, 617],
     [1856, 630],
     [1858, 654],
     [1881, 715],
     [1879, 723],
     [1864, 740],
     [1901, 769],
     [1920, 763],
     [1919, 713],
     [1892, 653],
     [1894, 649],
     [1906, 643],
     [1907, 610],
     [1875, 586],
     [1903, 571],
     [1896, 551],
     [1867, 539]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1094, 667]}],
   "box": [915, 591, 1310, 757],
   "polygons": [
    [
     [1075, 592],
     [1033, 600],
     [930, 636],
     [923, 647],
     [920, 663],
     [915, 723],
     [919, 740],
     [994, 756],
     [1010, 757],
     [1056, 751],
     [1106, 737],
     [1254, 686],
     [1287, 673],
     [1310, 658],
     [1308, 647],
     [1294, 616],
     [1283, 605],
     [1256, 600],
     [1109, 591]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 688]}],
   "box": [435, 632, 618, 770],
   "polygons": [
    [
     [596, 633],
     [455, 636],
     [435, 646],
     [442, 673],
     [465, 680],
     [482, 697],
     [487, 724],
     [507, 745],
     [509, 770],
     [563, 763],
     [576, 758],
     [571, 718],
     [575, 675],
     [594, 648],
     [618, 634],
     [609, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [323, 716]}],
   "box": [159, 641, 496, 779],
   "polygons": [
    [
     [317, 642],
     [188, 669],
     [159, 678],
     [175, 725],
     [202, 774],
     [230, 779],
     [458, 771],
     [484, 769],
     [496, 765],
     [495, 735],
     [473, 726],
     [463, 688],
     [427, 683],
     [423, 647],
     [379, 657],
     [361, 656],
     [330, 641]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [728, 694]}],
   "box": [580, 629, 889, 765],
   "polygons": [
    [
     [694, 630],
     [608, 647],
     [590, 655],
     [580, 699],
     [583, 757],
     [586, 765],
     [826, 747],
     [838, 744],
     [877, 722],
     [885, 709],
     [889, 687],
     [887, 666],
     [881, 649],
     [826, 632],
     [797, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [943, 601]}],
   "box": [838, 559, 1054, 641],
   "polygons": [
    [
     [1041, 560],
     [866, 600],
     [842, 607],
     [838, 611],
     [845, 625],
     [851, 630],
     [878, 641],
     [893, 640],
     [1046, 586],
     [1052, 580],
     [1054, 572],
     [1052, 561],
     [1048, 559]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1136, 567]}],
   "box": [1062, 538, 1214, 591],
   "polygons": [
    [
     [1153, 539],
     [1063, 555],
     [1062, 586],
     [1149, 591],
     [1193, 588],
     [1204, 585],
     [1214, 578],
     [1207, 565],
     [1186, 548],
     [1168, 538]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1561, 572]}],
   "box": [1435, 527, 1700, 612],
   "polygons": [
    [
     [1510, 528],
     [1494, 532],
     [1466, 558],
     [1435, 578],
     [1435, 584],
     [1464, 607],
     [1502, 593],
     [1542, 589],
     [1568, 591],
     [1625, 612],
     [1676, 604],
     [1700, 587],
     [1699, 583],
     [1687, 577],
     [1637, 556],
     [1523, 527]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1728, 578]}],
   "box": [1524, 488, 1843, 704],
   "polygons": [
    [
     [1540, 489],
     [1534, 492],
     [1525, 505],
     [1524, 510],
     [1529, 522],
     [1684, 569],
     [1745, 599],
     [1753, 607],
     [1754, 648],
     [1786, 696],
     [1795, 704],
     [1843, 701],
     [1829, 631],
     [1834, 604],
     [1799, 576],
     [1801, 567],
     [1818, 545],
     [1813, 511],
     [1783, 503],
     [1772, 504],
     [1742, 542],
     [1731, 549],
     [1608, 502],
     [1581, 489],
     [1570, 488]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1878, 647]}],
   "box": [1815, 540, 1920, 769],
   "polygons": [
    [
     [1863, 541],
     [1830, 563],
     [1815, 577],
     [1821, 585],
     [1863, 618],
     [1855, 632],
     [1857, 656],
     [1879, 714],
     [1878, 724],
     [1863, 739],
     [1864, 743],
     [1895, 769],
     [1906, 769],
     [1920, 764],
     [1919, 718],
     [1891, 654],
     [1905, 645],
     [1906, 611],
     [1875, 590],
     [1876, 586],
     [1903, 573],
     [1894, 552],
     [1864, 540]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1092, 668]}],
   "box": [914, 592, 1309, 756],
   "polygons": [
    [
     [1072, 593],
     [1028, 602],
     [927, 638],
     [921, 651],
     [918, 672],
     [914, 719],
     [916, 738],
     [933, 745],
     [990, 756],
     [1024, 756],
     [1056, 751],
     [1106, 737],
     [1263, 683],
     [1294, 670],
     [1309, 658],
     [1291, 614],
     [1276, 604],
     [1110, 592]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 688]}],
   "box": [435, 633, 617, 770],
   "polygons": [
    [
     [535, 634],
     [455, 636],
     [435, 647],
     [442, 672],
     [467, 681],
     [483, 698],
     [486, 722],
     [507, 746],
     [509, 770],
     [565, 762],
     [575, 758],
     [571, 722],
     [574, 677],
     [592, 650],
     [617, 635],
     [616, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [323, 715]}],
   "box": [160, 640, 495, 777],
   "polygons": [
    [
     [325, 641],
     [186, 669],
     [160, 678],
     [172, 715],
     [201, 772],
     [217, 777],
     [276, 776],
     [461, 770],
     [495, 765],
     [495, 736],
     [473, 726],
     [462, 686],
     [427, 682],
     [424, 647],
     [376, 657],
     [356, 654],
     [327, 640]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [727, 694]}],
   "box": [579, 629, 888, 765],
   "polygons": [
    [
     [695, 630],
     [612, 646],
     [597, 650],
     [589, 656],
     [579, 703],
     [582, 753],
     [586, 765],
     [701, 754],
     [824, 747],
     [839, 743],
     [865, 730],
     [879, 718],
     [885, 706],
     [888, 686],
     [885, 662],
     [879, 649],
     [819, 631],
     [776, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [942, 601]}],
   "box": [839, 560, 1053, 642],
   "polygons": [
    [
     [1039, 561],
     [860, 602],
     [839, 609],
     [839, 615],
     [845, 626],
     [857, 634],
     [880, 642],
     [894, 640],
     [1045, 587],
     [1053, 576],
     [1052, 564],
     [1048, 560]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1135, 568]}],
   "box": [1061, 539, 1213, 592],
   "polygons": [
    [
     [1153, 540],
     [1062, 556],
     [1061, 586],
     [1069, 589],
     [1160, 592],
     [1197, 588],
     [1213, 580],
     [1211, 571],
     [1204, 564],
     [1184, 548],
     [1167, 539]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1561, 573]}],
   "box": [1435, 528, 1700, 613],
   "polygons": [
    [
     [1513, 529],
     [1493, 534],
     [1466, 559],
     [1435, 579],
     [1435, 587],
     [1464, 609],
     [1503, 594],
     [1547, 590],
     [1565, 592],
     [1621, 613],
     [1674, 606],
     [1700, 588],
     [1685, 578],
     [1635, 557],
     [1520, 528]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1728, 579]}],
   "box": [1524, 490, 1842, 706],
   "polygons": [
    [
     [1539, 491],
     [1531, 497],
     [1524, 509],
     [1529, 524],
     [1687, 572],
     [1745, 601],
     [1752, 608],
     [1753, 650],
     [1785, 698],
     [1795, 706],
     [1832, 705],
     [1842, 702],
     [1828, 631],
     [1834, 612],
     [1833, 605],
     [1798, 576],
     [1818, 547],
     [1812, 512],
     [1778, 504],
     [1772, 506],
     [1740, 546],
     [1733, 550],
     [1718, 547],
     [1576, 490]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1878, 650]}],
   "box": [1815, 542, 1920, 772],
   "polygons": [
    [
     [1863, 543],
     [1831, 564],
     [1815, 579],
     [1821, 587],
     [1862, 619],
     [1854, 635],
     [1856, 657],
     [1878, 718],
     [1875, 728],
     [1861, 743],
     [1897, 772],
     [1920, 765],
     [1919, 723],
     [1891, 659],
     [1892, 653],
     [1904, 648],
     [1906, 614],
     [1875, 591],
     [1877, 587],
     [1903, 574],
     [1894, 554],
     [1864, 542]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1091, 668]}],
   "box": [913, 593, 1308, 757],
   "polygons": [
    [
     [1069, 594],
     [1026, 603],
     [928, 637],
     [923, 643],
     [918, 663],
     [913, 715],
     [915, 739],
     [948, 749],
     [996, 757],
     [1056, 751],
     [1106, 737],
     [1264, 683],
     [1298, 668],
     [1308, 659],
     [1293, 620],
     [1278, 606],
     [1112, 593]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 687]}],
   "box": [436, 633, 617, 769],
   "polygons": [
    [
     [532, 634],
     [454, 636],
     [436, 646],
     [443, 673],
     [466, 680],
     [483, 698],
     [486, 722],
     [506, 744],
     [508, 769],
     [574, 758],
     [570, 717],
     [574, 676],
     [591, 651],
     [617, 635],
     [616, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [324, 714]}],
   "box": [161, 640, 495, 776],
   "polygons": [
    [
     [322, 641],
     [189, 668],
     [169, 673],
     [161, 678],
     [173, 714],
     [202, 771],
     [219, 776],
     [276, 775],
     [466, 769],
     [487, 767],
     [495, 763],
     [494, 734],
     [472, 724],
     [463, 687],
     [428, 683],
     [425, 647],
     [373, 657],
     [357, 654],
     [330, 640]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [726, 694]}],
   "box": [579, 629, 887, 764],
   "polygons": [
    [
     [699, 630],
     [617, 645],
     [590, 654],
     [579, 698],
     [584, 764],
     [823, 747],
     [864, 730],
     [880, 715],
     [887, 684],
     [883, 659],
     [878, 649],
     [815, 631],
     [743, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [942, 602]}],
   "box": [837, 561, 1052, 642],
   "polygons": [
    [
     [1038, 562],
     [882, 597],
     [844, 607],
     [837, 612],
     [843, 625],
     [850, 631],
     [876, 642],
     [892, 641],
     [1044, 588],
     [1052, 578],
     [1051, 563],
     [1048, 561]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1134, 569]}],
   "box": [1061, 540, 1213, 593],
   "polygons": [
    [
     [1153, 541],
     [1062, 557],
     [1061, 588],
     [1155, 593],
     [1192, 590],
     [1203, 587],
     [1213, 580],
     [1210, 572],
     [1202, 564],
     [1182, 548],
     [1166, 540]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1560, 575]}],
   "box": [1432, 530, 1699, 615],
   "polygons": [
    [
     [1510, 531],
     [1493, 535],
     [1466, 560],
     [1435, 580],
     [1432, 583],
     [1434, 588],
     [1462, 610],
     [1500, 596],
     [1540, 592],
     [1566, 594],
     [1623, 615],
     [1676, 607],
     [1699, 590],
     [1697, 586],
     [1685, 580],
     [1638, 560],
     [1522, 530]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1727, 581]}],
   "box": [1525, 491, 1840, 708],
   "polygons": [
    [
     [1541, 492],
     [1535, 494],
     [1525, 507],
     [1528, 525],
     [1682, 572],
     [1743, 602],
     [1751, 609],
     [1752, 652],
     [1787, 704],
     [1795, 708],
     [1840, 705],
     [1828, 639],
     [1834, 610],
     [1829, 603],
     [1798, 579],
     [1799, 573],
     [1818, 548],
     [1812, 514],
     [1774, 507],
     [1733, 552],
     [1721, 550],
     [1583, 493],
     [1558, 491]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1877, 652]}],
   "box": [1815, 544, 1920, 774],
   "polygons": [
    [
     [1863, 545],
     [1832, 565],
     [1815, 580],
     [1822, 590],
     [1862, 622],
     [1853, 637],
     [1855, 657],
     [1877, 721],
     [1875, 728],
     [1860, 743],
     [1867, 753],
     [1896, 774],
     [1920, 766],
     [1919, 727],
     [1890, 661],
     [1892, 654],
     [1903, 650],
     [1905, 615],
     [1874, 592],
     [1902, 577],
     [1894, 556],
     [1865, 544]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1090, 669]}],
   "box": [911, 593, 1307, 758],
   "polygons": [
    [
     [1087, 594],
     [1035, 601],
     [925, 639],
     [919, 652],
     [916, 671],
     [911, 722],
     [914, 740],
     [990, 757],
     [1011, 758],
     [1052, 752],
     [1125, 731],
     [1257, 686],
     [1295, 670],
     [1307, 659],
     [1290, 618],
     [1277, 607],
     [1088, 593]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 687]}],
   "box": [436, 633, 617, 769],
   "polygons": [
    [
     [539, 634],
     [455, 636],
     [436, 647],
     [443, 672],
     [465, 679],
     [482, 696],
     [485, 720],
     [506, 745],
     [508, 769],
     [573, 758],
     [570, 721],
     [574, 675],
     [590, 652],
     [617, 635],
     [615, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [325, 713]}],
   "box": [163, 640, 494, 775],
   "polygons": [
    [
     [320, 641],
     [187, 668],
     [163, 676],
     [175, 714],
     [202, 769],
     [225, 775],
     [468, 768],
     [494, 763],
     [494, 734],
     [472, 724],
     [463, 686],
     [428, 682],
     [425, 647],
     [381, 656],
     [364, 655],
     [333, 640]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [726, 694]}],
   "box": [578, 629, 886, 764],
   "polygons": [
    [
     [710, 630],
     [682, 632],
     [617, 645],
     [594, 651],
     [589, 655],
     [578, 703],
     [581, 759],
     [584, 764],
     [692, 754],
     [821, 747],
     [834, 744],
     [861, 731],
     [879, 715],
     [886, 684],
     [878, 650],
     [812, 631],
     [718, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [941, 603]}],
   "box": [838, 562, 1052, 643],
   "polygons": [
    [
     [1038, 563],
     [856, 604],
     [838, 610],
     [838, 616],
     [843, 626],
     [858, 636],
     [878, 643],
     [893, 641],
     [1045, 588],
     [1052, 577],
     [1051, 566],
     [1047, 562]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1134, 570]}],
   "box": [1060, 541, 1212, 593],
   "polygons": [
    [
     [1154, 542],
     [1061, 558],
     [1060, 589],
     [1114, 593],
     [1171, 593],
     [1200, 589],
     [1212, 581],
     [1210, 574],
     [1200, 564],
     [1175, 545],
     [1164, 541]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1559, 577]}],
   "box": [1431, 531, 1698, 616],
   "polygons": [
    [
     [1515, 532],
     [1491, 538],
     [1465, 562],
     [1435, 581],
     [1431, 585],
     [1433, 589],
     [1463, 612],
     [1502, 597],
     [1549, 593],
     [1563, 595],
     [1618, 616],
     [1674, 609],
     [1698, 592],
     [1697, 588],
     [1638, 562],
     [1516, 531]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1727, 583]}],
   "box": [1524, 493, 1839, 710],
   "polygons": [
    [
     [1540, 494],
     [1532, 499],
     [1524, 511],
     [1528, 527],
     [1679, 573],
     [1744, 605],
     [1751, 613],
     [1749, 646],
     [1752, 656],
     [1785, 705],
     [1795, 710],
     [1839, 707],
     [1827, 640],
     [1832, 609],
     [1797, 579],
     [1818, 550],
     [1812, 516],
     [1779, 508],
     [1772, 510],
     [1742, 547],
     [1732, 554],
     [1721, 552],
     [1583, 495],
     [1564, 493]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1877, 654]}],
   "box": [1814, 546, 1920, 776],
   "polygons": [
    [
     [1864, 547],
     [1831, 568],
     [1814, 583],
     [1861, 623],
     [1853, 638],
     [1855, 664],
     [1876, 724],
     [1858, 746],
     [1884, 770],
     [1896, 776],
     [1920, 768],
     [1919, 732],
     [1889, 662],
     [1890, 657],
     [1902, 653],
     [1905, 618],
     [1874, 595],
     [1875, 592],
     [1902, 579],
     [1894, 558],
     [1864, 546]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1089, 670]}],
   "box": [910, 594, 1305, 758],
   "polygons": [
    [
     [1084, 595],
     [1034, 602],
     [925, 639],
     [916, 663],
     [910, 719],
     [912, 739],
     [918, 743],
     [978, 756],
     [998, 758],
     [1037, 755],
     [1103, 738],
     [1247, 690],
     [1288, 674],
     [1305, 662],
     [1289, 619],
     [1275, 608],
     [1089, 594]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 687]}],
   "box": [437, 633, 618, 768],
   "polygons": [
    [
     [540, 634],
     [455, 636],
     [437, 646],
     [443, 671],
     [466, 679],
     [483, 697],
     [485, 719],
     [505, 742],
     [507, 768],
     [573, 757],
     [569, 714],
     [574, 675],
     [590, 652],
     [618, 634],
     [615, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [325, 712]}],
   "box": [164, 639, 494, 774],
   "polygons": [
    [
     [326, 640],
     [194, 666],
     [164, 676],
     [178, 717],
     [205, 769],
     [234, 774],
     [468, 767],
     [494, 762],
     [494, 733],
     [472, 723],
     [463, 685],
     [428, 681],
     [425, 646],
     [376, 656],
     [359, 653],
     [332, 639]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [725, 694]}],
   "box": [578, 630, 885, 763],
   "polygons": [
    [
     [689, 631],
     [607, 647],
     [589, 655],
     [578, 700],
     [580, 754],
     [583, 763],
     [699, 753],
     [818, 747],
     [835, 743],
     [869, 725],
     [880, 711],
     [885, 687],
     [883, 666],
     [878, 651],
     [817, 632],
     [780, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [941, 603]}],
   "box": [836, 563, 1052, 643],
   "polygons": [
    [
     [1036, 564],
     [881, 598],
     [846, 607],
     [836, 613],
     [843, 627],
     [849, 632],
     [876, 643],
     [889, 642],
     [1034, 594],
     [1044, 589],
     [1051, 580],
     [1052, 573],
     [1048, 563]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1133, 571]}],
   "box": [1059, 542, 1211, 594],
   "polygons": [
    [
     [1153, 543],
     [1063, 558],
     [1059, 562],
     [1059, 588],
     [1063, 591],
     [1122, 594],
     [1182, 593],
     [1201, 589],
     [1211, 583],
     [1210, 576],
     [1202, 567],
     [1175, 546],
     [1164, 542]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1559, 578]}],
   "box": [1432, 533, 1698, 618],
   "polygons": [
    [
     [1509, 534],
     [1491, 539],
     [1466, 562],
     [1432, 584],
     [1432, 590],
     [1462, 613],
     [1502, 598],
     [1537, 595],
     [1565, 597],
     [1621, 618],
     [1675, 610],
     [1698, 593],
     [1688, 585],
     [1634, 562],
     [1521, 533]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1726, 584]}],
   "box": [1524, 494, 1838, 711],
   "polygons": [
    [
     [1543, 495],
     [1537, 496],
     [1524, 512],
     [1527, 528],
     [1686, 577],
     [1743, 606],
     [1750, 613],
     [1751, 657],
     [1782, 704],
     [1792, 711],
     [1838, 708],
     [1826, 638],
     [1833, 613],
     [1797, 582],
     [1818, 551],
     [1813, 518],
     [1781, 510],
     [1774, 511],
     [1739, 552],
     [1730, 556],
     [1582, 496],
     [1550, 494]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1876, 656]}],
   "box": [1814, 548, 1920, 777],
   "polygons": [
    [
     [1863, 549],
     [1833, 568],
     [1814, 585],
     [1861, 625],
     [1852, 641],
     [1854, 664],
     [1874, 722],
     [1872, 732],
     [1857, 747],
     [1865, 757],
     [1892, 777],
     [1920, 769],
     [1919, 736],
     [1889, 665],
     [1890, 658],
     [1902, 653],
     [1905, 621],
     [1901, 615],
     [1874, 597],
     [1876, 593],
     [1902, 581],
     [1895, 560],
     [1865, 548]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1088, 670]}],
   "box": [909, 595, 1304, 757],
   "polygons": [
    [
     [1072, 596],
     [1028, 604],
     [926, 638],
     [919, 648],
     [915, 664],
     [909, 718],
     [912, 741],
     [984, 757],
     [1042, 754],
     [1109, 736],
     [1233, 695],
     [1284, 676],
     [1304, 663],
     [1288, 620],
     [1275, 609],
     [1099, 595]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 686]}],
   "box": [438, 633, 617, 767],
   "polygons": [
    [
     [522, 634],
     [454, 636],
     [438, 645],
     [444, 671],
     [467, 679],
     [483, 696],
     [486, 721],
     [505, 742],
     [506, 767],
     [566, 759],
     [573, 755],
     [569, 716],
     [574, 675],
     [589, 653],
     [596, 646],
     [617, 635],
     [615, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [326, 712]}],
   "box": [166, 639, 493, 772],
   "polygons": [
    [
     [321, 640],
     [196, 665],
     [166, 674],
     [179, 716],
     [206, 768],
     [222, 772],
     [285, 771],
     [468, 766],
     [493, 762],
     [493, 732],
     [472, 723],
     [463, 685],
     [428, 680],
     [426, 646],
     [382, 655],
     [365, 654],
     [335, 639]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [724, 693]}],
   "box": [577, 629, 884, 762],
   "polygons": [
    [
     [704, 630],
     [680, 632],
     [605, 647],
     [589, 654],
     [577, 703],
     [582, 762],
     [822, 746],
     [858, 731],
     [877, 714],
     [884, 684],
     [882, 666],
     [876, 650],
     [808, 631],
     [719, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [940, 603]}],
   "box": [836, 563, 1051, 643],
   "polygons": [
    [
     [1039, 564],
     [857, 604],
     [836, 611],
     [837, 617],
     [844, 629],
     [858, 637],
     [875, 643],
     [893, 641],
     [1042, 590],
     [1048, 585],
     [1051, 577],
     [1049, 564],
     [1045, 563]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1132, 572]}],
   "box": [1058, 543, 1210, 595],
   "polygons": [
    [
     [1150, 544],
     [1061, 559],
     [1058, 564],
     [1059, 590],
     [1134, 595],
     [1177, 594],
     [1196, 591],
     [1210, 584],
     [1208, 575],
     [1197, 564],
     [1165, 543]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1558, 579]}],
   "box": [1431, 534, 1697, 619],
   "polygons": [
    [
     [1509, 535],
     [1492, 539],
     [1464, 564],
     [1431, 585],
     [1431, 590],
     [1461, 614],
     [1501, 599],
     [1536, 596],
     [1561, 597],
     [1620, 619],
     [1674, 611],
     [1697, 594],
     [1689, 587],
     [1633, 563],
     [1520, 534]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1726, 586]}],
   "box": [1523, 496, 1836, 712],
   "polygons": [
    [
     [1538, 497],
     [1523, 515],
     [1528, 530],
     [1682, 577],
     [1740, 606],
     [1749, 614],
     [1747, 649],
     [1750, 659],
     [1781, 706],
     [1790, 712],
     [1836, 709],
     [1825, 640],
     [1832, 617],
     [1831, 612],
     [1797, 584],
     [1799, 576],
     [1817, 555],
     [1815, 526],
     [1812, 519],
     [1779, 511],
     [1772, 513],
     [1743, 548],
     [1731, 557],
     [1574, 496]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1876, 658]}],
   "box": [1814, 550, 1920, 778],
   "polygons": [
    [
     [1862, 551],
     [1814, 585],
     [1821, 595],
     [1860, 626],
     [1851, 642],
     [1853, 665],
     [1873, 725],
     [1871, 732],
     [1855, 749],
     [1890, 778],
     [1920, 769],
     [1919, 741],
     [1887, 663],
     [1901, 655],
     [1904, 621],
     [1873, 598],
     [1876, 594],
     [1901, 584],
     [1900, 574],
     [1894, 561],
     [1867, 550]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1087, 671]}],
   "box": [907, 596, 1303, 757],
   "polygons": [
    [
     [1065, 597],
     [1025, 605],
     [924, 639],
     [918, 648],
     [913, 671],
     [907, 724],
     [910, 740],
     [983, 757],
     [1040, 754],
     [1108, 736],
     [1253, 688],
     [1290, 673],
     [1303, 662],
     [1286, 619],
     [1277, 611],
     [1251, 606],
     [1107, 596]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 686]}],
   "box": [438, 633, 616, 766],
   "polygons": [
    [
     [493, 634],
     [453, 636],
     [438, 646],
     [444, 671],
     [468, 679],
     [483, 696],
     [485, 718],
     [505, 742],
     [507, 766],
     [561, 759],
     [572, 755],
     [569, 722],
     [574, 673],
     [594, 647],
     [616, 633]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [326, 711]}],
   "box": [167, 638, 493, 771],
   "polygons": [
    [
     [327, 639],
     [198, 664],
     [167, 674],
     [180, 714],
     [206, 766],
     [229, 771],
     [468, 765],
     [493, 761],
     [493, 731],
     [472, 722],
     [462, 683],
     [429, 680],
     [426, 645],
     [376, 655],
     [358, 651],
     [334, 638]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [724, 693]}],
   "box": [577, 629, 883, 763],
   "polygons": [
    [
     [702, 630],
     [604, 647],
     [589, 654],
     [577, 700],
     [581, 761],
     [587, 763],
     [682, 753],
     [819, 746],
     [862, 728],
     [876, 714],
     [883, 686],
     [881, 665],
     [875, 650],
     [814, 632],
     [719, 629]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [940, 604]}],
   "box": [835, 564, 1049, 643],
   "polygons": [
    [
     [1035, 565],
     [874, 600],
     [840, 609],
     [835, 613],
     [841, 626],
     [849, 633],
     [874, 643],
     [889, 642],
     [1035, 594],
     [1049, 583],
     [1049, 566],
     [1046, 564]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1132, 573]}],
   "box": [1058, 544, 1210, 596],
   "polygons": [
    [
     [1149, 545],
     [1060, 560],
     [1058, 590],
     [1072, 593],
     [1146, 596],
     [1190, 593],
     [1210, 584],
     [1209, 578],
     [1199, 567],
     [1166, 544]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1558, 580]}],
   "box": [1429, 535, 1697, 620],
   "polygons": [
    [
     [1510, 536],
     [1492, 540],
     [1465, 564],
     [1432, 585],
     [1429, 588],
     [1431, 592],
     [1460, 615],
     [1501, 600],
     [1538, 597],
     [1563, 599],
     [1618, 620],
     [1671, 613],
     [1697, 595],
     [1681, 585],
     [1637, 566],
     [1519, 535]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1725, 587]}],
   "box": [1524, 497, 1835, 713],
   "polygons": [
    [
     [1539, 498],
     [1524, 513],
     [1526, 530],
     [1686, 580],
     [1744, 610],
     [1749, 617],
     [1749, 660],
     [1779, 706],
     [1787, 713],
     [1835, 711],
     [1825, 646],
     [1831, 620],
     [1830, 613],
     [1796, 584],
     [1817, 556],
     [1813, 521],
     [1782, 513],
     [1774, 514],
     [1740, 553],
     [1732, 558],
     [1717, 555],
     [1583, 499],
     [1567, 497]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1875, 660]}],
   "box": [1814, 551, 1920, 778],
   "polygons": [
    [
     [1863, 552],
     [1830, 573],
     [1814, 587],
     [1818, 594],
     [1860, 628],
     [1851, 643],
     [1852, 664],
     [1872, 726],
     [1870, 734],
     [1854, 750],
     [1885, 778],
     [1897, 778],
     [1920, 770],
     [1919, 746],
     [1887, 667],
     [1888, 661],
     [1901, 655],
     [1905, 628],
     [1900, 618],
     [1873, 599],
     [1902, 584],
     [1896, 564],
     [1865, 551]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1086, 671]}],
   "box": [906, 596, 1302, 757],
   "polygons": [
    [
     [1071, 597],
     [1026, 605],
     [922, 640],
     [916, 651],
     [912, 672],
     [906, 723],
     [908, 739],
     [918, 744],
     [982, 757],
     [1039, 754],
     [1101, 738],
     [1245, 691],
     [1282, 677],
     [1302, 663],
     [1287, 623],
     [1281, 615],
     [1271, 610],
     [1096, 596]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 685]}],
   "box": [439, 632, 617, 765],
   "polygons": [
    [
     [583, 633],
     [456, 635],
     [439, 644],
     [445, 671],
     [466, 677],
     [483, 695],
     [486, 720],
     [505, 742],
     [505, 765],
     [571, 755],
     [568, 712],
     [573, 676],
     [594, 647],
     [617, 634],
     [601, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [327, 710]}],
   "box": [169, 638, 493, 770],
   "polygons": [
    [
     [324, 639],
     [196, 664],
     [169, 673],
     [183, 716],
     [208, 765],
     [238, 770],
     [469, 764],
     [493, 760],
     [493, 730],
     [472, 721],
     [463, 683],
     [430, 680],
     [427, 645],
     [384, 654],
     [368, 653],
     [337, 638]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [723, 693]}],
   "box": [577, 630, 882, 761],
   "polygons": [
    [
     [688, 631],
     [608, 646],
     [589, 654],
     [577, 699],
     [581, 761],
     [823, 745],
     [859, 729],
     [873, 717],
     [878, 708],
     [882, 688],
     [881, 669],
     [875, 651],
     [818, 633],
     [760, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [939, 604]}],
   "box": [835, 565, 1049, 644],
   "polygons": [
    [
     [1034, 566],
     [863, 603],
     [835, 613],
     [841, 627],
     [848, 633],
     [876, 644],
     [891, 642],
     [1034, 595],
     [1046, 588],
     [1049, 583],
     [1049, 568],
     [1046, 565]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1132, 573]}],
   "box": [1058, 545, 1210, 597],
   "polygons": [
    [
     [1148, 546],
     [1059, 561],
     [1058, 592],
     [1153, 597],
     [1190, 594],
     [1210, 584],
     [1205, 574],
     [1194, 564],
     [1165, 545]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1557, 582]}],
   "box": [1429, 537, 1696, 622],
   "polygons": [
    [
     [1507, 538],
     [1491, 542],
     [1464, 566],
     [1432, 586],
     [1429, 589],
     [1430, 593],
     [1459, 616],
     [1498, 602],
     [1544, 598],
     [1561, 600],
     [1621, 622],
     [1669, 615],
     [1696, 597],
     [1686, 589],
     [1635, 567],
     [1521, 537]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1725, 589]}],
   "box": [1523, 498, 1834, 715],
   "polygons": [
    [
     [1544, 499],
     [1537, 500],
     [1525, 513],
     [1523, 519],
     [1527, 532],
     [1684, 581],
     [1739, 609],
     [1748, 617],
     [1748, 661],
     [1778, 708],
     [1788, 715],
     [1834, 712],
     [1824, 645],
     [1831, 621],
     [1830, 615],
     [1796, 586],
     [1818, 556],
     [1814, 523],
     [1783, 515],
     [1774, 516],
     [1739, 556],
     [1732, 560],
     [1718, 557],
     [1582, 500],
     [1548, 498]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1875, 662]}],
   "box": [1814, 553, 1919, 781],
   "polygons": [
    [
     [1863, 554],
     [1830, 575],
     [1814, 589],
     [1817, 595],
     [1860, 631],
     [1850, 646],
     [1852, 670],
     [1871, 728],
     [1869, 735],
     [1853, 751],
     [1857, 758],
     [1887, 781],
     [1919, 772],
     [1919, 750],
     [1886, 667],
     [1888, 663],
     [1900, 658],
     [1904, 637],
     [1903, 623],
     [1873, 602],
     [1875, 598],
     [1902, 587],
     [1901, 580],
     [1895, 565],
     [1866, 553]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1085, 671]}],
   "box": [905, 597, 1301, 757],
   "polygons": [
    [
     [1067, 598],
     [1021, 607],
     [922, 640],
     [917, 647],
     [912, 666],
     [905, 722],
     [907, 739],
     [913, 743],
     [979, 757],
     [1039, 754],
     [1114, 734],
     [1249, 690],
     [1287, 675],
     [1301, 664],
     [1285, 622],
     [1279, 615],
     [1270, 611],
     [1099, 597]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 685]}],
   "box": [440, 632, 617, 764],
   "polygons": [
    [
     [580, 633],
     [467, 634],
     [453, 636],
     [440, 644],
     [445, 669],
     [469, 678],
     [483, 694],
     [486, 718],
     [505, 741],
     [505, 764],
     [571, 754],
     [568, 713],
     [573, 676],
     [594, 647],
     [617, 634],
     [601, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [328, 709]}],
   "box": [170, 637, 493, 768],
   "polygons": [
    [
     [330, 638],
     [194, 664],
     [170, 673],
     [182, 710],
     [209, 764],
     [224, 768],
     [396, 765],
     [471, 763],
     [493, 758],
     [493, 730],
     [472, 720],
     [464, 684],
     [430, 679],
     [428, 645],
     [379, 654],
     [362, 651],
     [335, 637]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [723, 693]}],
   "box": [576, 630, 881, 760],
   "polygons": [
    [
     [688, 631],
     [607, 646],
     [588, 655],
     [576, 705],
     [580, 760],
     [821, 745],
     [856, 730],
     [872, 717],
     [881, 689],
     [880, 669],
     [874, 651],
     [811, 632],
     [755, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [939, 605]}],
   "box": [836, 565, 1050, 644],
   "polygons": [
    [
     [1038, 566],
     [860, 604],
     [836, 611],
     [836, 618],
     [843, 630],
     [857, 638],
     [874, 644],
     [891, 642],
     [1042, 591],
     [1048, 585],
     [1050, 577],
     [1048, 567],
     [1044, 565]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1131, 574]}],
   "box": [1057, 545, 1209, 597],
   "polygons": [
    [
     [1154, 546],
     [1060, 561],
     [1057, 566],
     [1057, 592],
     [1126, 597],
     [1180, 596],
     [1199, 592],
     [1209, 585],
     [1206, 577],
     [1189, 561],
     [1171, 548],
     [1160, 545]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1557, 583]}],
   "box": [1430, 538, 1696, 623],
   "polygons": [
    [
     [1508, 539],
     [1491, 543],
     [1461, 569],
     [1430, 588],
     [1430, 595],
     [1458, 617],
     [1497, 603],
     [1546, 599],
     [1560, 601],
     [1618, 623],
     [1673, 615],
     [1696, 598],
     [1687, 591],
     [1634, 568],
     [1520, 538]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1725, 590]}],
   "box": [1523, 500, 1833, 716],
   "polygons": [
    [
     [1539, 501],
     [1523, 518],
     [1526, 533],
     [1680, 581],
     [1736, 609],
     [1744, 614],
     [1748, 621],
     [1745, 647],
     [1747, 662],
     [1778, 711],
     [1785, 716],
     [1833, 713],
     [1824, 652],
     [1829, 616],
     [1796, 588],
     [1797, 583],
     [1817, 560],
     [1813, 524],
     [1780, 516],
     [1773, 518],
     [1737, 559],
     [1722, 560],
     [1605, 513],
     [1584, 502],
     [1569, 500]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1874, 664]}],
   "box": [1813, 555, 1920, 781],
   "polygons": [
    [
     [1863, 556],
     [1829, 577],
     [1813, 591],
     [1859, 631],
     [1850, 646],
     [1851, 670],
     [1870, 731],
     [1852, 751],
     [1853, 756],
     [1882, 781],
     [1894, 781],
     [1920, 772],
     [1919, 755],
     [1886, 671],
     [1887, 664],
     [1900, 658],
     [1903, 625],
     [1873, 603],
     [1874, 600],
     [1902, 587],
     [1894, 566],
     [1867, 555]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1084, 672]}],
   "box": [904, 598, 1300, 757],
   "polygons": [
    [
     [1062, 599],
     [1015, 609],
     [923, 639],
     [918, 644],
     [912, 661],
     [904, 720],
     [906, 740],
     [978, 757],
     [1033, 755],
     [1107, 736],
     [1258, 687],
     [1289, 674],
     [1300, 664],
     [1283, 621],
     [1273, 613],
     [1252, 609],
     [1104, 598]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 685]}],
   "box": [440, 632, 617, 764],
   "polygons": [
    [
     [560, 633],
     [463, 634],
     [440, 644],
     [446, 670],
     [468, 677],
     [483, 693],
     [486, 718],
     [504, 739],
     [506, 764],
     [564, 756],
     [571, 752],
     [568, 718],
     [573, 675],
     [595, 646],
     [617, 634],
     [607, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [328, 708]}],
   "box": [172, 637, 493, 767],
   "polygons": [
    [
     [327, 638],
     [192, 664],
     [172, 671],
     [184, 711],
     [210, 763],
     [227, 767],
     [296, 766],
     [473, 762],
     [492, 758],
     [493, 730],
     [472, 720],
     [464, 683],
     [430, 678],
     [429, 645],
     [386, 653],
     [368, 652],
     [338, 637]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [722, 693]}],
   "box": [576, 630, 880, 760],
   "polygons": [
    [
     [688, 631],
     [591, 651],
     [586, 660],
     [576, 700],
     [577, 754],
     [580, 760],
     [689, 751],
     [818, 745],
     [851, 732],
     [871, 717],
     [880, 689],
     [879, 668],
     [873, 651],
     [814, 633],
     [748, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [938, 605]}],
   "box": [834, 566, 1048, 644],
   "polygons": [
    [
     [1035, 567],
     [865, 603],
     [836, 611],
     [834, 614],
     [839, 626],
     [846, 633],
     [872, 644],
     [887, 643],
     [1033, 596],
     [1045, 589],
     [1048, 584],
     [1048, 569],
     [1045, 566]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1131, 575]}],
   "box": [1055, 546, 1208, 598],
   "polygons": [
    [
     [1152, 547],
     [1059, 562],
     [1055, 589],
     [1060, 594],
     [1135, 598],
     [1185, 596],
     [1200, 592],
     [1208, 586],
     [1204, 576],
     [1191, 564],
     [1171, 549],
     [1161, 546]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1556, 584]}],
   "box": [1428, 539, 1695, 624],
   "polygons": [
    [
     [1509, 540],
     [1488, 546],
     [1462, 569],
     [1428, 590],
     [1429, 595],
     [1457, 618],
     [1497, 604],
     [1532, 601],
     [1562, 603],
     [1616, 624],
     [1669, 617],
     [1695, 600],
     [1693, 596],
     [1679, 589],
     [1630, 568],
     [1518, 539]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1724, 591]}],
   "box": [1523, 501, 1832, 717],
   "polygons": [
    [
     [1540, 502],
     [1536, 503],
     [1523, 519],
     [1527, 535],
     [1684, 584],
     [1740, 613],
     [1747, 621],
     [1744, 648],
     [1746, 664],
     [1778, 714],
     [1783, 717],
     [1832, 714],
     [1823, 653],
     [1829, 618],
     [1796, 591],
     [1799, 581],
     [1817, 561],
     [1814, 526],
     [1783, 518],
     [1774, 519],
     [1741, 556],
     [1730, 563],
     [1604, 514],
     [1583, 503],
     [1560, 501]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1873, 665]}],
   "box": [1813, 556, 1920, 784],
   "polygons": [
    [
     [1864, 557],
     [1828, 579],
     [1813, 592],
     [1821, 602],
     [1858, 632],
     [1857, 638],
     [1849, 648],
     [1850, 671],
     [1868, 729],
     [1867, 737],
     [1850, 754],
     [1858, 764],
     [1885, 784],
     [1920, 772],
     [1916, 752],
     [1885, 672],
     [1886, 666],
     [1899, 660],
     [1903, 639],
     [1902, 626],
     [1873, 606],
     [1875, 601],
     [1900, 591],
     [1900, 581],
     [1896, 569],
     [1865, 556]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1082, 672]}],
   "box": [902, 598, 1299, 757],
   "polygons": [
    [
     [1068, 599],
     [1023, 607],
     [921, 640],
     [916, 646],
     [910, 667],
     [902, 727],
     [905, 740],
     [976, 757],
     [1032, 755],
     [1093, 740],
     [1247, 691],
     [1289, 674],
     [1299, 664],
     [1285, 627],
     [1270, 613],
     [1093, 598]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 684]}],
   "box": [441, 632, 617, 763],
   "polygons": [
    [
     [542, 633],
     [461, 634],
     [441, 644],
     [446, 669],
     [469, 677],
     [484, 694],
     [486, 718],
     [504, 739],
     [505, 763],
     [560, 756],
     [570, 752],
     [568, 722],
     [573, 674],
     [595, 646],
     [617, 634],
     [608, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [329, 707]}],
   "box": [173, 636, 493, 766],
   "polygons": [
    [
     [335, 637],
     [239, 654],
     [175, 669],
     [173, 672],
     [185, 710],
     [207, 758],
     [217, 764],
     [233, 766],
     [448, 762],
     [492, 757],
     [493, 730],
     [472, 719],
     [464, 682],
     [430, 677],
     [429, 644],
     [381, 653],
     [361, 649],
     [344, 638],
     [335, 636]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [721, 692]}],
   "box": [576, 630, 879, 759],
   "polygons": [
    [
     [687, 631],
     [605, 646],
     [589, 653],
     [576, 696],
     [579, 759],
     [815, 745],
     [845, 734],
     [869, 718],
     [875, 707],
     [879, 689],
     [878, 668],
     [872, 651],
     [813, 633],
     [746, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [937, 606]}],
   "box": [834, 567, 1049, 645],
   "polygons": [
    [
     [1032, 568],
     [846, 608],
     [834, 612],
     [835, 619],
     [844, 632],
     [857, 639],
     [875, 645],
     [887, 643],
     [1031, 597],
     [1044, 590],
     [1048, 583],
     [1049, 576],
     [1045, 567]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1130, 576]}],
   "box": [1056, 547, 1208, 599],
   "polygons": [
    [
     [1150, 548],
     [1060, 562],
     [1056, 567],
     [1056, 593],
     [1148, 599],
     [1193, 595],
     [1208, 586],
     [1205, 579],
     [1186, 561],
     [1162, 547]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1555, 585]}],
   "box": [1427, 540, 1694, 625],
   "polygons": [
    [
     [1509, 541],
     [1489, 546],
     [1460, 571],
     [1430, 589],
     [1427, 592],
     [1428, 596],
     [1456, 619],
     [1496, 605],
     [1533, 602],
     [1557, 603],
     [1614, 625],
     [1669, 618],
     [1694, 601],
     [1692, 597],
     [1634, 571],
     [1517, 540]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1723, 593]}],
   "box": [1523, 502, 1830, 719],
   "polygons": [
    [
     [1541, 503],
     [1535, 505],
     [1523, 519],
     [1526, 536],
     [1677, 583],
     [1737, 613],
     [1746, 622],
     [1743, 650],
     [1745, 665],
     [1775, 713],
     [1785, 719],
     [1830, 716],
     [1822, 653],
     [1823, 640],
     [1829, 625],
     [1828, 619],
     [1795, 591],
     [1799, 582],
     [1817, 562],
     [1813, 527],
     [1780, 519],
     [1773, 521],
     [1743, 555],
     [1731, 564],
     [1709, 558],
     [1582, 504],
     [1551, 502]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1872, 667]}],
   "box": [1813, 558, 1919, 785],
   "polygons": [
    [
     [1863, 559],
     [1830, 579],
     [1813, 593],
     [1820, 603],
     [1858, 634],
     [1848, 650],
     [1849, 671],
     [1867, 731],
     [1865, 739],
     [1848, 756],
     [1882, 785],
     [1919, 773],
     [1914, 752],
     [1884, 673],
     [1886, 667],
     [1898, 662],
     [1903, 637],
     [1902, 628],
     [1872, 606],
     [1875, 602],
     [1902, 590],
     [1895, 570],
     [1867, 558]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1081, 673]}],
   "box": [901, 599, 1297, 757],
   "polygons": [
    [
     [1061, 600],
     [1020, 608],
     [919, 641],
     [913, 651],
     [909, 667],
     [901, 724],
     [903, 739],
     [909, 743],
     [974, 757],
     [1031, 755],
     [1099, 738],
     [1229, 697],
     [1279, 679],
     [1297, 667],
     [1285, 630],
     [1279, 620],
     [1272, 615],
     [1244, 610],
     [1099, 599]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 684]}],
   "box": [441, 632, 617, 762],
   "polygons": [
    [
     [485, 633],
     [455, 635],
     [441, 645],
     [446, 668],
     [468, 676],
     [483, 692],
     [485, 715],
     [504, 739],
     [505, 762],
     [569, 752],
     [567, 714],
     [573, 673],
     [593, 647],
     [617, 634],
     [612, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [330, 706]}],
   "box": [175, 636, 492, 764],
   "polygons": [
    [
     [328, 637],
     [191, 663],
     [175, 669],
     [186, 707],
     [210, 759],
     [227, 764],
     [316, 763],
     [471, 760],
     [492, 755],
     [492, 727],
     [472, 719],
     [464, 681],
     [431, 677],
     [430, 644],
     [386, 652],
     [371, 651],
     [341, 636]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [720, 692]}],
   "box": [575, 630, 878, 758],
   "polygons": [
    [
     [686, 631],
     [599, 647],
     [588, 654],
     [575, 701],
     [578, 758],
     [818, 744],
     [855, 728],
     [869, 716],
     [878, 689],
     [877, 667],
     [871, 651],
     [812, 633],
     [747, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [937, 606]}],
   "box": [833, 567, 1048, 644],
   "polygons": [
    [
     [1035, 568],
     [862, 604],
     [833, 614],
     [837, 625],
     [846, 634],
     [870, 644],
     [883, 644],
     [1034, 596],
     [1046, 587],
     [1048, 581],
     [1047, 570],
     [1043, 567]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1129, 576]}],
   "box": [1055, 548, 1207, 599],
   "polygons": [
    [
     [1147, 549],
     [1059, 563],
     [1055, 572],
     [1055, 593],
     [1125, 599],
     [1178, 598],
     [1197, 594],
     [1207, 587],
     [1196, 571],
     [1178, 556],
     [1163, 548]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1555, 586]}],
   "box": [1427, 541, 1693, 626],
   "polygons": [
    [
     [1511, 542],
     [1489, 547],
     [1461, 571],
     [1428, 591],
     [1427, 597],
     [1455, 620],
     [1496, 606],
     [1535, 603],
     [1559, 605],
     [1612, 626],
     [1670, 619],
     [1693, 603],
     [1687, 596],
     [1630, 571],
     [1514, 541]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1723, 594]}],
   "box": [1522, 504, 1829, 720],
   "polygons": [
    [
     [1538, 505],
     [1522, 522],
     [1525, 537],
     [1681, 586],
     [1737, 615],
     [1745, 623],
     [1742, 650],
     [1744, 667],
     [1773, 714],
     [1781, 720],
     [1829, 717],
     [1821, 655],
     [1822, 641],
     [1828, 627],
     [1827, 620],
     [1795, 594],
     [1796, 587],
     [1817, 563],
     [1813, 529],
     [1782, 521],
     [1775, 522],
     [1735, 564],
     [1729, 566],
     [1609, 519],
     [1583, 506],
     [1569, 504]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1872, 668]}],
   "box": [1812, 560, 1918, 786],
   "polygons": [
    [
     [1862, 561],
     [1825, 584],
     [1812, 596],
     [1857, 635],
     [1847, 652],
     [1848, 673],
     [1865, 731],
     [1864, 740],
     [1847, 756],
     [1856, 768],
     [1880, 786],
     [1914, 777],
     [1918, 772],
     [1883, 675],
     [1884, 669],
     [1897, 664],
     [1902, 640],
     [1901, 629],
     [1872, 609],
     [1874, 604],
     [1900, 594],
     [1900, 586],
     [1894, 571],
     [1867, 560]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1080, 673]}],
   "box": [900, 599, 1296, 757],
   "polygons": [
    [
     [1068, 600],
     [1021, 608],
     [920, 640],
     [913, 649],
     [908, 667],
     [900, 721],
     [901, 738],
     [908, 743],
     [974, 757],
     [1035, 754],
     [1091, 740],
     [1226, 698],
     [1272, 682],
     [1296, 667],
     [1282, 627],
     [1276, 619],
     [1266, 614],
     [1089, 599]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 683]}],
   "box": [442, 632, 618, 761],
   "polygons": [
    [
     [466, 633],
     [455, 635],
     [442, 643],
     [446, 665],
     [448, 669],
     [468, 675],
     [483, 691],
     [485, 714],
     [504, 738],
     [505, 761],
     [561, 754],
     [569, 750],
     [567, 718],
     [573, 673],
     [590, 650],
     [618, 633],
     [614, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [330, 705]}],
   "box": [176, 635, 492, 763],
   "polygons": [
    [
     [335, 636],
     [208, 659],
     [178, 667],
     [176, 670],
     [187, 706],
     [209, 756],
     [218, 761],
     [231, 763],
     [446, 760],
     [483, 758],
     [491, 755],
     [492, 727],
     [472, 718],
     [464, 681],
     [431, 677],
     [430, 643],
     [381, 652],
     [365, 649],
     [346, 637],
     [337, 635]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [720, 692]}],
   "box": [575, 630, 877, 758],
   "polygons": [
    [
     [686, 631],
     [591, 650],
     [586, 657],
     [575, 697],
     [575, 753],
     [578, 758],
     [678, 750],
     [815, 744],
     [852, 729],
     [870, 713],
     [877, 689],
     [876, 666],
     [870, 651],
     [811, 633],
     [742, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [936, 606]}],
   "box": [834, 568, 1048, 644],
   "polygons": [
    [
     [1033, 569],
     [851, 607],
     [834, 612],
     [834, 619],
     [845, 634],
     [868, 644],
     [884, 644],
     [1030, 598],
     [1043, 591],
     [1046, 586],
     [1048, 576],
     [1046, 569],
     [1043, 568]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1129, 577]}],
   "box": [1055, 549, 1206, 600],
   "polygons": [
    [
     [1146, 550],
     [1058, 564],
     [1055, 568],
     [1055, 595],
     [1133, 600],
     [1175, 599],
     [1193, 596],
     [1206, 589],
     [1204, 581],
     [1191, 568],
     [1163, 549]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1554, 588]}],
   "box": [1426, 543, 1693, 628],
   "polygons": [
    [
     [1506, 544],
     [1489, 548],
     [1462, 571],
     [1426, 593],
     [1427, 599],
     [1454, 621],
     [1492, 608],
     [1537, 604],
     [1557, 606],
     [1615, 628],
     [1670, 620],
     [1693, 603],
     [1677, 593],
     [1631, 573],
     [1519, 543]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1722, 596]}],
   "box": [1522, 505, 1828, 721],
   "polygons": [
    [
     [1539, 506],
     [1533, 509],
     [1522, 523],
     [1526, 539],
     [1682, 588],
     [1734, 615],
     [1744, 623],
     [1741, 653],
     [1743, 668],
     [1773, 717],
     [1779, 721],
     [1827, 719],
     [1820, 654],
     [1828, 626],
     [1822, 617],
     [1794, 594],
     [1796, 588],
     [1817, 564],
     [1814, 531],
     [1784, 523],
     [1774, 524],
     [1738, 563],
     [1731, 567],
     [1711, 562],
     [1585, 508],
     [1559, 505]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1871, 670]}],
   "box": [1812, 561, 1916, 788],
   "polygons": [
    [
     [1863, 562],
     [1827, 584],
     [1812, 597],
     [1857, 638],
     [1847, 652],
     [1848, 679],
     [1864, 733],
     [1863, 741],
     [1845, 759],
     [1873, 785],
     [1880, 788],
     [1914, 778],
     [1916, 775],
     [1882, 675],
     [1884, 670],
     [1897, 664],
     [1901, 631],
     [1872, 610],
     [1873, 606],
     [1901, 595],
     [1900, 588],
     [1896, 574],
     [1866, 561]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1079, 673]}],
   "box": [898, 600, 1295, 757],
   "polygons": [
    [
     [1061, 601],
     [1022, 608],
     [918, 641],
     [911, 652],
     [907, 668],
     [898, 728],
     [901, 740],
     [972, 757],
     [1029, 755],
     [1101, 737],
     [1239, 694],
     [1277, 680],
     [1295, 667],
     [1280, 626],
     [1268, 616],
     [1247, 612],
     [1094, 600]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [523, 683]}],
   "box": [442, 632, 617, 761],
   "polygons": [
    [
     [464, 633],
     [442, 643],
     [446, 664],
     [449, 669],
     [469, 675],
     [484, 692],
     [485, 714],
     [503, 736],
     [502, 755],
     [506, 761],
     [568, 750],
     [568, 699],
     [573, 672],
     [594, 646],
     [617, 634],
     [615, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [331, 705]}],
   "box": [179, 635, 492, 762],
   "polygons": [
    [
     [331, 636],
     [180, 666],
     [179, 674],
     [188, 704],
     [211, 756],
     [235, 762],
     [453, 759],
     [484, 757],
     [491, 754],
     [492, 726],
     [472, 717],
     [465, 681],
     [432, 677],
     [431, 643],
     [389, 651],
     [372, 650],
     [342, 635]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [719, 692]}],
   "box": [575, 630, 877, 757],
   "polygons": [
    [
     [686, 631],
     [602, 646],
     [593, 649],
     [587, 655],
     [575, 697],
     [577, 757],
     [813, 744],
     [851, 729],
     [868, 715],
     [877, 683],
     [874, 660],
     [869, 651],
     [809, 633],
     [738, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [936, 607]}],
   "box": [833, 568, 1047, 645],
   "polygons": [
    [
     [1039, 569],
     [860, 605],
     [833, 613],
     [834, 620],
     [843, 633],
     [854, 639],
     [870, 645],
     [885, 644],
     [1031, 598],
     [1042, 592],
     [1047, 583],
     [1045, 569],
     [1039, 568]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1128, 578]}],
   "box": [1054, 549, 1206, 600],
   "polygons": [
    [
     [1152, 550],
     [1059, 564],
     [1055, 568],
     [1054, 595],
     [1117, 600],
     [1170, 600],
     [1192, 597],
     [1206, 589],
     [1203, 581],
     [1184, 563],
     [1169, 552],
     [1158, 549]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1554, 589]}],
   "box": [1426, 544, 1692, 629],
   "polygons": [
    [
     [1506, 545],
     [1489, 549],
     [1462, 572],
     [1427, 593],
     [1426, 599],
     [1453, 622],
     [1497, 608],
     [1539, 605],
     [1560, 608],
     [1614, 629],
     [1667, 622],
     [1692, 605],
     [1690, 601],
     [1633, 575],
     [1518, 544]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1722, 597]}],
   "box": [1522, 506, 1828, 723],
   "polygons": [
    [
     [1541, 507],
     [1535, 509],
     [1522, 524],
     [1526, 540],
     [1681, 589],
     [1735, 617],
     [1744, 626],
     [1740, 657],
     [1742, 669],
     [1771, 717],
     [1782, 723],
     [1827, 719],
     [1820, 660],
     [1821, 644],
     [1828, 626],
     [1794, 596],
     [1797, 588],
     [1817, 566],
     [1814, 532],
     [1782, 524],
     [1773, 526],
     [1736, 566],
     [1728, 569],
     [1610, 522],
     [1582, 508],
     [1552, 506]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1870, 671]}],
   "box": [1812, 563, 1915, 789],
   "polygons": [
    [
     [1863, 564],
     [1812, 598],
     [1822, 610],
     [1856, 638],
     [1846, 655],
     [1847, 678],
     [1863, 734],
     [1861, 744],
     [1844, 760],
     [1877, 789],
     [1911, 780],
     [1915, 776],
     [1882, 679],
     [1883, 672],
     [1896, 667],
     [1901, 633],
     [1872, 612],
     [1874, 607],
     [1900, 597],
     [1900, 588],
     [1894, 574],
     [1867, 563]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1078, 674]}],
   "box": [897, 600, 1294, 758],
   "polygons": [
    [
     [1072, 601],
     [1020, 609],
     [918, 641],
     [911, 651],
     [906, 670],
     [897, 728],
     [900, 740],
     [934, 750],
     [979, 758],
     [1034, 754],
     [1111, 734],
     [1224, 699],
     [1275, 681],
     [1294, 668],
     [1280, 628],
     [1275, 621],
     [1265, 616],
     [1080, 600]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 683]}],
   "box": [443, 632, 618, 760],
   "polygons": [
    [
     [463, 633],
     [443, 643],
     [447, 664],
     [451, 669],
     [470, 675],
     [484, 691],
     [486, 715],
     [503, 735],
     [502, 754],
     [505, 760],
     [559, 753],
     [568, 749],
     [568, 699],
     [573, 672],
     [594, 646],
     [618, 633],
     [615, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [331, 704]}],
   "box": [179, 635, 492, 761],
   "polygons": [
    [
     [329, 636],
     [199, 660],
     [179, 667],
     [191, 708],
     [213, 756],
     [236, 761],
     [460, 758],
     [485, 756],
     [491, 753],
     [492, 726],
     [472, 717],
     [465, 681],
     [432, 676],
     [431, 643],
     [387, 651],
     [375, 650],
     [364, 647],
     [344, 635]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [719, 692]}],
   "box": [573, 630, 876, 758],
   "polygons": [
    [
     [687, 631],
     [606, 645],
     [590, 651],
     [574, 700],
     [573, 739],
     [576, 756],
     [585, 758],
     [679, 749],
     [811, 744],
     [855, 726],
     [868, 713],
     [876, 684],
     [874, 664],
     [868, 651],
     [807, 633],
     [733, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [936, 607]}],
   "box": [832, 569, 1046, 646],
   "polygons": [
    [
     [1034, 570],
     [870, 603],
     [838, 611],
     [832, 615],
     [837, 628],
     [845, 635],
     [873, 646],
     [885, 644],
     [1034, 597],
     [1042, 592],
     [1046, 585],
     [1046, 574],
     [1042, 569]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1128, 578]}],
   "box": [1052, 550, 1205, 601],
   "polygons": [
    [
     [1150, 551],
     [1058, 565],
     [1054, 571],
     [1052, 592],
     [1057, 597],
     [1124, 601],
     [1179, 600],
     [1196, 596],
     [1205, 590],
     [1201, 580],
     [1186, 566],
     [1167, 552],
     [1159, 550]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1553, 590]}],
   "box": [1425, 545, 1692, 630],
   "polygons": [
    [
     [1507, 546],
     [1489, 550],
     [1460, 574],
     [1425, 595],
     [1425, 600],
     [1452, 623],
     [1491, 610],
     [1541, 606],
     [1564, 611],
     [1612, 630],
     [1667, 623],
     [1692, 606],
     [1679, 597],
     [1629, 575],
     [1516, 545]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1722, 599]}],
   "box": [1522, 508, 1827, 724],
   "polygons": [
    [
     [1538, 509],
     [1522, 525],
     [1525, 541],
     [1674, 588],
     [1737, 620],
     [1743, 627],
     [1739, 658],
     [1741, 671],
     [1770, 719],
     [1778, 724],
     [1825, 721],
     [1819, 661],
     [1820, 645],
     [1827, 629],
     [1820, 619],
     [1794, 598],
     [1796, 590],
     [1817, 566],
     [1814, 534],
     [1784, 526],
     [1774, 527],
     [1735, 568],
     [1730, 570],
     [1711, 565],
     [1583, 510],
     [1569, 508]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1869, 673]}],
   "box": [1812, 564, 1913, 790],
   "polygons": [
    [
     [1864, 565],
     [1812, 599],
     [1821, 611],
     [1856, 641],
     [1846, 654],
     [1846, 680],
     [1862, 737],
     [1860, 744],
     [1843, 760],
     [1854, 774],
     [1875, 790],
     [1904, 783],
     [1913, 778],
     [1911, 765],
     [1881, 680],
     [1882, 673],
     [1895, 669],
     [1901, 643],
     [1900, 634],
     [1871, 613],
     [1873, 609],
     [1901, 598],
     [1900, 591],
     [1896, 577],
     [1866, 564]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1077, 674]}],
   "box": [896, 601, 1293, 757],
   "polygons": [
    [
     [1062, 602],
     [1021, 609],
     [916, 642],
     [910, 651],
     [905, 670],
     [896, 726],
     [898, 739],
     [908, 744],
     [968, 757],
     [1014, 757],
     [1033, 754],
     [1114, 733],
     [1237, 695],
     [1273, 682],
     [1288, 674],
     [1293, 668],
     [1279, 629],
     [1273, 621],
     [1265, 617],
     [1089, 601]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 682]}],
   "box": [444, 632, 617, 759],
   "polygons": [
    [
     [462, 633],
     [444, 642],
     [447, 663],
     [450, 668],
     [471, 675],
     [485, 692],
     [486, 714],
     [503, 735],
     [504, 759],
     [567, 749],
     [566, 714],
     [572, 674],
     [595, 645],
     [617, 634],
     [615, 632]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [332, 703]}],
   "box": [181, 634, 492, 759],
   "polygons": [
    [
     [332, 635],
     [183, 664],
     [181, 667],
     [189, 697],
     [212, 752],
     [233, 759],
     [445, 757],
     [490, 753],
     [492, 725],
     [472, 716],
     [465, 679],
     [433, 676],
     [432, 642],
     [389, 650],
     [374, 649],
     [344, 634]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [718, 691]}],
   "box": [574, 630, 875, 757],
   "polygons": [
    [
     [685, 631],
     [604, 645],
     [588, 653],
     [574, 697],
     [575, 755],
     [583, 757],
     [682, 748],
     [813, 743],
     [848, 729],
     [859, 722],
     [868, 711],
     [875, 684],
     [869, 652],
     [807, 633],
     [736, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [935, 608]}],
   "box": [832, 570, 1045, 646],
   "polygons": [
    [
     [1030, 571],
     [866, 604],
     [835, 612],
     [832, 614],
     [833, 620],
     [840, 632],
     [854, 640],
     [872, 646],
     [885, 644],
     [1030, 599],
     [1041, 593],
     [1045, 587],
     [1043, 570]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1127, 579]}],
   "box": [1053, 551, 1204, 602],
   "polygons": [
    [
     [1148, 552],
     [1056, 566],
     [1053, 596],
     [1131, 602],
     [1175, 601],
     [1195, 597],
     [1204, 591],
     [1202, 583],
     [1188, 569],
     [1167, 553],
     [1160, 551]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1553, 591]}],
   "box": [1423, 546, 1691, 631],
   "polygons": [
    [
     [1509, 547],
     [1489, 551],
     [1460, 575],
     [1423, 599],
     [1454, 625],
     [1492, 611],
     [1528, 608],
     [1557, 610],
     [1609, 631],
     [1664, 625],
     [1691, 608],
     [1689, 604],
     [1630, 577],
     [1513, 546]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1721, 600]}],
   "box": [1522, 509, 1826, 725],
   "polygons": [
    [
     [1539, 510],
     [1522, 526],
     [1524, 542],
     [1675, 590],
     [1732, 619],
     [1742, 627],
     [1738, 660],
     [1740, 672],
     [1769, 721],
     [1775, 725],
     [1824, 722],
     [1818, 661],
     [1819, 648],
     [1826, 633],
     [1825, 626],
     [1793, 598],
     [1816, 571],
     [1815, 536],
     [1786, 528],
     [1774, 529],
     [1737, 568],
     [1728, 572],
     [1610, 525],
     [1582, 511],
     [1557, 509]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1869, 674]}],
   "box": [1811, 566, 1912, 792],
   "polygons": [
    [
     [1864, 567],
     [1831, 586],
     [1811, 602],
     [1855, 641],
     [1853, 648],
     [1845, 657],
     [1844, 670],
     [1860, 734],
     [1859, 745],
     [1841, 762],
     [1853, 776],
     [1874, 792],
     [1910, 782],
     [1912, 779],
     [1880, 681],
     [1881, 675],
     [1894, 671],
     [1901, 641],
     [1896, 632],
     [1871, 615],
     [1872, 611],
     [1900, 600],
     [1900, 593],
     [1895, 578],
     [1866, 566]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1076, 675]}],
   "box": [895, 602, 1292, 758],
   "polygons": [
    [
     [1055, 603],
     [1018, 610],
     [917, 641],
     [906, 661],
     [895, 724],
     [897, 739],
     [936, 751],
     [976, 758],
     [1026, 755],
     [1078, 743],
     [1231, 697],
     [1271, 683],
     [1291, 671],
     [1292, 667],
     [1280, 634],
     [1275, 625],
     [1267, 619],
     [1250, 615],
     [1095, 602]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 681]}],
   "box": [444, 631, 617, 758],
   "polygons": [
    [
     [467, 632],
     [457, 634],
     [444, 642],
     [448, 664],
     [452, 668],
     [471, 674],
     [484, 689],
     [486, 713],
     [503, 734],
     [504, 758],
     [560, 751],
     [567, 747],
     [567, 701],
     [573, 671],
     [593, 646],
     [617, 633],
     [596, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [333, 702]}],
   "box": [182, 634, 492, 758],
   "polygons": [
    [
     [330, 635],
     [203, 658],
     [182, 665],
     [194, 707],
     [216, 754],
     [235, 758],
     [459, 756],
     [490, 752],
     [492, 725],
     [472, 715],
     [465, 679],
     [433, 675],
     [432, 642],
     [386, 650],
     [366, 646],
     [346, 634]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [718, 691]}],
   "box": [572, 630, 874, 755],
   "polygons": [
    [
     [685, 631],
     [599, 646],
     [587, 654],
     [573, 703],
     [572, 736],
     [575, 755],
     [811, 743],
     [852, 726],
     [867, 711],
     [874, 684],
     [871, 658],
     [866, 651],
     [804, 633],
     [732, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [934, 608]}],
   "box": [831, 570, 1045, 646],
   "polygons": [
    [
     [1035, 571],
     [872, 603],
     [831, 615],
     [836, 628],
     [843, 635],
     [870, 646],
     [886, 644],
     [1033, 598],
     [1041, 593],
     [1045, 586],
     [1044, 571],
     [1039, 570]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1127, 580]}],
   "box": [1052, 552, 1204, 602],
   "polygons": [
    [
     [1146, 553],
     [1057, 566],
     [1053, 572],
     [1052, 596],
     [1116, 602],
     [1171, 602],
     [1187, 600],
     [1204, 591],
     [1200, 582],
     [1190, 572],
     [1161, 552]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1552, 593]}],
   "box": [1424, 548, 1690, 633],
   "polygons": [
    [
     [1504, 549],
     [1483, 556],
     [1458, 577],
     [1424, 597],
     [1424, 603],
     [1453, 626],
     [1491, 612],
     [1529, 609],
     [1552, 610],
     [1614, 633],
     [1664, 626],
     [1690, 609],
     [1688, 605],
     [1626, 577],
     [1518, 548]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1720, 601]}],
   "box": [1521, 510, 1826, 727],
   "polygons": [
    [
     [1541, 511],
     [1533, 514],
     [1521, 529],
     [1525, 544],
     [1679, 593],
     [1734, 622],
     [1742, 631],
     [1738, 671],
     [1766, 720],
     [1771, 725],
     [1779, 727],
     [1822, 724],
     [1817, 662],
     [1818, 650],
     [1826, 631],
     [1793, 601],
     [1794, 595],
     [1816, 572],
     [1814, 537],
     [1783, 529],
     [1775, 530],
     [1737, 569],
     [1729, 573],
     [1718, 571],
     [1609, 526],
     [1584, 513],
     [1551, 510]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1868, 676]}],
   "box": [1811, 568, 1910, 793],
   "polygons": [
    [
     [1863, 569],
     [1833, 586],
     [1811, 603],
     [1819, 613],
     [1854, 642],
     [1853, 648],
     [1844, 659],
     [1843, 673],
     [1859, 738],
     [1858, 746],
     [1839, 764],
     [1872, 793],
     [1910, 781],
     [1879, 682],
     [1881, 676],
     [1894, 671],
     [1900, 646],
     [1899, 637],
     [1870, 616],
     [1871, 613],
     [1901, 600],
     [1895, 580],
     [1867, 568]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1075, 675]}],
   "box": [894, 602, 1290, 758],
   "polygons": [
    [
     [1063, 603],
     [1012, 612],
     [915, 642],
     [907, 655],
     [902, 676],
     [894, 723],
     [896, 740],
     [926, 749],
     [974, 758],
     [1031, 754],
     [1092, 739],
     [1238, 695],
     [1278, 680],
     [1290, 671],
     [1280, 637],
     [1274, 626],
     [1264, 619],
     [1084, 602]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 681]}],
   "box": [445, 631, 617, 757],
   "polygons": [
    [
     [465, 632],
     [445, 641],
     [448, 662],
     [451, 667],
     [470, 673],
     [485, 690],
     [486, 713],
     [503, 735],
     [503, 757],
     [566, 748],
     [567, 699],
     [572, 673],
     [593, 646],
     [617, 633],
     [598, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [334, 701]}],
   "box": [185, 633, 493, 757],
   "polygons": [
    [
     [336, 634],
     [185, 663],
     [191, 693],
     [214, 750],
     [223, 755],
     [237, 757],
     [464, 755],
     [490, 751],
     [493, 727],
     [490, 722],
     [472, 714],
     [465, 678],
     [433, 674],
     [432, 641],
     [393, 649],
     [375, 648],
     [345, 633]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [717, 691]}],
   "box": [573, 630, 873, 754],
   "polygons": [
    [
     [685, 631],
     [603, 645],
     [593, 648],
     [586, 655],
     [573, 700],
     [574, 754],
     [809, 743],
     [851, 726],
     [865, 713],
     [873, 687],
     [872, 666],
     [868, 653],
     [809, 634],
     [729, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [934, 608]}],
   "box": [831, 571, 1044, 646],
   "polygons": [
    [
     [1031, 572],
     [847, 609],
     [831, 614],
     [832, 620],
     [836, 629],
     [844, 636],
     [868, 646],
     [882, 645],
     [1027, 601],
     [1040, 594],
     [1044, 588],
     [1044, 573],
     [1041, 571]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1126, 581]}],
   "box": [1052, 553, 1203, 604],
   "polygons": [
    [
     [1144, 554],
     [1056, 567],
     [1052, 578],
     [1052, 597],
     [1148, 604],
     [1185, 601],
     [1200, 595],
     [1203, 591],
     [1198, 581],
     [1178, 563],
     [1161, 553]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1551, 594]}],
   "box": [1422, 549, 1690, 634],
   "polygons": [
    [
     [1505, 550],
     [1483, 557],
     [1462, 575],
     [1422, 599],
     [1424, 605],
     [1451, 627],
     [1491, 613],
     [1532, 610],
     [1554, 612],
     [1610, 634],
     [1665, 627],
     [1690, 610],
     [1679, 602],
     [1627, 579],
     [1516, 549]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1720, 603]}],
   "box": [1521, 512, 1825, 728],
   "polygons": [
    [
     [1538, 513],
     [1530, 518],
     [1521, 530],
     [1524, 545],
     [1680, 595],
     [1736, 625],
     [1741, 631],
     [1737, 672],
     [1766, 723],
     [1774, 728],
     [1821, 725],
     [1816, 662],
     [1818, 649],
     [1825, 634],
     [1823, 628],
     [1792, 601],
     [1816, 573],
     [1815, 539],
     [1785, 531],
     [1775, 532],
     [1735, 572],
     [1726, 575],
     [1608, 527],
     [1583, 514],
     [1565, 512]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1867, 677]}],
   "box": [1811, 569, 1909, 795],
   "polygons": [
    [
     [1864, 570],
     [1829, 590],
     [1811, 604],
     [1819, 615],
     [1854, 645],
     [1843, 661],
     [1844, 687],
     [1858, 741],
     [1855, 750],
     [1838, 765],
     [1858, 786],
     [1873, 795],
     [1906, 785],
     [1909, 781],
     [1878, 682],
     [1880, 678],
     [1893, 673],
     [1900, 643],
     [1896, 636],
     [1870, 618],
     [1872, 614],
     [1901, 601],
     [1894, 581],
     [1865, 569]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1074, 675]}],
   "box": [892, 603, 1289, 758],
   "polygons": [
    [
     [1056, 604],
     [1017, 611],
     [915, 642],
     [908, 651],
     [903, 667],
     [892, 730],
     [895, 740],
     [933, 751],
     [972, 758],
     [1025, 755],
     [1088, 740],
     [1229, 698],
     [1274, 682],
     [1289, 672],
     [1279, 638],
     [1274, 628],
     [1264, 620],
     [1090, 603]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 681]}],
   "box": [445, 631, 617, 757],
   "polygons": [
    [
     [464, 632],
     [445, 642],
     [449, 663],
     [452, 667],
     [470, 672],
     [484, 688],
     [486, 712],
     [503, 734],
     [501, 750],
     [504, 757],
     [558, 750],
     [566, 746],
     [567, 698],
     [573, 670],
     [593, 646],
     [617, 633],
     [601, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [334, 701]}],
   "box": [185, 633, 491, 756],
   "polygons": [
    [
     [333, 634],
     [187, 662],
     [185, 665],
     [192, 691],
     [215, 749],
     [225, 754],
     [241, 756],
     [466, 754],
     [490, 750],
     [491, 722],
     [472, 714],
     [466, 678],
     [434, 674],
     [433, 641],
     [389, 649],
     [370, 646],
     [348, 633]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [717, 691]}],
   "box": [571, 630, 873, 754],
   "polygons": [
    [
     [685, 631],
     [602, 645],
     [593, 648],
     [586, 655],
     [573, 698],
     [571, 740],
     [575, 754],
     [676, 747],
     [806, 743],
     [850, 726],
     [864, 713],
     [873, 680],
     [866, 652],
     [808, 634],
     [727, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [934, 609]}],
   "box": [831, 572, 1044, 647],
   "polygons": [
    [
     [1029, 573],
     [852, 608],
     [831, 614],
     [832, 622],
     [840, 634],
     [851, 640],
     [873, 647],
     [883, 645],
     [1030, 600],
     [1039, 595],
     [1044, 588],
     [1042, 572]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1126, 581]}],
   "box": [1051, 553, 1202, 604],
   "polygons": [
    [
     [1151, 554],
     [1055, 568],
     [1051, 597],
     [1060, 600],
     [1129, 604],
     [1183, 602],
     [1202, 594],
     [1200, 585],
     [1184, 569],
     [1165, 555],
     [1156, 553]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1551, 595]}],
   "box": [1423, 550, 1689, 635],
   "polygons": [
    [
     [1506, 551],
     [1486, 556],
     [1459, 578],
     [1423, 599],
     [1423, 605],
     [1451, 628],
     [1491, 614],
     [1534, 611],
     [1553, 613],
     [1608, 635],
     [1665, 628],
     [1689, 612],
     [1684, 606],
     [1626, 580],
     [1515, 550]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1720, 604]}],
   "box": [1521, 513, 1825, 729],
   "polygons": [
    [
     [1539, 514],
     [1521, 531],
     [1524, 546],
     [1676, 595],
     [1735, 626],
     [1740, 631],
     [1736, 673],
     [1766, 726],
     [1772, 729],
     [1820, 726],
     [1817, 652],
     [1825, 634],
     [1792, 603],
     [1794, 597],
     [1816, 575],
     [1814, 540],
     [1783, 532],
     [1776, 533],
     [1729, 576],
     [1713, 572],
     [1582, 515],
     [1558, 513]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1866, 679]}],
   "box": [1811, 571, 1907, 796],
   "polygons": [
    [
     [1864, 572],
     [1811, 605],
     [1815, 613],
     [1854, 647],
     [1843, 661],
     [1842, 670],
     [1844, 692],
     [1857, 743],
     [1855, 750],
     [1837, 766],
     [1844, 776],
     [1870, 796],
     [1907, 784],
     [1905, 770],
     [1878, 687],
     [1880, 679],
     [1893, 674],
     [1899, 650],
     [1898, 640],
     [1870, 619],
     [1871, 616],
     [1901, 603],
     [1895, 583],
     [1867, 571]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1073, 676]}],
   "box": [891, 603, 1288, 758],
   "polygons": [
    [
     [1066, 604],
     [1036, 607],
     [1007, 614],
     [913, 643],
     [905, 657],
     [898, 689],
     [891, 729],
     [894, 740],
     [932, 751],
     [971, 758],
     [1024, 755],
     [1084, 741],
     [1233, 697],
     [1270, 684],
     [1288, 672],
     [1274, 630],
     [1261, 620],
     [1077, 603]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 680]}],
   "box": [446, 631, 617, 756],
   "polygons": [
    [
     [463, 632],
     [446, 641],
     [450, 664],
     [471, 672],
     [484, 687],
     [486, 711],
     [503, 734],
     [503, 756],
     [559, 749],
     [566, 745],
     [565, 710],
     [573, 670],
     [594, 645],
     [617, 633],
     [607, 631]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [335, 700]}],
   "box": [187, 632, 491, 755],
   "polygons": [
    [
     [338, 633],
     [189, 661],
     [187, 664],
     [194, 692],
     [216, 747],
     [227, 753],
     [249, 755],
     [467, 753],
     [490, 749],
     [491, 721],
     [472, 713],
     [466, 677],
     [434, 673],
     [434, 641],
     [395, 648],
     [376, 647],
     [347, 632]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [716, 691]}],
   "box": [571, 630, 872, 753],
   "polygons": [
    [
     [685, 631],
     [606, 644],
     [591, 649],
     [585, 657],
     [573, 696],
     [571, 728],
     [574, 753],
     [810, 742],
     [850, 725],
     [864, 711],
     [872, 681],
     [866, 653],
     [807, 634],
     [726, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [933, 609]}],
   "box": [830, 572, 1044, 646],
   "polygons": [
    [
     [1033, 573],
     [866, 605],
     [830, 616],
     [834, 628],
     [844, 637],
     [865, 646],
     [883, 645],
     [1033, 599],
     [1041, 593],
     [1044, 586],
     [1043, 574],
     [1039, 572]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1125, 582]}],
   "box": [1051, 554, 1201, 605],
   "polygons": [
    [
     [1148, 555],
     [1056, 568],
     [1052, 573],
     [1051, 599],
     [1135, 605],
     [1181, 603],
     [1201, 595],
     [1200, 587],
     [1188, 574],
     [1165, 556],
     [1157, 554]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1550, 596]}],
   "box": [1421, 551, 1688, 636],
   "polygons": [
    [
     [1507, 552],
     [1487, 556],
     [1460, 578],
     [1421, 601],
     [1422, 606],
     [1449, 629],
     [1491, 615],
     [1537, 612],
     [1563, 618],
     [1607, 636],
     [1665, 629],
     [1688, 613],
     [1686, 609],
     [1627, 582],
     [1513, 551]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1719, 606]}],
   "box": [1521, 514, 1824, 731],
   "polygons": [
    [
     [1541, 515],
     [1533, 518],
     [1521, 531],
     [1523, 547],
     [1677, 597],
     [1735, 628],
     [1739, 633],
     [1735, 675],
     [1762, 724],
     [1769, 730],
     [1798, 731],
     [1818, 728],
     [1816, 653],
     [1824, 636],
     [1819, 628],
     [1792, 606],
     [1794, 598],
     [1816, 576],
     [1815, 542],
     [1785, 534],
     [1775, 535],
     [1733, 576],
     [1719, 576],
     [1581, 516],
     [1550, 514]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1865, 680]}],
   "box": [1810, 573, 1906, 797],
   "polygons": [
    [
     [1863, 574],
     [1829, 593],
     [1810, 608],
     [1853, 648],
     [1843, 661],
     [1841, 672],
     [1843, 694],
     [1855, 741],
     [1854, 750],
     [1835, 767],
     [1845, 780],
     [1867, 797],
     [1896, 790],
     [1906, 784],
     [1877, 689],
     [1878, 681],
     [1891, 677],
     [1899, 647],
     [1895, 639],
     [1869, 620],
     [1900, 606],
     [1899, 598],
     [1895, 585],
     [1868, 573]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1072, 676]}],
   "box": [890, 604, 1287, 758],
   "polygons": [
    [
     [1056, 605],
     [1008, 614],
     [914, 642],
     [907, 650],
     [902, 664],
     [890, 726],
     [892, 739],
     [935, 752],
     [969, 758],
     [1023, 755],
     [1094, 738],
     [1230, 698],
     [1270, 684],
     [1287, 672],
     [1276, 637],
     [1271, 628],
     [1263, 622],
     [1244, 618],
     [1085, 604]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 680]}],
   "box": [447, 630, 617, 755],
   "polygons": [
    [
     [470, 631],
     [447, 640],
     [450, 662],
     [453, 666],
     [472, 672],
     [486, 689],
     [486, 711],
     [502, 731],
     [503, 755],
     [554, 749],
     [565, 745],
     [566, 702],
     [572, 672],
     [594, 645],
     [617, 633],
     [528, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [336, 699]}],
   "box": [188, 632, 491, 753],
   "polygons": [
    [
     [334, 633],
     [190, 660],
     [188, 663],
     [196, 694],
     [216, 745],
     [222, 750],
     [237, 753],
     [468, 752],
     [489, 748],
     [491, 721],
     [472, 712],
     [466, 677],
     [434, 672],
     [434, 640],
     [390, 648],
     [372, 645],
     [350, 632]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [715, 690]}],
   "box": [570, 630, 871, 752],
   "polygons": [
    [
     [683, 631],
     [599, 645],
     [588, 652],
     [572, 699],
     [570, 736],
     [573, 752],
     [806, 742],
     [847, 726],
     [861, 714],
     [871, 682],
     [869, 663],
     [864, 652],
     [806, 634],
     [727, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [933, 610]}],
   "box": [831, 573, 1044, 647],
   "polygons": [
    [
     [1028, 574],
     [853, 608],
     [831, 614],
     [831, 622],
     [834, 629],
     [849, 640],
     [868, 647],
     [883, 645],
     [1029, 601],
     [1042, 591],
     [1044, 583],
     [1040, 573]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1124, 583]}],
   "box": [1050, 555, 1201, 606],
   "polygons": [
    [
     [1145, 556],
     [1054, 569],
     [1051, 576],
     [1050, 599],
     [1148, 606],
     [1183, 603],
     [1201, 595],
     [1199, 587],
     [1192, 579],
     [1171, 561],
     [1158, 555]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1549, 597]}],
   "box": [1420, 552, 1688, 637],
   "polygons": [
    [
     [1507, 553],
     [1487, 557],
     [1458, 580],
     [1420, 602],
     [1421, 607],
     [1449, 630],
     [1490, 616],
     [1538, 613],
     [1562, 619],
     [1605, 637],
     [1661, 631],
     [1688, 614],
     [1685, 610],
     [1626, 583],
     [1512, 552]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1718, 607]}],
   "box": [1520, 516, 1823, 732],
   "polygons": [
    [
     [1537, 517],
     [1520, 534],
     [1522, 548],
     [1676, 598],
     [1732, 628],
     [1739, 637],
     [1733, 666],
     [1734, 677],
     [1761, 726],
     [1772, 732],
     [1817, 729],
     [1815, 655],
     [1823, 638],
     [1821, 632],
     [1791, 606],
     [1793, 600],
     [1816, 576],
     [1814, 543],
     [1783, 535],
     [1774, 537],
     [1740, 571],
     [1727, 579],
     [1610, 532],
     [1583, 518],
     [1567, 516]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1865, 682]}],
   "box": [1810, 574, 1904, 798],
   "polygons": [
    [
     [1864, 575],
     [1810, 608],
     [1814, 616],
     [1852, 649],
     [1851, 654],
     [1841, 665],
     [1840, 675],
     [1854, 745],
     [1852, 752],
     [1833, 769],
     [1853, 790],
     [1865, 798],
     [1895, 791],
     [1904, 786],
     [1876, 690],
     [1878, 682],
     [1891, 677],
     [1898, 651],
     [1897, 643],
     [1869, 622],
     [1870, 619],
     [1900, 606],
     [1894, 586],
     [1866, 574]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1071, 676]}],
   "box": [889, 604, 1286, 759],
   "polygons": [
    [
     [1066, 605],
     [1016, 612],
     [912, 643],
     [904, 655],
     [900, 669],
     [889, 725],
     [891, 739],
     [934, 752],
     [969, 758],
     [984, 759],
     [1027, 754],
     [1097, 737],
     [1243, 694],
     [1278, 680],
     [1286, 672],
     [1271, 630],
     [1257, 621],
     [1071, 604]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 679]}],
   "box": [447, 630, 618, 754],
   "polygons": [
    [
     [466, 631],
     [447, 640],
     [451, 664],
     [473, 672],
     [485, 687],
     [486, 710],
     [502, 730],
     [502, 754],
     [564, 745],
     [566, 699],
     [572, 671],
     [595, 644],
     [618, 632],
     [558, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [336, 698]}],
   "box": [190, 631, 491, 752],
   "polygons": [
    [
     [339, 632],
     [210, 654],
     [190, 660],
     [197, 692],
     [217, 744],
     [227, 750],
     [242, 752],
     [469, 751],
     [489, 747],
     [491, 720],
     [472, 712],
     [466, 676],
     [435, 672],
     [435, 640],
     [395, 647],
     [378, 646],
     [349, 631]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [715, 690]}],
   "box": [570, 630, 870, 752],
   "polygons": [
    [
     [682, 631],
     [598, 645],
     [586, 654],
     [572, 697],
     [570, 727],
     [573, 752],
     [809, 741],
     [849, 724],
     [863, 709],
     [870, 683],
     [867, 657],
     [863, 652],
     [805, 634],
     [728, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [932, 610]}],
   "box": [829, 573, 1042, 647],
   "polygons": [
    [
     [1033, 574],
     [858, 607],
     [831, 614],
     [829, 617],
     [835, 631],
     [844, 638],
     [867, 647],
     [883, 645],
     [1027, 602],
     [1038, 596],
     [1042, 590],
     [1042, 575],
     [1037, 573]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1124, 583]}],
   "box": [1049, 556, 1200, 606],
   "polygons": [
    [
     [1142, 557],
     [1053, 570],
     [1049, 599],
     [1130, 606],
     [1181, 604],
     [1200, 596],
     [1197, 586],
     [1182, 571],
     [1159, 556]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1549, 599]}],
   "box": [1421, 554, 1687, 639],
   "polygons": [
    [
     [1502, 555],
     [1485, 559],
     [1459, 580],
     [1421, 602],
     [1421, 609],
     [1448, 631],
     [1490, 617],
     [1549, 616],
     [1609, 639],
     [1661, 632],
     [1687, 616],
     [1682, 610],
     [1625, 584],
     [1516, 554]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1718, 608]}],
   "box": [1520, 517, 1822, 733],
   "polygons": [
    [
     [1538, 518],
     [1520, 535],
     [1523, 550],
     [1677, 600],
     [1731, 629],
     [1738, 636],
     [1732, 668],
     [1733, 678],
     [1761, 729],
     [1768, 733],
     [1815, 731],
     [1814, 657],
     [1822, 640],
     [1820, 633],
     [1791, 609],
     [1792, 602],
     [1815, 580],
     [1817, 563],
     [1814, 545],
     [1785, 537],
     [1775, 538],
     [1736, 576],
     [1728, 580],
     [1716, 578],
     [1582, 519],
     [1560, 517]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1864, 683]}],
   "box": [1809, 576, 1902, 800],
   "polygons": [
    [
     [1863, 577],
     [1830, 595],
     [1809, 611],
     [1851, 650],
     [1850, 656],
     [1840, 667],
     [1839, 678],
     [1852, 741],
     [1851, 753],
     [1832, 770],
     [1839, 780],
     [1865, 800],
     [1902, 789],
     [1900, 773],
     [1875, 691],
     [1876, 684],
     [1890, 679],
     [1897, 655],
     [1896, 644],
     [1869, 624],
     [1871, 620],
     [1900, 608],
     [1893, 587],
     [1867, 576]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1070, 677]}],
   "box": [888, 605, 1285, 759],
   "polygons": [
    [
     [1054, 606],
     [1013, 613],
     [910, 644],
     [905, 651],
     [898, 674],
     [888, 724],
     [889, 738],
     [894, 742],
     [953, 756],
     [983, 759],
     [1014, 756],
     [1074, 743],
     [1214, 703],
     [1263, 687],
     [1284, 675],
     [1285, 671],
     [1273, 636],
     [1268, 628],
     [1260, 623],
     [1083, 605]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [524, 679]}],
   "box": [448, 630, 618, 753],
   "polygons": [
    [
     [465, 631],
     [448, 639],
     [451, 662],
     [471, 670],
     [485, 686],
     [486, 709],
     [502, 730],
     [502, 753],
     [564, 744],
     [566, 698],
     [572, 671],
     [593, 645],
     [618, 632],
     [581, 630]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [337, 697]}],
   "box": [191, 631, 491, 751],
   "polygons": [
    [
     [337, 632],
     [219, 652],
     [191, 660],
     [199, 693],
     [219, 744],
     [225, 748],
     [243, 751],
     [473, 750],
     [489, 747],
     [491, 719],
     [473, 712],
     [466, 675],
     [435, 671],
     [435, 639],
     [392, 647],
     [371, 643],
     [351, 631]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [715, 690]}],
   "box": [569, 630, 869, 751],
   "polygons": [
    [
     [682, 631],
     [602, 644],
     [593, 647],
     [586, 654],
     [572, 696],
     [569, 740],
     [572, 751],
     [807, 741],
     [848, 724],
     [861, 711],
     [869, 686],
     [868, 665],
     [864, 653],
     [804, 634],
     [727, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [932, 610]}],
   "box": [829, 574, 1042, 647],
   "polygons": [
    [
     [1028, 575],
     [846, 610],
     [829, 615],
     [830, 621],
     [840, 636],
     [865, 647],
     [879, 646],
     [1030, 601],
     [1042, 590],
     [1042, 576],
     [1039, 574]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1124, 584]}],
   "box": [1049, 556, 1200, 607],
   "polygons": [
    [
     [1147, 557],
     [1054, 570],
     [1050, 579],
     [1049, 600],
     [1141, 607],
     [1178, 605],
     [1200, 596],
     [1197, 587],
     [1182, 572],
     [1165, 559],
     [1155, 556]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1548, 600]}],
   "box": [1420, 555, 1686, 640],
   "polygons": [
    [
     [1501, 556],
     [1485, 560],
     [1454, 584],
     [1420, 603],
     [1420, 609],
     [1448, 632],
     [1488, 618],
     [1548, 617],
     [1609, 640],
     [1661, 633],
     [1686, 617],
     [1683, 612],
     [1627, 586],
     [1516, 555]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1717, 609]}],
   "box": [1520, 518, 1822, 734],
   "polygons": [
    [
     [1538, 519],
     [1520, 535],
     [1523, 551],
     [1671, 599],
     [1730, 630],
     [1737, 637],
     [1731, 670],
     [1732, 679],
     [1759, 729],
     [1767, 734],
     [1814, 732],
     [1813, 660],
     [1822, 640],
     [1820, 635],
     [1790, 608],
     [1815, 581],
     [1817, 563],
     [1814, 546],
     [1784, 538],
     [1776, 539],
     [1732, 580],
     [1719, 580],
     [1611, 535],
     [1584, 521],
     [1557, 518]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1863, 684]}],
   "box": [1809, 577, 1901, 801],
   "polygons": [
    [
     [1864, 578],
     [1809, 611],
     [1818, 623],
     [1851, 652],
     [1840, 667],
     [1838, 683],
     [1851, 743],
     [1850, 754],
     [1830, 772],
     [1862, 801],
     [1901, 789],
     [1874, 691],
     [1876, 685],
     [1890, 679],
     [1897, 654],
     [1896, 646],
     [1868, 625],
     [1869, 622],
     [1900, 609],
     [1894, 589],
     [1866, 577]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1069, 677]}],
   "box": [887, 605, 1284, 759],
   "polygons": [
    [
     [1064, 606],
     [1023, 611],
     [910, 644],
     [904, 652],
     [897, 676],
     [887, 725],
     [889, 739],
     [924, 750],
     [967, 758],
     [984, 759],
     [1025, 754],
     [1081, 741],
     [1231, 698],
     [1268, 685],
     [1282, 677],
     [1284, 673],
     [1272, 636],
     [1264, 626],
     [1257, 623],
     [1070, 605]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 678]}],
   "box": [449, 629, 618, 752],
   "polygons": [
    [
     [473, 630],
     [464, 631],
     [449, 639],
     [451, 658],
     [453, 664],
     [472, 670],
     [485, 685],
     [486, 708],
     [502, 729],
     [501, 752],
     [561, 745],
     [565, 739],
     [566, 698],
     [574, 667],
     [593, 645],
     [618, 632],
     [505, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [338, 696]}],
   "box": [194, 630, 491, 750],
   "polygons": [
    [
     [344, 631],
     [194, 658],
     [200, 691],
     [220, 743],
     [230, 748],
     [248, 750],
     [474, 749],
     [489, 746],
     [491, 719],
     [472, 710],
     [467, 675],
     [437, 672],
     [435, 669],
     [438, 645],
     [436, 639],
     [398, 646],
     [379, 645],
     [349, 630]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [714, 690]}],
   "box": [569, 630, 868, 750],
   "polygons": [
    [
     [682, 631],
     [601, 644],
     [594, 646],
     [585, 655],
     [572, 694],
     [569, 730],
     [571, 750],
     [804, 741],
     [843, 726],
     [861, 709],
     [868, 686],
     [867, 664],
     [863, 653],
     [802, 634],
     [724, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [931, 611]}],
   "box": [828, 575, 1041, 647],
   "polygons": [
    [
     [1026, 576],
     [865, 606],
     [835, 613],
     [828, 618],
     [839, 636],
     [864, 647],
     [879, 646],
     [1026, 603],
     [1037, 597],
     [1041, 591],
     [1039, 575]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1123, 585]}],
   "box": [1048, 557, 1199, 608],
   "polygons": [
    [
     [1145, 558],
     [1053, 571],
     [1050, 576],
     [1048, 600],
     [1148, 608],
     [1182, 605],
     [1199, 597],
     [1197, 589],
     [1189, 580],
     [1165, 560],
     [1156, 557]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1547, 601]}],
   "box": [1418, 556, 1685, 641],
   "polygons": [
    [
     [1502, 557],
     [1483, 562],
     [1452, 586],
     [1418, 605],
     [1419, 610],
     [1446, 633],
     [1489, 619],
     [1547, 618],
     [1605, 641],
     [1661, 634],
     [1685, 618],
     [1683, 614],
     [1620, 585],
     [1514, 556]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1716, 611]}],
   "box": [1520, 519, 1821, 735],
   "polygons": [
    [
     [1540, 520],
     [1532, 523],
     [1520, 536],
     [1522, 552],
     [1672, 601],
     [1727, 630],
     [1736, 638],
     [1730, 670],
     [1731, 681],
     [1757, 730],
     [1764, 735],
     [1813, 732],
     [1812, 661],
     [1821, 642],
     [1819, 636],
     [1790, 610],
     [1791, 605],
     [1815, 582],
     [1815, 548],
     [1785, 540],
     [1775, 541],
     [1735, 579],
     [1727, 583],
     [1709, 578],
     [1583, 522],
     [1549, 519]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1862, 686]}],
   "box": [1808, 579, 1899, 802],
   "polygons": [
    [
     [1863, 580],
     [1808, 613],
     [1850, 653],
     [1849, 658],
     [1839, 669],
     [1837, 684],
     [1850, 750],
     [1848, 756],
     [1829, 772],
     [1830, 776],
     [1859, 802],
     [1899, 791],
     [1873, 692],
     [1874, 687],
     [1887, 683],
     [1890, 678],
     [1896, 657],
     [1895, 647],
     [1868, 627],
     [1872, 622],
     [1898, 613],
     [1898, 603],
     [1893, 590],
     [1867, 579]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1068, 677]}],
   "box": [885, 606, 1282, 759],
   "polygons": [
    [
     [1053, 607],
     [1004, 616],
     [908, 645],
     [900, 660],
     [888, 713],
     [885, 729],
     [888, 740],
     [957, 757],
     [981, 759],
     [1012, 756],
     [1084, 740],
     [1225, 700],
     [1266, 686],
     [1282, 675],
     [1272, 640],
     [1268, 632],
     [1257, 624],
     [1080, 606]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 678]}],
   "box": [449, 629, 618, 752],
   "polygons": [
    [
     [470, 630],
     [449, 639],
     [452, 660],
     [455, 664],
     [473, 670],
     [486, 686],
     [486, 707],
     [502, 729],
     [502, 752],
     [563, 743],
     [565, 703],
     [572, 670],
     [593, 645],
     [618, 632],
     [520, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [338, 696]}],
   "box": [194, 630, 492, 749],
   "polygons": [
    [
     [340, 631],
     [196, 657],
     [194, 660],
     [201, 690],
     [220, 741],
     [227, 746],
     [249, 749],
     [477, 748],
     [489, 744],
     [492, 722],
     [489, 717],
     [472, 710],
     [467, 675],
     [436, 671],
     [437, 639],
     [396, 646],
     [382, 645],
     [352, 630]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [713, 690]}],
   "box": [568, 630, 867, 750],
   "polygons": [
    [
     [682, 631],
     [606, 643],
     [590, 649],
     [584, 657],
     [571, 697],
     [568, 735],
     [571, 750],
     [672, 744],
     [802, 741],
     [845, 724],
     [858, 712],
     [867, 686],
     [863, 654],
     [806, 635],
     [721, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [930, 611]}],
   "box": [829, 575, 1041, 648],
   "polygons": [
    [
     [1029, 576],
     [852, 609],
     [829, 615],
     [829, 622],
     [833, 631],
     [845, 640],
     [867, 648],
     [880, 646],
     [1029, 602],
     [1036, 598],
     [1041, 590],
     [1041, 578],
     [1037, 575]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1122, 585]}],
   "box": [1048, 558, 1198, 608],
   "polygons": [
    [
     [1143, 559],
     [1054, 571],
     [1050, 574],
     [1048, 601],
     [1129, 608],
     [1179, 606],
     [1198, 598],
     [1195, 588],
     [1181, 574],
     [1163, 560],
     [1156, 558]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1547, 602]}],
   "box": [1417, 557, 1685, 642],
   "polygons": [
    [
     [1503, 558],
     [1486, 561],
     [1453, 586],
     [1417, 606],
     [1418, 611],
     [1445, 634],
     [1488, 620],
     [1545, 619],
     [1603, 642],
     [1657, 636],
     [1685, 619],
     [1680, 614],
     [1624, 588],
     [1512, 557]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1716, 612]}],
   "box": [1519, 521, 1820, 737],
   "polygons": [
    [
     [1537, 522],
     [1519, 538],
     [1521, 553],
     [1673, 603],
     [1729, 633],
     [1735, 639],
     [1729, 670],
     [1730, 684],
     [1756, 732],
     [1762, 736],
     [1786, 737],
     [1811, 734],
     [1811, 662],
     [1820, 644],
     [1819, 639],
     [1789, 611],
     [1815, 582],
     [1815, 550],
     [1783, 541],
     [1776, 542],
     [1735, 580],
     [1728, 584],
     [1718, 583],
     [1582, 523],
     [1564, 521]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1861, 687]}],
   "box": [1808, 580, 1899, 803],
   "polygons": [
    [
     [1864, 581],
     [1808, 614],
     [1818, 627],
     [1849, 654],
     [1847, 661],
     [1838, 670],
     [1836, 684],
     [1848, 747],
     [1847, 756],
     [1827, 774],
     [1857, 803],
     [1897, 792],
     [1895, 776],
     [1872, 695],
     [1873, 688],
     [1887, 684],
     [1896, 653],
     [1893, 647],
     [1867, 627],
     [1899, 612],
     [1893, 592],
     [1865, 580]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1067, 678]}],
   "box": [884, 607, 1281, 758],
   "polygons": [
    [
     [1047, 608],
     [1001, 617],
     [909, 644],
     [901, 655],
     [894, 680],
     [884, 727],
     [886, 739],
     [934, 753],
     [963, 758],
     [1018, 755],
     [1091, 738],
     [1222, 701],
     [1266, 686],
     [1281, 676],
     [1271, 641],
     [1259, 626],
     [1234, 621],
     [1085, 607]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 678]}],
   "box": [450, 629, 617, 751],
   "polygons": [
    [
     [469, 630],
     [450, 639],
     [452, 659],
     [454, 663],
     [474, 670],
     [485, 684],
     [486, 707],
     [502, 730],
     [499, 747],
     [501, 751],
     [562, 743],
     [565, 699],
     [572, 669],
     [594, 644],
     [617, 633],
     [595, 630],
     [527, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [339, 695]}],
   "box": [195, 630, 491, 748],
   "polygons": [
    [
     [339, 631],
     [198, 656],
     [195, 660],
     [203, 692],
     [221, 740],
     [228, 745],
     [249, 748],
     [465, 748],
     [488, 745],
     [491, 718],
     [472, 709],
     [467, 674],
     [437, 671],
     [437, 639],
     [392, 646],
     [376, 643],
     [354, 630]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [713, 689]}],
   "box": [568, 630, 867, 749],
   "polygons": [
    [
     [682, 631],
     [605, 643],
     [590, 649],
     [584, 657],
     [572, 691],
     [568, 731],
     [570, 749],
     [799, 741],
     [844, 724],
     [858, 711],
     [867, 681],
     [864, 657],
     [861, 653],
     [805, 635],
     [719, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [930, 611]}],
   "box": [828, 576, 1040, 648],
   "polygons": [
    [
     [1026, 577],
     [840, 612],
     [828, 616],
     [829, 623],
     [836, 635],
     [851, 643],
     [865, 648],
     [880, 646],
     [1025, 604],
     [1036, 598],
     [1040, 592],
     [1038, 576]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1122, 586]}],
   "box": [1047, 559, 1198, 609],
   "polygons": [
    [
     [1140, 560],
     [1052, 572],
     [1049, 577],
     [1047, 602],
     [1139, 609],
     [1177, 607],
     [1198, 598],
     [1194, 588],
     [1181, 575],
     [1157, 559]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1546, 603]}],
   "box": [1417, 558, 1684, 643],
   "polygons": [
    [
     [1503, 559],
     [1484, 563],
     [1454, 586],
     [1418, 606],
     [1417, 611],
     [1445, 635],
     [1487, 621],
     [1545, 620],
     [1603, 643],
     [1657, 637],
     [1684, 620],
     [1681, 616],
     [1626, 590],
     [1512, 558]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1715, 613]}],
   "box": [1518, 522, 1820, 738],
   "polygons": [
    [
     [1537, 523],
     [1520, 537],
     [1518, 542],
     [1521, 554],
     [1675, 605],
     [1728, 634],
     [1735, 642],
     [1728, 672],
     [1729, 684],
     [1755, 733],
     [1761, 737],
     [1779, 738],
     [1810, 735],
     [1810, 665],
     [1820, 644],
     [1817, 638],
     [1789, 613],
     [1790, 608],
     [1815, 583],
     [1815, 551],
     [1786, 543],
     [1775, 544],
     [1734, 582],
     [1729, 585],
     [1717, 584],
     [1606, 537],
     [1584, 525],
     [1561, 522]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1860, 688]}],
   "box": [1808, 582, 1899, 804],
   "polygons": [
    [
     [1862, 583],
     [1808, 615],
     [1810, 621],
     [1849, 656],
     [1837, 672],
     [1835, 688],
     [1847, 748],
     [1846, 756],
     [1826, 774],
     [1828, 780],
     [1855, 804],
     [1896, 793],
     [1871, 693],
     [1873, 689],
     [1887, 684],
     [1895, 658],
     [1894, 650],
     [1867, 629],
     [1899, 613],
     [1894, 594],
     [1867, 582]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1066, 678]}],
   "box": [883, 607, 1280, 758],
   "polygons": [
    [
     [1052, 608],
     [1002, 617],
     [907, 645],
     [901, 654],
     [894, 677],
     [883, 728],
     [885, 739],
     [933, 753],
     [962, 758],
     [1017, 755],
     [1087, 739],
     [1239, 696],
     [1264, 687],
     [1280, 676],
     [1270, 641],
     [1259, 627],
     [1236, 622],
     [1077, 607]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 677]}],
   "box": [450, 629, 618, 751],
   "polygons": [
    [
     [468, 630],
     [450, 639],
     [453, 660],
     [457, 664],
     [473, 669],
     [485, 683],
     [487, 708],
     [502, 729],
     [499, 747],
     [503, 751],
     [562, 742],
     [565, 699],
     [573, 667],
     [594, 644],
     [618, 632],
     [535, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [340, 694]}],
   "box": [197, 629, 491, 747],
   "polygons": [
    [
     [345, 630],
     [216, 651],
     [197, 657],
     [204, 689],
     [223, 740],
     [234, 745],
     [253, 747],
     [469, 747],
     [489, 743],
     [491, 717],
     [473, 709],
     [468, 674],
     [437, 670],
     [438, 638],
     [400, 645],
     [383, 644],
     [353, 629]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [713, 689]}],
   "box": [567, 630, 866, 749],
   "polygons": [
    [
     [682, 631],
     [599, 644],
     [585, 655],
     [571, 695],
     [567, 740],
     [571, 749],
     [674, 743],
     [803, 740],
     [845, 723],
     [859, 708],
     [866, 684],
     [862, 654],
     [804, 635],
     [720, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [930, 612]}],
   "box": [827, 576, 1041, 648],
   "polygons": [
    [
     [1030, 577],
     [854, 609],
     [830, 615],
     [827, 619],
     [832, 631],
     [840, 638],
     [864, 648],
     [881, 646],
     [1026, 604],
     [1035, 599],
     [1041, 589],
     [1040, 578],
     [1035, 576]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1121, 586]}],
   "box": [1046, 559, 1197, 610],
   "polygons": [
    [
     [1146, 560],
     [1051, 573],
     [1046, 594],
     [1047, 602],
     [1152, 610],
     [1180, 607],
     [1197, 599],
     [1193, 588],
     [1180, 575],
     [1165, 563],
     [1153, 559]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1546, 604]}],
   "box": [1416, 559, 1684, 644],
   "polygons": [
    [
     [1504, 560],
     [1484, 564],
     [1452, 588],
     [1416, 608],
     [1418, 614],
     [1445, 636],
     [1487, 622],
     [1544, 621],
     [1601, 644],
     [1657, 638],
     [1684, 621],
     [1617, 588],
     [1511, 559]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1715, 615]}],
   "box": [1519, 523, 1819, 739],
   "polygons": [
    [
     [1538, 524],
     [1530, 528],
     [1519, 540],
     [1520, 555],
     [1674, 606],
     [1727, 635],
     [1734, 642],
     [1727, 675],
     [1728, 685],
     [1753, 733],
     [1764, 739],
     [1809, 736],
     [1810, 664],
     [1819, 646],
     [1818, 641],
     [1789, 615],
     [1790, 609],
     [1815, 585],
     [1817, 565],
     [1814, 552],
     [1784, 544],
     [1776, 545],
     [1733, 584],
     [1726, 587],
     [1706, 581],
     [1581, 525],
     [1556, 523]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1860, 690]}],
   "box": [1808, 583, 1899, 806],
   "polygons": [
    [
     [1864, 584],
     [1808, 616],
     [1809, 621],
     [1848, 656],
     [1847, 662],
     [1837, 673],
     [1835, 685],
     [1845, 757],
     [1824, 777],
     [1856, 806],
     [1887, 798],
     [1895, 793],
     [1871, 699],
     [1872, 691],
     [1885, 687],
     [1888, 682],
     [1895, 659],
     [1894, 652],
     [1867, 630],
     [1899, 615],
     [1895, 596],
     [1866, 583]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1065, 679]}],
   "box": [882, 608, 1279, 758],
   "polygons": [
    [
     [1046, 609],
     [1007, 616],
     [907, 645],
     [900, 655],
     [892, 683],
     [882, 729],
     [885, 740],
     [961, 758],
     [1016, 755],
     [1075, 742],
     [1223, 701],
     [1262, 688],
     [1279, 677],
     [1269, 641],
     [1264, 632],
     [1254, 626],
     [1083, 608]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 677]}],
   "box": [451, 629, 618, 750],
   "polygons": [
    [
     [468, 630],
     [451, 639],
     [454, 661],
     [473, 668],
     [486, 683],
     [487, 707],
     [502, 727],
     [499, 746],
     [502, 750],
     [554, 744],
     [563, 740],
     [565, 699],
     [574, 666],
     [594, 644],
     [618, 632],
     [549, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [340, 694]}],
   "box": [199, 629, 492, 746],
   "polygons": [
    [
     [344, 630],
     [220, 650],
     [199, 656],
     [206, 691],
     [223, 738],
     [231, 743],
     [254, 746],
     [474, 746],
     [488, 743],
     [492, 720],
     [490, 716],
     [473, 709],
     [468, 674],
     [437, 669],
     [440, 645],
     [438, 638],
     [398, 645],
     [385, 644],
     [355, 629]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [712, 689]}],
   "box": [567, 630, 865, 748],
   "polygons": [
    [
     [684, 631],
     [604, 643],
     [589, 650],
     [583, 659],
     [571, 693],
     [567, 731],
     [569, 748],
     [802, 740],
     [842, 724],
     [856, 711],
     [865, 684],
     [864, 665],
     [860, 654],
     [801, 635],
     [713, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [929, 612]}],
   "box": [828, 577, 1040, 649],
   "polygons": [
    [
     [1028, 578],
     [847, 611],
     [828, 616],
     [828, 623],
     [831, 631],
     [846, 642],
     [867, 649],
     [878, 647],
     [1027, 604],
     [1034, 600],
     [1040, 591],
     [1039, 578],
     [1036, 577]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1121, 587]}],
   "box": [1046, 560, 1197, 610],
   "polygons": [
    [
     [1146, 561],
     [1053, 573],
     [1048, 579],
     [1046, 603],
     [1125, 610],
     [1172, 609],
     [1186, 606],
     [1197, 599],
     [1193, 590],
     [1178, 575],
     [1161, 562],
     [1152, 560]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1545, 605]}],
   "box": [1416, 561, 1683, 646],
   "polygons": [
    [
     [1499, 562],
     [1484, 565],
     [1452, 589],
     [1417, 608],
     [1416, 614],
     [1443, 637],
     [1482, 624],
     [1527, 621],
     [1545, 623],
     [1604, 646],
     [1657, 639],
     [1683, 623],
     [1680, 619],
     [1623, 592],
     [1513, 561]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1714, 616]}],
   "box": [1517, 524, 1818, 740],
   "polygons": [
    [
     [1541, 525],
     [1533, 527],
     [1520, 539],
     [1517, 546],
     [1519, 556],
     [1669, 606],
     [1727, 637],
     [1733, 643],
     [1725, 679],
     [1727, 688],
     [1752, 736],
     [1759, 740],
     [1807, 738],
     [1808, 670],
     [1818, 648],
     [1817, 643],
     [1788, 616],
     [1789, 611],
     [1814, 588],
     [1817, 564],
     [1814, 554],
     [1785, 546],
     [1775, 547],
     [1732, 586],
     [1717, 587],
     [1606, 540],
     [1582, 527],
     [1543, 524]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1858, 692]}],
   "box": [1807, 585, 1897, 807],
   "polygons": [
    [
     [1863, 586],
     [1807, 618],
     [1810, 625],
     [1848, 660],
     [1836, 674],
     [1833, 694],
     [1844, 750],
     [1843, 760],
     [1822, 778],
     [1852, 807],
     [1887, 799],
     [1893, 795],
     [1870, 701],
     [1872, 692],
     [1885, 688],
     [1894, 660],
     [1892, 652],
     [1866, 632],
     [1897, 619],
     [1897, 608],
     [1893, 597],
     [1866, 585]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1064, 679]}],
   "box": [881, 608, 1278, 758],
   "polygons": [
    [
     [1059, 609],
     [1013, 615],
     [908, 644],
     [902, 650],
     [893, 674],
     [881, 726],
     [882, 738],
     [898, 745],
     [958, 758],
     [1010, 756],
     [1071, 743],
     [1224, 701],
     [1260, 689],
     [1278, 677],
     [1264, 635],
     [1249, 626],
     [1066, 608]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 677]}],
   "box": [452, 629, 618, 749],
   "polygons": [
    [
     [468, 630],
     [452, 638],
     [454, 659],
     [457, 663],
     [475, 669],
     [486, 683],
     [487, 707],
     [501, 725],
     [501, 749],
     [561, 741],
     [565, 696],
     [573, 667],
     [594, 644],
     [618, 632],
     [544, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [341, 693]}],
   "box": [199, 629, 490, 746],
   "polygons": [
    [
     [342, 630],
     [201, 655],
     [199, 659],
     [206, 687],
     [225, 739],
     [253, 745],
     [328, 746],
     [479, 745],
     [488, 742],
     [490, 716],
     [472, 707],
     [468, 673],
     [439, 670],
     [437, 667],
     [439, 638],
     [395, 645],
     [376, 641],
     [357, 629]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [711, 689]}],
   "box": [566, 630, 865, 749],
   "polygons": [
    [
     [686, 631],
     [592, 647],
     [584, 656],
     [571, 691],
     [566, 737],
     [568, 747],
     [574, 749],
     [661, 743],
     [801, 740],
     [843, 723],
     [857, 708],
     [865, 676],
     [859, 654],
     [798, 635],
     [707, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [929, 613]}],
   "box": [826, 578, 1039, 649],
   "polygons": [
    [
     [1026, 579],
     [868, 607],
     [832, 615],
     [826, 619],
     [831, 632],
     [839, 639],
     [863, 649],
     [874, 648],
     [1028, 604],
     [1039, 593],
     [1039, 580],
     [1036, 578]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1120, 588]}],
   "box": [1045, 561, 1196, 611],
   "polygons": [
    [
     [1145, 562],
     [1052, 574],
     [1048, 578],
     [1045, 603],
     [1055, 606],
     [1128, 611],
     [1178, 609],
     [1196, 600],
     [1193, 592],
     [1173, 572],
     [1162, 564],
     [1151, 561]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1544, 607]}],
   "box": [1415, 562, 1682, 647],
   "polygons": [
    [
     [1502, 563],
     [1481, 568],
     [1455, 588],
     [1415, 610],
     [1415, 615],
     [1441, 638],
     [1482, 625],
     [1543, 624],
     [1600, 647],
     [1655, 641],
     [1682, 625],
     [1680, 621],
     [1618, 592],
     [1510, 562]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1713, 618]}],
   "box": [1518, 526, 1817, 742],
   "polygons": [
    [
     [1537, 527],
     [1518, 543],
     [1519, 558],
     [1672, 609],
     [1725, 638],
     [1732, 645],
     [1724, 680],
     [1726, 690],
     [1751, 738],
     [1762, 742],
     [1806, 739],
     [1807, 672],
     [1817, 650],
     [1816, 644],
     [1787, 617],
     [1789, 612],
     [1814, 589],
     [1814, 556],
     [1785, 548],
     [1775, 549],
     [1731, 588],
     [1717, 589],
     [1580, 528],
     [1553, 526]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1857, 693]}],
   "box": [1806, 587, 1898, 809],
   "polygons": [
    [
     [1863, 588],
     [1826, 607],
     [1806, 621],
     [1847, 661],
     [1835, 676],
     [1832, 696],
     [1842, 747],
     [1842, 760],
     [1821, 779],
     [1836, 797],
     [1852, 809],
     [1891, 797],
     [1869, 703],
     [1870, 694],
     [1885, 688],
     [1893, 664],
     [1892, 655],
     [1866, 634],
     [1898, 619],
     [1895, 601],
     [1866, 587]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1063, 680]}],
   "box": [880, 609, 1277, 758],
   "polygons": [
    [
     [1050, 610],
     [1011, 616],
     [905, 646],
     [892, 675],
     [880, 726],
     [882, 740],
     [955, 758],
     [1010, 756],
     [1087, 739],
     [1222, 702],
     [1261, 689],
     [1271, 684],
     [1277, 677],
     [1265, 639],
     [1258, 631],
     [1248, 627],
     [1072, 609]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 677]}],
   "box": [452, 629, 618, 749],
   "polygons": [
    [
     [469, 630],
     [452, 638],
     [455, 661],
     [474, 668],
     [487, 684],
     [487, 707],
     [501, 725],
     [499, 741],
     [502, 749],
     [561, 740],
     [565, 695],
     [574, 665],
     [594, 644],
     [618, 632],
     [543, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [342, 693]}],
   "box": [201, 629, 492, 746],
   "polygons": [
    [
     [341, 630],
     [203, 654],
     [201, 657],
     [208, 688],
     [226, 738],
     [252, 744],
     [305, 745],
     [414, 746],
     [470, 745],
     [488, 742],
     [492, 718],
     [473, 708],
     [469, 674],
     [438, 669],
     [440, 638],
     [404, 644],
     [384, 643],
     [358, 629]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [711, 689]}],
   "box": [566, 630, 864, 747],
   "polygons": [
    [
     [688, 631],
     [595, 645],
     [584, 656],
     [571, 691],
     [566, 734],
     [568, 747],
     [799, 740],
     [842, 723],
     [858, 704],
     [864, 681],
     [861, 657],
     [856, 653],
     [803, 636],
     [702, 630]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [928, 613]}],
   "box": [826, 579, 1040, 649],
   "polygons": [
    [
     [1023, 580],
     [870, 607],
     [833, 615],
     [826, 619],
     [830, 631],
     [840, 640],
     [861, 649],
     [875, 648],
     [1024, 606],
     [1035, 600],
     [1038, 595],
     [1040, 587],
     [1037, 579]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1120, 589]}],
   "box": [1045, 562, 1195, 612],
   "polygons": [
    [
     [1143, 563],
     [1051, 575],
     [1047, 582],
     [1045, 604],
     [1135, 612],
     [1175, 610],
     [1195, 602],
     [1192, 592],
     [1173, 573],
     [1153, 562]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1544, 608]}],
   "box": [1416, 563, 1681, 648],
   "polygons": [
    [
     [1503, 564],
     [1481, 569],
     [1450, 592],
     [1416, 610],
     [1416, 618],
     [1440, 639],
     [1482, 626],
     [1542, 625],
     [1599, 648],
     [1655, 642],
     [1681, 626],
     [1674, 619],
     [1620, 594],
     [1509, 563]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1713, 619]}],
   "box": [1519, 527, 1817, 743],
   "polygons": [
    [
     [1538, 528],
     [1519, 543],
     [1519, 559],
     [1666, 608],
     [1726, 640],
     [1732, 648],
     [1724, 678],
     [1725, 691],
     [1749, 738],
     [1759, 743],
     [1805, 740],
     [1807, 670],
     [1817, 651],
     [1816, 646],
     [1787, 619],
     [1789, 613],
     [1814, 590],
     [1817, 568],
     [1814, 557],
     [1784, 549],
     [1776, 550],
     [1733, 588],
     [1727, 591],
     [1716, 590],
     [1582, 530],
     [1550, 527]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1857, 694]}],
   "box": [1806, 588, 1898, 810],
   "polygons": [
    [
     [1864, 589],
     [1806, 622],
     [1846, 661],
     [1845, 667],
     [1834, 679],
     [1832, 692],
     [1841, 761],
     [1819, 781],
     [1850, 810],
     [1887, 801],
     [1890, 798],
     [1868, 702],
     [1870, 695],
     [1884, 690],
     [1893, 663],
     [1891, 655],
     [1866, 636],
     [1868, 632],
     [1898, 620],
     [1893, 600],
     [1865, 588]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1062, 680]}],
   "box": [879, 610, 1276, 758],
   "polygons": [
    [
     [1044, 611],
     [989, 622],
     [905, 646],
     [893, 669],
     [879, 726],
     [881, 740],
     [953, 758],
     [1010, 756],
     [1091, 738],
     [1223, 702],
     [1259, 690],
     [1271, 684],
     [1276, 678],
     [1266, 644],
     [1262, 636],
     [1254, 630],
     [1229, 625],
     [1079, 610]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [525, 676]}],
   "box": [453, 628, 618, 748],
   "polygons": [
    [
     [478, 629],
     [468, 630],
     [453, 638],
     [455, 659],
     [475, 668],
     [487, 683],
     [487, 706],
     [501, 725],
     [500, 748],
     [559, 741],
     [562, 737],
     [565, 695],
     [573, 667],
     [593, 645],
     [618, 632],
     [489, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [342, 692]}],
   "box": [202, 628, 490, 744],
   "polygons": [
    [
     [352, 629],
     [204, 654],
     [202, 657],
     [209, 687],
     [226, 736],
     [241, 742],
     [281, 744],
     [477, 744],
     [488, 741],
     [490, 715],
     [473, 707],
     [469, 673],
     [442, 670],
     [438, 667],
     [440, 638],
     [403, 644],
     [385, 643],
     [355, 628]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [710, 689]}],
   "box": [565, 631, 863, 747],
   "polygons": [
    [
     [679, 632],
     [599, 644],
     [584, 656],
     [570, 694],
     [565, 740],
     [568, 747],
     [799, 740],
     [838, 725],
     [855, 709],
     [863, 684],
     [861, 659],
     [858, 655],
     [800, 636],
     [718, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [928, 614]}],
   "box": [826, 580, 1038, 650],
   "polygons": [
    [
     [1022, 581],
     [839, 614],
     [826, 618],
     [827, 625],
     [833, 636],
     [849, 645],
     [864, 650],
     [877, 648],
     [1023, 607],
     [1033, 602],
     [1038, 595],
     [1036, 580]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1119, 590]}],
   "box": [1044, 563, 1195, 613],
   "polygons": [
    [
     [1143, 564],
     [1050, 576],
     [1044, 605],
     [1136, 613],
     [1175, 611],
     [1195, 602],
     [1192, 594],
     [1178, 579],
     [1162, 566],
     [1152, 563]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1543, 609]}],
   "box": [1413, 565, 1681, 649],
   "polygons": [
    [
     [1498, 566],
     [1481, 570],
     [1450, 593],
     [1413, 613],
     [1414, 617],
     [1439, 640],
     [1483, 627],
     [1539, 626],
     [1597, 649],
     [1656, 643],
     [1681, 627],
     [1676, 622],
     [1621, 596],
     [1512, 565]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1713, 621]}],
   "box": [1517, 529, 1817, 745],
   "polygons": [
    [
     [1536, 530],
     [1517, 547],
     [1520, 561],
     [1664, 609],
     [1721, 639],
     [1731, 648],
     [1723, 680],
     [1724, 692],
     [1748, 740],
     [1754, 744],
     [1787, 745],
     [1804, 741],
     [1806, 672],
     [1816, 653],
     [1815, 647],
     [1787, 621],
     [1788, 616],
     [1814, 591],
     [1817, 569],
     [1814, 559],
     [1785, 551],
     [1773, 553],
     [1738, 585],
     [1725, 593],
     [1711, 590],
     [1583, 532],
     [1558, 529]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1856, 696]}],
   "box": [1806, 590, 1898, 811],
   "polygons": [
    [
     [1863, 591],
     [1806, 623],
     [1809, 630],
     [1846, 664],
     [1835, 677],
     [1831, 694],
     [1840, 751],
     [1839, 764],
     [1818, 782],
     [1823, 790],
     [1847, 811],
     [1884, 803],
     [1889, 797],
     [1867, 702],
     [1869, 697],
     [1883, 692],
     [1893, 663],
     [1887, 654],
     [1865, 637],
     [1898, 622],
     [1895, 604],
     [1866, 590]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1061, 681]}],
   "box": [878, 611, 1275, 759],
   "polygons": [
    [
     [1041, 612],
     [1011, 617],
     [905, 646],
     [900, 651],
     [891, 674],
     [878, 726],
     [880, 740],
     [920, 752],
     [958, 759],
     [1011, 756],
     [1080, 741],
     [1228, 701],
     [1260, 690],
     [1275, 679],
     [1263, 640],
     [1251, 630],
     [1080, 611]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 676]}],
   "box": [453, 629, 618, 748],
   "polygons": [
    [
     [470, 630],
     [453, 639],
     [456, 661],
     [474, 667],
     [486, 681],
     [487, 705],
     [501, 724],
     [498, 744],
     [501, 748],
     [560, 740],
     [564, 699],
     [573, 667],
     [593, 645],
     [618, 633],
     [538, 629]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [343, 691]}],
   "box": [204, 628, 492, 743],
   "polygons": [
    [
     [347, 629],
     [206, 653],
     [204, 656],
     [211, 688],
     [227, 734],
     [243, 741],
     [297, 743],
     [478, 743],
     [488, 740],
     [492, 717],
     [473, 706],
     [469, 672],
     [439, 668],
     [442, 646],
     [440, 637],
     [400, 644],
     [389, 643],
     [359, 628]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [710, 689]}],
   "box": [565, 631, 862, 746],
   "polygons": [
    [
     [679, 632],
     [595, 645],
     [584, 656],
     [570, 693],
     [565, 735],
     [567, 746],
     [795, 740],
     [840, 723],
     [854, 709],
     [862, 685],
     [859, 656],
     [799, 636],
     [718, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [927, 614]}],
   "box": [825, 580, 1038, 649],
   "polygons": [
    [
     [1026, 581],
     [869, 608],
     [828, 617],
     [825, 621],
     [830, 633],
     [838, 640],
     [858, 649],
     [872, 649],
     [1024, 607],
     [1033, 602],
     [1038, 594],
     [1038, 582],
     [1034, 580]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1119, 591]}],
   "box": [1043, 564, 1194, 614],
   "polygons": [
    [
     [1140, 565],
     [1049, 577],
     [1043, 599],
     [1044, 606],
     [1143, 614],
     [1178, 611],
     [1192, 605],
     [1194, 602],
     [1191, 594],
     [1166, 570],
     [1153, 564]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1542, 611]}],
   "box": [1413, 566, 1680, 651],
   "polygons": [
    [
     [1499, 567],
     [1481, 571],
     [1451, 593],
     [1415, 612],
     [1413, 618],
     [1438, 641],
     [1478, 629],
     [1524, 626],
     [1542, 628],
     [1599, 651],
     [1652, 645],
     [1680, 629],
     [1676, 624],
     [1619, 597],
     [1510, 566]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1712, 622]}],
   "box": [1516, 530, 1817, 746],
   "polygons": [
    [
     [1537, 531],
     [1518, 546],
     [1516, 552],
     [1520, 563],
     [1665, 611],
     [1721, 641],
     [1730, 649],
     [1721, 684],
     [1722, 691],
     [1747, 742],
     [1763, 746],
     [1802, 743],
     [1805, 674],
     [1816, 653],
     [1813, 647],
     [1786, 622],
     [1814, 592],
     [1817, 569],
     [1815, 561],
     [1780, 552],
     [1767, 559],
     [1731, 592],
     [1722, 595],
     [1608, 547],
     [1582, 533],
     [1551, 530]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1855, 698]}],
   "box": [1805, 592, 1897, 813],
   "polygons": [
    [
     [1863, 593],
     [1805, 625],
     [1808, 631],
     [1845, 665],
     [1844, 670],
     [1833, 680],
     [1830, 695],
     [1838, 764],
     [1816, 783],
     [1828, 798],
     [1847, 813],
     [1883, 804],
     [1887, 799],
     [1866, 705],
     [1867, 699],
     [1882, 694],
     [1892, 666],
     [1891, 660],
     [1865, 639],
     [1868, 635],
     [1897, 625],
     [1896, 613],
     [1893, 604],
     [1866, 592]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1060, 681]}],
   "box": [877, 611, 1273, 760],
   "polygons": [
    [
     [1047, 612],
     [1008, 618],
     [903, 647],
     [896, 657],
     [888, 682],
     [877, 726],
     [878, 739],
     [896, 746],
     [949, 758],
     [974, 760],
     [1010, 756],
     [1063, 745],
     [1211, 706],
     [1258, 691],
     [1273, 681],
     [1263, 643],
     [1253, 632],
     [1229, 627],
     [1070, 611]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 676]}],
   "box": [454, 628, 618, 746],
   "polygons": [
    [
     [475, 629],
     [454, 638],
     [456, 657],
     [457, 661],
     [475, 667],
     [488, 683],
     [486, 701],
     [501, 724],
     [499, 746],
     [560, 739],
     [564, 698],
     [574, 665],
     [593, 645],
     [618, 632],
     [501, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [344, 691]}],
   "box": [206, 628, 490, 744],
   "polygons": [
    [
     [344, 629],
     [222, 648],
     [206, 653],
     [213, 690],
     [229, 735],
     [245, 740],
     [299, 742],
     [449, 744],
     [488, 739],
     [490, 714],
     [473, 706],
     [469, 672],
     [443, 669],
     [439, 666],
     [443, 641],
     [441, 637],
     [406, 643],
     [386, 642],
     [361, 628]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [710, 689]}],
   "box": [565, 631, 862, 746],
   "polygons": [
    [
     [679, 632],
     [598, 644],
     [584, 656],
     [569, 697],
     [565, 731],
     [568, 746],
     [673, 741],
     [792, 740],
     [809, 736],
     [839, 723],
     [853, 709],
     [862, 679],
     [858, 656],
     [803, 637],
     [716, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [927, 615]}],
   "box": [825, 581, 1038, 650],
   "polygons": [
    [
     [1022, 582],
     [865, 609],
     [832, 616],
     [825, 620],
     [830, 634],
     [837, 640],
     [860, 650],
     [873, 649],
     [1027, 606],
     [1035, 600],
     [1038, 593],
     [1035, 581]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1118, 591]}],
   "box": [1043, 565, 1194, 614],
   "polygons": [
    [
     [1138, 566],
     [1050, 577],
     [1045, 587],
     [1043, 606],
     [1126, 614],
     [1169, 613],
     [1191, 606],
     [1194, 602],
     [1189, 593],
     [1172, 576],
     [1154, 565]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1542, 612]}],
   "box": [1413, 567, 1679, 652],
   "polygons": [
    [
     [1500, 568],
     [1481, 572],
     [1454, 592],
     [1413, 614],
     [1413, 620],
     [1437, 642],
     [1484, 629],
     [1541, 629],
     [1597, 652],
     [1652, 646],
     [1679, 630],
     [1675, 625],
     [1618, 598],
     [1509, 567]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1711, 623]}],
   "box": [1518, 531, 1817, 747],
   "polygons": [
    [
     [1540, 532],
     [1531, 535],
     [1518, 547],
     [1518, 563],
     [1666, 613],
     [1723, 644],
     [1729, 651],
     [1720, 686],
     [1721, 692],
     [1744, 741],
     [1754, 747],
     [1800, 745],
     [1804, 676],
     [1815, 655],
     [1785, 622],
     [1813, 595],
     [1817, 570],
     [1814, 562],
     [1784, 554],
     [1776, 555],
     [1735, 590],
     [1724, 596],
     [1708, 592],
     [1581, 534],
     [1545, 531]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1854, 699]}],
   "box": [1805, 594, 1897, 814],
   "polygons": [
    [
     [1862, 595],
     [1805, 626],
     [1808, 633],
     [1844, 666],
     [1843, 672],
     [1832, 682],
     [1829, 697],
     [1837, 753],
     [1836, 767],
     [1815, 783],
     [1818, 791],
     [1844, 814],
     [1879, 806],
     [1885, 802],
     [1865, 705],
     [1867, 700],
     [1881, 696],
     [1891, 668],
     [1890, 661],
     [1864, 640],
     [1897, 626],
     [1894, 607],
     [1867, 594]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1059, 681]}],
   "box": [876, 612, 1272, 759],
   "polygons": [
    [
     [1041, 613],
     [1005, 619],
     [903, 647],
     [894, 660],
     [888, 679],
     [876, 726],
     [877, 739],
     [906, 749],
     [955, 759],
     [1010, 756],
     [1067, 744],
     [1219, 704],
     [1256, 692],
     [1272, 682],
     [1263, 646],
     [1258, 637],
     [1250, 632],
     [1076, 612]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 675]}],
   "box": [455, 628, 618, 746],
   "polygons": [
    [
     [473, 629],
     [455, 637],
     [457, 660],
     [476, 667],
     [488, 683],
     [488, 705],
     [501, 723],
     [499, 746],
     [556, 740],
     [561, 736],
     [564, 697],
     [572, 668],
     [593, 645],
     [618, 632],
     [512, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [345, 690]}],
   "box": [207, 627, 492, 743],
   "polygons": [
    [
     [355, 628],
     [227, 647],
     [207, 653],
     [214, 688],
     [230, 734],
     [244, 739],
     [283, 741],
     [417, 743],
     [475, 742],
     [488, 738],
     [492, 716],
     [473, 705],
     [470, 672],
     [440, 667],
     [442, 637],
     [406, 643],
     [388, 642],
     [364, 628],
     [357, 627]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [709, 689]}],
   "box": [564, 631, 861, 745],
   "polygons": [
    [
     [681, 632],
     [594, 646],
     [584, 656],
     [570, 691],
     [564, 736],
     [566, 745],
     [791, 740],
     [835, 725],
     [853, 708],
     [861, 683],
     [857, 656],
     [801, 637],
     [711, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [926, 615]}],
   "box": [825, 582, 1038, 651],
   "polygons": [
    [
     [1020, 583],
     [847, 613],
     [825, 619],
     [826, 626],
     [833, 638],
     [850, 647],
     [863, 651],
     [875, 649],
     [1021, 609],
     [1035, 600],
     [1038, 592],
     [1035, 582]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1118, 592]}],
   "box": [1042, 566, 1192, 615],
   "polygons": [
    [
     [1137, 567],
     [1049, 578],
     [1042, 600],
     [1043, 607],
     [1130, 615],
     [1174, 613],
     [1192, 606],
     [1192, 600],
     [1187, 592],
     [1163, 570],
     [1154, 566]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1541, 613]}],
   "box": [1411, 568, 1678, 653],
   "polygons": [
    [
     [1502, 569],
     [1481, 573],
     [1449, 596],
     [1411, 616],
     [1412, 621],
     [1439, 644],
     [1478, 631],
     [1539, 630],
     [1595, 653],
     [1653, 647],
     [1678, 632],
     [1672, 625],
     [1619, 600],
     [1506, 568]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1711, 625]}],
   "box": [1516, 533, 1817, 749],
   "polygons": [
    [
     [1536, 534],
     [1518, 548],
     [1516, 553],
     [1519, 565],
     [1667, 615],
     [1720, 644],
     [1728, 651],
     [1719, 687],
     [1720, 694],
     [1744, 744],
     [1751, 748],
     [1785, 749],
     [1799, 746],
     [1803, 679],
     [1814, 657],
     [1813, 652],
     [1785, 625],
     [1786, 621],
     [1813, 597],
     [1817, 572],
     [1814, 564],
     [1786, 556],
     [1775, 557],
     [1730, 595],
     [1715, 596],
     [1606, 549],
     [1580, 535],
     [1556, 533]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1853, 701]}],
   "box": [1805, 595, 1897, 815],
   "polygons": [
    [
     [1863, 596],
     [1805, 628],
     [1806, 632],
     [1844, 669],
     [1831, 684],
     [1828, 700],
     [1836, 755],
     [1835, 768],
     [1813, 785],
     [1841, 815],
     [1879, 807],
     [1884, 802],
     [1865, 712],
     [1866, 702],
     [1880, 698],
     [1891, 668],
     [1887, 660],
     [1864, 642],
     [1897, 627],
     [1894, 608],
     [1865, 595]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1058, 682]}],
   "box": [875, 612, 1271, 759],
   "polygons": [
    [
     [1052, 613],
     [1003, 620],
     [903, 647],
     [896, 655],
     [890, 670],
     [875, 726],
     [876, 739],
     [912, 751],
     [952, 759],
     [1004, 757],
     [1059, 746],
     [1213, 706],
     [1249, 695],
     [1271, 683],
     [1262, 647],
     [1252, 634],
     [1230, 629],
     [1061, 612]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [526, 675]}],
   "box": [456, 628, 618, 745],
   "polygons": [
    [
     [474, 629],
     [456, 637],
     [457, 656],
     [459, 661],
     [477, 667],
     [489, 684],
     [488, 705],
     [501, 723],
     [499, 745],
     [558, 739],
     [561, 734],
     [565, 692],
     [574, 665],
     [593, 645],
     [618, 632],
     [510, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [346, 690]}],
   "box": [209, 627, 491, 742],
   "polygons": [
    [
     [354, 628],
     [211, 651],
     [209, 654],
     [214, 682],
     [231, 733],
     [244, 738],
     [271, 740],
     [390, 742],
     [481, 741],
     [488, 738],
     [491, 713],
     [474, 705],
     [470, 671],
     [441, 667],
     [443, 637],
     [406, 643],
     [390, 642],
     [360, 627]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [709, 689]}],
   "box": [565, 631, 861, 746],
   "polygons": [
    [
     [684, 632],
     [604, 643],
     [593, 647],
     [585, 655],
     [571, 688],
     [565, 743],
     [570, 746],
     [660, 741],
     [790, 740],
     [828, 728],
     [845, 718],
     [852, 709],
     [861, 680],
     [856, 656],
     [799, 637],
     [705, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [927, 616]}],
   "box": [826, 582, 1037, 651],
   "polygons": [
    [
     [1026, 583],
     [840, 615],
     [826, 619],
     [826, 626],
     [834, 639],
     [849, 647],
     [860, 651],
     [877, 649],
     [1025, 608],
     [1032, 604],
     [1037, 596],
     [1037, 584],
     [1032, 582]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1117, 593]}],
   "box": [1042, 567, 1193, 616],
   "polygons": [
    [
     [1136, 568],
     [1049, 579],
     [1042, 600],
     [1043, 608],
     [1133, 616],
     [1173, 614],
     [1190, 608],
     [1193, 604],
     [1185, 591],
     [1163, 571],
     [1154, 567]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1541, 614]}],
   "box": [1411, 570, 1678, 654],
   "polygons": [
    [
     [1497, 571],
     [1478, 576],
     [1449, 597],
     [1411, 617],
     [1412, 622],
     [1438, 645],
     [1478, 632],
     [1537, 631],
     [1594, 654],
     [1650, 649],
     [1678, 633],
     [1671, 626],
     [1618, 601],
     [1511, 570]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1711, 626]}],
   "box": [1517, 534, 1817, 750],
   "polygons": [
    [
     [1538, 535],
     [1528, 540],
     [1517, 551],
     [1518, 566],
     [1666, 616],
     [1719, 645],
     [1728, 653],
     [1719, 686],
     [1720, 698],
     [1742, 744],
     [1749, 749],
     [1766, 750],
     [1795, 749],
     [1799, 746],
     [1803, 678],
     [1814, 658],
     [1812, 652],
     [1785, 627],
     [1787, 621],
     [1813, 599],
     [1817, 576],
     [1814, 565],
     [1783, 557],
     [1775, 559],
     [1724, 599],
     [1708, 595],
     [1582, 537],
     [1550, 534]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1853, 702]}],
   "box": [1805, 597, 1897, 817],
   "polygons": [
    [
     [1863, 598],
     [1805, 629],
     [1806, 634],
     [1844, 671],
     [1831, 685],
     [1828, 699],
     [1835, 755],
     [1834, 770],
     [1812, 786],
     [1814, 792],
     [1842, 817],
     [1879, 808],
     [1883, 803],
     [1864, 711],
     [1866, 703],
     [1880, 699],
     [1891, 669],
     [1885, 660],
     [1864, 644],
     [1867, 640],
     [1897, 629],
     [1895, 611],
     [1867, 597]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1057, 683]}],
   "box": [874, 613, 1270, 759],
   "polygons": [
    [
     [1045, 614],
     [997, 622],
     [902, 648],
     [889, 672],
     [874, 728],
     [875, 739],
     [903, 749],
     [950, 759],
     [1005, 757],
     [1064, 745],
     [1222, 704],
     [1253, 694],
     [1270, 684],
     [1262, 649],
     [1258, 641],
     [1249, 634],
     [1068, 613]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 675]}],
   "box": [456, 628, 619, 745],
   "polygons": [
    [
     [475, 629],
     [456, 638],
     [458, 658],
     [478, 667],
     [489, 682],
     [488, 704],
     [501, 722],
     [499, 745],
     [557, 739],
     [561, 734],
     [564, 698],
     [573, 667],
     [595, 644],
     [619, 632],
     [507, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [346, 689]}],
   "box": [210, 627, 491, 742],
   "polygons": [
    [
     [353, 628],
     [212, 651],
     [210, 654],
     [216, 684],
     [232, 732],
     [252, 738],
     [317, 740],
     [433, 742],
     [474, 741],
     [488, 737],
     [491, 713],
     [474, 705],
     [471, 672],
     [441, 667],
     [444, 637],
     [405, 643],
     [392, 642],
     [362, 627]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [708, 689]}],
   "box": [564, 631, 860, 746],
   "polygons": [
    [
     [688, 632],
     [611, 642],
     [592, 648],
     [585, 655],
     [570, 691],
     [564, 732],
     [565, 743],
     [572, 746],
     [654, 741],
     [797, 739],
     [837, 723],
     [847, 715],
     [854, 703],
     [860, 680],
     [858, 660],
     [853, 655],
     [795, 637],
     [699, 631]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [926, 617]}],
   "box": [824, 583, 1036, 651],
   "polygons": [
    [
     [1024, 584],
     [862, 611],
     [829, 618],
     [824, 622],
     [829, 635],
     [839, 643],
     [858, 651],
     [874, 650],
     [1024, 609],
     [1036, 598],
     [1036, 584],
     [1033, 583]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1117, 594]}],
   "box": [1042, 568, 1192, 617],
   "polygons": [
    [
     [1136, 569],
     [1048, 580],
     [1042, 598],
     [1042, 609],
     [1135, 617],
     [1172, 615],
     [1189, 609],
     [1192, 605],
     [1187, 595],
     [1165, 574],
     [1153, 568]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1540, 616]}],
   "box": [1412, 571, 1677, 656],
   "polygons": [
    [
     [1499, 572],
     [1480, 576],
     [1449, 598],
     [1412, 617],
     [1412, 625],
     [1436, 646],
     [1479, 633],
     [1521, 631],
     [1548, 636],
     [1595, 656],
     [1651, 650],
     [1677, 634],
     [1671, 628],
     [1616, 602],
     [1508, 571]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1710, 628]}],
   "box": [1517, 536, 1817, 751],
   "polygons": [
    [
     [1536, 537],
     [1517, 552],
     [1517, 567],
     [1661, 616],
     [1722, 649],
     [1727, 655],
     [1717, 692],
     [1740, 745],
     [1749, 751],
     [1797, 748],
     [1802, 679],
     [1813, 660],
     [1812, 655],
     [1788, 634],
     [1784, 627],
     [1811, 603],
     [1817, 576],
     [1814, 567],
     [1784, 559],
     [1774, 561],
     [1734, 595],
     [1722, 601],
     [1615, 556],
     [1580, 538],
     [1556, 536]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1852, 704]}],
   "box": [1804, 599, 1897, 818],
   "polygons": [
    [
     [1862, 600],
     [1804, 631],
     [1805, 635],
     [1843, 672],
     [1830, 687],
     [1826, 707],
     [1833, 769],
     [1829, 775],
     [1810, 788],
     [1812, 793],
     [1839, 818],
     [1876, 810],
     [1881, 805],
     [1863, 713],
     [1864, 705],
     [1880, 699],
     [1890, 672],
     [1888, 665],
     [1863, 645],
     [1897, 630],
     [1894, 612],
     [1867, 599]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1056, 683]}],
   "box": [875, 614, 1269, 759],
   "polygons": [
    [
     [1041, 615],
     [999, 622],
     [899, 650],
     [891, 664],
     [875, 719],
     [875, 741],
     [947, 759],
     [1005, 757],
     [1089, 739],
     [1216, 706],
     [1256, 693],
     [1269, 684],
     [1260, 648],
     [1248, 635],
     [1071, 614]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 675]}],
   "box": [457, 628, 618, 744],
   "polygons": [
    [
     [476, 629],
     [457, 637],
     [459, 660],
     [477, 666],
     [490, 684],
     [487, 700],
     [501, 721],
     [499, 744],
     [558, 738],
     [564, 697],
     [573, 667],
     [594, 645],
     [618, 633],
     [505, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [347, 689]}],
   "box": [212, 627, 491, 741],
   "polygons": [
    [
     [352, 628],
     [214, 650],
     [212, 653],
     [217, 682],
     [233, 731],
     [251, 737],
     [305, 739],
     [412, 741],
     [479, 740],
     [488, 737],
     [491, 712],
     [474, 704],
     [471, 671],
     [442, 667],
     [444, 637],
     [402, 643],
     [383, 639],
     [364, 627]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [708, 689]}],
   "box": [563, 632, 859, 745],
   "polygons": [
    [
     [677, 633],
     [595, 646],
     [584, 657],
     [570, 690],
     [563, 738],
     [565, 743],
     [569, 745],
     [795, 739],
     [833, 725],
     [851, 708],
     [859, 684],
     [858, 664],
     [854, 656],
     [800, 638],
     [715, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [925, 617]}],
   "box": [824, 584, 1037, 651],
   "polygons": [
    [
     [1022, 585],
     [859, 612],
     [827, 619],
     [824, 622],
     [833, 640],
     [856, 651],
     [870, 651],
     [1025, 609],
     [1034, 602],
     [1037, 594],
     [1036, 585],
     [1033, 584]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1116, 595]}],
   "box": [1040, 569, 1190, 618],
   "polygons": [
    [
     [1135, 570],
     [1047, 581],
     [1040, 607],
     [1046, 611],
     [1138, 618],
     [1171, 616],
     [1190, 609],
     [1190, 601],
     [1183, 592],
     [1166, 576],
     [1153, 569]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1539, 617]}],
   "box": [1410, 572, 1676, 657],
   "polygons": [
    [
     [1502, 573],
     [1480, 577],
     [1449, 599],
     [1410, 619],
     [1410, 624],
     [1435, 647],
     [1480, 634],
     [1538, 634],
     [1593, 657],
     [1651, 651],
     [1676, 636],
     [1673, 631],
     [1617, 604],
     [1505, 572]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1709, 629]}],
   "box": [1515, 537, 1817, 753],
   "polygons": [
    [
     [1537, 538],
     [1517, 553],
     [1515, 558],
     [1518, 569],
     [1662, 618],
     [1719, 649],
     [1726, 656],
     [1716, 692],
     [1717, 698],
     [1740, 748],
     [1746, 752],
     [1772, 753],
     [1792, 752],
     [1796, 749],
     [1801, 681],
     [1813, 660],
     [1784, 630],
     [1786, 624],
     [1812, 603],
     [1817, 577],
     [1814, 569],
     [1786, 561],
     [1776, 562],
     [1729, 600],
     [1715, 601],
     [1581, 540],
     [1550, 537]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1851, 705]}],
   "box": [1804, 600, 1897, 819],
   "polygons": [
    [
     [1864, 601],
     [1804, 632],
     [1805, 637],
     [1842, 673],
     [1841, 678],
     [1830, 687],
     [1826, 703],
     [1832, 757],
     [1831, 773],
     [1809, 789],
     [1811, 795],
     [1836, 819],
     [1872, 812],
     [1879, 808],
     [1862, 714],
     [1864, 706],
     [1879, 701],
     [1890, 671],
     [1863, 647],
     [1867, 643],
     [1893, 635],
     [1897, 630],
     [1892, 612],
     [1865, 600]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1056, 684]}],
   "box": [872, 615, 1268, 759],
   "polygons": [
    [
     [1036, 616],
     [1001, 622],
     [900, 649],
     [889, 668],
     [879, 702],
     [872, 728],
     [874, 741],
     [945, 759],
     [999, 758],
     [1073, 743],
     [1214, 707],
     [1252, 695],
     [1268, 685],
     [1259, 648],
     [1253, 639],
     [1245, 635],
     [1074, 615]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 675]}],
   "box": [458, 628, 619, 744],
   "polygons": [
    [
     [476, 629],
     [458, 637],
     [459, 657],
     [462, 661],
     [478, 666],
     [490, 683],
     [489, 704],
     [501, 721],
     [499, 744],
     [557, 738],
     [560, 735],
     [564, 696],
     [573, 667],
     [594, 645],
     [619, 633],
     [506, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [348, 688]}],
   "box": [213, 627, 491, 741],
   "polygons": [
    [
     [350, 628],
     [215, 650],
     [213, 653],
     [219, 684],
     [234, 730],
     [252, 736],
     [300, 738],
     [450, 741],
     [482, 739],
     [488, 736],
     [491, 712],
     [474, 703],
     [471, 670],
     [442, 666],
     [446, 644],
     [444, 636],
     [411, 642],
     [391, 641],
     [366, 627]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [708, 689]}],
   "box": [563, 632, 859, 745],
   "polygons": [
    [
     [678, 633],
     [595, 646],
     [584, 657],
     [570, 690],
     [563, 735],
     [565, 743],
     [571, 745],
     [663, 740],
     [794, 739],
     [828, 727],
     [848, 712],
     [859, 681],
     [857, 660],
     [853, 656],
     [798, 638],
     [713, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [925, 618]}],
   "box": [824, 585, 1037, 652],
   "polygons": [
    [
     [1019, 586],
     [861, 612],
     [824, 622],
     [828, 635],
     [834, 641],
     [858, 652],
     [871, 651],
     [1021, 611],
     [1034, 602],
     [1037, 593],
     [1034, 585]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1116, 596]}],
   "box": [1041, 569, 1191, 618],
   "polygons": [
    [
     [1142, 570],
     [1048, 581],
     [1044, 588],
     [1041, 610],
     [1124, 618],
     [1168, 617],
     [1184, 613],
     [1191, 608],
     [1187, 598],
     [1166, 577],
     [1148, 569]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1539, 618]}],
   "box": [1410, 574, 1676, 658],
   "polygons": [
    [
     [1496, 575],
     [1480, 578],
     [1452, 598],
     [1410, 620],
     [1410, 626],
     [1434, 648],
     [1481, 635],
     [1536, 635],
     [1591, 658],
     [1648, 653],
     [1676, 637],
     [1672, 632],
     [1616, 605],
     [1509, 574]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1709, 631]}],
   "box": [1517, 538, 1817, 754],
   "polygons": [
    [
     [1540, 539],
     [1532, 541],
     [1517, 554],
     [1517, 570],
     [1666, 621],
     [1721, 652],
     [1726, 659],
     [1715, 696],
     [1739, 750],
     [1756, 754],
     [1794, 752],
     [1800, 684],
     [1812, 663],
     [1810, 657],
     [1784, 632],
     [1785, 627],
     [1812, 604],
     [1817, 580],
     [1815, 571],
     [1783, 562],
     [1775, 564],
     [1731, 600],
     [1721, 604],
     [1606, 555],
     [1583, 542],
     [1542, 538]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1850, 707]}],
   "box": [1803, 602, 1896, 821],
   "polygons": [
    [
     [1863, 603],
     [1825, 621],
     [1803, 635],
     [1842, 675],
     [1829, 689],
     [1825, 706],
     [1831, 759],
     [1830, 774],
     [1807, 791],
     [1810, 797],
     [1836, 821],
     [1872, 813],
     [1878, 808],
     [1861, 713],
     [1863, 708],
     [1878, 703],
     [1889, 675],
     [1887, 668],
     [1863, 649],
     [1866, 645],
     [1896, 634],
     [1894, 615],
     [1866, 602]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1055, 684]}],
   "box": [871, 615, 1267, 761],
   "polygons": [
    [
     [1044, 616],
     [1003, 622],
     [900, 649],
     [894, 656],
     [885, 679],
     [871, 728],
     [873, 741],
     [943, 759],
     [965, 761],
     [999, 758],
     [1069, 744],
     [1215, 707],
     [1255, 694],
     [1267, 686],
     [1257, 646],
     [1245, 636],
     [1065, 615]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 675]}],
   "box": [458, 628, 619, 743],
   "polygons": [
    [
     [476, 629],
     [458, 638],
     [460, 658],
     [479, 666],
     [490, 682],
     [489, 703],
     [502, 723],
     [499, 743],
     [558, 737],
     [564, 696],
     [574, 666],
     [597, 643],
     [619, 633],
     [510, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [348, 688]}],
   "box": [215, 626, 491, 740],
   "polygons": [
    [
     [360, 627],
     [239, 644],
     [215, 650],
     [220, 683],
     [234, 728],
     [241, 733],
     [262, 736],
     [432, 740],
     [487, 737],
     [491, 711],
     [474, 703],
     [471, 670],
     [443, 666],
     [447, 640],
     [445, 636],
     [409, 642],
     [393, 641],
     [369, 627],
     [362, 626]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [707, 689]}],
   "box": [563, 632, 858, 744],
   "polygons": [
    [
     [679, 633],
     [595, 646],
     [584, 657],
     [570, 689],
     [563, 732],
     [564, 742],
     [568, 744],
     [654, 740],
     [792, 739],
     [833, 724],
     [849, 709],
     [858, 683],
     [854, 657],
     [796, 638],
     [710, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [925, 618]}],
   "box": [824, 585, 1035, 652],
   "polygons": [
    [
     [1023, 586],
     [837, 617],
     [824, 621],
     [825, 629],
     [833, 641],
     [844, 647],
     [857, 652],
     [872, 651],
     [1022, 611],
     [1031, 606],
     [1035, 599],
     [1035, 586],
     [1032, 585]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1115, 596]}],
   "box": [1040, 570, 1190, 619],
   "polygons": [
    [
     [1139, 571],
     [1047, 582],
     [1040, 603],
     [1041, 611],
     [1130, 619],
     [1165, 618],
     [1188, 611],
     [1190, 608],
     [1186, 598],
     [1162, 575],
     [1150, 570]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1538, 619]}],
   "box": [1409, 575, 1675, 659],
   "polygons": [
    [
     [1496, 576],
     [1478, 580],
     [1443, 604],
     [1409, 621],
     [1410, 628],
     [1433, 649],
     [1480, 636],
     [1535, 636],
     [1590, 659],
     [1648, 654],
     [1675, 638],
     [1671, 633],
     [1615, 606],
     [1509, 575]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1708, 632]}],
   "box": [1516, 540, 1817, 755],
   "polygons": [
    [
     [1535, 541],
     [1516, 556],
     [1516, 571],
     [1665, 622],
     [1720, 653],
     [1725, 660],
     [1714, 697],
     [1737, 750],
     [1742, 754],
     [1751, 755],
     [1793, 752],
     [1799, 686],
     [1811, 664],
     [1810, 659],
     [1783, 633],
     [1785, 627],
     [1812, 605],
     [1817, 580],
     [1814, 572],
     [1786, 564],
     [1776, 565],
     [1731, 601],
     [1722, 605],
     [1709, 602],
     [1582, 543],
     [1556, 540]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1849, 708]}],
   "box": [1803, 603, 1896, 822],
   "polygons": [
    [
     [1864, 604],
     [1803, 636],
     [1841, 676],
     [1828, 691],
     [1824, 707],
     [1829, 774],
     [1806, 792],
     [1811, 801],
     [1834, 822],
     [1876, 811],
     [1861, 722],
     [1862, 709],
     [1877, 705],
     [1889, 674],
     [1862, 650],
     [1896, 635],
     [1894, 617],
     [1864, 603]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1054, 684]}],
   "box": [870, 616, 1266, 760],
   "polygons": [
    [
     [1036, 617],
     [1000, 623],
     [900, 649],
     [893, 657],
     [884, 680],
     [870, 729],
     [872, 741],
     [949, 760],
     [999, 758],
     [1069, 744],
     [1212, 708],
     [1253, 695],
     [1266, 686],
     [1257, 649],
     [1247, 638],
     [1224, 633],
     [1071, 616]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [527, 674]}],
   "box": [459, 628, 619, 743],
   "polygons": [
    [
     [476, 629],
     [459, 637],
     [460, 655],
     [462, 660],
     [478, 665],
     [490, 681],
     [489, 703],
     [501, 720],
     [499, 743],
     [556, 737],
     [560, 733],
     [564, 696],
     [573, 667],
     [597, 643],
     [619, 633],
     [513, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [349, 687]}],
   "box": [216, 626, 491, 739],
   "polygons": [
    [
     [358, 627],
     [218, 649],
     [216, 652],
     [221, 680],
     [235, 727],
     [245, 733],
     [285, 736],
     [416, 739],
     [481, 738],
     [488, 735],
     [491, 711],
     [474, 701],
     [472, 670],
     [443, 665],
     [446, 636],
     [409, 642],
     [395, 641],
     [365, 626]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [707, 689]}],
   "box": [563, 632, 857, 744],
   "polygons": [
    [
     [681, 633],
     [605, 643],
     [594, 647],
     [584, 657],
     [570, 689],
     [563, 730],
     [564, 742],
     [569, 744],
     [647, 740],
     [791, 739],
     [824, 728],
     [837, 721],
     [847, 711],
     [857, 685],
     [854, 658],
     [800, 639],
     [705, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [924, 619]}],
   "box": [823, 586, 1035, 652],
   "polygons": [
    [
     [1021, 587],
     [865, 612],
     [830, 619],
     [823, 623],
     [832, 641],
     [855, 652],
     [868, 652],
     [1023, 611],
     [1030, 607],
     [1035, 599],
     [1035, 587],
     [1032, 586]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1115, 597]}],
   "box": [1040, 571, 1190, 620],
   "polygons": [
    [
     [1138, 572],
     [1049, 582],
     [1044, 587],
     [1040, 611],
     [1133, 620],
     [1170, 618],
     [1187, 612],
     [1190, 608],
     [1183, 596],
     [1163, 577],
     [1150, 571]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1538, 621]}],
   "box": [1408, 576, 1675, 661],
   "polygons": [
    [
     [1497, 577],
     [1478, 581],
     [1448, 602],
     [1410, 621],
     [1408, 627],
     [1432, 650],
     [1473, 638],
     [1533, 637],
     [1593, 661],
     [1648, 655],
     [1675, 639],
     [1668, 633],
     [1611, 606],
     [1507, 576]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1708, 633]}],
   "box": [1514, 541, 1817, 756],
   "polygons": [
    [
     [1536, 542],
     [1516, 557],
     [1514, 562],
     [1517, 573],
     [1666, 624],
     [1717, 653],
     [1724, 660],
     [1713, 697],
     [1736, 752],
     [1745, 756],
     [1792, 753],
     [1798, 688],
     [1811, 665],
     [1783, 635],
     [1789, 625],
     [1811, 608],
     [1817, 582],
     [1815, 574],
     [1783, 565],
     [1778, 566],
     [1768, 571],
     [1727, 605],
     [1713, 605],
     [1581, 544],
     [1551, 541]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1849, 710]}],
   "box": [1803, 605, 1896, 823],
   "polygons": [
    [
     [1863, 606],
     [1803, 637],
     [1805, 643],
     [1840, 677],
     [1839, 682],
     [1828, 691],
     [1823, 709],
     [1829, 768],
     [1827, 777],
     [1804, 793],
     [1811, 804],
     [1832, 823],
     [1870, 815],
     [1875, 811],
     [1860, 723],
     [1861, 711],
     [1877, 705],
     [1888, 678],
     [1886, 671],
     [1862, 652],
     [1865, 648],
     [1896, 637],
     [1894, 619],
     [1866, 605]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1053, 685]}],
   "box": [871, 616, 1265, 761],
   "polygons": [
    [
     [1048, 617],
     [1002, 623],
     [897, 651],
     [887, 669],
     [871, 721],
     [871, 741],
     [939, 759],
     [959, 761],
     [999, 758],
     [1069, 744],
     [1217, 707],
     [1251, 696],
     [1265, 687],
     [1257, 652],
     [1253, 644],
     [1244, 638],
     [1056, 616]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [528, 674]}],
   "box": [460, 628, 619, 742],
   "polygons": [
    [
     [477, 629],
     [460, 637],
     [461, 658],
     [480, 666],
     [491, 682],
     [489, 702],
     [502, 722],
     [499, 742],
     [555, 737],
     [560, 731],
     [564, 695],
     [574, 666],
     [596, 644],
     [619, 633],
     [512, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [350, 687]}],
   "box": [218, 626, 491, 739],
   "polygons": [
    [
     [355, 627],
     [242, 643],
     [218, 649],
     [222, 677],
     [237, 727],
     [252, 733],
     [285, 735],
     [451, 739],
     [488, 735],
     [491, 710],
     [475, 702],
     [472, 669],
     [444, 665],
     [447, 636],
     [405, 642],
     [387, 638],
     [368, 626]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [707, 689]}],
   "box": [562, 632, 857, 743],
   "polygons": [
    [
     [681, 633],
     [604, 643],
     [594, 647],
     [584, 657],
     [570, 689],
     [562, 736],
     [564, 741],
     [567, 743],
     [661, 739],
     [788, 739],
     [823, 728],
     [839, 719],
     [847, 710],
     [857, 682],
     [854, 658],
     [799, 639],
     [705, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [924, 619]}],
   "box": [823, 586, 1035, 652],
   "polygons": [
    [
     [1026, 587],
     [861, 613],
     [823, 623],
     [827, 636],
     [833, 642],
     [854, 652],
     [869, 652],
     [1024, 611],
     [1035, 599],
     [1035, 589],
     [1029, 586]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1115, 598]}],
   "box": [1040, 572, 1189, 621],
   "polygons": [
    [
     [1134, 573],
     [1048, 583],
     [1043, 590],
     [1040, 612],
     [1144, 621],
     [1173, 618],
     [1189, 611],
     [1188, 604],
     [1182, 596],
     [1162, 577],
     [1152, 572]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1537, 622]}],
   "box": [1408, 577, 1674, 662],
   "polygons": [
    [
     [1498, 578],
     [1478, 582],
     [1446, 604],
     [1408, 623],
     [1408, 629],
     [1432, 651],
     [1472, 639],
     [1532, 638],
     [1592, 662],
     [1648, 656],
     [1674, 641],
     [1669, 635],
     [1613, 608],
     [1506, 577]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1707, 635]}],
   "box": [1515, 542, 1817, 758],
   "polygons": [
    [
     [1537, 543],
     [1516, 558],
     [1515, 573],
     [1665, 625],
     [1716, 654],
     [1724, 664],
     [1712, 700],
     [1734, 752],
     [1743, 757],
     [1776, 758],
     [1791, 754],
     [1798, 686],
     [1810, 667],
     [1809, 662],
     [1782, 635],
     [1789, 626],
     [1811, 609],
     [1817, 584],
     [1816, 576],
     [1786, 567],
     [1776, 568],
     [1729, 605],
     [1720, 608],
     [1606, 559],
     [1583, 546],
     [1547, 542]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1848, 711]}],
   "box": [1802, 607, 1896, 824],
   "polygons": [
    [
     [1862, 608],
     [1802, 639],
     [1840, 679],
     [1827, 693],
     [1822, 712],
     [1827, 759],
     [1826, 777],
     [1803, 794],
     [1805, 800],
     [1830, 824],
     [1872, 815],
     [1874, 809],
     [1860, 730],
     [1860, 712],
     [1876, 707],
     [1888, 677],
     [1862, 653],
     [1863, 650],
     [1896, 638],
     [1894, 620],
     [1867, 607]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1052, 685]}],
   "box": [868, 617, 1264, 760],
   "polygons": [
    [
     [1037, 618],
     [999, 624],
     [897, 651],
     [887, 668],
     [873, 712],
     [868, 730],
     [870, 741],
     [946, 760],
     [998, 758],
     [1073, 743],
     [1214, 708],
     [1244, 699],
     [1264, 688],
     [1256, 652],
     [1244, 639],
     [1066, 617]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [528, 674]}],
   "box": [461, 628, 619, 742],
   "polygons": [
    [
     [477, 629],
     [461, 636],
     [462, 658],
     [480, 665],
     [491, 680],
     [490, 703],
     [502, 720],
     [499, 742],
     [556, 736],
     [560, 731],
     [564, 696],
     [574, 666],
     [596, 644],
     [619, 633],
     [518, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [351, 686]}],
   "box": [219, 626, 493, 738],
   "polygons": [
    [
     [356, 627],
     [221, 648],
     [219, 651],
     [225, 684],
     [239, 728],
     [273, 734],
     [428, 738],
     [480, 737],
     [488, 734],
     [493, 712],
     [475, 702],
     [473, 669],
     [445, 666],
     [448, 636],
     [415, 641],
     [394, 640],
     [369, 626]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [707, 689]}],
   "box": [563, 632, 857, 743],
   "polygons": [
    [
     [685, 633],
     [605, 643],
     [591, 650],
     [584, 657],
     [570, 689],
     [563, 740],
     [568, 743],
     [656, 739],
     [788, 739],
     [831, 724],
     [845, 712],
     [850, 703],
     [857, 676],
     [854, 659],
     [846, 655],
     [796, 639],
     [699, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [924, 619]}],
   "box": [823, 587, 1034, 653],
   "polygons": [
    [
     [1023, 588],
     [833, 619],
     [823, 622],
     [824, 629],
     [827, 637],
     [843, 648],
     [856, 653],
     [871, 652],
     [1018, 614],
     [1028, 609],
     [1034, 601],
     [1034, 588],
     [1030, 587]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1114, 598]}],
   "box": [1039, 573, 1189, 621],
   "polygons": [
    [
     [1133, 574],
     [1047, 584],
     [1042, 593],
     [1039, 613],
     [1125, 621],
     [1166, 620],
     [1187, 613],
     [1189, 610],
     [1185, 601],
     [1163, 579],
     [1152, 573]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1537, 623]}],
   "box": [1406, 578, 1673, 663],
   "polygons": [
    [
     [1500, 579],
     [1478, 583],
     [1444, 606],
     [1409, 623],
     [1406, 625],
     [1407, 629],
     [1431, 652],
     [1472, 640],
     [1531, 639],
     [1590, 663],
     [1648, 657],
     [1673, 642],
     [1666, 635],
     [1614, 610],
     [1504, 578]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1707, 636]}],
   "box": [1516, 544, 1817, 759],
   "polygons": [
    [
     [1535, 545],
     [1516, 559],
     [1516, 575],
     [1666, 627],
     [1723, 662],
     [1712, 696],
     [1713, 708],
     [1735, 756],
     [1765, 759],
     [1786, 758],
     [1790, 755],
     [1797, 689],
     [1810, 667],
     [1782, 637],
     [1788, 628],
     [1812, 609],
     [1817, 586],
     [1815, 577],
     [1788, 569],
     [1778, 569],
     [1726, 608],
     [1714, 608],
     [1605, 560],
     [1582, 547],
     [1557, 544]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1847, 712]}],
   "box": [1801, 608, 1896, 826],
   "polygons": [
    [
     [1863, 609],
     [1802, 640],
     [1810, 652],
     [1839, 679],
     [1838, 685],
     [1826, 695],
     [1821, 715],
     [1826, 762],
     [1825, 778],
     [1801, 796],
     [1805, 803],
     [1831, 826],
     [1869, 817],
     [1872, 814],
     [1858, 722],
     [1861, 713],
     [1875, 709],
     [1887, 681],
     [1886, 675],
     [1861, 654],
     [1896, 639],
     [1894, 622],
     [1865, 608]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1051, 686]}],
   "box": [867, 618, 1263, 760],
   "polygons": [
    [
     [1033, 619],
     [997, 625],
     [897, 651],
     [889, 662],
     [876, 700],
     [867, 731],
     [869, 741],
     [943, 760],
     [999, 758],
     [1065, 745],
     [1208, 710],
     [1245, 699],
     [1263, 689],
     [1254, 650],
     [1244, 640],
     [1070, 618]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [528, 674]}],
   "box": [461, 628, 620, 741],
   "polygons": [
    [
     [478, 629],
     [461, 637],
     [463, 659],
     [480, 665],
     [492, 682],
     [490, 702],
     [502, 720],
     [499, 741],
     [555, 736],
     [559, 733],
     [564, 696],
     [574, 666],
     [595, 645],
     [620, 633],
     [514, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [352, 686]}],
   "box": [221, 626, 493, 737],
   "polygons": [
    [
     [354, 627],
     [246, 642],
     [221, 648],
     [225, 678],
     [240, 727],
     [259, 732],
     [307, 734],
     [420, 737],
     [488, 734],
     [493, 713],
     [490, 708],
     [475, 700],
     [473, 669],
     [445, 665],
     [448, 636],
     [414, 641],
     [396, 640],
     [372, 626]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [706, 689]}],
   "box": [563, 632, 856, 743],
   "polygons": [
    [
     [685, 633],
     [604, 643],
     [591, 650],
     [584, 657],
     [569, 692],
     [563, 740],
     [572, 743],
     [644, 739],
     [792, 738],
     [835, 721],
     [849, 704],
     [856, 680],
     [854, 660],
     [848, 656],
     [795, 639],
     [699, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [924, 620]}],
   "box": [823, 588, 1034, 654],
   "polygons": [
    [
     [1018, 589],
     [838, 618],
     [823, 622],
     [824, 630],
     [829, 640],
     [849, 651],
     [860, 654],
     [876, 651],
     [1021, 613],
     [1034, 601],
     [1032, 588]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1114, 599]}],
   "box": [1039, 573, 1187, 622],
   "polygons": [
    [
     [1139, 574],
     [1048, 584],
     [1043, 589],
     [1039, 613],
     [1132, 622],
     [1169, 620],
     [1186, 614],
     [1187, 606],
     [1174, 590],
     [1156, 575],
     [1148, 573]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1536, 624]}],
   "box": [1405, 579, 1673, 664],
   "polygons": [
    [
     [1499, 580],
     [1476, 585],
     [1405, 628],
     [1431, 653],
     [1471, 641],
     [1530, 640],
     [1589, 664],
     [1643, 659],
     [1673, 643],
     [1667, 637],
     [1611, 610],
     [1503, 579]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1706, 637]}],
   "box": [1513, 545, 1817, 760],
   "polygons": [
    [
     [1535, 546],
     [1515, 561],
     [1513, 567],
     [1517, 577],
     [1665, 628],
     [1714, 656],
     [1722, 664],
     [1710, 703],
     [1732, 755],
     [1739, 759],
     [1763, 760],
     [1788, 757],
     [1796, 690],
     [1809, 669],
     [1781, 637],
     [1811, 611],
     [1817, 586],
     [1815, 578],
     [1786, 570],
     [1776, 571],
     [1726, 609],
     [1713, 609],
     [1609, 563],
     [1584, 549],
     [1555, 545]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1846, 714]}],
   "box": [1800, 610, 1895, 827],
   "polygons": [
    [
     [1862, 611],
     [1802, 641],
     [1803, 646],
     [1839, 682],
     [1826, 695],
     [1820, 717],
     [1824, 778],
     [1822, 782],
     [1800, 796],
     [1813, 814],
     [1829, 827],
     [1867, 818],
     [1871, 812],
     [1857, 723],
     [1859, 715],
     [1875, 709],
     [1887, 680],
     [1861, 656],
     [1862, 653],
     [1895, 642],
     [1893, 622],
     [1867, 610]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1051, 686]}],
   "box": [866, 618, 1262, 760],
   "polygons": [
    [
     [1038, 619],
     [998, 625],
     [898, 650],
     [892, 656],
     [884, 673],
     [866, 731],
     [868, 740],
     [904, 752],
     [943, 760],
     [998, 758],
     [1069, 744],
     [1216, 708],
     [1250, 697],
     [1262, 689],
     [1253, 650],
     [1244, 641],
     [1221, 636],
     [1063, 618]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [529, 673]}],
   "box": [462, 628, 620, 741],
   "polygons": [
    [
     [478, 629],
     [462, 636],
     [463, 657],
     [480, 664],
     [492, 680],
     [490, 701],
     [502, 719],
     [499, 741],
     [553, 736],
     [559, 732],
     [564, 695],
     [574, 666],
     [595, 645],
     [620, 633],
     [520, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [353, 685]}],
   "box": [222, 625, 493, 737],
   "polygons": [
    [
     [364, 626],
     [224, 647],
     [222, 650],
     [227, 679],
     [240, 724],
     [248, 729],
     [273, 732],
     [449, 737],
     [488, 733],
     [493, 713],
     [491, 708],
     [476, 701],
     [474, 669],
     [446, 665],
     [450, 643],
     [448, 635],
     [413, 641],
     [399, 640],
     [369, 625]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [706, 688]}],
   "box": [562, 632, 856, 742],
   "polygons": [
    [
     [687, 633],
     [596, 646],
     [585, 656],
     [572, 683],
     [562, 731],
     [563, 740],
     [568, 742],
     [661, 738],
     [790, 738],
     [831, 723],
     [843, 713],
     [850, 700],
     [856, 675],
     [854, 661],
     [847, 656],
     [794, 639],
     [696, 632]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [923, 620]}],
   "box": [823, 588, 1035, 654],
   "polygons": [
    [
     [1024, 589],
     [835, 619],
     [823, 623],
     [824, 631],
     [831, 642],
     [846, 650],
     [857, 654],
     [872, 652],
     [1022, 613],
     [1030, 608],
     [1035, 596],
     [1033, 589],
     [1028, 588]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1114, 600]}],
   "box": [1037, 574, 1188, 623],
   "polygons": [
    [
     [1137, 575],
     [1047, 585],
     [1043, 589],
     [1037, 612],
     [1049, 616],
     [1138, 623],
     [1173, 620],
     [1185, 615],
     [1188, 611],
     [1183, 601],
     [1158, 577],
     [1149, 574]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1536, 625]}],
   "box": [1406, 581, 1672, 665],
   "polygons": [
    [
     [1493, 582],
     [1476, 586],
     [1443, 608],
     [1406, 626],
     [1407, 633],
     [1430, 654],
     [1471, 642],
     [1528, 641],
     [1588, 665],
     [1644, 660],
     [1672, 644],
     [1666, 638],
     [1615, 613],
     [1508, 581]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1706, 639]}],
   "box": [1515, 546, 1817, 761],
   "polygons": [
    [
     [1536, 547],
     [1515, 562],
     [1515, 577],
     [1664, 629],
     [1713, 657],
     [1720, 662],
     [1722, 668],
     [1710, 698],
     [1711, 710],
     [1730, 755],
     [1737, 760],
     [1754, 761],
     [1787, 758],
     [1795, 693],
     [1808, 671],
     [1805, 663],
     [1781, 640],
     [1787, 631],
     [1812, 611],
     [1817, 589],
     [1816, 580],
     [1784, 571],
     [1778, 572],
     [1731, 607],
     [1720, 612],
     [1606, 563],
     [1581, 549],
     [1551, 546]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1846, 715]}],
   "box": [1799, 611, 1895, 828],
   "polygons": [
    [
     [1863, 612],
     [1801, 643],
     [1838, 682],
     [1837, 687],
     [1825, 697],
     [1820, 714],
     [1824, 769],
     [1822, 781],
     [1799, 797],
     [1801, 804],
     [1826, 828],
     [1863, 820],
     [1869, 816],
     [1857, 731],
     [1858, 716],
     [1874, 711],
     [1886, 684],
     [1885, 678],
     [1861, 658],
     [1863, 654],
     [1895, 643],
     [1894, 625],
     [1866, 611]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1050, 686]}],
   "box": [867, 619, 1261, 760],
   "polygons": [
    [
     [1032, 620],
     [991, 627],
     [898, 650],
     [885, 669],
     [867, 724],
     [867, 740],
     [903, 752],
     [941, 760],
     [998, 758],
     [1069, 744],
     [1217, 708],
     [1248, 698],
     [1261, 690],
     [1253, 653],
     [1250, 647],
     [1241, 641],
     [1068, 619]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [529, 673]}],
   "box": [463, 628, 620, 740],
   "polygons": [
    [
     [479, 629],
     [463, 636],
     [464, 658],
     [481, 664],
     [492, 679],
     [491, 702],
     [502, 718],
     [499, 740],
     [554, 735],
     [559, 732],
     [564, 696],
     [575, 665],
     [594, 646],
     [620, 633],
     [522, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [354, 685]}],
   "box": [224, 625, 492, 736],
   "polygons": [
    [
     [361, 626],
     [226, 646],
     [224, 649],
     [228, 677],
     [242, 725],
     [259, 730],
     [296, 732],
     [436, 736],
     [488, 733],
     [492, 708],
     [476, 700],
     [474, 668],
     [446, 663],
     [450, 635],
     [409, 641],
     [393, 638],
     [372, 625]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [706, 688]}],
   "box": [562, 633, 855, 742],
   "polygons": [
    [
     [675, 634],
     [604, 643],
     [596, 646],
     [585, 656],
     [570, 688],
     [562, 729],
     [563, 740],
     [571, 742],
     [649, 738],
     [788, 738],
     [830, 723],
     [845, 709],
     [855, 681],
     [852, 659],
     [798, 640],
     [713, 633]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [923, 621]}],
   "box": [822, 589, 1034, 654],
   "polygons": [
    [
     [1019, 590],
     [857, 615],
     [827, 621],
     [822, 625],
     [826, 637],
     [835, 645],
     [856, 654],
     [868, 653],
     [1018, 615],
     [1032, 605],
     [1034, 599],
     [1031, 589]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1113, 600]}],
   "box": [1038, 575, 1187, 623],
   "polygons": [
    [
     [1134, 576],
     [1046, 586],
     [1041, 595],
     [1038, 614],
     [1125, 623],
     [1164, 622],
     [1187, 614],
     [1181, 600],
     [1163, 582],
     [1150, 575]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1535, 626]}],
   "box": [1405, 582, 1671, 666],
   "polygons": [
    [
     [1493, 583],
     [1474, 588],
     [1446, 607],
     [1407, 626],
     [1405, 632],
     [1429, 655],
     [1470, 643],
     [1527, 642],
     [1587, 666],
     [1643, 661],
     [1671, 646],
     [1665, 639],
     [1614, 614],
     [1507, 582]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1705, 640]}],
   "box": [1514, 547, 1817, 762],
   "polygons": [
    [
     [1536, 548],
     [1515, 563],
     [1514, 578],
     [1663, 630],
     [1718, 662],
     [1721, 668],
     [1708, 705],
     [1729, 757],
     [1735, 761],
     [1751, 762],
     [1785, 760],
     [1795, 691],
     [1808, 671],
     [1781, 642],
     [1783, 635],
     [1810, 615],
     [1817, 590],
     [1815, 581],
     [1787, 573],
     [1776, 574],
     [1728, 610],
     [1722, 613],
     [1713, 612],
     [1580, 550],
     [1548, 547]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1845, 716]}],
   "box": [1797, 613, 1895, 829],
   "polygons": [
    [
     [1862, 614],
     [1801, 644],
     [1804, 651],
     [1837, 683],
     [1836, 689],
     [1824, 699],
     [1819, 715],
     [1821, 782],
     [1797, 799],
     [1801, 807],
     [1825, 829],
     [1862, 821],
     [1868, 815],
     [1856, 732],
     [1857, 718],
     [1874, 711],
     [1886, 683],
     [1860, 658],
     [1895, 644],
     [1894, 627],
     [1867, 613]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1049, 687]}],
   "box": [864, 619, 1260, 760],
   "polygons": [
    [
     [1038, 620],
     [988, 628],
     [895, 652],
     [884, 670],
     [867, 721],
     [864, 733],
     [866, 740],
     [902, 752],
     [940, 760],
     [997, 758],
     [1069, 744],
     [1218, 708],
     [1246, 699],
     [1260, 690],
     [1252, 653],
     [1241, 642],
     [1059, 619]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [529, 673]}],
   "box": [464, 627, 621, 740],
   "polygons": [
    [
     [486, 628],
     [464, 636],
     [464, 652],
     [465, 658],
     [482, 664],
     [493, 680],
     [491, 701],
     [502, 717],
     [499, 740],
     [556, 734],
     [559, 731],
     [565, 692],
     [575, 665],
     [597, 644],
     [621, 633],
     [492, 627]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [354, 684]}],
   "box": [225, 625, 493, 736],
   "polygons": [
    [
     [361, 626],
     [227, 646],
     [225, 649],
     [231, 684],
     [243, 724],
     [257, 729],
     [286, 731],
     [453, 736],
     [488, 732],
     [493, 715],
     [492, 708],
     [476, 699],
     [475, 669],
     [447, 664],
     [452, 638],
     [450, 635],
     [408, 641],
     [394, 638],
     [373, 625]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [706, 688]}],
   "box": [561, 633, 854, 741],
   "polygons": [
    [
     [677, 634],
     [605, 643],
     [594, 648],
     [584, 658],
     [571, 685],
     [561, 734],
     [563, 739],
     [567, 741],
     [788, 738],
     [831, 722],
     [845, 708],
     [854, 684],
     [851, 659],
     [796, 640],
     [708, 633]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [923, 621]}],
   "box": [822, 590, 1033, 655],
   "polygons": [
    [
     [1017, 591],
     [854, 616],
     [825, 622],
     [822, 625],
     [825, 636],
     [831, 643],
     [861, 655],
     [1019, 615],
     [1027, 611],
     [1033, 602],
     [1031, 590]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1113, 601]}],
   "box": [1036, 576, 1186, 624],
   "polygons": [
    [
     [1132, 577],
     [1045, 587],
     [1036, 613],
     [1045, 617],
     [1129, 624],
     [1169, 622],
     [1186, 615],
     [1186, 609],
     [1179, 599],
     [1163, 583],
     [1150, 576]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1535, 627]}],
   "box": [1405, 583, 1671, 667],
   "polygons": [
    [
     [1494, 584],
     [1472, 590],
     [1444, 609],
     [1405, 628],
     [1405, 634],
     [1429, 656],
     [1469, 644],
     [1513, 642],
     [1541, 647],
     [1586, 667],
     [1643, 662],
     [1671, 647],
     [1664, 640],
     [1613, 615],
     [1506, 583]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1705, 641]}],
   "box": [1515, 548, 1817, 763],
   "polygons": [
    [
     [1538, 549],
     [1531, 551],
     [1515, 564],
     [1515, 580],
     [1662, 631],
     [1711, 659],
     [1720, 668],
     [1708, 701],
     [1709, 713],
     [1728, 758],
     [1733, 762],
     [1745, 763],
     [1782, 762],
     [1785, 759],
     [1794, 693],
     [1807, 673],
     [1805, 667],
     [1780, 642],
     [1782, 637],
     [1810, 616],
     [1817, 591],
     [1816, 583],
     [1785, 574],
     [1777, 575],
     [1727, 612],
     [1712, 613],
     [1609, 567],
     [1582, 552],
     [1543, 548]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1844, 718]}],
   "box": [1796, 614, 1895, 830],
   "polygons": [
    [
     [1863, 615],
     [1801, 645],
     [1806, 655],
     [1837, 685],
     [1836, 690],
     [1824, 699],
     [1818, 718],
     [1820, 782],
     [1796, 800],
     [1805, 814],
     [1823, 830],
     [1862, 822],
     [1866, 818],
     [1855, 733],
     [1856, 719],
     [1872, 714],
     [1885, 686],
     [1884, 681],
     [1860, 660],
     [1862, 657],
     [1895, 646],
     [1893, 627],
     [1866, 614]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1048, 687]}],
   "box": [864, 620, 1259, 759],
   "polygons": [
    [
     [1033, 621],
     [990, 628],
     [895, 652],
     [881, 677],
     [864, 729],
     [865, 740],
     [931, 759],
     [990, 759],
     [1065, 745],
     [1204, 712],
     [1247, 699],
     [1259, 691],
     [1252, 656],
     [1248, 648],
     [1241, 643],
     [1064, 620]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [530, 673]}],
   "box": [464, 628, 621, 739],
   "polygons": [
    [
     [480, 629],
     [464, 637],
     [465, 657],
     [483, 664],
     [493, 679],
     [490, 696],
     [502, 716],
     [499, 739],
     [555, 734],
     [559, 730],
     [564, 696],
     [575, 665],
     [596, 645],
     [621, 633],
     [522, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [355, 684]}],
   "box": [228, 625, 493, 735],
   "polygons": [
    [
     [361, 626],
     [228, 646],
     [231, 677],
     [243, 722],
     [252, 727],
     [279, 730],
     [438, 735],
     [488, 732],
     [493, 715],
     [492, 707],
     [477, 700],
     [475, 668],
     [448, 664],
     [451, 635],
     [419, 640],
     [399, 639],
     [375, 625]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [705, 688]}],
   "box": [562, 633, 854, 741],
   "polygons": [
    [
     [679, 634],
     [605, 643],
     [593, 649],
     [584, 658],
     [571, 685],
     [562, 738],
     [567, 741],
     [636, 738],
     [786, 738],
     [825, 725],
     [844, 709],
     [854, 681],
     [850, 659],
     [795, 640],
     [706, 633]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [922, 622]}],
   "box": [822, 590, 1034, 654],
   "polygons": [
    [
     [1021, 591],
     [830, 621],
     [822, 624],
     [823, 631],
     [827, 640],
     [835, 646],
     [853, 654],
     [866, 654],
     [1015, 617],
     [1030, 608],
     [1034, 596],
     [1029, 590]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1112, 602]}],
   "box": [1037, 576, 1185, 625],
   "polygons": [
    [
     [1138, 577],
     [1046, 587],
     [1041, 594],
     [1037, 616],
     [1135, 625],
     [1166, 623],
     [1185, 616],
     [1185, 609],
     [1178, 599],
     [1158, 580],
     [1147, 576]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1534, 628]}],
   "box": [1404, 584, 1670, 668],
   "polygons": [
    [
     [1494, 585],
     [1475, 589],
     [1445, 609],
     [1404, 629],
     [1404, 634],
     [1429, 657],
     [1468, 645],
     [1526, 644],
     [1585, 668],
     [1643, 663],
     [1670, 648],
     [1665, 642],
     [1610, 615],
     [1506, 584]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1704, 642]}],
   "box": [1512, 549, 1817, 764],
   "polygons": [
    [
     [1540, 550],
     [1531, 552],
     [1514, 566],
     [1512, 572],
     [1515, 581],
     [1659, 631],
     [1712, 661],
     [1719, 668],
     [1707, 702],
     [1708, 714],
     [1728, 761],
     [1746, 764],
     [1781, 763],
     [1784, 760],
     [1793, 695],
     [1807, 673],
     [1780, 644],
     [1782, 638],
     [1811, 616],
     [1817, 593],
     [1815, 584],
     [1788, 576],
     [1778, 576],
     [1727, 613],
     [1711, 614],
     [1579, 552],
     [1540, 549]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1843, 719]}],
   "box": [1794, 615, 1895, 831],
   "polygons": [
    [
     [1864, 616],
     [1800, 647],
     [1836, 686],
     [1835, 691],
     [1822, 702],
     [1817, 720],
     [1819, 783],
     [1794, 801],
     [1798, 809],
     [1821, 831],
     [1860, 823],
     [1865, 818],
     [1854, 732],
     [1855, 721],
     [1871, 716],
     [1885, 686],
     [1880, 678],
     [1860, 662],
     [1862, 658],
     [1895, 647],
     [1893, 628],
     [1865, 615]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1047, 688]}],
   "box": [864, 620, 1259, 759],
   "polygons": [
    [
     [1041, 621],
     [996, 627],
     [895, 652],
     [882, 673],
     [864, 726],
     [864, 740],
     [930, 759],
     [990, 759],
     [1065, 745],
     [1205, 712],
     [1247, 699],
     [1259, 690],
     [1251, 656],
     [1241, 644],
     [1219, 639],
     [1054, 620]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [530, 673]}],
   "box": [465, 628, 621, 739],
   "polygons": [
    [
     [481, 629],
     [465, 636],
     [466, 657],
     [483, 664],
     [494, 680],
     [492, 701],
     [503, 718],
     [499, 739],
     [553, 734],
     [559, 730],
     [565, 692],
     [576, 664],
     [596, 645],
     [621, 633],
     [522, 628]
    ]
   ]
  }
 ],
 [
  {
   "color": "#60ff00ff",
   "pois": [{"id": "centroid", "point": [356, 683]}],
   "box": [228, 625, 494, 734],
   "polygons": [
    [
     [358, 626],
     [230, 645],
     [228, 648],
     [232, 674],
     [245, 722],
     [266, 728],
     [310, 730],
     [434, 734],
     [483, 733],
     [489, 730],
     [494, 710],
     [477, 699],
     [476, 668],
     [448, 663],
     [452, 635],
     [417, 640],
     [402, 639],
     [377, 625]
    ]
   ]
  },
  {
   "color": "#e7c19eff",
   "pois": [{"id": "centroid", "point": [705, 688]}],
   "box": [561, 633, 853, 738],
   "polygons": [
    [
     [679, 634],
     [604, 643],
     [592, 650],
     [584, 658],
     [571, 685],
     [561, 731],
     [562, 738],
     [783, 738],
     [824, 725],
     [843, 709],
     [853, 683],
     [851, 660],
     [799, 641],
     [705, 633]
    ]
   ]
  },
  {
   "color": "#a9cbc7ff",
   "pois": [{"id": "centroid", "point": [922, 622]}],
   "box": [822, 591, 1033, 655],
   "polygons": [
    [
     [1018, 592],
     [831, 621],
     [822, 624],
     [823, 632],
     [826, 639],
     [836, 647],
     [855, 655],
     [867, 654],
     [1016, 617],
     [1030, 608],
     [1033, 601],
     [1030, 591]
    ]
   ]
  },
  {
   "color": "#e5bcc9ff",
   "pois": [{"id": "centroid", "point": [1112, 603]}],
   "box": [1037, 577, 1186, 626],
   "polygons": [
    [
     [1136, 578],
     [1045, 588],
     [1037, 607],
     [1037, 617],
     [1142, 626],
     [1170, 623],
     [1183, 618],
     [1186, 615],
     [1185, 611],
     [1176, 598],
     [1158, 581],
     [1148, 577]
    ]
   ]
  },
  {
   "color": "#677de0ff",
   "pois": [{"id": "centroid", "point": [1533, 629]}],
   "box": [1402, 585, 1670, 670],
   "polygons": [
    [
     [1496, 586],
     [1477, 589],
     [1402, 633],
     [1428, 658],
     [1468, 646],
     [1530, 646],
     [1590, 670],
     [1643, 664],
     [1670, 649],
     [1662, 642],
     [1611, 617],
     [1504, 585]
    ]
   ]
  },
  {
   "color": "#1e401eff",
   "pois": [{"id": "centroid", "point": [1704, 644]}],
   "box": [1514, 551, 1817, 766],
   "polygons": [
    [
     [1536, 552],
     [1514, 567],
     [1514, 582],
     [1660, 633],
     [1715, 665],
     [1719, 671],
     [1706, 704],
     [1707, 715],
     [1725, 760],
     [1736, 765],
     [1767, 766],
     [1782, 762],
     [1792, 697],
     [1806, 676],
     [1804, 670],
     [1779, 644],
     [1810, 619],
     [1817, 595],
     [1816, 586],
     [1785, 577],
     [1777, 578],
     [1720, 617],
     [1702, 612],
     [1583, 555],
     [1551, 551]
    ]
   ]
  },
  {
   "color": "#8e2c9aff",
   "pois": [{"id": "centroid", "point": [1842, 720]}],
   "box": [1793, 617, 1895, 832],
   "polygons": [
    [
     [1863, 618],
     [1800, 648],
     [1803, 655],
     [1836, 688],
     [1822, 703],
     [1816, 722],
     [1819, 770],
     [1817, 786],
     [1793, 802],
     [1797, 811],
     [1819, 832],
     [1852, 826],
     [1863, 821],
     [1854, 724],
     [1872, 715],
     [1884, 689],
     [1883, 683],
     [1859, 663],
     [1895, 648],
     [1893, 630],
     [1866, 617]
    ]
   ]
  },
  {
   "color": "#ff0000ff",
   "pois": [{"id": "centroid", "point": [1047, 688]}],
   "box": [862, 621, 1257, 760],
   "polygons": [
    [
     [1032, 622],
     [984, 630],
     [899, 650],
     [892, 654],
     [875, 691],
     [862, 730],
     [863, 740],
     [895, 751],
     [935, 760],
     [990, 759],
     [1065, 745],
     [1198, 714],
     [1240, 702],
     [1257, 693],
     [1249, 654],
     [1238, 644],
     [1061, 621]
    ]
   ]
  },
  {
   "color": "#fff600ff",
   "pois": [{"id": "centroid", "point": [530, 672]}],
   "box": [466, 627, 621, 738],
   "polygons": [
    [
     [485, 628],
     [466, 636],
     [467, 657],
     [483, 663],
     [494, 679],
     [492, 700],
     [503, 717],
     [499, 738],
     [557, 732],
     [565, 692],
     [576, 664],
     [595, 646],
     [621, 634],
     [499, 627]
    ]
   ]
  }
 ]
]