import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { outputText } from '../../modules/SegmentGenerator/reducerSegmentGenerator'

import {
  GRAPHIC_ELEMENT,
  isGraphicElementVisible
} from '../../modules/SegmentGenerator/reducerSGVisualization'

import RCGGraphic from './RCGGraphic'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  text: {
    backgroundColor: 'white',
    overflow: 'auto',
    width: '100%',
    minWidth: '500px',
    height: '100%',
    flex: 1,
    margin: 'auto'
  }
}))

function RCGCenter() {
  const isVisible = useSelector(isGraphicElementVisible)
  const output = useSelector(outputText)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {isVisible(GRAPHIC_ELEMENT.OUTPUT) ? (
        <pre className={classes.text}>{output.text}</pre>
      ) : null}
      {isVisible(GRAPHIC_ELEMENT.GRAPHIC) ? <RCGGraphic /> : null}
    </div>
  )
}
export default RCGCenter
