import { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'

import CircularProgress from '@material-ui/core/CircularProgress'
import { drawPolygon, drawShape } from '../utils/draw'
import { getColorFromArray, rgbaToHex } from '../utils/color'
// import { boundingBox } from '../utils/mathFunctions'
import { globalPointToLocalPoint, getChildOffset } from '../utils/centeredChild'

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: '100%',
    height: '100%'
  },
  progress: { position: 'absolute', zIndex: 10 }
}))

function RSegmentsCanvas({
  scale,
  translation,
  imageSrc,
  segments,
  drawSegment,
  onCanvasBoundsChanged,
  onImageSizeChanged,
  onDrawStart,
  onDrawEnd,
  onSegmentClick,
  onSegmentHoverChanged
}) {
  const [boundsRef, bounds] = useMeasure()
  const canvasRef = useRef(null)
  const [image, setImage] = useState(null)
  const [maskRead, setMaskRead] = useState(false)
  const [maskImageData, setMaskImageData] = useState(false)
  const [imageTopLeft, setImageTopLeft] = useState({ x: 0, y: 0 })
  const [hoverSegment, setHoverSegment] = useState('')
  const [touchStart, setTouchStart] = useState(false)
  // const [box, setBox] = useState([0, 0, 0, 0])
  const classes = useStyles()

  useEffect(() => {
    if (onCanvasBoundsChanged) {
      onCanvasBoundsChanged(bounds)
    }
  }, [bounds, onCanvasBoundsChanged])

  useEffect(() => {
    if (onImageSizeChanged) {
      const imageSize = {
        width: image ? image.naturalWidth : 0,
        height: image ? image.naturalHeight : 0
      }
      onImageSizeChanged(imageSize)
    }
  }, [image, onImageSizeChanged])

  const drawMask = useCallback(
    (ctx) => {
      console.log(segments)
      for (let segment of segments) {
        drawShape(ctx, segment.polygons, true, segment.color)
      }
    },
    [segments]
  )

  const draw2 = () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    draw(ctx)
  }

  const draw = useCallback(
    (ctx) => {
      const imageSize = {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
      if (onDrawStart) {
        onDrawStart()
      }
      //Desplazamiento para centrar imagen
      const offset = getChildOffset(scale, translation, imageSize, bounds)
      setImageTopLeft({
        x: offset.x + bounds.left,
        y: offset.y + bounds.top
      })
      //Borra y recupera tamaño despues de mascara
      ctx.canvas.width = bounds.width
      ctx.canvas.height = bounds.height
      ctx.setTransform(scale, 0, 0, scale, offset.x, offset.y)
      //Imagen
      ctx.drawImage(image, 0, 0)
      // const boxWidth = box[2] - box[0]
      // const boxHeight = box[3] - box[1]
      // ctx.drawImage(
      //   image,
      //   box[0],
      //   box[1],
      //   boxWidth,
      //   boxHeight,
      //   box[0],
      //   box[1],
      //   boxWidth,
      //   boxHeight
      // )

      //Segments
      for (let segment of segments) {
        drawSegment(ctx, segment)
      }
      //
      if (onDrawEnd) {
        onDrawEnd()
      }
    },
    [
      image,
      scale,
      bounds,
      translation,
      segments,
      drawSegment,
      onDrawStart,
      onDrawEnd
    ]
  )

  //Cambio de imagen
  useEffect(() => {
    setImage(null)
    setMaskRead(false)
    const img = new Image()
    img.onload = () => {
      setImage(img)
    }
    img.src = imageSrc
    img.src2 = imageSrc
  }, [imageSrc])

  //Imagen cargada, cambio en seleccion. Dibujar todo de una vez para que
  //no parpadee
  useEffect(() => {
    if (!canvasRef.current || !bounds || !image || image.src2 !== imageSrc) {
      return
    }
    // console.log('draw')
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    //Lee mascaras
    if (!maskRead) {
      setMaskRead(true)
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      drawMask(ctx)
      setMaskImageData(ctx.getImageData(0, 0, canvas.width, canvas.height))
    }
    //Dibujar
    console.log('draw')
    // window.requestAnimationFrame(draw2)
    draw(ctx)
  }, [image, maskRead, translation, scale, drawMask, draw, bounds, imageSrc])

  //Color del pixel en la mascara actual
  const getPixelColor = useCallback(
    (x, y) => {
      const imagePoint = globalPointToLocalPoint({ x, y }, scale, imageTopLeft)
      if (
        !maskImageData ||
        imagePoint.x >= maskImageData.width ||
        imagePoint.x < 0 ||
        imagePoint.y >= maskImageData.height ||
        imagePoint.y < 0
      ) {
        return '#00000000'
      }
      let index = (imagePoint.y * maskImageData.width + imagePoint.x) * 4
      const color = getColorFromArray(index, maskImageData.data)
      if (color.a === 0) {
        return '#00000000'
      }
      return rgbaToHex(color)
    },
    [imageTopLeft, maskImageData, scale]
  )

  const getSegmentAtPoint = useCallback(
    (x, y) => {
      const hexColor = getPixelColor(x, y)
      console.log(hexColor, segments)
      const segment = segments.find((segment) => segment.color === hexColor)
      return segment ? hexColor : ''
    },
    [getPixelColor, segments]
  )

  const handleMove = useCallback(
    (e) => {
      if (!onSegmentHoverChanged) {
        return
      }
      if (!touchStart) {
        const segment = getSegmentAtPoint(e.pageX, e.pageY)
        if (hoverSegment !== segment) {
          setHoverSegment(segment)
          onSegmentHoverChanged(segment)
        }
      }
    },
    [touchStart, getSegmentAtPoint, onSegmentHoverChanged, hoverSegment]
  )

  const handleClick = useCallback(
    (e) => {
      if (!onSegmentClick) {
        return
      }
      const segment = getSegmentAtPoint(e.pageX, e.pageY)
      if (segment !== '') {
        onSegmentClick(segment)
      }
      setTouchStart(false)
    },
    [onSegmentClick, getSegmentAtPoint]
  )

  const handleTouchStart = (e) => {
    setTouchStart(true)
  }

  return (
    <>
      {!image ? <CircularProgress className={classes.progress} /> : null}
      <canvas
        className={classes.canvas}
        ref={mergeRefs([canvasRef, boundsRef])}
        onMouseMove={handleMove}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
      />
    </>
  )
}
export default RSegmentsCanvas
