import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import {
  currentGroupSelector,
  setGroup,
  groupsSelector
} from '../../../../modules/reducerGroups'
import {
  generateAllSteps,
  readAllSteps,
  readAllStepsPois,
  clearAllSteps
} from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'

const useStyles = makeStyles((theme) => ({
  root: { border: '1px solid black', padding: '5px 10px', margin: '5px 0px' },
  formControl: { width: '100%' },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  button: { width: '100%', padding: 0 }
}))

const RCGGroups = () => {
  const classes = useStyles()
  const currentGroup = useSelector(currentGroupSelector)
  const groups = useSelector(groupsSelector)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(setGroup(e.target.value))
    },
    [dispatch]
  )

  const handleGenerate = useCallback(() => {
    dispatch(generateAllSteps())
  }, [dispatch])

  const handleRead = useCallback(() => {
    dispatch(readAllSteps())
  }, [dispatch])

  const handleReadPois = useCallback(() => {
    dispatch(readAllStepsPois())
  }, [dispatch])

  const handleClear = useCallback(() => {
    dispatch(clearAllSteps())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id="groupLabel">Group</InputLabel>
        <Select
          labelId="groupLabel"
          id="groupSelect"
          value={currentGroup}
          onChange={handleChange}
        >
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentGroup !== '' ? (
        <div className={classes.buttonWrapper}>
          <button className={classes.button} onClick={handleGenerate}>
            Generate
          </button>
          <button className={classes.button} onClick={handleRead}>
            Read
          </button>
          <button className={classes.button} onClick={handleReadPois}>
            Pois
          </button>
          <button className={classes.button} onClick={handleClear}>
            Clear
          </button>
        </div>
      ) : null}
    </div>
  )
}
export default RCGGroups
