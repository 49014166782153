import React, { useState } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'

import RTooltipContent from './RTooltipContent'
const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1
    // '&[x-placement*="bottom"] $arrow': {
    //   top: 0,
    //   left: 0,
    //   marginTop: '-0.9em',
    //   width: '3em',
    //   height: '1em',
    //   '&::before': {
    //     borderWidth: '0 1em 1em 1em',
    //     borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
    //   }
    // },
    // '&[x-placement*="top"] $arrow': {
    //   bottom: 0,
    //   left: 0,
    //   marginBottom: '-0.9em',
    //   width: '3em',
    //   height: '1em',
    //   '&::before': {
    //     borderWidth: '1em 1em 0 1em',
    //     borderColor: `${theme.palette.background.paper} transparent transparent transparent`
    //   }
    // },
    // '&[x-placement*="right"] $arrow': {
    //   left: 0,
    //   marginLeft: '-0.9em',
    //   height: '3em',
    //   width: '1em',
    //   '&::before': {
    //     borderWidth: '1em 1em 1em 0',
    //     borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
    //   }
    // },
    // '&[x-placement*="left"] $arrow': {
    //   right: 0,
    //   marginRight: '-0.9em',
    //   height: '3em',
    //   width: '1em',
    //   '&::before': {
    //     borderWidth: '1em 0 1em 1em',
    //     borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
    //   }
    // }
  },
  arrow: {
    // position: 'absolute',
    // fontSize: 7,
    // width: '3em',
    // height: '3em',
    // '&::before': {
    //   content: '""',
    //   margin: 'auto',
    //   display: 'block',
    //   width: 0,
    //   height: 0,
    //   borderStyle: 'solid'
    // }
  }
}))

const RTooltip = ({
  parentRef,
  master,
  houseId,
  centroid,
  centroidVisible
}) => {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState(null)
  const virtualReference = () => {
    const width = 10
    const height = 10
    return {
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect: () => {
        return {
          top: centroid.y - height / 2,
          left: centroid.x - width / 2,
          bottom: centroid.y + height / 2,
          right: centroid.x + width / 2,
          width: width,
          height: height
        }
      }
    }
  }
  return (
    <Popper
      className={classes.popper}
      placement="left"
      open={houseId !== ''}
      anchorEl={virtualReference()}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: parentRef.current
        },
        flip: {
          enabled: true
        },
        arrow: {
          enabled: centroidVisible,
          element: arrowRef
        },
        keepTogether: {
          enabled: centroidVisible
        },
        offset: { enabled: true, offset: '0px 100px' }
      }}
    >
      {centroidVisible ? (
        <span className={classes.arrow} ref={setArrowRef} />
      ) : null}
      <RTooltipContent houseId={houseId} master={master} />
    </Popper>
  )
}
export default RTooltip
