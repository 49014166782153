import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import RBarButton from './RBarButton'
import { barVisibleSelector } from '../../../modules/reducerMaster'

import { modeSelector, MODE } from '../../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RFullWindowButton = (props) => {
  // const classes = useStyles()
  const barVisible = useSelector(barVisibleSelector)
  const mode = useSelector(modeSelector)
  // const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    console.log('Full window')
  }, [])

  return (
    <RBarButton
      {...props}
      tooltip="Full window"
      tooltipEnabled={barVisible}
      onClick={handleClick}
      disabled={mode === MODE.PANNING || mode === MODE.ROT_MOUSE}
    >
      <ZoomOutMapIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RFullWindowButton
