import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useSpring, animated } from 'react-spring'
import Button from '@material-ui/core/Button'

import { groupsSelector, setGroup } from '../../../modules/reducerGroups'
import { lateralVisibleSelector } from '../../../modules/reducerMaster'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000F31', //'rgb(241, 234, 207)',
    opacity: '0.8',
    width: '200px',

    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'center',

    padding: '20px 0px'
  },
  button: { width: '100%', margin: '2px', color: 'white' }
}))

const RLateral = () => {
  const classes = useStyles()
  const groups = useSelector(groupsSelector)
  // const currentGroup = useSelector(currentGroupSelector)
  const lateralVisible = useSelector(lateralVisibleSelector)
  const dispatch = useDispatch()

  const animation = useSpring({
    marginLeft: lateralVisible ? 0 : -200,
    opacity: lateralVisible ? 1 : 0
  })

  //Cambio de grupo.
  const handleGroupChanged = useCallback(
    (group) => {
      dispatch(setGroup(group))
    },
    [dispatch]
  )
  return (
    <animated.div className={classes.root} style={animation}>
      {groups.map((group) => (
        <Button
          key={group.id}
          className={classes.button}
          onClick={() => handleGroupChanged(group.id)}
        >
          {group.name}
        </Button>
      ))}
    </animated.div>
  )
}
export default RLateral
