import { useRef, useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RSegmentsCanvas from '../RSegmentsCanvas'
import RTooltip from '../Tooltip/RTooltip'
import RRotator from '../Rotator/RRotator'

import {
  stepSelector,
  scaleSelector,
  getTranslation
} from '../../modules/reducerRotator'

import {
  currentGroupSelector,
  getCurrentGroupImages,
  getCurrentSegments,
  getCurrentLocalCentroid
} from '../../modules/reducerGroups'
import {
  hoverSegmentSelector,
  selectedSegmentsSelector,
  toggleSegmentSelection,
  mouseOverSegment
} from '../../modules/reducerShare'
import { drawHouse } from '../../utils/draw'
import { isPointInsideBounds } from '../../utils/mathFunctions'
import {
  localPointToGlobalPoint,
  getChildTopLeft
} from '../../utils/centeredChild'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', height: '100%' }
}))

const RMCenter = () => {
  const rootRef = useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [stepDrawn, setStepDrawn] = useState(-1)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [canvasBounds, setCanvasBounds] = useState(null)
  const [globalCentroid, setGlobalCentroid] = useState({ x: 0, y: 0 })
  const [centroidVisible, setCentroidVisible] = useState(false)
  const hoverSegment = useSelector(hoverSegmentSelector)
  const selectedSegments = useSelector(selectedSegmentsSelector)
  const currentGroup = useSelector(currentGroupSelector)
  const currentStep = useSelector(stepSelector)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(getTranslation)
  const images = useSelector(getCurrentGroupImages)
  const segments = useSelector(getCurrentSegments)
  const gCurrentLocalCentroid = useSelector(getCurrentLocalCentroid)

  useEffect(() => {
    if (hoverSegment !== '') {
      const segment = segments.find((segment) => segment.color === hoverSegment)
      if (segment) {
        const topLeft = getChildTopLeft(
          scale,
          translation,
          imageSize,
          canvasBounds
        )
        const localCentroid = gCurrentLocalCentroid(segment.color)
        const centroid = localPointToGlobalPoint(
          {
            x: localCentroid ? localCentroid[0] : 0,
            y: localCentroid ? localCentroid[1] : 0
          },
          scale,
          topLeft
        )
        setGlobalCentroid(centroid)
        setCentroidVisible(
          isPointInsideBounds(centroid.x, centroid.y, canvasBounds)
        )
      }
    }
  }, [
    hoverSegment,
    segments,
    canvasBounds,
    imageSize,
    scale,
    translation,
    currentGroup,
    gCurrentLocalCentroid
  ])

  useEffect(() => {
    setStepDrawn(false)
  }, [currentGroup, currentStep])

  const handleCanvasBoundsChanged = useCallback((bounds) => {
    setCanvasBounds(bounds)
  }, [])

  const handleImageSizeChanged = useCallback((imageSize) => {
    setImageSize(imageSize)
  }, [])

  const handleDrawStart = useCallback(() => {}, [])

  const handleDrawEnd = useCallback(() => {
    setStepDrawn(true)
  }, [])

  const handleDrawSegment = useCallback(
    (ctx, segment) => {
      drawHouse(
        ctx,
        segment,
        'house',
        selectedSegments.indexOf(segment.color) >= 0,
        hoverSegment === segment.color
      )
    },
    [selectedSegments, hoverSegment]
  )

  const handleSegmentClick = useCallback(
    (segmentId) => {
      dispatch(toggleSegmentSelection(segmentId))
      dispatch(mouseOverSegment(segmentId))
    },
    [dispatch]
  )

  const handleSegmentHoverChanged = useCallback(
    (segmentId) => {
      dispatch(mouseOverSegment(segmentId))
    },
    [dispatch]
  )

  return (
    <div ref={rootRef} className={classes.root}>
      <RRotator>
        <RSegmentsCanvas
          scale={scale}
          translation={translation}
          imageSrc={images[currentStep]}
          segments={segments}
          drawSegment={handleDrawSegment}
          onCanvasBoundsChanged={handleCanvasBoundsChanged}
          onImageSizeChanged={handleImageSizeChanged}
          onDrawStart={handleDrawStart}
          onDrawEnd={handleDrawEnd}
          onSegmentClick={handleSegmentClick}
          onSegmentHoverChanged={handleSegmentHoverChanged}
        />
      </RRotator>
      <RTooltip
        parentRef={rootRef}
        master={true}
        houseId={stepDrawn ? hoverSegment : ''}
        centroid={globalCentroid}
        centroidVisible={centroidVisible}
      />
    </div>
  )
}
export default RMCenter
