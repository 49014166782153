import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import RCGPoint from './RCGPoint'

import { setPoi } from '../../../../modules/SegmentGenerator/reducerSGEditorActions'

import {
  getCurrentSegmentPoi,
  getCurrentSegmentPoint,
  isPoiSelected,
  getCurrentSegmentNotUsedPredefinedPoisNames
} from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  wrapper: { minWidth: 0 },
  poiId: {
    width: '100%',
    boxSizing: 'border-box',
    marginLeft: '2px',
    marginBottom: '2px'
  },
  poiNameWrapper: { display: 'flex', flexFlow: 'row' },
  button: { padding: 0 }
}))

const RCGPointsOfInterestItem = ({ poiId, onSelect }) => {
  const gPoi = useSelector(getCurrentSegmentPoi)
  const poi = gPoi(poiId)
  const gCurrentSegmentPoint = useSelector(getCurrentSegmentPoint)
  const point = gCurrentSegmentPoint(poiId)
  const [localName, setLocalName] = useState(poi.name)
  const isSelected = useSelector(isPoiSelected)
  const selected = isSelected(poiId)
  const notUsedPredefinedPoisNames = useSelector(
    getCurrentSegmentNotUsedPredefinedPoisNames
  )
  const [menuAnchor, setMenuAnchor] = useState(null)

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    setLocalName(poi.name)
  }, [poi])

  const handleChange = useCallback(
    (x, y) => {
      dispatch(setPoi(poi.pointId, poi.name, x, y))
    },
    [dispatch, poi]
  )
  const handleIdChange = useCallback((e) => {
    setLocalName(e.target.value)
  }, [])

  const handleIdBlur = useCallback(
    (e) => {
      dispatch(setPoi(point.id, localName, point.x, point.y))
    },
    [dispatch, point, localName]
  )

  const handleIdKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        dispatch(setPoi(point.id, localName, point.x, point.y))
      }
    },
    [dispatch, point, localName]
  )

  const handleIconClick = (event) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleMenuItemClick = (event, index) => {
    dispatch(
      setPoi(point.id, notUsedPredefinedPoisNames[index].name, point.x, point.y)
    )
    handleMenuClose()
  }

  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.button2}
        checked={selected}
        onChange={() => onSelect(poiId, !selected)}
      />
      <div className={classes.wrapper}>
        <div className={classes.poiNameWrapper}>
          <input
            className={classes.poiId}
            value={localName}
            onChange={handleIdChange}
            onBlur={handleIdBlur}
            onKeyPress={handleIdKeyPress}
          />
          <IconButton className={classes.button} onClick={handleIconClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={menuAnchor}
            onClose={handleMenuClose}
          >
            {notUsedPredefinedPoisNames.map((poiName, index) => (
              <MenuItem
                key={poiName.id}
                onClick={(e) => handleMenuItemClick(e, index)}
              >
                {poiName.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <RCGPoint x={point.x} y={point.y} onChange={handleChange} />
      </div>
    </div>
  )
}
export default RCGPointsOfInterestItem
/*

*/
//
