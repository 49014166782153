import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RCGPointItem from './RCGPointItem'
import {} from '../../../../modules/reducerGroups'
import {
  insertPoint,
  removePoints,
  movePoint,
  selectPoint
} from '../../../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  currentPolygonSelector,
  selectedPointsSelector,
  getCurrentSegmentPolygon,
  getCurrentSegmentPointIndex,
  getCurrentSegmentSelectedPolygonsPoints
} from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  root: { border: '1px solid black', padding: '5px 10px', margin: '5px 0px' },
  title: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0
  }
}))

const RCGPoints = () => {
  const currentPolygon = useSelector(currentPolygonSelector)
  const gPolygon = useSelector(getCurrentSegmentPolygon)
  const gPointIndex = useSelector(getCurrentSegmentPointIndex)
  const selectedPoints = useSelector(getCurrentSegmentSelectedPolygonsPoints)
  const polygon = gPolygon(currentPolygon)
  const points = polygon ? polygon.points : []
  const dispatch = useDispatch()

  const classes = useStyles()

  const handleAddPoint = useCallback(() => {
    let position = 0
    if (selectedPoints.length === 1) {
      position = Math.max(0, gPointIndex(currentPolygon, selectedPoints[0]))
    }
    dispatch(insertPoint(currentPolygon, position, 0, 0))
  }, [dispatch, currentPolygon, selectedPoints, gPointIndex])

  const handleRemovePoints = useCallback(() => {
    dispatch(removePoints(selectedPoints))
  }, [dispatch, selectedPoints])

  const handleUp = useCallback(() => {
    if (selectedPoints.length !== 1) {
      return
    }
    const pfrom = gPointIndex(currentPolygon, selectedPoints[0])
    dispatch(movePoint(currentPolygon, pfrom, pfrom - 1))
  }, [dispatch, currentPolygon, selectedPoints, gPointIndex])

  const handleDown = useCallback(() => {
    if (selectedPoints.length !== 1) {
      return
    }
    const pfrom = gPointIndex(currentPolygon, selectedPoints[0])
    dispatch(movePoint(currentPolygon, pfrom, pfrom + 1))
  }, [dispatch, currentPolygon, selectedPoints, gPointIndex])

  const handleSelect = useCallback(
    (pointId, select) => {
      dispatch(selectPoint(pointId, select))
    },
    [dispatch]
  )

  return currentPolygon !== '' ? (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>Points</div>
        <div>
          <IconButton className={classes.button} onClick={handleDown}>
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton className={classes.button} onClick={handleUp}>
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton className={classes.button} onClick={handleAddPoint}>
            <AddIcon />
          </IconButton>
          <IconButton className={classes.button} onClick={handleRemovePoints}>
            <RemoveIcon />
          </IconButton>
        </div>
      </div>
      {points.map((point) => {
        return (
          <RCGPointItem
            key={point.id}
            pointId={point.id}
            onSelect={handleSelect}
          />
        )
      })}
    </div>
  ) : null
}
export default RCGPoints
