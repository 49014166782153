import { createSelector } from 'reselect'
import {
  getGroupStepCount,
  getCurrentGroupAllStepsSegments,
  getCurrentGroupStepSegments,
  currentGroupSelector,
  SET_GROUP
} from '../reducerGroups'

import { getBoundingBox } from '../../utils/mathFunctions'
const stringify = require('json-stringify-pretty-compact')

export const SET_STEP_DATA = 'SET_STEP_DATA'
export const SET_ALL_STEPS_DATA = 'SET_ALL_STEPS_DATA'
export const SET_STEP_POIS = 'SET_STEP_POIS'
export const SET_ALL_STEPS_POIS = 'SET_ALL_STEPS_POIS'
export const GENERATE_STEP = 'GENERATE_STEP'
export const GENERATE_ALL_STEPS = 'GENERATE_ALL_STEPS'
export const CLEAR_STEP = 'CLEAR_STEP'
export const CLEAR_ALL_STEPS = 'CLEAR_ALL_STEPS'

const initialState = {
  data: [],
  stepsQueue: [] //Pasos a generar
}

const emptyData = (stepCount) => {
  const data = []
  for (let i = 0; i < stepCount; ++i) {
    data.push([])
  }
  return data
}

const changeState = (state = initialState, action) => {
  // console.log(action, state)
  switch (action.type) {
    case SET_GROUP: {
      return {
        ...state,
        data: emptyData(action.stepCount),
        stepsQueue: []
      }
    }

    case CLEAR_ALL_STEPS: {
      return {
        ...state,
        data: emptyData(state.data.length),
        stepsQueue: []
      }
    }

    case CLEAR_STEP: {
      const newData = state.data.map((step, index) =>
        index === action.step ? [] : step
      )
      return {
        ...state,
        data: newData
      }
    }

    case GENERATE_STEP: {
      if (action.step === '' || state.stepsQueue.indexOf(action.step) >= 0) {
        return state
      }
      return {
        ...state,
        stepsQueue: [...state.stepsQueue, action.step]
      }
    }
    case GENERATE_ALL_STEPS: {
      const steps = []
      for (let i = 0; i < state.data.length; ++i) {
        steps.push(i)
      }
      return {
        ...state,
        stepsQueue: steps
      }
    }

    case SET_STEP_DATA: {
      const newData = state.data.map((step, index) => {
        return index === action.step ? action.data : step
      })
      const newStepsQueue = state.stepsQueue.filter(
        (step) => step !== action.step
      )
      return {
        ...state,
        data: newData,
        stepsQueue: newStepsQueue
      }
    }
    case SET_ALL_STEPS_DATA:
      return {
        ...state,
        data: action.data,
        stepsQueue: []
      }
    case SET_ALL_STEPS_POIS:
      //Actualiza puntos de interes de las casas que ya existen. No crea las
      //casas que esten en action.data y no este en el estado
      const newData = state.data.map((step, nStep) => {
        return step.map((segment) => {
          if (nStep < action.data.length) {
            const actionStep = action.data[nStep]
            const actionSegment = actionStep.find(
              (actionSegment) => segment.color === actionSegment.color
            )
            if (actionSegment) {
              return { ...segment, pois: actionSegment.pois }
            }
          }
          return segment
        })
      })
      return {
        ...state,
        data: newData
      }

    case SET_STEP_POIS: {
      //Actualiza puntos de interes de las casas que ya existen. No crea las
      //casas que esten en action.data y no este en el estado
      const newData = state.data.map((step, nStep) => {
        if (nStep !== action.step) {
          return step
        }
        return step.map((segment) => {
          const actionSegment = action.data.find(
            (actionSegment) => segment.color === actionSegment.color
          )
          if (actionSegment) {
            return { ...segment, pois: actionSegment.pois }
          }
          return segment
        })
      })
      return {
        ...state,
        data: newData
      }
    }

    default:
      return state
  }
}
export default changeState

export const clearAllSteps = () => ({
  type: CLEAR_ALL_STEPS
})

export const clearStep = (step) => ({
  type: CLEAR_STEP,
  step
})

export const generateStep = (step) => ({
  type: GENERATE_STEP,
  step
})

export const generateAllSteps = () => ({
  type: GENERATE_ALL_STEPS
})

export const setStepData = (data, step) => ({
  type: SET_STEP_DATA,
  data,
  step
})

export const readAllSteps = () => (dispatch, getState) => {
  const state = getState()
  const data = getCurrentGroupAllStepsSegments(state)
  const newData = JSON.parse(JSON.stringify(data))
  dispatch({
    type: SET_ALL_STEPS_DATA,
    data: newData
  })
}

export const readStep = (step) => (dispatch, getState) => {
  const state = getState()
  const data = getCurrentGroupStepSegments(state)(step)
  const newData = JSON.parse(JSON.stringify(data))
  dispatch({
    type: SET_STEP_DATA,
    data: newData,
    step
  })
}

export const readAllStepsPois = () => (dispatch, getState) => {
  const state = getState()
  const data = getCurrentGroupAllStepsSegments(state)
  const newData = JSON.parse(JSON.stringify(data))
  dispatch({
    type: SET_ALL_STEPS_POIS,
    data: newData
  })
}

export const readStepPois = (step) => (dispatch, getState) => {
  const state = getState()
  const data = getCurrentGroupStepSegments(state)(step)
  const newData = JSON.parse(JSON.stringify(data))
  dispatch({
    type: SET_STEP_POIS,
    data: newData,
    step: step
  })
}

export const dataSelector = (state) => state.generator.data
export const stepsQueueSelector = (state) => state.generator.stepsQueue

//Segmentos en el paso indicado
export const getGeneratedStepSegments = createSelector(
  [dataSelector, currentGroupSelector, getGroupStepCount],
  (data, currentGroup, gGroupStepCount) => (nStep) => {
    if (nStep === '' || nStep < 0 || nStep >= gGroupStepCount(currentGroup)) {
      return []
    }
    return data[nStep]
  }
)

export const getGeneratedSegment = createSelector(
  [getGeneratedStepSegments],
  (gGeneratedStepSegments) => (nStep, color) => {
    return gGeneratedStepSegments(nStep).find(
      (segment) => segment.color === color
    )
  }
)

export const outputText = createSelector(
  [currentGroupSelector, dataSelector],
  (currentGroup, data) => {
    return {
      text:
        `export const group_${currentGroup}_segments =` +
        stringify(data, { maxLength: 100, indent: 1 }),
      // `export const group_${currentGroup}_contours =` +
      //   JSON.stringify(data, null, 2)}
      fileName: `group_${currentGroup}_segments.js`
    }
  }
)

//Boundingbox de todas los segmentos
export const getGeneratedAllSegmentsBoundingBox = createSelector(
  [getGeneratedStepSegments],
  (gGeneratedStepSegments) => (nStep) => {
    const points = gGeneratedStepSegments(nStep).reduce((acc, segment) => {
      acc.push([segment.box[0], segment.box[1]])
      acc.push([segment.box[2], segment.box[3]])

      return acc
    }, [])
    return getBoundingBox(points)
  }
)
