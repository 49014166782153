import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { getCurrentGroupColors } from '../../../../modules/reducerGroups'
import { getGeneratedSegment } from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'
import { stepSelector } from '../../../../modules/reducerRotator'
import { currentSegmentSelector } from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'
import {
  setCurrentSegment,
  setSegmentData
} from '../../../../modules/SegmentGenerator/reducerSGEditorActions'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid black',
    padding: '5px 10px',
    margin: '5px 0px',

    display: 'flex',
    flexFlow: 'column'
  },
  title: { paddingBottom: '10px' },
  segmentsWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    paddingBottom: '10px'
  },
  segmentButton: {
    width: '50%',
    minWidth: '0px',
    height: '30px',
    padding: '0px'
  },
  buttonsWrapper: {
    // paddingTop: '20px',
    margin: '5px 0px',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around'
  },
  button: { width: '100%' }
}))

const RCGSegments = () => {
  const classes = useStyles()
  const currentStep = useSelector(stepSelector)
  const gGeneratedSegment = useSelector(getGeneratedSegment)
  const currentSegment = useSelector(currentSegmentSelector)
  const colors = useSelector(getCurrentGroupColors)
  const dispatch = useDispatch()

  const handleSegmentClick = useCallback(
    (color) => {
      dispatch(setCurrentSegment(color))
    },
    [dispatch]
  )
  //Borra area
  const handleClear = useCallback(() => {
    dispatch(setSegmentData(currentSegment, null))
  }, [dispatch, currentSegment])

  //Lee area en reducerSegmentGenerator
  const handleUndo = useCallback(() => {
    dispatch(
      setSegmentData(
        currentSegment,
        gGeneratedSegment(currentStep, currentSegment)
      )
    )
  }, [dispatch, currentStep, currentSegment, gGeneratedSegment])

  return (
    <div className={classes.root}>
      <div className={classes.title}>Segments</div>
      <div className={classes.segmentsWrapper}>
        {colors.map((color, index) => (
          <Button
            key={index}
            className={classes.segmentButton}
            style={{
              backgroundColor: color,
              border:
                currentSegment === color ? '2px solid red' : '2px solid grey'
            }}
            variant="contained"
            color={currentSegment === color ? 'secondary' : 'default'}
            disableFocusRipple
            onClick={(event) => handleSegmentClick(color)}
          >
            {color}
          </Button>
        ))}
      </div>
      <div className={classes.buttonsWrapper}>
        <button className={classes.button} onClick={handleUndo}>
          Undo
        </button>
        <button className={classes.button} onClick={handleClear}>
          Clear
        </button>
      </div>
      <div className={classes.buttonsWrapper}></div>
    </div>
  )
}
export default RCGSegments
