import { useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import RCGLateral from './Lateral/RCGLateral'
import RCGCenter from './RCGCenter'
import RCGCalculator from './RCGCalculator'
import RCGProgress from './RCGProgress'
import RCGFooter from './Footer/RCGFooter'
import {
  keyPressed,
  keyReleased
} from '../../modules/SegmentGenerator/reducerSGEditorActions'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexFlow: 'column'
  },
  top: {
    // backgroundColor: 'rgb(241, 234, 207)',
    backgroundColor: 'black',
    flex: '0 1 auto',
    width: '100%',
    height: '100%',
    minHeight: '0px',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  footer: { backgroundColor: 'white', flex: '1 0 auto', height: '50px' }
}))

function RSegmentGenerator() {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  })

  const handleKeyDown = useCallback(
    (e) => {
      if (!e.repeat) {
        dispatch(keyPressed(e.key))
      }
    },
    [dispatch]
  )

  const handleKeyUp = useCallback(
    (e) => {
      dispatch(keyReleased())
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <RCGLateral />
        <RCGCenter />
      </div>
      <RCGFooter />
      <RCGCalculator />
      <RCGProgress />
    </div>
  )
}
export default RSegmentGenerator
