import { createSelector } from 'reselect'

import { vector, rangeValue, boxCenter } from '../utils/mathFunctions'
import {
  getChildOffset,
  getChildTopLeft2,
  globalPointToLocalPoint,
  localPointToGlobalPoint2,
  getChildCenter,
  getFitChildScale,
  zoomChildAtGlobalPoint,
  centerAtLocalPoint,
  setLocalBoxVisible
} from '../utils/centeredChild'

export const SET_MODE = 'SET_MODE'

export const RESET_ROTATOR = 'RESET'
export const SET_N_STEPS = 'SET_N_STEPS'
export const SET_STEP = 'SET_STEP'
export const ROTATE = 'ROTATE'
export const SET_SCALE = 'SET_SCALE'
export const FIT_IMAGE = 'FIT_IMAGE'
export const ZOOM_AT_POINT = 'ZOOM_AT_POINT'
export const ZOOM_AT_CENTER = 'ZOOM_AT_CENTER'
export const SET_TRANSLATION = 'SET_TRANSLATION'
export const SET_ROTATOR_BOUNDS = 'SET_ROTATOR_BOUNDS'
export const SET_IMAGE_SIZE = 'SET_IMAGE_SIZE'
export const SHOW_BOX = 'SHOW_BOX'
export const CENTER_AT_POINT = 'CENTER_AT_POINT'

export const SCALE_RANGE = [0.5, 100]
export const ZOOM_FACTOR = 0.1
export const ROTATION_SPEED = 150

export const MODE = {
  DISABLE: 'DISABLE',
  BUTTONS: 'BUTTONS',
  ROT_AUTOMATIC: 'ROT_AUTOMATIC',
  ROT_MOUSE: 'ROT_MOUSE',
  PAN: 'PAN',
  PANNING: 'PANNING'
}

const initialState = {
  stepCount: 0,
  step: 0,
  scale: 1,
  translation: vector(), //Desplazamiento normalizado tamaño de la imagen y escala
  mode: MODE.BUTTONS,
  bounds: null,
  imageSize: { width: 0, height: 0 }
}

export const denormalizeTranslation = (translation, imageSize, scale) => {
  return {
    x: translation.x * imageSize.width * scale,
    y: translation.y * imageSize.height * scale
  }
}

export const normalizeTranslation = (translation, imageSize, scale) => {
  return {
    x: translation.x / (imageSize.width * scale),
    y: translation.y / (imageSize.height * scale)
  }
}

const getNextStep = (stepCount, currentStep, stepInc) => {
  if (stepCount <= 1) {
    return 0
  }
  currentStep += stepInc
  currentStep %= stepCount
  if (currentStep < 0) {
    return stepCount + currentStep
  }
  return currentStep
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        mode: action.mode
      }
    case RESET_ROTATOR:
      return {
        ...state,
        step: 0,
        translation: vector(),
        scale: getFitChildScale(state.imageSize, state.bounds)
      }
    case SET_N_STEPS:
      return {
        ...state,
        stepCount: action.nSteps,
        step: getNextStep(action.nSteps, state.step, 0)
      }
    case SET_STEP:
      return {
        ...state,
        step: getNextStep(state.stepCount, action.step, 0)
      }
    case ROTATE:
      return {
        ...state,
        step: getNextStep(state.stepCount, state.step, action.steps)
      }
    case SET_SCALE:
      return {
        ...state,
        scale: rangeValue(action.scale, SCALE_RANGE[0], SCALE_RANGE[1])
      }
    case FIT_IMAGE:
      return {
        ...state,
        scale: getFitChildScale(state.imageSize, state.bounds)
      }
    case ZOOM_AT_POINT: {
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.imageSize,
        state.scale
      )
      const { scale, translation } = zoomChildAtGlobalPoint(
        state.scale,
        SCALE_RANGE,
        currentTranslation,
        action.factor,
        action.point,
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(translation, state.imageSize, scale),
        scale
      }
    }
    case ZOOM_AT_CENTER: {
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.imageSize,
        state.scale
      )
      const { scale, translation } = zoomChildAtGlobalPoint(
        state.scale,
        SCALE_RANGE,
        currentTranslation,
        action.factor,
        boxCenter(
          state.bounds.top,
          state.bounds.left,
          state.bounds.width,
          state.bounds.height
        ),
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(translation, state.imageSize, scale),
        scale
      }
    }
    case SET_TRANSLATION:
      return {
        ...state,
        translation: action.translation
      }
    case SET_ROTATOR_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }
    case SET_IMAGE_SIZE:
      return { ...state, imageSize: action.size }
    case SHOW_BOX:
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.imageSize,
        state.scale
      )
      const { scale, translation } = setLocalBoxVisible(
        action.box,
        currentTranslation,
        state.scale,
        SCALE_RANGE,
        state.imageSize,
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(translation, state.imageSize, scale),
        scale
      }
    case CENTER_AT_POINT: {
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.imageSize,
        state.scale
      )
      const translation = centerAtLocalPoint(
        action.point,
        currentTranslation,
        state.scale,
        state.imageSize,
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(
          translation,
          state.imageSize,
          state.scale
        )
      }
    }
    default:
      return state
  }
}
export default changeState

export const setNSteps = (nSteps) => ({
  type: SET_N_STEPS,
  nSteps
})

export const setStep = (step) => ({
  type: SET_STEP,
  step
})

export const setMode = (mode) => ({
  type: SET_MODE,
  mode
})

export const setScale = (scale) => ({
  type: SET_SCALE,
  scale
})

export const fitImage = () => ({
  type: FIT_IMAGE
})

export const setTranslation = (value) => ({
  type: SET_TRANSLATION,
  translation: value
})

export const rotate = (steps) => ({
  type: ROTATE,
  steps
})

export const zoomAtGlobalPoint = (factor, globalPoint) => ({
  type: ZOOM_AT_POINT,
  factor,
  point: globalPoint
})

export const zoomAtCenter = (factor) => ({
  type: ZOOM_AT_CENTER,
  factor
})

export const resetView = () => ({
  type: RESET_ROTATOR
})

export const setRotatorBounds = (bounds) => ({
  type: SET_ROTATOR_BOUNDS,
  bounds
})

export const setImageSize = (size) => ({
  type: SET_IMAGE_SIZE,
  size
})

export const setBoxVisible = (box) => ({
  type: SHOW_BOX,
  box
})

export const centerAtPoint = (point) => ({
  type: CENTER_AT_POINT,
  point
})

export const stepSelector = (state) => state.rotator.step
export const scaleSelector = (state) => state.rotator.scale
export const translationSelector = (state) => state.rotator.translation
export const stepCountSelector = (state) => state.rotator.stepCount
export const modeSelector = (state) => state.rotator.mode
export const boundsSelector = (state) => state.rotator.bounds
export const imageSizeSelector = (state) => state.rotator.imageSize

export const getStepLength = createSelector(
  [stepCountSelector],
  (stepCount) => {
    if (stepCount <= 1) {
      return 360
    }
    return 360 / stepCount
  }
)

//Desplazamiento con la escala y tamaño de la imagen aplicado
export const getTranslation = createSelector(
  [translationSelector, imageSizeSelector, scaleSelector],
  (translation, imageSize, scale) => {
    return denormalizeTranslation(translation, imageSize, scale)
  }
)

export const getTranslation2 = createSelector(
  [translationSelector, imageSizeSelector],
  (translation, imageSize) => (scale) => {
    return denormalizeTranslation(translation, imageSize, scale)
  }
)

//Centro del container
export const getGlobalCenter = createSelector([boundsSelector], (bounds) => {
  if (!bounds) {
    return { x: 0, y: 0 }
  }
  return boxCenter(bounds.top, bounds.left, bounds.width, bounds.height)
})

//Centro de la imagen
export const getGlobalImageCenter = createSelector(
  [boundsSelector, getTranslation],
  (bounds, translation) => {
    return getChildCenter(bounds, translation)
  }
)

//Desplazamiento de la imagen para que este centrada en el canvas.
export const getImageOffset = createSelector(
  [boundsSelector, scaleSelector, getTranslation, imageSizeSelector],
  (bounds, scale, translation, imageSize) => {
    return getChildOffset(scale, translation, imageSize, bounds)
  }
)

//Punto topLeft de la imagen
export const getGlobalImageTopLeft = createSelector(
  [getImageOffset, boundsSelector],
  (offset, bounds) => {
    return getChildTopLeft2(offset, bounds)
  }
)

//Punto de la imagen en coordenadas globales
export const imagePointToGlobalPoint = createSelector(
  [scaleSelector, boundsSelector, getImageOffset],
  (scale, bounds, offset) => (x, y) => {
    return localPointToGlobalPoint2({ x, y }, offset, bounds)
  }
)

//Punto global a coordenadas imagen
export const globalPointToImagePoint = createSelector(
  [scaleSelector, getGlobalImageTopLeft],
  (scale, topLeft) => (x, y) => {
    return globalPointToLocalPoint({ x, y }, scale, topLeft)
  }
)
