import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { currentGroupSelector } from '../../../../modules/reducerGroups'
import RSGMethods from './RSGMethods'
import RSGParameters from './RSGParameters'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid black',
    padding: '5px 10px',
    margin: '5px 0px'
  }
}))

const RSGConfiguration = () => {
  const currentGroup = useSelector(currentGroupSelector)
  const classes = useStyles()

  return currentGroup !== '' ? (
    <div className={classes.root}>
      <RSGMethods />
      <RSGParameters />
    </div>
  ) : null
}
export default RSGConfiguration
//
