import { getSegmentBox } from './reducerSGEditorSelectors'
import { SHOW_BOX, ZOOM_AT_CENTER, RESET_ROTATOR } from '../reducerRotator'

export const SET_CURRENT_SEGMENT = 'SET_CURRENT_SEGMENT'
export const SET_SEGMENTS_DATA = 'SET_SEGMENTS_DATA'
export const SET_SEGMENT_DATA = 'SET_SEGMENT_DATA'

export const SET_CURRENT_POLYGON = 'SET_CURRENT_POLYGON'
export const ADD_POLYGON = 'ADD_POLYGON'
export const REMOVE_POLYGON = 'REMOVE_POLYGON'

export const SELECT_POINT = 'SELECT_POINT'
export const SET_POINT = 'SET_POINT'
export const INSERT_POINT = 'ADD_POINT'
export const REMOVE_POINTS = 'REMOVE_POINTS'
export const MOVE_POINT = 'MOVE_POINT'

export const SET_POI = 'SET_POI'
export const INSERT_POI = 'INSERT_POI'
export const REMOVE_POIS = 'REMOVE_POI'

export const CLICKED = 'CLICKED'
export const KEY_PRESS = 'KEY_PRESS'
export const KEY_UP = 'KEY_UP'

export const setSegments = (colors, segmentsData) => ({
  type: SET_SEGMENTS_DATA,
  colors,
  segmentsData
})

export const setSegmentData = (color, segmentData) => ({
  type: SET_SEGMENT_DATA,
  color,
  segmentData
})

export const setCurrentSegment = (color) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: SET_CURRENT_SEGMENT,
    color
  })
  const box = getSegmentBox(state)(color)
  if (box[2] - box[0] > 0 && box[3] - box[1] > 0) {
    dispatch({
      type: SHOW_BOX,
      box
    })
    dispatch({
      type: ZOOM_AT_CENTER,
      factor: 0.9
    })
  } else {
    dispatch({ type: RESET_ROTATOR })
  }
}

export const setCurrentPolygon = (id) => ({
  type: SET_CURRENT_POLYGON,
  id
})

export const addPolygon = () => ({
  type: ADD_POLYGON
})

export const removePolygon = (id) => ({
  type: REMOVE_POLYGON,
  id
})

export const selectPoint = (id, select) => ({
  type: SELECT_POINT,
  id,
  select
})

export const insertPoint = (polygon, position, x, y) => ({
  type: INSERT_POINT,
  polygon,
  position,
  x,
  y
})

export const removePoints = (points) => ({
  type: REMOVE_POINTS,
  points
})
export const movePoint = (polygonId, from, to) => ({
  type: MOVE_POINT,
  polygonId,
  from,
  to
})
export const setPoint = (id, x, y) => ({
  type: SET_POINT,
  id,
  x,
  y
})

export const clickAtPoint = (x, y) => ({
  type: CLICKED,
  x,
  y
})

export const setPoi = (id, name, x, y) => ({
  type: SET_POI,
  id,
  name,
  x,
  y
})

export const insertPoi = (position, x, y) => ({
  type: INSERT_POI,
  position,
  x,
  y
})

export const removePois = (pois) => ({
  type: REMOVE_POIS,
  pois
})

export const keyPressed = (key) => ({
  type: KEY_PRESS,
  key
})

export const keyReleased = () => ({
  type: KEY_PRESS,
  key: ''
})
