import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  scaleSelector,
  getTranslation,
  globalPointToImagePoint,
  MODE,
  setMode
} from '../../modules/reducerRotator'

import RCGDrawCanvas from './RCGDrawCanvas'
import RCGMaskImage from './RCGMaskImage'
import RCGImage from './RCGImage'
import RCGSegmentedImageCanvas from './RCGSegmentedImageCanvas'
import RRotator from '../Rotator/RRotator'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    flex: 2,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  coord: {
    zIndex: 4,
    fontSize: '32px',
    position: 'fixed',
    top: 20,
    right: 80,
    backgroundColor: 'white'
  },
  imageTransforms: {
    position: 'relative',
    transform: ({ scale, translation }) =>
      `translate(${translation.x}px, ${translation.y}px) scale(${scale})`
  }
}))

function RCGGraphic() {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
  const scale = useSelector(scaleSelector)
  const translation = useSelector(getTranslation)
  const transformGlobalPointToImagePoint = useSelector(globalPointToImagePoint)
  // const readMouse = useSelector(readMouseSelector)
  const dispatch = useDispatch()
  const classes = useStyles({
    scale,
    translation
  })

  useEffect(() => {
    dispatch(setMode(MODE.PAN))
  }, [dispatch])

  const handleMouseMove = useCallback(
    (e) => {
      setMousePos(transformGlobalPointToImagePoint(e.pageX, e.pageY))
    },
    [transformGlobalPointToImagePoint]
  )

  return (
    <div className={classes.root} onMouseMove={handleMouseMove}>
      <div className={classes.coord}>
        X:{Math.round(mousePos.x)} Y:{Math.round(mousePos.y)}
      </div>
      <RRotator>
        <div className={classes.imageTransforms}>
          <RCGMaskImage />
          <RCGImage />
          <RCGSegmentedImageCanvas />
        </div>
        <RCGDrawCanvas />
      </RRotator>
    </div>
  )
}
export default RCGGraphic
