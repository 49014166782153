import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  getSegmentedImage,
  maskImageDataSelector,
  getTotalImage
} from '../../modules/SegmentGenerator/reducerSGMaskImage'
import {
  GRAPHIC_ELEMENT,
  isGraphicElementVisible
} from '../../modules/SegmentGenerator/reducerSGVisualization'
import { scaleSelector } from '../../modules/reducerRotator'
const useStyles = makeStyles((theme) => ({
  canvas: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3
  }
}))

function RCGSegmentedImageCanvas() {
  const canvasRef = useRef(null)
  const isVisible = useSelector(isGraphicElementVisible)
  const regionsCanvasVisible = isVisible(GRAPHIC_ELEMENT.REGIONS)
  const scale = useSelector(scaleSelector)
  const segmentedImage = useSelector(getSegmentedImage)
  const maskImageData = useSelector(maskImageDataSelector)
  const totalImage = useSelector(getTotalImage)
  const classes = useStyles({ scale })

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas || !regionsCanvasVisible) {
      return
    }
    const ctx = canvas.getContext('2d')
    canvas.width = maskImageData.width
    canvas.height = maskImageData.height
    ctx.save()
    ctx.scale(scale, scale)
    ctx.putImageData(totalImage, 0, 0)
    ctx.restore()
  }, [regionsCanvasVisible, scale, totalImage, maskImageData])

  return regionsCanvasVisible ? (
    <canvas className={classes.canvas} ref={canvasRef} />
  ) : null
}
export default RCGSegmentedImageCanvas
