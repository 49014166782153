// import { createSelector } from 'reselect'
const initialState = {
  houses: [
    { id: 'h0', name: 'Casa 0' },
    { id: 'h1', name: 'Casa 1' },
    { id: 'h2', name: 'Casa 2' },
    { id: 'h3', name: 'Casa 3' },
    { id: 'h4', name: 'Casa 4' },
    { id: 'h5', name: 'Casa 5' },
    { id: 'h6', name: 'Casa 6' },
    { id: 'h7', name: 'Casa 7' },
    { id: 'h8', name: 'Casa 8' },
    { id: 'h9', name: 'Casa 9' },
    { id: 'h10', name: 'Casa 10' },
    { id: 'h11', name: 'Casa 11' },
    { id: 'h12', name: 'Casa 12' }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    default:
      return state
  }
}
export default changeState

export const housesSelector = (state) => state.houses.houses
