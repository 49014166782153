import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import RBarButton from './RBarButton'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import {
  setBarVisible,
  barVisibleSelector
} from '../../../modules/reducerMaster'

import { modeSelector, MODE } from '../../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RHideBarButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const barVisible = useSelector(barVisibleSelector)
  const dispatch = useDispatch()
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setBarVisible(false))
    },
    [dispatch]
  )

  return (
    <RBarButton
      {...props}
      color="primary"
      tooltip={'Hide bar'}
      tooltipEnabled={barVisible}
      onClick={handleClick}
      disabled={mode === MODE.PANNING || mode === MODE.ROT_MOUSE}
    >
      <ArrowDownwardIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RHideBarButton
