import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'

import {
  vector,
  addVectors,
  vectorFromPoints,
  radToDegree,
  degreeToRad,
  angleBetweenVectors,
  rotateVector
} from '../../utils/mathFunctions'

import {
  modeSelector,
  setMode,
  zoomAtGlobalPoint,
  MODE,
  rotate,
  translationSelector,
  setTranslation,
  scaleSelector,
  imageSizeSelector,
  getStepLength,
  ZOOM_FACTOR,
  setRotatorBounds,
  fitImage,
  getGlobalImageCenter
} from '../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  rotator: {
    backgroundColor: '#c0c0c0', //'#c0c0c0',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: ({ mode }) => {
      switch (mode) {
        case MODE.PAN:
          return 'grab'
        case MODE.PANNING:
          return 'grabbing'
        case MODE.ROT_MOUSE:
        default:
          return ''
      }
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  }
}))

const RRotator = ({ children }) => {
  const [rootRef, rootBounds] = useMeasure()
  const [leftDown, setLeftDown] = useState(false)
  const [clickPoint, setClickPoint] = useState(vector())
  const [initialTranslation, setInitialTranslation] = useState(vector())
  const [firstImage, setFirstImage] = useState(true)
  const dispatch = useDispatch()
  const mode = useSelector(modeSelector)
  const stepLengthDegree = useSelector(getStepLength)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(translationSelector)
  const imageCenter = useSelector(getGlobalImageCenter)
  const imageSize = useSelector(imageSizeSelector)
  const classes = useStyles({ mode, translation, scale })

  //Cambio tamaño contenedor
  useEffect(() => {
    dispatch(setRotatorBounds(rootBounds))
    if (firstImage) {
      if (
        rootBounds.width > 0 &&
        rootBounds.height > 0 &&
        imageSize.width > 0 &&
        imageSize.height > 0
      ) {
        //Primera imagen en cargarse. Ajusta escala para que se vea toda
        setFirstImage(false)
        dispatch(fitImage())
      }
    }
  }, [dispatch, rootBounds, firstImage, imageSize])

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const handleDrag = function (e) {
    e.preventDefault()
  }

  const handleWheel = (e) => {
    if (e.deltaY) {
      dispatch(
        zoomAtGlobalPoint(
          e.deltaY > 0 ? 1 + ZOOM_FACTOR : 1 - ZOOM_FACTOR,
          vector(e.pageX, e.pageY),
          { width: 1500, height: 1125 },
          rootBounds
        )
      )
    }
  }

  const handleLeftDown = (x, y) => {
    // console.log('left down', x, y)
    setClickPoint(vector(x, y))
    if (mode === MODE.BUTTONS) {
      setModeD(MODE.ROT_MOUSE)
    } else if (mode === MODE.PAN) {
      setInitialTranslation(translation)
      setModeD(MODE.PANNING)
    }
  }

  const linearRotation = useCallback(
    (x, y) => {
      const DESP = 100
      let inc = vectorFromPoints(clickPoint, vector(x, y))
      const nSteps = Math.floor(inc.x / DESP)
      setClickPoint(addVectors(clickPoint, { x: nSteps * DESP, y: inc.y }))
      dispatch(rotate(nSteps))
    },
    [dispatch, clickPoint]
  )

  const circularRotation = useCallback(
    (x, y) => {
      //Calcula angulo entre el punto que se pincho y la posicion actual. El
      //angulo se calcula con respecto al centro de la imagen
      const vStart = vectorFromPoints(imageCenter, clickPoint)
      const vEnd = vectorFromPoints(imageCenter, vector(x, y))

      const angle = radToDegree(angleBetweenVectors(vStart, vEnd))
      const absAngle = Math.abs(angle)
      if (absAngle > stepLengthDegree) {
        const nSteps =
          Math.sign(angle) * Math.floor(absAngle / stepLengthDegree)
        const rotatedAngle = nSteps * stepLengthDegree
        //Mueve punto de inicio el angulo que se va a girar
        const point = rotateVector(vStart, degreeToRad(rotatedAngle))
        setClickPoint(addVectors(imageCenter, point))
        dispatch(rotate(nSteps))
      }
    },
    [imageCenter, dispatch, stepLengthDegree, clickPoint]
  )

  const dragging = useCallback(
    (x, y) => {
      // console.log('moving')
      if (mode === MODE.PANNING) {
        let inc = vectorFromPoints(clickPoint, vector(x, y))
        inc.x /= imageSize.width
        inc.y /= imageSize.height
        inc.x /= scale
        inc.y /= scale
        dispatch(setTranslation(addVectors(initialTranslation, inc)))
      } else if (mode === MODE.ROT_MOUSE) {
        //Giro con el raton.
        // circularRotation(x, y)
        linearRotation(x, y)
      }
    },
    [
      mode,
      clickPoint,
      initialTranslation,
      dispatch,
      imageSize,
      scale,
      // circularRotation,
      linearRotation
    ]
  )
  const handleLeftUp = (e) => {
    // console.log('left up')
    if (mode === MODE.ROT_MOUSE) {
      setModeD(MODE.BUTTONS)
    } else if (mode === MODE.PANNING) {
      setModeD(MODE.PAN)
    }
  }

  const handleMouseDown = function (e) {
    // console.log('mouse down')
    if (e.button === 0) {
      setLeftDown(true)
      handleLeftDown(e.pageX, e.pageY)
    } else {
      setLeftDown(false)
    }
  }
  const handleMouseMove = (e) => {
    // console.log('mouse move')
    if (leftDown) {
      dragging(e.pageX, e.pageY)
    }
  }
  const handleMouseUp = function (e) {
    // console.log('mouse up')
    setLeftDown(false)
    handleLeftUp(e)
  }
  const handleTouchStart = (e) => {
    // console.log('touch start')
    setLeftDown(true)
    handleLeftDown(e.touches[0].pageX, e.touches[0].pageY)
  }
  const handleTouchMove = useCallback(
    (e) => {
      if (leftDown) {
        // console.log('touch move')
        const touch = e.touches[0] || e.changedTouches[0]
        if (touch) {
          dragging(touch.pageX, touch.pageY)
        }
      }
    },
    [leftDown, dragging]
  )
  const handleTouchEnd = (e) => {
    // console.log('touch end')
    if (leftDown) {
      handleLeftUp(e)
      setLeftDown(false)
    }
  }

  const handleLeave = function (e) {
    if (mode === MODE.ROT_MOUSE) {
      setModeD(MODE.BUTTONS)
    } else if (mode === MODE.PANNING) setModeD(MODE.PAN)
  }
  return (
    <div
      ref={rootRef}
      className={classes.rotator}
      onDragStart={handleDrag}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleLeave}
    >
      {children}
    </div>
  )
}
export default RRotator
