// import { createSelector } from 'reselect'
import { SET_GROUP } from './reducerGroups'

export const MOUSE_OVER_SEGMENT = 'MOUSE_OVER_SEGMENT'
export const SELECT_SEGMENT = 'SELECT_SEGMENT'
export const DESELECT_SEGMENT = 'DESELECT_SEGMENT'

const initialState = {
  hoverSegment: '',
  selectedSegments: []
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_GROUP: {
      return {
        ...state,
        hoverSegment: ''
      }
    }
    case MOUSE_OVER_SEGMENT:
      return {
        ...state,
        hoverSegment: action.segment
      }
    case SELECT_SEGMENT: {
      if (state.selectedSegments.indexOf(action.segment) >= 0) {
        return state
      }
      return {
        ...state,
        selectedSegments: [...state.selectedSegments, action.segment]
      }
    }
    case DESELECT_SEGMENT:
      if (state.selectedSegments.indexOf(action.segment) < 0) {
        return state
      }
      return {
        ...state,
        selectedSegments: state.selectedSegments.filter(
          (id) => id !== action.segment
        )
      }
    default:
      return state
  }
}
export default changeState

export const mouseOverSegment = (segment) => ({
  type: MOUSE_OVER_SEGMENT,
  segment
})

export const setSegmentSelected = (segment, select) => {
  if (select) {
    return {
      type: SELECT_SEGMENT,
      segment
    }
  } else {
    return {
      type: DESELECT_SEGMENT,
      segment
    }
  }
}

export const toggleSegmentSelection = (segment) => (dispatch, getState) => {
  const state = getState()
  const selected = selectedSegmentsSelector(state).indexOf(segment) >= 0
  if (selected) {
    dispatch({
      type: DESELECT_SEGMENT,
      segment
    })
  } else {
    dispatch({
      type: SELECT_SEGMENT,
      segment
    })
  }
}
export const selectSegment = (segment) => ({
  type: SELECT_SEGMENT,
  segment
})

export const deselectSegment = (segment) => ({
  type: DESELECT_SEGMENT,
  segment
})

export const hoverSegmentSelector = (state) => state.share.hoverSegment
export const selectedSegmentsSelector = (state) => state.share.selectedSegments
