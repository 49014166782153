import {
  SET_SEGMENTS_DATA,
  SET_SEGMENT_DATA,
  SET_CURRENT_SEGMENT,
  SET_CURRENT_POLYGON,
  ADD_POLYGON,
  REMOVE_POLYGON,
  SELECT_POINT,
  INSERT_POINT,
  REMOVE_POINTS,
  MOVE_POINT,
  SET_POINT,
  SET_POI,
  INSERT_POI,
  REMOVE_POIS,
  CLICKED,
  KEY_PRESS
} from './reducerSGEditorActions'

import { SHOW_EDITOR } from './reducerSGVisualization'

import {
  selectItem,
  deselectItem,
  toggleItemSelection
} from '../../utils/selection'

const CLICK_DIAMETER = 5

const initialState = {
  keyPressed: '',
  currentSegment: '',
  currentPolygon: '',
  selectedPoints: [],
  pointSeed: 0,
  polygonSeed: 0,

  segments: [
    // {
    //   id: '',
    //   points: [{ id: 'p0', x: 0, y: 0 }],
    //   pois: [{ pointId: '', name: 'centroid' }],
    //   polygons: [{ id: 'poly0', points: ['p0'] }]
    // }
  ],
  predefinedPoisNames: [
    { id: 'pn0', name: 'centroid' },
    { id: 'pn1', name: 'poiName' },
    { id: 'pn2', name: 'poiName1' },
    { id: 'pn3', name: 'poiName2' },
    { id: 'pn4', name: 'poiName3' }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SHOW_EDITOR:
      return clearEditor(state)
    case SET_SEGMENTS_DATA: {
      return readSegmentsData(state, action.colors, action.segmentsData)
    }
    case SET_SEGMENT_DATA: {
      return readSegmentData(state, action.color, action.segmentData)
    }
    case SET_CURRENT_SEGMENT:
      const segment = getSegment(state, action.color)
      return {
        ...state,
        currentSegment: action.color,
        currentPolygon:
          segment && segment.polygons.length > 0 ? segment.polygons[0].id : '',
        selectedPoints: []
      }
    case SET_CURRENT_POLYGON:
      return { ...state, currentPolygon: action.id }
    case REMOVE_POLYGON:
      return removePolygon(state, state.currentSegment, action.id)
    case ADD_POLYGON:
      return addSegmentPolygon(state, state.currentSegment)
    case SELECT_POINT: {
      return selectPoint(state, action.select, action.id)
    }
    case INSERT_POINT: {
      return insertSegmentPolygonPoint(
        state,
        state.currentSegment,
        action.polygon,
        action.x,
        action.y,
        action.position
      )
    }
    case REMOVE_POINTS: {
      return removePoints(state, state.currentSegment, action.points)
    }
    case MOVE_POINT: {
      return moveSegmentPolygonPoint(
        state,
        state.currentSegment,
        action.polygonId,
        action.from,
        action.to
      )
    }
    case SET_POINT:
      return setSegmentPoint(
        state,
        state.currentSegment,
        action.id,
        action.x,
        action.y
      )

    case SET_POI:
      return setSegmentPoi(
        state,
        state.currentSegment,
        action.id,
        action.name,
        action.x,
        action.y
      )

    case INSERT_POI:
      return addSegmentPoi(state, state.currentSegment, action.x, action.y)

    case REMOVE_POIS:
      return removePois(state, state.currentSegment, action.pois)

    case KEY_PRESS:
      let newState = state
      if (action.key === ' ') {
        //Borrar seleccion
        newState = removePois(state, state.currentSegment, state.selectedPoints)
        newState = removePoints(
          newState,
          state.currentSegment,
          state.selectedPoints
        )
      }
      return { ...newState, keyPressed: action.key }

    case CLICKED:
      if (state.keyPressed === 'Control') {
        //Seleccion multiple
        const clickPoint = getClickedPoint(state, action.x, action.y)
        if (clickPoint) {
          return {
            ...state,
            selectedPoints: toggleItemSelection(
              state.selectedPoints,
              clickPoint.point.id
            )
          }
        }
        return state
      } else if (state.keyPressed === 'Shift') {
        //Mover punto
        if (state.selectedPoints.length === 1) {
          return setSegmentPoint(
            state,
            state.currentSegment,
            state.selectedPoints[0],
            action.x,
            action.y
          )
        }
      } else if (state.keyPressed === 'a') {
        //Añadir puntos
        return addPointAtClick(state, action.x, action.y)
      } else {
        //Seleccion simple
        const clickPoint = getClickedPoint(state, action.x, action.y)
        return {
          ...state,
          selectedPoints: clickPoint ? [clickPoint.point.id] : []
        }
      }
      return state
    default:
      return state
  }
}
export default changeState

const clearEditor = (state) => {
  return {
    ...state,
    keyPressed: '',
    currentSegment: '',
    currentPolygon: '',
    selectedPoints: [],
    pointSeed: 0,
    polygonSeed: 0,
    segments: []
  }
}

const Point = (x, y, seed) => {
  return { id: `p${seed}`, x, y }
}

const Poly = (seed) => {
  return {
    id: `poly${seed}`,
    points: []
  }
}

const readSegmentData = (state, color, segmentData) => {
  let polySeed = state.polygonSeed
  let pointSeed = state.pointSeed
  const segment = {
    id: color,
    points: [],
    pois: [],
    polygons: []
  }
  //Quita segmento actual
  const newSegments = state.segments.filter((segment) => segment.id !== color)
  newSegments.push(segment)
  if (segmentData) {
    //Crea puntos y polygonos
    for (let polygon of segmentData.polygons) {
      const newPolygon = Poly(polySeed++)
      for (let point of polygon) {
        const newPoint = Point(point[0], point[1], pointSeed++)
        segment.points.push(newPoint)
        newPolygon.points.push(newPoint.id)
      }
      segment.polygons.push(newPolygon)
    }
    //Pois
    for (let poi of segmentData.pois) {
      const newPoint = Point(poi.point[0], poi.point[1], pointSeed++)
      segment.points.push(newPoint)
      segment.pois.push({
        name: poi.id,
        pointId: newPoint.id
      })
    }
  }
  return {
    ...state,
    polygonSeed: polySeed,
    pointSeed: pointSeed,
    segments: newSegments,
    currentSegment: '',
    currentPolygon: '',
    selectedPoints: []
  }
}

const readSegmentsData = (state, colors, segmentsData) => {
  let newState = clearEditor(state)
  for (let color of colors) {
    const segmentData = segmentsData.find((segment) => segment.color === color)
    newState = readSegmentData(newState, color, segmentData)
  }
  return newState
}
const swap = (input, index_A, index_B) => {
  if (
    index_A >= 0 &&
    index_A < input.length &&
    index_B >= 0 &&
    index_B < input.length
  ) {
    let temp = input[index_A]

    input[index_A] = input[index_B]
    input[index_B] = temp
  }
}

const getSegment = (state, segmentId) => {
  return state.segments.find((segment) => segment.id === segmentId)
}

const getClickedPoint = (state, x, y) => {
  const segment = state.segments.find(
    (segment) => segment.id === state.currentSegment
  )
  if (!segment) {
    return null
  }
  const clickElements = segment.points.reduce((accumulator, point) => {
    const dist = Math.abs(point.x - x) + Math.abs(point.y - y)
    if (dist <= CLICK_DIAMETER) {
      accumulator.push({ point, dist })
    }
    return accumulator
  }, [])
  if (clickElements.length === 0) {
    return null
  } else if (clickElements.length === 1) {
    return clickElements[0]
  } else {
    const min = clickElements.reduce((prev, current) => {
      return prev.dist < current.dist ? prev : current
    })
    return min
  }
}

const getSegmentPolygonPointIndex = (segment, pointId) => {
  if (segment) {
    for (let polygon of segment.polygons) {
      const idx = polygon.points.indexOf(pointId)
      if (idx >= 0) {
        return { polygon, idx }
      }
    }
  }
  return null
}

const setSegmentPoint = (state, segmentId, pointId, x, y) => {
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        points: segment.points.map((point) => {
          if (point.id === pointId) {
            return { ...point, x: parseInt(x), y: parseInt(y) }
          }
          return point
        })
      }
    })
  }
}

const setSegmentPoi = (state, segmentId, poiId, name, x, y) => {
  const segment = getSegment(state, segmentId)
  if (segment) {
    const poi = segment.pois.find((poi) => poi.pointId === poiId)
    if (poi) {
      const newState = {
        ...state,
        segments: state.segments.map((segment) => {
          if (segment.id !== segmentId) {
            return segment
          }
          return {
            ...segment,
            pois: segment.pois.map((poi) => {
              if (poi.pointId !== poiId) {
                return poi
              }
              return {
                ...poi,
                name: (poi.name =
                  poi.name === name
                    ? name
                    : getNotUsedSegmentPoiName(state, segmentId, name))
              }
            })
          }
        })
      }
      return setSegmentPoint(newState, segmentId, poiId, x, y)
    }
  }
  return state
}

const insertSegmentPolygonPoint = (
  state,
  segmentId,
  polygonId,
  x,
  y,
  position
) => {
  let lPointSeed = state.pointSeed
  let newPoint = null
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      newPoint = Point(x, y, lPointSeed++)
      return {
        ...segment,
        points: [...segment.points, newPoint],
        polygons: segment.polygons.map((polygon) => {
          if (polygon.id !== polygonId) {
            return polygon
          }
          const newPoints = [...polygon.points]
          newPoints.splice(position, 0, newPoint.id)
          return { ...polygon, points: newPoints }
        })
      }
    }),
    pointSeed: lPointSeed,
    selectedPoints: newPoint ? [newPoint.id] : []
  }
}

const getNotUsedSegmentPoiName = (state, segmentId, name) => {
  const segment = getSegment(state, segmentId)
  if (segment) {
    let idx = 0
    let newName = name
    const nameUsed = (pois, name) => {
      return pois.findIndex((poi) => poi.name === name) >= 0
    }
    while (nameUsed(segment.pois, newName)) {
      idx++
      newName = name + '_' + idx
    }
    return newName
  }
  return ''
}

const addSegmentPoi = (state, segmentId, x, y) => {
  let lPointSeed = state.pointSeed
  let newPoint = null
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      newPoint = Point(x, y, lPointSeed++)
      return {
        ...segment,
        points: [...segment.points, newPoint],
        pois: [
          ...segment.pois,
          {
            pointId: newPoint.id,
            name: getNotUsedSegmentPoiName(state, state.currentSegment, 'poi')
          }
        ]
      }
    }),
    pointSeed: lPointSeed
  }
}

const isPoi = (segment, pointId) => {
  return segment ? segment.pois.find((poi) => poi.pointId === pointId) : false
}

const addPointAtClick = (state, x, y) => {
  if (state.selectedPoints.length === 1) {
    const segment = getSegment(state, state.currentSegment)
    if (segment && !isPoi(segment, state.selectedPoints[0])) {
      //Hay solo un punto seleccionado
      const selectedPoint = getSegmentPolygonPointIndex(
        segment,
        state.selectedPoints[0]
      )
      if (selectedPoint) {
        //Añade punto
        return insertSegmentPolygonPoint(
          state,
          state.currentSegment,
          selectedPoint.polygon.id,
          Math.round(x),
          Math.round(y),
          selectedPoint.idx + 1
        )
      }
    }
  }
  return state
}

const addSegmentPolygon = (state, segmentId) => {
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        polygons: [...segment.polygons, Poly(state.polygonSeed)]
      }
    }),
    polygonSeed: state.polygonSeed + 1
  }
}

const selectPoint = (state, select, id) => {
  let newSelection
  if (select) {
    newSelection = selectItem(state.selectedPoints, id)
  } else {
    newSelection = deselectItem(state.selectedPoints, id)
  }
  return { ...state, selectedPoints: newSelection }
}

const moveSegmentPolygonPoint = (state, segmentId, polygonId, pFrom, pTo) => {
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        polygons: segment.polygons.map((poly) => {
          if (poly.id !== polygonId) {
            return poly
          }
          const newPoints = [...poly.points]
          swap(newPoints, pFrom, pTo)
          return { ...poly, points: newPoints }
        })
      }
    })
  }
}

//Filtra pointsId y deja solo lo que sean pois
const filterPois = (state, segmentId, pointsId) => {
  const segment = getSegment(state, segmentId)
  if (!segment) {
    return []
  }
  const poisId = pointsId.filter(
    (pointId) => segment.pois.findIndex((poi) => poi.pointId === pointId) >= 0
  )
  return poisId
}

//Filtra pointsId y deja solo lo que sean points
const filterPoints = (state, segmentId, pointsId) => {
  const segment = getSegment(state, segmentId)
  if (!segment) {
    return []
  }
  const poisId = pointsId.filter(
    (pointId) => segment.pois.findIndex((poi) => poi.pointId === pointId) < 0
  )
  return poisId
}

const removePois = (state, segmentId, pointsId) => {
  const poisId = filterPois(state, segmentId, pointsId)
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        pois: segment.pois.filter((poi) => poisId.indexOf(poi.pointId) < 0),
        points: segment.points.filter((point) => poisId.indexOf(point.id) < 0)
      }
    })
  }
}

const removePoints = (state, segmentId, pointsId) => {
  const ids = filterPoints(state, segmentId, pointsId)
  return {
    ...state,
    segments: state.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        points: segment.points.filter((point) => ids.indexOf(point.id) < 0),
        polygons: segment.polygons.map((poly) => {
          return {
            ...poly,
            points: poly.points.filter((pointId) => ids.indexOf(pointId) < 0)
          }
        })
      }
    })
  }
}

const removePolygon = (state, segmentId, polyId) => {
  const segment = getSegment(state, segmentId)
  if (!segment) {
    return state
  }
  const poly = segment.polygons.find((poly) => poly.id === polyId)
  if (!poly) {
    return state
  }
  const polys = segment.polygons.filter((poly) => poly.id !== polyId)
  const currentPolygon = polys.length > 0 ? polys[0].id : ''
  const newState = removePoints(state, segmentId, poly.points)
  return {
    ...newState,
    segments: newState.segments.map((segment) => {
      if (segment.id !== segmentId) {
        return segment
      }
      return {
        ...segment,
        polygons: polys
      }
    }),
    currentPolygon: currentPolygon
  }
}
