import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import HomeIcon from '@material-ui/icons/Home'
import RCGShowElements from './RCGShowElements'
import {
  scaleSelector,
  setScale,
  resetView,
  ZOOM_FACTOR,
  zoomAtGlobalPoint,
  getGlobalCenter
} from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: '1px solid black',
    backgroundColor: 'white',
    flex: '1 0 auto',
    boxSizing: 'border-box',
    height: '50px',
    width: '100%',
    overflow: 'auto',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',

    padding: '0px 20px'
  }
}))

function RCGFooter() {
  const classes = useStyles()
  const scale = useSelector(scaleSelector)
  const canvasCenter = useSelector(getGlobalCenter)
  const dispatch = useDispatch()

  const handleIn = useCallback(
    (e) => {
      dispatch(setScale(scale + 0.2))
    },
    [dispatch, scale]
  )
  const handleOut = useCallback(
    (e) => {
      dispatch(zoomAtGlobalPoint(1 - ZOOM_FACTOR, canvasCenter))
      // dispatch(setScale(scale - 0.2))
    },
    [dispatch, canvasCenter]
  )
  const handleReset = useCallback(
    (e) => {
      dispatch(zoomAtGlobalPoint(1 + ZOOM_FACTOR, canvasCenter))
      dispatch(resetView())
    },
    [dispatch, canvasCenter]
  )
  return (
    <div className={classes.footer}>
      <div>
        <IconButton onClick={handleIn}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={handleReset}>
          <HomeIcon />
        </IconButton>
        <IconButton onClick={handleOut}>
          <ZoomOutIcon />
        </IconButton>
      </div>
      <RCGShowElements />
    </div>
  )
}
export default RCGFooter
