import { group_vita2_segments } from './group_vita2_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i <= 45; ++i) {
    images.push('./Vita/2/Vita_fondo_' + `${i}`.padStart(5, 0) + '.jpg')
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i <= 45; ++i) {
    images.push('./Vita/2/Mask/vita_mask_' + `${i}`.padStart(5, 0) + '.jpg')
  }
  return images
}
export const groupVita2 = {
  id: 'vita2',
  name: 'Vita 2',
  imageSize: { width: 1920, height: 1080 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: ['#fe0000ff'],
  segments: group_vita2_segments
}
