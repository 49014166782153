import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { getGeneratedStepSegments } from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'
import { stepSelector } from '../../../../modules/reducerRotator'
import { setEditorVisible } from '../../../../modules/SegmentGenerator/reducerSGVisualization'
import { setSegments } from '../../../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  getCurrentGroupColors,
  currentGroupSelector
} from '../../../../modules/reducerGroups'
const useStyles = makeStyles((theme) => ({
  button: { width: '100%', height: '40px' }
}))

const RSGEditorButton = () => {
  const classes = useStyles()
  const currentGroup = useSelector(currentGroupSelector)
  const currentStep = useSelector(stepSelector)
  const colors = useSelector(getCurrentGroupColors)
  const gGeneratedStepSegments = useSelector(getGeneratedStepSegments)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setEditorVisible(true))
    dispatch(setSegments(colors, gGeneratedStepSegments(currentStep)))
  }, [dispatch, currentStep, colors, gGeneratedStepSegments])

  return currentGroup !== '' ? (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      disableFocusRipple
      disableRipple
      onClick={handleClick}
    >
      Editor
    </Button>
  ) : null
}
export default RSGEditorButton
