import { group_g3_segments } from './group_g3_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i <= 5; ++i) {
    images.push(
      './images/GiroCasa/base3/render-' + `${i}`.padStart(4, 0) + '.jpg'
    )
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i <= 5; ++i) {
    images.push(
      './images/GiroCasa/mascara3/mapping-' + `${i}`.padStart(4, 0) + '.png'
    )
  }
  return images
}
export const group3 = {
  id: 'g3',
  name: 'Edificio 2',
  imageSize: { width: 1920, height: 1080 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: [
    '#1489d0ff',
    '#1485e8ff',
    '#148200ff',
    '#147e18ff',
    '#147a30ff',
    '#147648ff',
    '#147260ff',
    '#146e78ff',
    '#146a90ff',
    '#1466a8ff',
    '#1462c0ff',
    '#145ed8ff',
    '#145af0ff',
    '#145708ff',
    '#145320ff',
    '#144f38ff',
    '#144b50ff',
    '#144768ff',
    '#144380ff',
    '#143f98ff',
    '#143bb0ff',
    '#1437c8ff',
    '#1433e0ff',
    '#142ff8ff',
    '#142c10ff',
    '#142828ff',
    '#142440ff',
    '#142058ff',
    '#141c70ff',
    '#141888ff',
    '#1414a0ff',
    '#1410b8ff',
    '#140cd0ff',
    '#1408e8ff',
    '#140500ff',
    '#140118ff',
    '#13fd30ff',
    '#13f948ff',
    '#13f560ff',
    '#13f178ff',
    '#13ed90ff',
    '#13e9a8ff',
    '#13e5c0ff',
    '#13e1d8ff',
    '#13ddf0ff',
    '#13da08ff',
    '#13d620ff',
    '#13d238ff',
    '#13ce50ff',
    '#13ca68ff',
    '#13c680ff'
  ],
  segments: group_g3_segments
}
