import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import RCGPointsOfInterestItem from './RCGPointsOfInterestItem'
import {} from '../../../../modules/reducerGroups'
import {
  selectPoint,
  insertPoi,
  removePois
} from '../../../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  getCurrentSegment,
  getCurrentSegmentSelectedPois
} from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  root: { border: '1px solid black', padding: '5px 10px', margin: '5px 0px' },
  title: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0
  },
  inputsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  poiId: { marginLeft: '24px' }
}))

const RCGPointsOfInterest = () => {
  const currentSegment = useSelector(getCurrentSegment)
  const selectedPois = useSelector(getCurrentSegmentSelectedPois)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleAddPoi = useCallback(() => {
    dispatch(insertPoi(0, 0, 0))
  }, [dispatch])

  const handleRemovePois = useCallback(() => {
    dispatch(removePois(selectedPois))
  }, [dispatch, selectedPois])

  const handleSelect = useCallback(
    (pointId, select) => {
      dispatch(selectPoint(pointId, select))
    },
    [dispatch]
  )
  return currentSegment ? (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>Points of interest</div>
        <div>
          <IconButton className={classes.button} onClick={handleAddPoi}>
            <AddIcon />
          </IconButton>
          <IconButton className={classes.button} onClick={handleRemovePois}>
            <RemoveIcon />
          </IconButton>
        </div>
      </div>
      {currentSegment.pois.map((poi) => (
        <RCGPointsOfInterestItem
          key={poi.pointId}
          poiId={poi.pointId}
          onSelect={handleSelect}
        />
      ))}
    </div>
  ) : null
}
export default RCGPointsOfInterest
