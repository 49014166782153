import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'

import {
  getElements,
  showGraphicElement
} from '../../../modules/SegmentGenerator/reducerSGVisualization'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '5px 10px',
    // margin: '5px 0px',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
    // justifyContent: 'space-between'
  },
  item: {
    padding: '2px',
    margin: '0px 2px',
    textTransform: 'none'
    // maxHeight: '20px'
  }
}))

const RCGShowElements = () => {
  const classes = useStyles()
  const elements = useSelector(getElements)
  const dispatch = useDispatch()

  const handleShow = useCallback(
    (element) => {
      dispatch(showGraphicElement(element.id, !element.show))
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      {elements.map((e) => (
        <Button
          key={e.id}
          className={classes.item}
          variant="contained"
          color={e.show ? 'primary' : 'default'}
          disableFocusRipple
          disableRipple
          onClick={() => handleShow(e)}
        >
          {e.name}
        </Button>
      ))}
    </div>
  )
}
export default RCGShowElements
