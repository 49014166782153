import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RSGParameter from './RSGParameter'
import {
  currentMethodSelector,
  getMethod
} from '../../../../modules/SegmentGenerator/reducerSGParameters'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '10px'
  }
}))

const RSGParameters = () => {
  const currentMethod = useSelector(currentMethodSelector)
  const gMethod = useSelector(getMethod)
  const method = gMethod(currentMethod)
  const classes = useStyles()

  return method ? (
    <div className={classes.root}>
      <div>Parameters</div>
      {method.params.map((paramId) => (
        <RSGParameter key={paramId} paramId={paramId} />
      ))}
    </div>
  ) : null
}
export default RSGParameters
