/*
  Divide binaryDataMatrix en regiones de tamaño minimo minimunArea.
  growOnes indica si se esta buscando valores a uno (growOnes=true) o a cero
*/
export const getRegions = (
  binaryDataMatrix,
  box,
  minimunArea,
  growOnes = true
) => {
  let seguir = true
  const regions = []
  while (seguir) {
    //Obtiene una region
    const region = getRegion(binaryDataMatrix, getNormalSeed, growOnes)
    if (region.length === 0) {
      //Si la region esta vacia, ya no hay mas. Terminar
      seguir = false
    } else {
      if (region.length > minimunArea) {
        //Guarda region si tiene un tamaño minimo
        regions.push(
          region.map((point) => {
            //Hay que pasar a coordenadas globales
            return [point[0] + box[0], point[1] + box[1]]
          })
        )
      }
    }
  }
  return { regions, box }
}

/*
  Busca una region de ceros/unos en binaryDataMatrix.
*/
export const getRegion = (
  binaryDataMatrix,
  getSeed, //Funcion para buscar el pixel inicial
  growOnes = true //Si true busca 1
) => {
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  const onValue = growOnes ? 1 : 0
  const offValue = growOnes ? 0 : 1
  const neighbours = [
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1]
  ]
  const seed = getSeed(binaryDataMatrix, onValue)
  const region = []
  if (seed[0] < 0) {
    return region
  }
  const stack = [seed]
  region.push([seed[0], seed[1]])
  binaryDataMatrix[seed[1]][seed[0]] = offValue
  while (stack.length > 0) {
    //Saca punto de la pila y comprueba vecinos
    const point = stack.shift()

    for (let neighbour of neighbours) {
      const x = point[0] + neighbour[0]
      const y = point[1] + neighbour[1]
      if (x < 0 || y < 0 || x > width - 1 || y > height - 1) {
        //Vecino cae fuera de la imagen
        continue
      }
      if (binaryDataMatrix[y][x] === onValue) {
        //Vecino on. Añadirlo a la region y a la pila. Borrarlo de la imagen
        region.push([x, y])
        stack.push([x, y])
        binaryDataMatrix[y][x] = offValue
      }
    }
  }
  return region
}

//Busca primer pixel a 'value'
const getNormalSeed = (binaryDataMatrix, value) => {
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      if (binaryDataMatrix[y][x] === value) {
        return [x, y]
      }
    }
  }
  return [-1, -1]
}

//Busca primer pixel a value. Solo tiene en cuenta los pixels de los bordes de
//binaryDataMatrix
export const getBorderSeed = (binaryDataMatrix, value) => {
  const width = binaryDataMatrix[0].length
  const height = binaryDataMatrix.length
  //Top
  for (let x = 0; x < width; ++x) {
    if (binaryDataMatrix[0][x] === value) {
      return [x, 0]
    }
  }
  //Right
  for (let y = 0; y < height; ++y) {
    if (binaryDataMatrix[y][width - 1] === value) {
      return [width - 1, y]
    }
  }
  //Bottom
  for (let x = 0; x < width; ++x) {
    if (binaryDataMatrix[height - 1][x] === value) {
      return [x, height - 1]
    }
  }
  //Left
  for (let y = 0; y < height; ++y) {
    if (binaryDataMatrix[y][0] === value) {
      return [0, y]
    }
  }
  return [-1, -1]
}
