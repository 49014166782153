import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import RBarButton from './RBarButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {
  barVisibleSelector,
  setBarVisible
} from '../../../modules/reducerMaster'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)'
  }
}))

const RShowBarButton = (props) => {
  const classes = useStyles()
  const barVisible = useSelector(barVisibleSelector)
  const dispatch = useDispatch()

  const showBarAnimation = useSpring({
    bottom: barVisible ? -200 : 10,
    opacity: barVisible ? 0 : 1
  })

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setBarVisible(true))
    },
    [dispatch]
  )

  return (
    <animated.div className={classes.root} style={showBarAnimation}>
      <RBarButton
        color="primary"
        tooltip={'Show bar'}
        tooltipEnabled={!barVisible}
        onClick={handleClick}
        disabled={false}
      >
        <ArrowUpwardIcon fontSize="inherit" />
      </RBarButton>
    </animated.div>
  )
}
export default RShowBarButton
