import { similarColors, rgbaColor, hexToRgba, rgbaToHex2 } from './color'
import { getRegions } from './regionGrow'
import { getBinaryDataMatrix, dilation, erosion, modeFilter } from './filters'

/*
Divide la imagen por colores
imageData: Objeto ImageData a dividir
colors: Array con los colores a dividir [Hex,Hex,...]. Hasta 254
isBackground: Funcion para buscar rapido si un pixel es del fondo isBackground(r,g,b,a)

Devuelve:
  [
    {
      hexColor: 'colorSegmento',
      regions: [ [[x,y],[x,y],...],[[x,y],[x,y],...]],
      box: [0, 0, 0, 0]
    }
  ]
*/

export const imageSegmentation = (
  imageData,
  hexColors,
  isBackground,
  minimumArea
) => {
  if (!imageData) return null
  const { pixelColorIdx } = classifyPixels(imageData, hexColors, isBackground)
  return getColorRegions(
    pixelColorIdx,
    hexColors,
    imageData.width,
    imageData.height,
    minimumArea
  )
}

/*
  Divide imagen por colores.
    imageData:Objeto ImageData
    hexColors: Array colores a clasificar #rrggbbaa
    isBackground: Funcion para buscar rapido si un pixel es del fondo isBackground(r,g,b,a)
  Devuelve:
    {
    hexColors: Colores de entrada
    pixelColorIdx: Array con valor que indica a que color pertenece cada pixel.
      Valor entre [0,255]. Si 255 no pertenece a ningun color
    }

*/
const classifyPixels = (imageData, hexColors, isBackground) => {
  let pixelColorIdx = []
  const obj = { hexColors, pixelColorIdx: pixelColorIdx }
  if (imageData) {
    const rgbaColors = hexColors.map((color) => {
      return hexToRgba(color)
    })
    // const colors = new Set()
    pixelColorIdx = new Uint8ClampedArray(imageData.width * imageData.height)
    for (let y = 0; y < imageData.height; ++y) {
      let idx = y * imageData.width
      for (let x = 0; x < imageData.width; ++x) {
        const rIdx = idx * 4
        const r = imageData.data[rIdx]
        const g = imageData.data[rIdx + 1]
        const b = imageData.data[rIdx + 2]
        const a = imageData.data[rIdx + 3]

        // colors.add(rgbaToHex2(r, g, b, a))

        const pixelColor = classifyPixel(r, g, b, a, rgbaColors, isBackground)
        if (pixelColor >= 0) {
          pixelColorIdx[idx] = pixelColor
        } else {
          pixelColorIdx[idx] = 255
        }
        idx++
      }
    }
    obj.pixelColorIdx = pixelColorIdx
    // console.log(Array.from(colors).sort())
  }
  return obj
}

/*
  Divide pixels del mismo color en regiones no unidas
    pixelColorIdx: Array indicando a que color pertenece el pixel
    hexColors: Array con los colores
    width, height:Ancho, alto de la Imagen

  Devuelve:
  [
    {
      hexColor
      regions: [ [[x,y],[x,y],...],[[x,y],[x,y],...]]
      box:[0,0,0,0]
    }
  ]
*/
const getColorRegions = (
  pixelColorIdx,
  hexColors,
  width,
  height,
  minimumArea
) => {
  const colorObjects = hexColors.map((color) => {
    return {
      hexColor: color,
      pixels: [],
      box: [0, 0, 0, 0],
      binaryDataMatrix: []
    }
  })
  //Divide pixels por color
  for (let y = 0; y < height; ++y) {
    let idx = y * width
    for (let x = 0; x < width; ++x) {
      if (pixelColorIdx[idx] !== 255) {
        colorObjects[pixelColorIdx[idx]].pixels.push([x, y])
      }
      idx++
    }
  }
  //Construye binaryDataMatrix
  for (let colorObject of colorObjects) {
    const { binaryDataMatrix, box } = getBinaryDataMatrix(colorObject.pixels)
    colorObject.binaryDataMatrix = binaryDataMatrix
    //Filtrar
    //TODO: Filtrar binaryDataMatrix si se quiere
    // colorObject.binaryDataMatrix = erosion(colorObject.binaryDataMatrix)
    colorObject.box = box
  }

  //Dividir
  return colorObjects.map((o) => {
    const { regions, box } = getRegions(o.binaryDataMatrix, o.box, minimumArea)
    return { hexColor: o.hexColor, regions, box }
  })
}

/*
  Devuelve indice de colors que corresponde al color que se le pasa
*/
const classifyPixel = (r, g, b, a, colors, isBackground) => {
  if (isBackground(r, g, b, a)) {
    return -1
  }
  return colors.findIndex((color) =>
    similarColors(rgbaColor(r, g, b, a), color, 5)
  )
}

/*
  Agrupa los pixels de todas la regiones de un segmento
*/
export const getSegmentPixels = (segment) => {
  return segment
    ? segment.regions.reduce((acc, region) => {
        acc = region.reduce((accPixels, pixel) => {
          accPixels.push(pixel)
          return accPixels
        }, acc)
        return acc
      }, [])
    : []
}

/*
  Contruye un ImageData con los pixels que se le pasan en el color indicado.
  La imagen tiene las dimensiones de la caja que envuelve a los pixels
*/
export const getBoxImageData = (pixels, r, g, b, a) => {
  const { binaryDataMatrix, box } = getBinaryDataMatrix(pixels)
  const boxWidth = box[2] - box[0] + 1
  const boxHeight = box[3] - box[1] + 1
  const data = new Uint8ClampedArray(boxWidth * boxHeight * 4)
  for (let y = 0; y < boxHeight; ++y) {
    let idx = y * boxWidth * 4
    for (let x = 0; x < boxWidth; ++x) {
      if (binaryDataMatrix[y][x]) {
        data[idx] = r
        data[idx + 1] = g
        data[idx + 2] = b
        data[idx + 3] = 255
      }
      idx += 4
    }
  }
  return { box, imageData: new ImageData(data, boxWidth, boxHeight) }
}

/*
  Contruye un ImageData con los pixels que se le pasan en el color indicado.
  La imagen tiene dimensiones width,height
*/
export const getImageData = (pixels, width, height, r, g, b, a) => {
  const { binaryDataMatrix, box } = getBinaryDataMatrix(pixels)
  const boxWidth = box[2] - box[0] + 1
  const boxHeight = box[3] - box[1] + 1
  const data = new Uint8ClampedArray(width * height * 4)
  for (let y = 0; y < boxHeight; ++y) {
    let idx = ((box[1] + y) * width + box[0]) * 4
    for (let x = 0; x < boxWidth; ++x) {
      if (binaryDataMatrix[y][x]) {
        data[idx] = r
        data[idx + 1] = g
        data[idx + 2] = b
        data[idx + 3] = a
      }
      idx += 4
    }
  }
  return new ImageData(data, width)
}
