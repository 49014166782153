import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import RBarButton from './RBarButton'
import { barVisibleSelector } from '../../../modules/reducerMaster'
import {
  ZOOM_FACTOR,
  modeSelector,
  MODE,
  zoomAtGlobalPoint,
  getGlobalCenter
} from '../../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RZoomOutButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const canvasCenter = useSelector(getGlobalCenter)
  const barVisible = useSelector(barVisibleSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(zoomAtGlobalPoint(1 - ZOOM_FACTOR, canvasCenter))
  }, [dispatch, canvasCenter])

  return (
    <RBarButton
      {...props}
      tooltip="Zoom out"
      tooltipEnabled={barVisible}
      onClick={handleClick}
      disabled={mode === MODE.PANNING || mode === MODE.ROT_MOUSE}
    >
      <ZoomOutIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RZoomOutButton
