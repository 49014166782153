// import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RSGGenerator from './Generator/RSGGenerator'
import RCGArea from './SegmentEditor/RCGArea'
import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white', //'rgb(241, 234, 207)',
    boxSizing: 'border-box',
    width: '280px',
    height: '100%',
    overflow: 'auto',

    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'strentch',

    padding: '10px'
  }
}))

const RCGLateral = () => {
  const classes = useStyles()
  const showEditor = useSelector(showEditorSelector)
  // const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      {showEditor ? <RCGArea /> : <RSGGenerator />}
    </div>
  )
}
export default RCGLateral
