import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import RBarButton from './RBarButton'
import { barVisibleSelector } from '../../../modules/reducerMaster'

import { rotate, modeSelector, MODE } from '../../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RRotateRightButton = (props) => {
  // const classes = useStyles()
  const mode = useSelector(modeSelector)
  const barVisible = useSelector(barVisibleSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(rotate(1))
  }, [dispatch])

  return (
    <RBarButton
      {...props}
      tooltip="Rotate right"
      tooltipEnabled={barVisible}
      onClick={handleClick}
      disabled={!(mode === MODE.BUTTONS || mode === MODE.PAN)}
    >
      <RotateRightIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RRotateRightButton
