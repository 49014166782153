import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  getCurrentGroupImageSize,
  getCurrentImage
} from '../../modules/reducerGroups'

import {
  GRAPHIC_ELEMENT,
  isGraphicElementVisible
} from '../../modules/SegmentGenerator/reducerSGVisualization'

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1
  }
}))

function RCGImage() {
  const imageSize = useSelector(getCurrentGroupImageSize)
  const imageSrc = useSelector(getCurrentImage)
  const isVisible = useSelector(isGraphicElementVisible)
  // const dispatch = useDispatch()
  const classes = useStyles()
  return isVisible(GRAPHIC_ELEMENT.IMAGE) ? (
    <img
      className={classes.image}
      src={imageSrc}
      alt={'Imagen'}
      width={imageSize.width}
      height={imageSize.height}
    />
  ) : null
}
export default RCGImage
