import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RotatorMaster from './Master/RotatorMaster'
import RotatorSlave from './Slave/RotatorSlave'
import RSegmentGenerator from './SegmentGenerator/RSegmentGenerator'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(241, 234, 207)',

    width: '100vw',
    height: '100vh',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}))

const RMain = () => {
  const classes = useStyles()
  // const [mode, setMode] = useState(true)
  // const handleClick = () => {
  //   setMode(!mode)
  // }
  return (
    <div className={classes.root}>
      <RotatorMaster />
    </div>
  )
}
export default RMain
/*
<RSegmentGenerator />
<RotatorMaster width={'100%'} height={'100%'} />
<RotatorMaster width={'50%'} height={'100%'} />
<RotatorSlave width={'40%'} height={'70%'} />
*/
