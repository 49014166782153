import { createSelector } from 'reselect'
import { scaleSelector } from '../reducerRotator'

export const SHOW_GRAPHIC_ELEMENT = 'SHOW_GRAPHIC_ELEMENT'
export const SHOW_EDITOR = 'SHOW_EDITOR'

export const GRAPHIC_ELEMENT = {
  OUTPUT: 'g0',
  GRAPHIC: 'g1',
  POINT: 'g2',
  MASK: 'g3',
  IMAGE: 'g4',
  BORDER: 'g6',
  BOUNDING_BOX: 'g8',
  ALL_BOUNDING_BOX: 'g9',
  REGIONS: 'g10',
  POIS: 'g11'
}

const initialState = {
  showEditor: false,
  graphicElements: [
    { id: GRAPHIC_ELEMENT.GRAPHIC, name: 'Graphic', show: true, editor: true },
    { id: GRAPHIC_ELEMENT.OUTPUT, name: 'Output', show: false, editor: true },
    { id: GRAPHIC_ELEMENT.POINT, name: 'Point', show: false, editor: false },
    { id: GRAPHIC_ELEMENT.MASK, name: 'Mask', show: true, editor: true },
    { id: GRAPHIC_ELEMENT.IMAGE, name: 'Image', show: false, editor: true },
    { id: GRAPHIC_ELEMENT.BORDER, name: 'Border', show: true, editor: false },
    {
      id: GRAPHIC_ELEMENT.BOUNDING_BOX,
      name: 'Bounding box',
      show: false,
      editor: false
    },
    {
      id: GRAPHIC_ELEMENT.POIS,
      name: 'Points of interest',
      show: true,
      editor: false
    },
    {
      id: GRAPHIC_ELEMENT.ALL_BOUNDING_BOX,
      name: 'All bounding box',
      show: false,
      editor: false
    },
    { id: GRAPHIC_ELEMENT.REGIONS, name: 'Regions', show: false, editor: true }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action, state)
  switch (action.type) {
    case SHOW_GRAPHIC_ELEMENT:
      return {
        ...state,
        graphicElements: state.graphicElements.map((ge) => {
          if (ge.id === action.elementId) {
            return { ...ge, show: action.show }
          }
          return ge
        })
      }
    case SHOW_EDITOR:
      return { ...state, showEditor: action.show }
    default:
      return state
  }
}
export default changeState

export const showGraphicElement = (elementId, show) => ({
  type: SHOW_GRAPHIC_ELEMENT,
  elementId,
  show
})

export const setEditorVisible = (show) => ({
  type: SHOW_EDITOR,
  show
})

export const graphicElementsSelector = (state) =>
  state.visualization.graphicElements
export const showEditorSelector = (state) => state.visualization.showEditor

export const getElements = createSelector(
  [graphicElementsSelector, showEditorSelector],
  (elements, editor) => {
    return elements.filter((e) => {
      if (editor) {
        return e.editor === editor
      }
      return true
    })
  }
)

export const isGraphicElementVisible = createSelector(
  [graphicElementsSelector],
  (elements) => (id) => {
    const element = elements.find((e) => e.id === id)
    return element ? element.show : null
  }
)

export const getLineWidth = createSelector([scaleSelector], (scale) => {
  return 5 / scale
})

export const getPointLineWidth = createSelector([scaleSelector], (scale) => {
  return 2 / scale
})

export const getPointLength = createSelector([scaleSelector], (scale) => {
  return 20 / scale
})

export const getDotPointWidth = createSelector([scaleSelector], (scale) => {
  return 15 / scale
})

export const getTextSize = createSelector([scaleSelector], (scale) => {
  return 20 / scale
})
