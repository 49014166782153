export const selectItem = (selection, itemId) => {
  let newSelection = [...selection]
  if (newSelection.indexOf(itemId) < 0) {
    newSelection.push(itemId)
  }
  return newSelection
}

export const deselectItem = (selection, itemId) => {
  const newSelection = selection.filter((id) => id !== itemId)
  return newSelection
}

export const toggleItemSelection = (selection, itemId) => {
  const selected = selection.indexOf(itemId) >= 0
  return selected
    ? deselectItem(selection, itemId)
    : selectItem(selection, itemId)
}
