import { useRef, useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import mergeRefs from 'react-merge-refs'
import useMeasure from 'react-use-measure'

import RSegmentsCanvas from '../RSegmentsCanvas'
import RTooltip from '../Tooltip/RTooltip'

import {
  stepSelector,
  scaleSelector,
  getTranslation2,
  boundsSelector
} from '../../modules/reducerRotator'

import {
  currentGroupSelector,
  getCurrentGroupImages,
  getCurrentSegments,
  getCurrentLocalCentroid
} from '../../modules/reducerGroups'
import {
  hoverSegmentSelector,
  selectedSegmentsSelector
} from '../../modules/reducerShare'
import { drawHouse } from '../../utils/draw'
import { isPointInsideBounds } from '../../utils/mathFunctions'
import {
  localPointToGlobalPoint,
  getChildTopLeft
} from '../../utils/centeredChild'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#c0c0c0',
    position: 'fixed',
    top: 0,
    right: 0,
    // width: '100%',
    // height: '100%'
    // width: '600px',
    // height: '500px',
    width: ({ width }) => width,
    height: ({ height }) => height,
    overflow: 'hidden'
  }
}))

const RotatorSlave = ({ width, height }) => {
  const [rootBoundsRef, rootBounds] = useMeasure()
  const rootRef = useRef(null)
  const classes = useStyles({ width, height })
  const [stepDrawn, setStepDrawn] = useState(-1)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [canvasBounds, setCanvasBounds] = useState(null)
  const [globalCentroid, setGlobalCentroid] = useState({ x: 0, y: 0 })
  const [centroidVisible, setCentroidVisible] = useState(false)
  const hoverSegment = useSelector(hoverSegmentSelector)
  const selectedSegments = useSelector(selectedSegmentsSelector)
  const currentGroup = useSelector(currentGroupSelector)
  const currentStep = useSelector(stepSelector)
  const masterScale = useSelector(scaleSelector)
  const [adaptScale, setAdaptScale] = useState(1)
  const gTranslation = useSelector(getTranslation2)
  // const translation = gTranslation(scale)
  const images = useSelector(getCurrentGroupImages)
  const segments = useSelector(getCurrentSegments)
  const gCurrentLocalCentroid = useSelector(getCurrentLocalCentroid)
  const masterBounds = useSelector(boundsSelector)

  useEffect(() => {
    if (!rootBounds || !masterBounds) return
    const adatpScale = Math.min(
      rootBounds.width / masterBounds.width,
      rootBounds.height / masterBounds.height
    )
    setAdaptScale(adatpScale)
  }, [masterBounds, rootBounds])

  useEffect(() => {
    if (hoverSegment !== '') {
      const segment = segments.find((segment) => segment.color === hoverSegment)
      if (segment) {
        const scale = adaptScale * masterScale
        const topLeft = getChildTopLeft(
          scale,
          gTranslation(scale),
          imageSize,
          canvasBounds
        )
        const localCentroid = gCurrentLocalCentroid(segment.color)
        const centroid = localPointToGlobalPoint(
          {
            x: localCentroid ? localCentroid[0] : 0,
            y: localCentroid ? localCentroid[1] : 0
          },
          scale,
          topLeft
        )
        setGlobalCentroid(centroid)
        setCentroidVisible(
          isPointInsideBounds(centroid.x, centroid.y, canvasBounds)
        )
      }
    }
  }, [
    hoverSegment,
    segments,
    canvasBounds,
    imageSize,
    adaptScale,
    masterScale,
    gTranslation,
    gCurrentLocalCentroid
  ])

  useEffect(() => {
    setStepDrawn(false)
  }, [currentGroup, currentStep])

  const handleCanvasBoundsChanged = useCallback((bounds) => {
    setCanvasBounds(bounds)
  }, [])

  const handleImageSizeChanged = useCallback((imageSize) => {
    setImageSize(imageSize)
  }, [])

  const handleDrawStart = useCallback(() => {}, [])

  const handleDrawEnd = useCallback(() => {
    setStepDrawn(true)
  }, [])

  const handleDrawSegment = useCallback(
    (ctx, segment) => {
      drawHouse(
        ctx,
        segment,
        'house',
        selectedSegments.indexOf(segment.color) >= 0,
        hoverSegment === segment.color
      )
    },
    [selectedSegments, hoverSegment]
  )

  return (
    <div ref={mergeRefs([rootRef, rootBoundsRef])} className={classes.root}>
      <RSegmentsCanvas
        scale={adaptScale * masterScale}
        translation={gTranslation(adaptScale * masterScale)}
        imageSrc={images[currentStep]}
        segments={segments}
        drawSegment={handleDrawSegment}
        onCanvasBoundsChanged={handleCanvasBoundsChanged}
        onImageSizeChanged={handleImageSizeChanged}
        onDrawStart={handleDrawStart}
        onDrawEnd={handleDrawEnd}
      />
      <RTooltip
        parentRef={rootRef}
        master={false}
        houseId={stepDrawn ? hoverSegment : ''}
        centroid={globalCentroid}
        centroidVisible={centroidVisible}
      />
    </div>
  )
}
export default RotatorSlave
