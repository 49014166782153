import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  currentGroupSelector,
  getCurrentMaskImage
} from '../../modules/reducerGroups'
import { stepSelector } from '../../modules/reducerRotator'
import {
  GRAPHIC_ELEMENT,
  isGraphicElementVisible
} from '../../modules/SegmentGenerator/reducerSGVisualization'
import { setMaskImageData } from '../../modules/SegmentGenerator/reducerSGMaskImage'

const useStyles = makeStyles((theme) => ({
  imageMask: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: ({ imageVisible, maskImageVisible }) => {
      if (!maskImageVisible) {
        return 0
      } else {
        if (imageVisible) {
          return 0.5
        }
        return 1
      }
    },
    zIndex: 2
  }
}))

function RCGMaskImage() {
  const maskCanvasRef = useRef(null)

  const currentStep = useSelector(stepSelector)
  const maskImageSrc = useSelector(getCurrentMaskImage)
  const currentGroup = useSelector(currentGroupSelector)
  const isVisible = useSelector(isGraphicElementVisible)
  const maskImageVisible = isVisible(GRAPHIC_ELEMENT.MASK)
  const imageVisible = isVisible(GRAPHIC_ELEMENT.IMAGE)
  const dispatch = useDispatch()
  const classes = useStyles({
    imageVisible,
    maskImageVisible
  })

  useEffect(() => {
    const canvas = maskCanvasRef.current
    if (currentGroup === '' || currentStep === '' || !canvas) {
      return
    }
    const maskImage = new Image()
    maskImage.onload = () => {
      canvas.width = maskImage.naturalWidth
      canvas.height = maskImage.naturalHeight
      const ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.drawImage(maskImage, 0, 0)
      dispatch(
        setMaskImageData(
          ctx.getImageData(
            0,
            0,
            maskImage.naturalWidth,
            maskImage.naturalHeight
          ),
          currentGroup,
          currentStep
        )
      )
    }
    maskImage.src = maskImageSrc
  }, [currentGroup, currentStep, maskImageSrc, dispatch])

  return <canvas ref={maskCanvasRef} className={classes.imageMask} />
}
export default RCGMaskImage
