// import { createSelector } from 'reselect'

export const SET_BAR_VISIBLE = 'SET_BAR_VISIBLE'
export const SET_LATERAL_VISIBLE = 'SET_LATERAL_VISIBLE'

const initialState = {
  barVisible: true,
  lateralVisible: false
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_BAR_VISIBLE:
      return {
        ...state,
        barVisible: action.visible
      }
    case SET_LATERAL_VISIBLE:
      return {
        ...state,
        lateralVisible: action.visible
      }
    default:
      return state
  }
}
export default changeState

export const setBarVisible = (value) => ({
  type: SET_BAR_VISIBLE,
  visible: value
})

export const setLateralVisible = (value) => ({
  type: SET_LATERAL_VISIBLE,
  visible: value
})

export const barVisibleSelector = (state) => state.master.barVisible
export const lateralVisibleSelector = (state) => state.master.lateralVisible
