import { group_g1_segments } from './group_g1_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i < 20; ++i) {
    images.push(
      './images/GiroCasa/base1/EDIFICIO_PLANTA_04.2_' +
        `${i}`.padStart(4, 0) +
        '.jpg'
    )
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i < 20; ++i) {
    images.push(
      './images/GiroCasa/mascara1/EDIFICIO_PLANTA_04.2_' +
        `${i}`.padStart(4, 0) +
        '.jpg'
    )
  }
  return images
}
export const group1 = {
  id: 'g1',
  name: 'Group 1',
  imageSize: { width: 1500, height: 1125 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: [
    '#08ff00ff',
    '#ffa200ff',
    '#0112ffff',
    '#fefe00ff',
    '#00ffffff',
    '#01ffffff'
  ],
  segments: group_g1_segments
}
