import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import RMainBar from './RMainBar'
import RShowBarButton from './RShowBarButton'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RBar = (props) => {
  return (
    <>
      <RMainBar />
      <RShowBarButton />
    </>
  )
}
export default RBar
