import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import {
  setCurrentPolygon,
  addPolygon,
  removePolygon
} from '../../../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  getCurrentSegment,
  currentPolygonSelector
} from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  root: { border: '1px solid black', padding: '5px 10px', margin: '5px 0px' },
  title: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button: {
    padding: 0
  },
  polysWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start'
  },
  polyButton: { width: '50%', minWidth: '0px', padding: '0px' }
}))

const RCGPolygons = () => {
  const classes = useStyles()
  const currentPolygon = useSelector(currentPolygonSelector)
  const segment = useSelector(getCurrentSegment)
  const dispatch = useDispatch()

  const handleAdd = useCallback(() => {
    dispatch(addPolygon())
  }, [dispatch])

  const handleRemove = useCallback(() => {
    dispatch(removePolygon(currentPolygon))
  }, [dispatch, currentPolygon])

  const handleClick = useCallback(
    (polygonId) => {
      dispatch(setCurrentPolygon(polygonId))
    },
    [dispatch]
  )
  return segment ? (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>Polygons</div>
        <div>
          <IconButton className={classes.button} onClick={handleAdd}>
            <AddIcon />
          </IconButton>
          <IconButton className={classes.button} onClick={handleRemove}>
            <RemoveIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.polysWrapper}>
        {segment.polygons.map((polygon, index) => (
          <Button
            key={polygon.id}
            className={classes.polyButton}
            variant="contained"
            color={currentPolygon === polygon.id ? 'secondary' : 'default'}
            disableFocusRipple
            onClick={(event) => handleClick(polygon.id)}
          >
            {polygon.id}
          </Button>
        ))}
      </div>
    </div>
  ) : null
}
export default RCGPolygons
