import { group_LLDH_0_segments } from './group_LLDH_0_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i <= 68; ++i) {
    images.push(
      './RENDERS/PARCELA GENERAL/Secuencia base/secuencia app_' +
        `${i}`.padStart(5, 0) +
        '.jpg'
    )
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i <= 68; ++i) {
    images.push(
      './RENDERS/PARCELA GENERAL/Secuencia mascaras colores/secuencia mascaras colores_' +
        `${i}`.padStart(5, 0) +
        '.png'
    )
  }
  return images
}
export const parcelaGeneral = {
  id: 'LLDH_0',
  name: 'Parcela general',
  imageSize: { width: 1920, height: 1080 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: [
    '#60ff00ff',
    '#e7c19eff',
    '#a9cbc7ff',
    '#e5bcc9ff',
    '#677de0ff',
    '#1e401eff',
    '#8e2c9aff',
    '#ff0000ff',
    '#fff600ff'
  ],
  segments: group_LLDH_0_segments
}
