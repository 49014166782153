import { createSelector } from 'reselect'
import { getCurrentGroupColors } from '../reducerGroups'
import { getMinimumArea } from './reducerSGParameters'
import { hexToRgba, inverRgbaColor } from '../../utils/color'
import { imageSegmentation } from '../../utils/imageProcessing'

export const SET_MASK_IMAGE_DATA = 'SET_MASK_IMAGE_DATA'

const initialState = {
  maskImageData: null,
  group: '',
  step: ''
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_MASK_IMAGE_DATA:
      return {
        ...state,
        maskImageData: action.data,
        group: action.group,
        step: action.step
      }
    default:
      return state
  }
}
export default changeState

export const setMaskImageData = (data, group, step) => ({
  type: SET_MASK_IMAGE_DATA,
  data,
  group,
  step
})

export const maskImageDataSelector = (state) => state.maskImage.maskImageData
export const maskImageDataGroupSelector = (state) => state.maskImage.group
export const maskImageDataStepSelector = (state) => state.maskImage.step

export const getSegmentedImage = createSelector(
  [maskImageDataSelector, getCurrentGroupColors, getMinimumArea],
  (maskData, colors, minimumArea) => {
    const isBackground = (r, g, b, a) => {
      return r === 0 && g === 0 && b === 0
    }
    return imageSegmentation(maskData, colors, isBackground, minimumArea)
  }
)

export const getTotalImage = createSelector(
  [maskImageDataSelector, getSegmentedImage],
  (maskImageData, segmentedImage) => {
    if (!maskImageData) {
      return new ImageData(1, 1)
    }
    const totalImageData = new ImageData(
      maskImageData.width,
      maskImageData.height
    )
    for (let segment of segmentedImage) {
      const segmentRgbaColor = hexToRgba(segment.hexColor)
      const invertedColor = inverRgbaColor(segmentRgbaColor)
      for (let region of segment.regions) {
        for (let pixel of region) {
          const idx = (pixel[1] * maskImageData.width + pixel[0]) * 4
          totalImageData.data[idx] = invertedColor.r
          totalImageData.data[idx + 1] = invertedColor.g
          totalImageData.data[idx + 2] = invertedColor.b
          totalImageData.data[idx + 3] = 255
        }
      }
    }
    return totalImageData
  }
)
