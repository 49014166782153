import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    justifyContent: 'space-between',
    minWidth: 0
  },
  input: { minWidth: '0px', marginLeft: '2px', '-moz-appearance': 'textfield' }

  // /* Chrome, Safari, Edge, Opera */
  // input::-webkit-outer-spin-button,
  // input::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }
  //
  // /* Firefox */
  // input[type=number] {
  //   -moz-appearance: textfield;
  // }
}))

const RCGPoint = ({ x, y, onChange }) => {
  // const [localPoint, setLocalPoint] = useState([parseInt(x), parseInt(y)])
  const [localPoint, setLocalPoint] = useState([parseInt(x), parseInt(y)])
  const classes = useStyles()

  useEffect(() => {
    setLocalPoint([x, y])
  }, [x, y])

  const handleBlur = useCallback(
    (e) => {
      onChange(parseInt(localPoint[0]), parseInt(localPoint[1]))
    },
    [localPoint, onChange]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onChange(parseInt(localPoint[0]), parseInt(localPoint[1]))
      }
    },
    [localPoint, onChange]
  )

  const handleXChange = useCallback(
    (e) => {
      setLocalPoint([e.target.value, localPoint[1]])
    },
    [localPoint]
  )

  const handleYChange = useCallback(
    (e) => {
      setLocalPoint([localPoint[0], e.target.value])
    },
    [localPoint]
  )

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        type="number"
        min={0}
        max={1500}
        value={localPoint[0]}
        onChange={handleXChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
      <input
        className={classes.input}
        type="number"
        min={0}
        max={1500}
        value={localPoint[1]}
        onChange={handleYChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
    </div>
  )
}
export default RCGPoint
