import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import RCGPoint from './RCGPoint'

import { setPoint } from '../../../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  getCurrentSegmentPoint,
  isPointSelected
} from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    justifyContent: 'space-between',
    minWidth: 0,
    marginBottom: '4px'
  },
  input: { minWidth: '0px' },
  button: { padding: 0 }
}))

const RCGPointItem = ({ pointId, onSelect }) => {
  const gPoint = useSelector(getCurrentSegmentPoint)
  const point = gPoint(pointId)
  const isSelected = useSelector(isPointSelected)
  const selected = isSelected(pointId)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (x, y) => {
      dispatch(setPoint(pointId, x, y))
    },
    [dispatch, pointId]
  )
  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.button}
        checked={selected}
        onChange={() => onSelect(pointId, !selected)}
      />
      <RCGPoint x={point.x} y={point.y} onChange={handleChange} />
    </div>
  )
}
export default RCGPointItem
