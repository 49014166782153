import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'

import ButtonGroup from '@material-ui/core/ButtonGroup'

import { barVisibleSelector } from '../../../modules/reducerMaster'
import RRotateRightButton from './RRotateRightButton'
import RRotateLeftButton from './RRotateLeftButton'
import RAutomaticRotationButton from './RAutomaticRotationButton'
import RZoomOutButton from './RZoomOutButton'
import RZoomInButton from './RZoomInButton'
import RPanButton from './RPanButton'
import RFullWindowButton from './RFullWindowButton'
import RHomeButton from './RHomeButton'
import RMenuButton from './RMenuButton'
import RHideBarButton from './RHideBarButton'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'white',
    // left: '50%',
    // transform: 'translate(-50%, 0%)',
    // position: 'absolute',

    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    maxWidth: '500px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  buttonGroup: {
    marginRight: '5px',
    marginLeft: '5px'
  }
}))

const RMainBar = () => {
  const classes = useStyles()
  const barVisible = useSelector(barVisibleSelector)

  const toolbarAnimation = useSpring({
    bottom: barVisible ? 10 : -200,
    opacity: barVisible ? 1 : 0
  })
  return (
    <animated.div className={classes.root} style={toolbarAnimation}>
      <ButtonGroup className={classes.buttonGroup}>
        <RMenuButton />
        <RHomeButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup}>
        <RRotateRightButton />
        <RAutomaticRotationButton />
        <RRotateLeftButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup}>
        <RZoomInButton />
        <RZoomOutButton />
        <RFullWindowButton />
        <RPanButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup}>
        <RHideBarButton />
      </ButtonGroup>
    </animated.div>
  )
}
export default RMainBar
