import { createSelector } from 'reselect'

export const SET_PARAM = 'SET_PARAM'
export const SET_METHOD = 'SET_METHOD'

export const PARAMS = {
  MINIMUM_AREA: 'p0',
  HULL_CONCAVITY: 'p1',
  POINT_SIMPLIFY_TOLERANCE: 'p2',
  POINT_SIMPLIFY_TOLERANCE_MSQR: 'p5'
}
export const METHODS = {
  HULL_AND_SIMPLIFY: 'm0',
  MSQR: 'm1'
}

const initialState = {
  currentMethod: METHODS.HULL_AND_SIMPLIFY,
  methods: [
    {
      id: METHODS.HULL_AND_SIMPLIFY,
      name: 'Hull',
      params: [
        PARAMS.MINIMUM_AREA,
        PARAMS.HULL_CONCAVITY,
        PARAMS.POINT_SIMPLIFY_TOLERANCE
      ]
    },
    {
      id: METHODS.MSQR,
      name: 'MSQR',
      params: [PARAMS.MINIMUM_AREA, PARAMS.POINT_SIMPLIFY_TOLERANCE_MSQR]
    }
  ],

  parameters: [
    {
      id: PARAMS.MINIMUM_AREA,
      name: 'Minimum area',
      type: 'number',
      value: 5,
      max: 1000,
      min: 1
    },
    {
      id: PARAMS.HULL_CONCAVITY,
      name: 'Hull Concavity',
      type: 'number',
      value: 10,
      max: 100,
      min: 0
    },
    {
      id: PARAMS.POINT_SIMPLIFY_TOLERANCE,
      name: 'Point simplify tolerance',
      type: 'number',
      value: 1,
      max: 100,
      min: 0
    },
    {
      id: PARAMS.POINT_SIMPLIFY_TOLERANCE_MSQR,
      name: 'Point simplify tolerance',
      type: 'number',
      value: 2,
      max: 100,
      min: 0
    }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action, state)
  switch (action.type) {
    case SET_METHOD:
      return { ...state, currentMethod: action.method }
    case SET_PARAM:
      return {
        ...state,
        parameters: state.parameters.map((param) => {
          if (param.id === action.paramId) {
            return { ...param, value: action.value }
          }
          return param
        })
      }

    default:
      return state
  }
}
export default changeState

export const setMethod = (method) => ({
  type: SET_METHOD,
  method
})

export const setParam = (paramId, value) => ({
  type: SET_PARAM,
  paramId,
  value
})

export const parametersSelector = (state) => state.parameters.parameters
export const methodsSelector = (state) => state.parameters.methods
export const currentMethodSelector = (state) => state.parameters.currentMethod

export const getMethod = createSelector(
  [methodsSelector],
  (methods) => (methodId) => {
    return methods.find((method) => method.id === methodId)
  }
)

export const getParameter = createSelector(
  [parametersSelector],
  (parameters) => (paramId) => {
    return parameters.find((param) => param.id === paramId)
  }
)

export const getMethodParameters = createSelector(
  [parametersSelector, getMethod],
  (parameters, gMethod) => (methodId) => {
    const method = gMethod(methodId)
    if (!method) {
      return []
    }
    return parameters.filter((param) => method.params.indexOf(param.id) >= 0)
  }
)

export const getParameterValue = createSelector(
  [parametersSelector],
  (parameters) => (id) => {
    const param = parameters.find((p) => p.id === id)
    return param ? param.value : null
  }
)

export const getMinimumArea = createSelector(
  [getParameterValue],
  (gParameterValue) => {
    return gParameterValue(PARAMS.MINIMUM_AREA)
  }
)

export const getHullConcavity = createSelector(
  [getParameterValue],
  (gParameterValue) => {
    return gParameterValue(PARAMS.HULL_CONCAVITY)
  }
)

export const getPointsSimplifyTolerance = createSelector(
  [getParameterValue],
  (gParameterValue) => {
    return gParameterValue(PARAMS.POINT_SIMPLIFY_TOLERANCE)
  }
)

export const getPointsSimplifyToleranceMSQR = createSelector(
  [getParameterValue],
  (gParameterValue) => {
    return gParameterValue(PARAMS.POINT_SIMPLIFY_TOLERANCE_MSQR)
  }
)
