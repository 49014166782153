export const group_vita2_segments =[
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1005, 631]}],
   "box": [978, 602, 1033, 661],
   "polygons": [[[1033, 644], [978, 661], [978, 616], [1033, 602]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1008, 636]}],
   "box": [978, 607, 1037, 667],
   "polygons": [[[1037, 650], [979, 667], [978, 621], [1037, 607]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1012, 642]}],
   "box": [981, 612, 1043, 673],
   "polygons": [[[1043, 656], [982, 673], [981, 627], [1043, 612]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1017, 648]}],
   "box": [984, 618, 1050, 680],
   "polygons": [[[1050, 662], [985, 680], [984, 632], [1049, 618]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1021, 654]}],
   "box": [987, 623, 1056, 686],
   "polygons": [[[1056, 668], [988, 686], [987, 638], [1055, 623]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1025, 659]}],
   "box": [989, 628, 1062, 692],
   "polygons": [[[1062, 674], [991, 692], [989, 643], [1061, 628]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1029, 665]}],
   "box": [991, 633, 1067, 698],
   "polygons": [[[1067, 681], [992, 698], [991, 648], [1066, 633]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1031, 671]}],
   "box": [992, 639, 1072, 704],
   "polygons": [[[1072, 687], [994, 704], [992, 653], [1070, 639]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1034, 677]}],
   "box": [993, 644, 1075, 710],
   "polygons": [[[1075, 694], [994, 710], [993, 658], [1074, 644]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1034, 682]}],
   "box": [992, 650, 1077, 716],
   "polygons": [[[1077, 700], [993, 716], [992, 663], [1075, 650]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1032, 688]}],
   "box": [988, 656, 1076, 722],
   "polygons": [[[1076, 707], [990, 722], [988, 669], [1075, 656]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1027, 694]}],
   "box": [982, 661, 1072, 727],
   "polygons": [[[1072, 713], [984, 727], [982, 674], [1070, 661]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1016, 699]}],
   "box": [970, 666, 1064, 732],
   "polygons": [[[1064, 719], [972, 732], [970, 678], [1062, 666]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [1001, 703]}],
   "box": [953, 670, 1050, 736],
   "polygons": [[[1050, 723], [956, 736], [953, 681], [1048, 670]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [980, 705]}],
   "box": [930, 672, 1030, 738],
   "polygons": [[[1030, 726], [933, 738], [930, 683], [1028, 672]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [951, 705]}],
   "box": [900, 673, 1002, 738],
   "polygons": [[[1002, 727], [904, 738], [900, 683], [999, 673]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [917, 705]}],
   "box": [866, 673, 968, 738],
   "polygons": [[[968, 727], [870, 738], [866, 682], [965, 673]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [879, 705]}],
   "box": [828, 673, 930, 737],
   "polygons": [[[930, 727], [832, 737], [828, 681], [927, 673]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [837, 703]}],
   "box": [784, 672, 889, 735],
   "polygons": [[[889, 726], [788, 735], [784, 679], [886, 672]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [793, 701]}],
   "box": [740, 671, 845, 733],
   "polygons": [[[845, 724], [745, 733], [740, 677], [842, 671]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [751, 699]}],
   "box": [699, 669, 803, 730],
   "polygons": [[[803, 722], [703, 730], [699, 675], [800, 669]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [716, 696]}],
   "box": [665, 667, 766, 726],
   "polygons": [[[766, 719], [669, 726], [665, 671], [764, 667]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [689, 691]}],
   "box": [639, 663, 740, 720],
   "polygons": [[[740, 714], [642, 720], [639, 668], [738, 663]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [679, 685]}],
   "box": [630, 659, 727, 711],
   "polygons": [[[727, 709], [633, 711], [630, 662], [725, 659]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [682, 677]}],
   "box": [636, 653, 727, 702],
   "polygons": [[[727, 702], [639, 701], [636, 654], [726, 653]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [692, 670]}],
   "box": [645, 644, 737, 696],
   "polygons": [[[737, 696], [647, 692], [645, 644], [736, 648]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [710, 662]}],
   "box": [669, 635, 751, 688],
   "polygons": [[[751, 688], [671, 685], [669, 635], [751, 641]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [736, 655]}],
   "box": [703, 631, 769, 679],
   "polygons": [[[769, 662], [768, 679], [703, 679], [703, 631], [769, 633]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [752, 646]}],
   "box": [721, 621, 785, 670],
   "polygons": [[[785, 637], [783, 670], [721, 668], [722, 621], [782, 625], [785, 626]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [758, 638]}],
   "box": [726, 613, 792, 662],
   "polygons": [[[792, 626], [789, 662], [726, 658], [726, 613], [792, 618]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [758, 631]}],
   "box": [729, 607, 789, 655],
   "polygons": [[[789, 620], [787, 655], [729, 651], [729, 607], [788, 612]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [748, 624]}],
   "box": [717, 598, 780, 649],
   "polygons": [[[780, 618], [778, 649], [718, 641], [717, 598], [780, 608]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [735, 619]}],
   "box": [704, 594, 767, 644],
   "polygons": [
    [[767, 614], [766, 644], [707, 636], [705, 635], [704, 594], [764, 602], [767, 603]]
   ]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [728, 615]}],
   "box": [703, 591, 753, 640],
   "polygons": [[[753, 640], [704, 631], [703, 591], [753, 598]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [718, 610]}],
   "box": [694, 586, 741, 636],
   "polygons": [[[741, 636], [695, 625], [694, 586], [741, 594]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [707, 610]}],
   "box": [682, 586, 731, 636],
   "polygons": [[[731, 636], [683, 624], [682, 586], [730, 594]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [699, 618]}],
   "box": [676, 594, 722, 643],
   "polygons": [[[722, 643], [677, 632], [676, 594], [721, 603]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [695, 629]}],
   "box": [675, 607, 715, 652],
   "polygons": [[[715, 652], [675, 643], [675, 607], [714, 614]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [694, 638]}],
   "box": [675, 615, 713, 662],
   "polygons": [[[713, 662], [675, 651], [675, 615], [712, 626]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [699, 648]}],
   "box": [682, 625, 717, 671],
   "polygons": [[[717, 671], [683, 661], [682, 625], [716, 636]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [710, 659]}],
   "box": [694, 638, 726, 680],
   "polygons": [[[726, 680], [695, 672], [694, 638], [725, 646]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [725, 666]}],
   "box": [711, 645, 739, 687],
   "polygons": [[[739, 687], [712, 678], [711, 645], [737, 655]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [741, 672]}],
   "box": [728, 650, 755, 692],
   "polygons": [[[755, 692], [729, 682], [728, 650], [754, 662]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [761, 674]}],
   "box": [749, 653, 773, 694],
   "polygons": [[[773, 694], [750, 684], [749, 653], [772, 664]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [780, 674]}],
   "box": [769, 655, 790, 693],
   "polygons": [[[790, 693], [770, 684], [769, 655], [790, 664]]]
  }
 ],
 [
  {
   "color": "#fe0000ff",
   "pois": [{"id": "centroid", "point": [796, 672]}],
   "box": [787, 654, 805, 690],
   "polygons": [[[805, 690], [787, 681], [787, 654], [805, 662]]]
  }
 ]
]