import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RMCenter from './RMCenter'
import RLateral from './Lateral/RLateral'
import { setGroup } from '../../modules/reducerGroups'
import RBar from './Bar/RBar'
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
    width: ({ width }) => (width ? width : '100%'),
    height: ({ height }) => (height ? height : '100%')
  },
  centerWrapper: {
    position: 'relative',
    width: '100px',
    overflow: 'hidden',
    flex: '1 1 auto'
  }
}))

const Rotator = ({ width, height }) => {
  const dispatch = useDispatch()
  const classes = useStyles({ width, height })

  useEffect(() => {
    dispatch(setGroup('g2'))
  })

  return (
    <div className={classes.root}>
      <RLateral />
      <div className={classes.centerWrapper}>
        <RMCenter />
        <RBar />
      </div>
    </div>
  )
}
export default Rotator
