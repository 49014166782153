import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  //rgb(231, 198, 100)
  button: {
    fontSize: '18px',
    border: '1px solid #BDBDBD',
    borderRadius: '24px',
    backgroundColor: 'white',
    opacity: '0.8',

    '&:hover': {
      color: 'white',
      backgroundColor: '#CFA57E',
      border: '1px solid #CFA57E'
    },
    '&:disabled': { backgroundColor: 'rgb(200, 200, 200)' },
    color: ({ checked }) => (checked ? '#CFA57E' : '#000F31')
  }
}))

const RBarButton = ({
  checked,
  tooltip,
  tooltipEnabled,
  onClick,
  disabled,
  ...props
}) => {
  const classes = useStyles({ checked: checked })
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Tooltip
      open={tooltipEnabled && open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={tooltip}
      arrow
    >
      <IconButton
        {...props}
        className={`${props.className} ${classes.button}`}
        onClick={onClick}
        disabled={disabled}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}
export default RBarButton
