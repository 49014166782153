export const group_g3_segments =[
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [959, 60]}],
   "box": [856, 23, 1061, 94],
   "polygons": [
    [
     [1061, 92],
     [1030, 92],
     [1028, 94],
     [1002, 94],
     [993, 92],
     [973, 92],
     [972, 94],
     [946, 94],
     [937, 92],
     [917, 92],
     [916, 94],
     [856, 92],
     [856, 40],
     [869, 40],
     [871, 24],
     [874, 23],
     [875, 25],
     [889, 25],
     [898, 23],
     [928, 23],
     [929, 25],
     [951, 25],
     [959, 23],
     [989, 23],
     [991, 25],
     [1011, 25],
     [1020, 23],
     [1046, 24],
     [1046, 39],
     [1061, 40]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [959, 102]}],
   "box": [856, 93, 1061, 112],
   "polygons": [
    [
     [1061, 109],
     [1055, 112],
     [1036, 112],
     [1015, 109],
     [1001, 109],
     [999, 111],
     [974, 111],
     [965, 109],
     [943, 111],
     [918, 111],
     [909, 109],
     [888, 111],
     [862, 111],
     [856, 109],
     [856, 93],
     [890, 93],
     [891, 95],
     [909, 95],
     [917, 93],
     [945, 93],
     [947, 95],
     [965, 95],
     [973, 93],
     [1001, 93],
     [1003, 95],
     [1019, 95],
     [1028, 93],
     [1061, 93]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [958, 121]}],
   "box": [856, 110, 1061, 132],
   "polygons": [
    [
     [1061, 129],
     [1036, 130],
     [1027, 132],
     [1003, 132],
     [995, 130],
     [973, 130],
     [972, 132],
     [946, 132],
     [937, 130],
     [917, 130],
     [916, 132],
     [891, 132],
     [883, 130],
     [862, 130],
     [856, 129],
     [856, 110],
     [861, 110],
     [863, 112],
     [881, 112],
     [889, 110],
     [917, 110],
     [919, 112],
     [935, 112],
     [944, 110],
     [973, 110],
     [975, 112],
     [991, 112],
     [1000, 110],
     [1028, 110],
     [1029, 112],
     [1039, 113],
     [1047, 113],
     [1056, 110],
     [1061, 110]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [959, 140]}],
   "box": [856, 130, 1061, 149],
   "polygons": [
    [
     [1061, 146],
     [1055, 149],
     [1029, 149],
     [1021, 147],
     [1001, 147],
     [999, 149],
     [974, 149],
     [965, 147],
     [945, 147],
     [943, 149],
     [918, 149],
     [909, 147],
     [889, 147],
     [888, 149],
     [862, 149],
     [856, 146],
     [856, 130],
     [890, 131],
     [905, 133],
     [917, 131],
     [945, 131],
     [951, 133],
     [965, 133],
     [973, 131],
     [1001, 131],
     [1005, 133],
     [1015, 133],
     [1028, 131],
     [1061, 130]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [958, 158]}],
   "box": [856, 147, 1061, 167],
   "polygons": [
    [
     [1061, 167],
     [856, 167],
     [856, 147],
     [861, 147],
     [861, 149],
     [863, 150],
     [881, 150],
     [889, 148],
     [917, 148],
     [919, 150],
     [935, 150],
     [944, 148],
     [973, 148],
     [975, 150],
     [991, 150],
     [1000, 148],
     [1028, 148],
     [1029, 150],
     [1047, 150],
     [1061, 147]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [959, 177]}],
   "box": [856, 168, 1061, 187],
   "polygons": [
    [
     [1061, 184],
     [1055, 187],
     [1034, 187],
     [1011, 184],
     [999, 186],
     [974, 186],
     [965, 184],
     [953, 184],
     [943, 186],
     [918, 186],
     [909, 184],
     [888, 186],
     [862, 186],
     [856, 184],
     [856, 168],
     [1061, 168]
    ]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [958, 196]}],
   "box": [856, 185, 1061, 207],
   "polygons": [
    [
     [1061, 204],
     [1032, 205],
     [1028, 207],
     [1002, 207],
     [993, 205],
     [973, 205],
     [972, 207],
     [946, 207],
     [937, 205],
     [917, 205],
     [916, 207],
     [891, 207],
     [883, 205],
     [860, 205],
     [856, 204],
     [856, 185],
     [861, 185],
     [863, 187],
     [881, 187],
     [890, 185],
     [916, 185],
     [919, 187],
     [935, 187],
     [944, 185],
     [973, 185],
     [975, 187],
     [991, 187],
     [1000, 185],
     [1028, 185],
     [1029, 187],
     [1041, 188],
     [1056, 187],
     [1056, 185],
     [1061, 185]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [958, 215]}],
   "box": [856, 205, 1061, 224],
   "polygons": [
    [
     [1061, 222],
     [1055, 224],
     [1029, 224],
     [1021, 222],
     [1001, 222],
     [999, 224],
     [974, 224],
     [965, 222],
     [945, 222],
     [943, 224],
     [918, 224],
     [909, 222],
     [889, 222],
     [888, 224],
     [862, 224],
     [856, 221],
     [856, 205],
     [890, 206],
     [895, 208],
     [909, 208],
     [917, 206],
     [946, 206],
     [955, 208],
     [973, 206],
     [1001, 206],
     [1003, 208],
     [1019, 208],
     [1028, 206],
     [1061, 205]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [959, 233]}],
   "box": [856, 222, 1061, 244],
   "polygons": [
    [
     [1061, 242],
     [1030, 242],
     [1028, 244],
     [1002, 244],
     [993, 242],
     [973, 242],
     [972, 244],
     [946, 244],
     [937, 242],
     [917, 242],
     [916, 244],
     [856, 242],
     [856, 222],
     [861, 223],
     [863, 225],
     [881, 225],
     [889, 223],
     [917, 223],
     [919, 225],
     [935, 225],
     [944, 223],
     [973, 223],
     [975, 225],
     [991, 225],
     [1000, 223],
     [1028, 223],
     [1029, 225],
     [1047, 225],
     [1060, 222]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [959, 252]}],
   "box": [856, 243, 1061, 262],
   "polygons": [
    [
     [1061, 259],
     [1055, 262],
     [1032, 262],
     [1021, 259],
     [1001, 259],
     [999, 261],
     [974, 261],
     [965, 259],
     [953, 259],
     [943, 261],
     [918, 261],
     [895, 259],
     [889, 259],
     [888, 261],
     [862, 261],
     [856, 259],
     [856, 243],
     [890, 243],
     [891, 245],
     [909, 245],
     [917, 243],
     [946, 243],
     [947, 245],
     [965, 245],
     [973, 243],
     [1001, 243],
     [1003, 245],
     [1021, 245],
     [1029, 243],
     [1061, 243]
    ]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [958, 271]}],
   "box": [856, 260, 1061, 282],
   "polygons": [
    [
     [1061, 279],
     [1027, 280],
     [1027, 282],
     [1002, 282],
     [993, 280],
     [973, 280],
     [972, 282],
     [947, 282],
     [939, 280],
     [917, 280],
     [916, 282],
     [891, 282],
     [883, 280],
     [862, 280],
     [856, 279],
     [856, 260],
     [861, 260],
     [863, 262],
     [881, 262],
     [889, 260],
     [917, 260],
     [919, 262],
     [935, 262],
     [944, 260],
     [972, 260],
     [975, 262],
     [991, 262],
     [1000, 260],
     [1029, 260],
     [1031, 262],
     [1047, 263],
     [1056, 260],
     [1061, 260]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [958, 290]}],
   "box": [856, 280, 1061, 299],
   "polygons": [
    [
     [1061, 296],
     [1055, 299],
     [1029, 299],
     [1021, 297],
     [1001, 297],
     [999, 299],
     [974, 299],
     [965, 297],
     [945, 297],
     [943, 299],
     [918, 299],
     [909, 297],
     [889, 297],
     [888, 299],
     [862, 299],
     [856, 297],
     [856, 280],
     [890, 281],
     [897, 283],
     [909, 283],
     [917, 281],
     [946, 281],
     [947, 283],
     [965, 283],
     [973, 281],
     [1001, 281],
     [1003, 283],
     [1019, 283],
     [1028, 281],
     [1061, 280]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [959, 308]}],
   "box": [856, 297, 1061, 319],
   "polygons": [
    [
     [1061, 317],
     [1029, 317],
     [1027, 319],
     [1002, 319],
     [993, 317],
     [973, 317],
     [972, 319],
     [946, 319],
     [937, 317],
     [917, 317],
     [916, 319],
     [856, 317],
     [856, 298],
     [860, 297],
     [863, 300],
     [881, 300],
     [889, 298],
     [917, 298],
     [919, 300],
     [935, 300],
     [944, 298],
     [973, 298],
     [975, 300],
     [991, 300],
     [1000, 298],
     [1028, 298],
     [1029, 300],
     [1047, 300],
     [1061, 297]
    ]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [959, 327]}],
   "box": [856, 318, 1061, 337],
   "polygons": [
    [
     [1061, 334],
     [1055, 337],
     [1037, 337],
     [1021, 334],
     [1001, 334],
     [999, 336],
     [974, 336],
     [965, 334],
     [945, 334],
     [943, 336],
     [918, 336],
     [901, 334],
     [889, 334],
     [888, 336],
     [862, 336],
     [856, 334],
     [856, 318],
     [890, 318],
     [891, 320],
     [909, 320],
     [917, 318],
     [945, 318],
     [947, 320],
     [965, 320],
     [973, 318],
     [1001, 318],
     [1003, 320],
     [1019, 320],
     [1029, 318],
     [1061, 318]
    ]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [958, 346]}],
   "box": [856, 335, 1061, 357],
   "polygons": [
    [
     [1061, 354],
     [1029, 355],
     [1027, 357],
     [1002, 357],
     [993, 355],
     [973, 355],
     [972, 357],
     [946, 357],
     [937, 355],
     [917, 355],
     [916, 357],
     [891, 357],
     [883, 355],
     [857, 355],
     [856, 335],
     [861, 335],
     [863, 337],
     [881, 337],
     [889, 335],
     [917, 335],
     [919, 337],
     [937, 337],
     [945, 335],
     [973, 335],
     [975, 337],
     [991, 337],
     [1000, 335],
     [1028, 335],
     [1029, 337],
     [1047, 338],
     [1056, 337],
     [1056, 335],
     [1061, 335]
    ]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [958, 365]}],
   "box": [856, 355, 1061, 374],
   "polygons": [
    [
     [1061, 371],
     [1055, 374],
     [1029, 374],
     [1021, 372],
     [1001, 372],
     [999, 374],
     [974, 374],
     [965, 372],
     [945, 372],
     [944, 374],
     [918, 374],
     [909, 372],
     [889, 372],
     [888, 374],
     [862, 374],
     [856, 372],
     [856, 355],
     [890, 356],
     [891, 358],
     [917, 356],
     [946, 356],
     [949, 358],
     [965, 358],
     [973, 356],
     [1001, 356],
     [1003, 358],
     [1019, 358],
     [1028, 356],
     [1061, 355]
    ]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [959, 383]}],
   "box": [856, 372, 1061, 394],
   "polygons": [
    [
     [1061, 392],
     [1030, 392],
     [1028, 394],
     [1002, 394],
     [993, 392],
     [973, 392],
     [972, 394],
     [947, 394],
     [939, 392],
     [917, 392],
     [916, 394],
     [856, 392],
     [856, 373],
     [861, 373],
     [863, 375],
     [881, 375],
     [889, 373],
     [917, 373],
     [919, 375],
     [935, 375],
     [944, 373],
     [973, 373],
     [975, 375],
     [991, 375],
     [1000, 373],
     [1028, 373],
     [1029, 375],
     [1047, 375],
     [1061, 372]
    ]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [959, 402]}],
   "box": [856, 393, 1061, 412],
   "polygons": [
    [
     [1061, 409],
     [1055, 412],
     [1005, 409],
     [1001, 409],
     [999, 411],
     [974, 411],
     [965, 409],
     [945, 409],
     [943, 411],
     [918, 411],
     [909, 409],
     [897, 409],
     [888, 411],
     [862, 411],
     [856, 409],
     [856, 393],
     [890, 393],
     [891, 395],
     [909, 395],
     [917, 393],
     [946, 393],
     [947, 395],
     [965, 395],
     [973, 393],
     [1001, 393],
     [1003, 395],
     [1021, 395],
     [1029, 393],
     [1061, 393]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [958, 421]}],
   "box": [856, 410, 1061, 432],
   "polygons": [
    [
     [1061, 430],
     [1030, 430],
     [1028, 432],
     [1002, 432],
     [993, 430],
     [973, 430],
     [972, 432],
     [947, 432],
     [939, 430],
     [917, 430],
     [916, 432],
     [856, 430],
     [856, 410],
     [861, 410],
     [863, 412],
     [881, 412],
     [889, 410],
     [917, 410],
     [919, 412],
     [935, 412],
     [944, 410],
     [972, 410],
     [975, 412],
     [991, 412],
     [1000, 410],
     [1028, 410],
     [1029, 412],
     [1047, 412],
     [1061, 410]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [958, 440]}],
   "box": [856, 431, 1061, 449],
   "polygons": [
    [
     [1061, 447],
     [1055, 449],
     [1030, 449],
     [1021, 447],
     [1001, 447],
     [999, 449],
     [974, 449],
     [965, 447],
     [945, 447],
     [943, 449],
     [918, 449],
     [909, 447],
     [889, 447],
     [888, 449],
     [862, 449],
     [856, 447],
     [856, 431],
     [890, 431],
     [891, 433],
     [917, 431],
     [945, 431],
     [955, 433],
     [965, 433],
     [973, 431],
     [1001, 431],
     [1003, 433],
     [1019, 433],
     [1028, 431],
     [1061, 431]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [959, 458]}],
   "box": [856, 448, 1061, 469],
   "polygons": [
    [
     [1061, 467],
     [1030, 467],
     [1028, 469],
     [1002, 469],
     [993, 467],
     [973, 467],
     [972, 469],
     [946, 469],
     [937, 467],
     [917, 467],
     [916, 469],
     [856, 467],
     [856, 448],
     [861, 448],
     [863, 450],
     [881, 450],
     [889, 448],
     [917, 448],
     [919, 450],
     [935, 450],
     [944, 448],
     [973, 448],
     [975, 450],
     [991, 450],
     [1000, 448],
     [1028, 448],
     [1031, 450],
     [1047, 450],
     [1061, 448]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [958, 477]}],
   "box": [856, 468, 1061, 486],
   "polygons": [
    [
     [1061, 484],
     [1055, 486],
     [1030, 486],
     [1021, 484],
     [1001, 484],
     [999, 486],
     [974, 486],
     [965, 484],
     [949, 484],
     [944, 486],
     [918, 486],
     [901, 484],
     [889, 484],
     [888, 486],
     [862, 486],
     [856, 484],
     [856, 468],
     [890, 468],
     [891, 470],
     [909, 470],
     [917, 468],
     [946, 468],
     [947, 470],
     [965, 470],
     [973, 468],
     [1001, 468],
     [1003, 470],
     [1019, 470],
     [1028, 468],
     [1061, 468]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [959, 496]}],
   "box": [856, 485, 1061, 507],
   "polygons": [
    [
     [1061, 505],
     [1030, 505],
     [1028, 507],
     [1002, 507],
     [993, 505],
     [973, 505],
     [972, 507],
     [947, 507],
     [939, 505],
     [917, 505],
     [916, 507],
     [856, 505],
     [856, 485],
     [861, 485],
     [863, 487],
     [881, 487],
     [889, 485],
     [917, 485],
     [919, 487],
     [935, 487],
     [944, 485],
     [973, 485],
     [975, 487],
     [991, 487],
     [1000, 485],
     [1028, 485],
     [1031, 487],
     [1047, 487],
     [1061, 485]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [958, 515]}],
   "box": [856, 506, 1061, 524],
   "polygons": [
    [
     [1061, 522],
     [1055, 524],
     [1029, 524],
     [1021, 522],
     [1001, 522],
     [999, 524],
     [974, 524],
     [965, 522],
     [945, 522],
     [943, 524],
     [918, 524],
     [909, 522],
     [889, 522],
     [888, 524],
     [862, 524],
     [856, 522],
     [856, 506],
     [890, 506],
     [891, 508],
     [905, 508],
     [917, 506],
     [946, 506],
     [963, 508],
     [973, 506],
     [1001, 506],
     [1003, 508],
     [1021, 508],
     [1029, 506],
     [1061, 506]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [959, 533]}],
   "box": [856, 523, 1061, 544],
   "polygons": [
    [
     [1061, 542],
     [1030, 542],
     [1028, 544],
     [1002, 544],
     [993, 542],
     [973, 542],
     [972, 544],
     [947, 544],
     [939, 542],
     [917, 542],
     [916, 544],
     [856, 542],
     [856, 523],
     [861, 523],
     [863, 525],
     [881, 525],
     [889, 523],
     [917, 523],
     [919, 525],
     [935, 525],
     [944, 523],
     [973, 523],
     [975, 525],
     [991, 525],
     [1000, 523],
     [1028, 523],
     [1029, 525],
     [1047, 525],
     [1061, 523]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [958, 552]}],
   "box": [856, 543, 1061, 562],
   "polygons": [
    [
     [1061, 559],
     [1053, 562],
     [1029, 561],
     [1021, 559],
     [1007, 559],
     [998, 562],
     [985, 562],
     [965, 559],
     [945, 559],
     [944, 561],
     [940, 562],
     [920, 562],
     [918, 560],
     [893, 559],
     [885, 562],
     [872, 562],
     [856, 559],
     [856, 543],
     [890, 543],
     [891, 545],
     [909, 545],
     [917, 543],
     [946, 543],
     [947, 545],
     [965, 545],
     [973, 543],
     [1001, 543],
     [1003, 545],
     [1021, 545],
     [1029, 543],
     [1061, 543]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [959, 571]}],
   "box": [856, 560, 1061, 582],
   "polygons": [
    [
     [1061, 580],
     [1029, 580],
     [1027, 582],
     [1002, 582],
     [993, 580],
     [973, 580],
     [972, 582],
     [947, 582],
     [939, 580],
     [917, 580],
     [916, 582],
     [856, 580],
     [856, 560],
     [860, 560],
     [863, 562],
     [881, 562],
     [889, 560],
     [917, 560],
     [919, 562],
     [936, 562],
     [944, 560],
     [973, 560],
     [975, 562],
     [991, 562],
     [1000, 560],
     [1029, 560],
     [1031, 562],
     [1048, 562],
     [1061, 560]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [958, 590]}],
   "box": [856, 581, 1061, 599],
   "polygons": [
    [
     [1061, 597],
     [1055, 599],
     [1029, 599],
     [1021, 597],
     [1001, 597],
     [999, 599],
     [974, 599],
     [965, 597],
     [945, 597],
     [943, 599],
     [918, 599],
     [909, 597],
     [889, 597],
     [888, 599],
     [862, 599],
     [856, 597],
     [856, 581],
     [890, 581],
     [891, 583],
     [909, 583],
     [917, 581],
     [946, 581],
     [947, 583],
     [965, 583],
     [973, 581],
     [1001, 581],
     [1003, 583],
     [1021, 583],
     [1029, 581],
     [1061, 581]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [958, 608]}],
   "box": [856, 598, 1061, 620],
   "polygons": [
    [
     [1061, 617],
     [1030, 617],
     [1028, 619],
     [1019, 620],
     [1004, 620],
     [993, 617],
     [973, 617],
     [972, 619],
     [958, 620],
     [948, 620],
     [937, 617],
     [917, 617],
     [916, 619],
     [905, 620],
     [892, 620],
     [883, 617],
     [856, 617],
     [856, 598],
     [861, 598],
     [863, 600],
     [881, 600],
     [889, 598],
     [917, 598],
     [919, 600],
     [935, 600],
     [944, 598],
     [973, 598],
     [975, 600],
     [991, 600],
     [1000, 598],
     [1028, 598],
     [1029, 600],
     [1047, 600],
     [1061, 598]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [958, 627]}],
   "box": [856, 618, 1061, 637],
   "polygons": [
    [
     [1061, 634],
     [1055, 637],
     [1034, 637],
     [1021, 634],
     [1001, 634],
     [998, 637],
     [981, 637],
     [965, 634],
     [949, 634],
     [942, 637],
     [921, 637],
     [909, 634],
     [889, 634],
     [887, 637],
     [862, 637],
     [856, 635],
     [856, 618],
     [890, 618],
     [891, 620],
     [909, 620],
     [917, 618],
     [945, 618],
     [947, 620],
     [965, 620],
     [973, 618],
     [1001, 618],
     [1003, 620],
     [1021, 620],
     [1029, 618],
     [1061, 618]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [959, 646]}],
   "box": [856, 635, 1061, 658],
   "polygons": [
    [
     [1061, 655],
     [1030, 655],
     [1028, 657],
     [1002, 657],
     [993, 655],
     [973, 655],
     [972, 657],
     [946, 657],
     [937, 655],
     [917, 655],
     [916, 657],
     [895, 658],
     [883, 655],
     [856, 655],
     [856, 636],
     [861, 635],
     [863, 637],
     [879, 638],
     [889, 637],
     [889, 635],
     [917, 635],
     [921, 638],
     [935, 638],
     [943, 637],
     [945, 635],
     [973, 635],
     [975, 637],
     [991, 638],
     [999, 637],
     [1000, 635],
     [1028, 635],
     [1029, 637],
     [1047, 638],
     [1056, 637],
     [1056, 635],
     [1061, 635]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [959, 665]}],
   "box": [856, 656, 1061, 675],
   "polygons": [
    [
     [1061, 672],
     [1053, 675],
     [1029, 674],
     [1021, 672],
     [1001, 672],
     [997, 675],
     [974, 674],
     [965, 672],
     [945, 672],
     [941, 675],
     [918, 674],
     [909, 672],
     [889, 672],
     [885, 675],
     [862, 674],
     [856, 672],
     [856, 656],
     [890, 656],
     [891, 658],
     [909, 658],
     [917, 656],
     [946, 656],
     [947, 658],
     [965, 658],
     [973, 656],
     [1001, 656],
     [1003, 658],
     [1021, 658],
     [1029, 656],
     [1061, 656]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [959, 684]}],
   "box": [856, 673, 1061, 695],
   "polygons": [
    [
     [1061, 693],
     [1029, 692],
     [1027, 695],
     [1003, 695],
     [993, 692],
     [973, 692],
     [970, 695],
     [948, 695],
     [939, 692],
     [917, 692],
     [916, 694],
     [912, 695],
     [892, 695],
     [883, 692],
     [856, 692],
     [856, 673],
     [861, 673],
     [863, 675],
     [881, 675],
     [889, 673],
     [917, 673],
     [919, 675],
     [937, 675],
     [944, 673],
     [973, 673],
     [975, 675],
     [992, 675],
     [1000, 673],
     [1028, 673],
     [1029, 675],
     [1047, 675],
     [1061, 673]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [959, 702]}],
   "box": [856, 693, 1061, 713],
   "polygons": [
    [
     [1061, 710],
     [1051, 713],
     [1030, 712],
     [1021, 709],
     [1001, 709],
     [999, 712],
     [975, 712],
     [965, 709],
     [945, 709],
     [943, 712],
     [918, 712],
     [909, 709],
     [889, 709],
     [885, 713],
     [864, 712],
     [856, 710],
     [856, 693],
     [890, 693],
     [893, 696],
     [905, 696],
     [917, 693],
     [945, 693],
     [949, 696],
     [966, 695],
     [973, 693],
     [1001, 693],
     [1003, 696],
     [1013, 696],
     [1028, 693],
     [1060, 693]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [958, 721]}],
   "box": [856, 710, 1061, 733],
   "polygons": [
    [
     [1061, 730],
     [1030, 730],
     [1028, 732],
     [1016, 733],
     [1004, 733],
     [993, 730],
     [973, 730],
     [972, 732],
     [958, 733],
     [948, 733],
     [937, 730],
     [917, 730],
     [916, 732],
     [903, 733],
     [892, 733],
     [883, 730],
     [856, 730],
     [856, 711],
     [859, 710],
     [867, 713],
     [881, 713],
     [889, 710],
     [917, 710],
     [919, 713],
     [935, 713],
     [944, 710],
     [973, 710],
     [979, 713],
     [991, 713],
     [1000, 710],
     [1029, 710],
     [1033, 713],
     [1047, 713],
     [1058, 710],
     [1061, 711]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [959, 740]}],
   "box": [856, 731, 1061, 750],
   "polygons": [
    [
     [1061, 747],
     [1054, 750],
     [1038, 750],
     [1021, 747],
     [1001, 747],
     [998, 750],
     [985, 750],
     [965, 747],
     [945, 747],
     [942, 750],
     [927, 750],
     [909, 747],
     [889, 747],
     [886, 750],
     [872, 750],
     [856, 747],
     [856, 731],
     [890, 731],
     [893, 734],
     [917, 731],
     [946, 731],
     [947, 733],
     [965, 733],
     [973, 731],
     [1001, 731],
     [1005, 734],
     [1029, 731],
     [1061, 731]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [958, 758]}],
   "box": [856, 748, 1061, 768],
   "polygons": [
    [
     [1061, 768],
     [857, 768],
     [856, 748],
     [873, 751],
     [881, 751],
     [889, 748],
     [917, 748],
     [919, 750],
     [933, 751],
     [944, 748],
     [973, 748],
     [975, 750],
     [985, 751],
     [991, 751],
     [1000, 748],
     [1029, 748],
     [1031, 750],
     [1047, 751],
     [1056, 748],
     [1061, 748]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [959, 777]}],
   "box": [856, 768, 1061, 788],
   "polygons": [
    [
     [1061, 785],
     [1053, 788],
     [1031, 787],
     [1021, 784],
     [1001, 784],
     [997, 788],
     [975, 787],
     [965, 784],
     [945, 784],
     [941, 788],
     [921, 787],
     [909, 784],
     [889, 784],
     [885, 788],
     [863, 787],
     [856, 785],
     [856, 768],
     [1060, 768]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [958, 795]}],
   "box": [856, 785, 1061, 806],
   "polygons": [
    [
     [1061, 804],
     [1029, 803],
     [1028, 805],
     [1021, 806],
     [1004, 806],
     [993, 803],
     [973, 803],
     [972, 805],
     [962, 806],
     [948, 806],
     [939, 803],
     [917, 803],
     [916, 805],
     [907, 806],
     [892, 806],
     [891, 804],
     [883, 803],
     [856, 804],
     [856, 786],
     [861, 785],
     [865, 788],
     [879, 788],
     [889, 787],
     [889, 785],
     [917, 785],
     [921, 788],
     [935, 788],
     [943, 787],
     [945, 785],
     [973, 785],
     [977, 788],
     [991, 788],
     [1000, 787],
     [1000, 785],
     [1028, 785],
     [1029, 787],
     [1033, 788],
     [1047, 788],
     [1060, 785]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [959, 814]}],
   "box": [856, 804, 1061, 825],
   "polygons": [
    [
     [1061, 822],
     [1053, 825],
     [1030, 824],
     [1021, 822],
     [1001, 822],
     [998, 825],
     [974, 824],
     [965, 822],
     [945, 822],
     [942, 825],
     [919, 824],
     [911, 822],
     [889, 822],
     [886, 825],
     [875, 825],
     [856, 822],
     [856, 805],
     [890, 804],
     [893, 807],
     [897, 807],
     [917, 804],
     [946, 804],
     [947, 806],
     [951, 807],
     [973, 804],
     [1001, 804],
     [1005, 807],
     [1009, 807],
     [1028, 804],
     [1060, 804]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [958, 833]}],
   "box": [856, 823, 1061, 844],
   "polygons": [
    [
     [1061, 841],
     [1029, 841],
     [1026, 843],
     [1013, 844],
     [1005, 844],
     [1002, 842],
     [993, 841],
     [973, 841],
     [970, 843],
     [958, 844],
     [948, 844],
     [947, 842],
     [939, 841],
     [917, 841],
     [914, 843],
     [900, 844],
     [892, 844],
     [883, 841],
     [856, 841],
     [856, 823],
     [877, 826],
     [887, 825],
     [889, 823],
     [917, 823],
     [919, 825],
     [933, 826],
     [943, 825],
     [944, 823],
     [973, 823],
     [975, 825],
     [991, 826],
     [999, 825],
     [1000, 823],
     [1028, 823],
     [1031, 825],
     [1055, 825],
     [1056, 823],
     [1061, 823]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [959, 852]}],
   "box": [856, 841, 1061, 863],
   "polygons": [
    [
     [1061, 860],
     [1053, 863],
     [1047, 863],
     [1021, 859],
     [1001, 859],
     [999, 862],
     [993, 863],
     [965, 859],
     [945, 859],
     [941, 863],
     [935, 863],
     [921, 862],
     [909, 859],
     [889, 859],
     [885, 863],
     [878, 863],
     [856, 860],
     [856, 842],
     [890, 842],
     [893, 845],
     [923, 841],
     [946, 842],
     [949, 845],
     [990, 841],
     [1001, 842],
     [1005, 845],
     [1029, 842],
     [1061, 842]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [958, 870]}],
   "box": [856, 860, 1061, 882],
   "polygons": [
    [
     [1061, 878],
     [1029, 878],
     [1028, 880],
     [1010, 882],
     [1005, 882],
     [1003, 880],
     [993, 878],
     [973, 878],
     [972, 880],
     [954, 882],
     [949, 882],
     [947, 880],
     [939, 878],
     [917, 878],
     [916, 880],
     [911, 881],
     [895, 882],
     [885, 878],
     [856, 879],
     [856, 861],
     [861, 860],
     [863, 862],
     [869, 863],
     [880, 863],
     [889, 860],
     [917, 860],
     [923, 863],
     [937, 863],
     [944, 860],
     [973, 860],
     [979, 863],
     [991, 863],
     [1000, 860],
     [1028, 860],
     [1029, 862],
     [1035, 863],
     [1049, 863],
     [1056, 860],
     [1061, 861]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [958, 889]}],
   "box": [856, 879, 1061, 901],
   "polygons": [
    [
     [1061, 897],
     [1053, 901],
     [1021, 897],
     [1001, 897],
     [996, 901],
     [965, 897],
     [945, 897],
     [940, 901],
     [909, 897],
     [889, 897],
     [885, 901],
     [870, 900],
     [856, 897],
     [856, 880],
     [890, 879],
     [891, 881],
     [901, 882],
     [917, 879],
     [946, 879],
     [947, 881],
     [957, 882],
     [973, 879],
     [1001, 879],
     [1003, 882],
     [1015, 882],
     [1029, 879],
     [1061, 879]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [970, 907]}],
   "box": [856, 898, 1061, 920],
   "polygons": [
    [
     [1061, 916],
     [1029, 916],
     [1027, 918],
     [1005, 920],
     [997, 916],
     [982, 916],
     [975, 908],
     [857, 909],
     [856, 898],
     [873, 901],
     [879, 901],
     [889, 898],
     [917, 898],
     [919, 900],
     [929, 901],
     [935, 901],
     [944, 898],
     [973, 898],
     [975, 900],
     [985, 901],
     [991, 901],
     [1000, 898],
     [1028, 898],
     [1029, 900],
     [1047, 901],
     [1056, 898],
     [1061, 898]
    ],
    [
     [932, 913],
     [931, 916],
     [920, 916],
     [916, 918],
     [894, 920],
     [891, 918],
     [892, 913],
     [898, 912],
     [921, 914]
    ],
    [[977, 915], [971, 918], [948, 919], [938, 916], [937, 914], [967, 912]]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [964, 926]}],
   "box": [890, 917, 1061, 938],
   "polygons": [
    [
     [932, 934],
     [931, 938],
     [920, 937],
     [911, 934],
     [890, 934],
     [890, 920],
     [909, 919],
     [917, 917],
     [931, 917]
    ],
    [
     [977, 919],
     [976, 936],
     [965, 934],
     [945, 934],
     [943, 937],
     [937, 938],
     [938, 917],
     [946, 917],
     [949, 920],
     [973, 917],
     [976, 917]
    ],
    [
     [1061, 923],
     [1043, 923],
     [1040, 925],
     [1031, 923],
     [1019, 924],
     [1014, 926],
     [1013, 934],
     [1002, 934],
     [999, 935],
     [998, 938],
     [988, 938],
     [982, 935],
     [981, 917],
     [1001, 917],
     [1007, 920],
     [1029, 917],
     [1061, 917]
    ]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [973, 942]}],
   "box": [891, 935, 1011, 957],
   "polygons": [
    [[926, 938], [921, 940], [894, 940], [891, 935], [917, 935]],
    [[976, 938], [972, 940], [942, 940], [944, 935], [973, 935]],
    [
     [1011, 941],
     [1009, 957],
     [1004, 957],
     [995, 953],
     [985, 953],
     [983, 941],
     [984, 938],
     [991, 939],
     [1000, 935],
     [1009, 935],
     [1011, 937]
    ],
    [[931, 939], [928, 940], [928, 938]]
   ]
  },
  {
   "color": "#13d238ff",
   "pois": [{"id": "centroid", "point": [996, 964]}],
   "box": [981, 954, 1010, 976],
   "polygons": [
    [
     [1010, 971],
     [999, 972],
     [997, 976],
     [985, 975],
     [982, 969],
     [981, 961],
     [984, 954],
     [1001, 954],
     [1008, 958],
     [1010, 964]
    ]
   ]
  },
  {
   "color": "#13ce50ff",
   "pois": [{"id": "centroid", "point": [998, 984]}],
   "box": [983, 973, 1010, 995],
   "polygons": [
    [
     [1010, 993],
     [1009, 995],
     [1005, 995],
     [997, 991],
     [985, 991],
     [983, 987],
     [984, 977],
     [1000, 973],
     [1010, 973]
    ]
   ]
  },
  {
   "color": "#13ca68ff",
   "pois": [{"id": "centroid", "point": [996, 1002]}],
   "box": [982, 992, 1011, 1014],
   "polygons": [
    [
     [1011, 996],
     [1010, 1009],
     [999, 1009],
     [999, 1012],
     [997, 1014],
     [986, 1013],
     [982, 1009],
     [983, 992],
     [1001, 992]
    ]
   ]
  },
  {
   "color": "#13c680ff",
   "pois": [{"id": "centroid", "point": [998, 1021]}],
   "box": [982, 1010, 1011, 1033],
   "polygons": [
    [
     [1011, 1028],
     [1009, 1032],
     [1005, 1033],
     [997, 1028],
     [983, 1027],
     [982, 1023],
     [984, 1014],
     [999, 1013],
     [1000, 1010],
     [1010, 1010]
    ]
   ]
  }
 ],
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [955, 60]}],
   "box": [851, 20, 1060, 115],
   "polygons": [
    [
     [1060, 109],
     [1059, 115],
     [1056, 90],
     [1047, 89],
     [1023, 90],
     [1022, 92],
     [1004, 93],
     [987, 91],
     [967, 92],
     [966, 94],
     [943, 95],
     [933, 93],
     [921, 93],
     [911, 94],
     [910, 96],
     [887, 97],
     [877, 95],
     [851, 95],
     [851, 44],
     [867, 43],
     [868, 28],
     [870, 26],
     [876, 27],
     [966, 22],
     [978, 22],
     [979, 24],
     [985, 24],
     [1003, 21],
     [1027, 20],
     [1031, 22],
     [1039, 20],
     [1042, 21],
     [1042, 29],
     [1044, 35],
     [1055, 36],
     [1056, 39],
     [1060, 79]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [955, 103]}],
   "box": [851, 89, 1060, 134],
   "polygons": [
    [
     [1060, 133],
     [1058, 134],
     [1058, 122],
     [1056, 111],
     [1048, 109],
     [995, 108],
     [993, 110],
     [985, 111],
     [938, 110],
     [937, 112],
     [923, 113],
     [896, 111],
     [884, 112],
     [883, 114],
     [858, 115],
     [851, 112],
     [851, 96],
     [902, 97],
     [921, 94],
     [959, 95],
     [978, 92],
     [1013, 93],
     [1022, 91],
     [1055, 89]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [955, 121]}],
   "box": [851, 106, 1060, 156],
   "polygons": [
    [
     [1060, 144],
     [1059, 156],
     [1056, 130],
     [1049, 127],
     [1031, 127],
     [1022, 128],
     [1021, 130],
     [997, 131],
     [988, 129],
     [971, 129],
     [964, 132],
     [911, 131],
     [910, 133],
     [897, 134],
     [886, 134],
     [878, 132],
     [854, 133],
     [851, 132],
     [851, 113],
     [856, 113],
     [857, 115],
     [875, 115],
     [883, 113],
     [911, 112],
     [914, 114],
     [952, 110],
     [966, 110],
     [968, 112],
     [974, 112],
     [1012, 108],
     [1022, 108],
     [1025, 110],
     [1043, 110],
     [1055, 106]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [955, 140]}],
   "box": [851, 127, 1059, 170],
   "polygons": [
    [
     [1059, 170],
     [1056, 150],
     [1047, 147],
     [1024, 147],
     [1016, 145],
     [1007, 145],
     [995, 146],
     [993, 148],
     [941, 147],
     [933, 150],
     [884, 149],
     [882, 151],
     [857, 152],
     [851, 149],
     [851, 133],
     [885, 133],
     [887, 135],
     [932, 131],
     [940, 131],
     [941, 133],
     [953, 133],
     [967, 130],
     [1013, 131],
     [1026, 128],
     [1055, 127],
     [1059, 157]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [955, 159]}],
   "box": [851, 144, 1060, 192],
   "polygons": [
    [
     [1060, 192],
     [1058, 192],
     [1056, 168],
     [1049, 165],
     [1022, 165],
     [1018, 167],
     [986, 166],
     [944, 169],
     [917, 168],
     [907, 170],
     [851, 170],
     [851, 150],
     [860, 153],
     [905, 149],
     [911, 149],
     [914, 151],
     [929, 151],
     [939, 148],
     [986, 149],
     [1001, 146],
     [1022, 146],
     [1025, 148],
     [1042, 148],
     [1050, 146],
     [1051, 144],
     [1055, 144],
     [1060, 175]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [955, 177]}],
   "box": [851, 165, 1060, 210],
   "polygons": [
    [
     [1060, 202],
     [1059, 203],
     [1058, 201],
     [1056, 186],
     [1048, 184],
     [1025, 185],
     [1016, 183],
     [995, 183],
     [990, 186],
     [968, 186],
     [956, 184],
     [938, 185],
     [938, 187],
     [912, 188],
     [904, 186],
     [884, 186],
     [882, 188],
     [876, 189],
     [857, 189],
     [851, 187],
     [851, 171],
     [899, 171],
     [912, 169],
     [959, 169],
     [981, 167],
     [1004, 168],
     [1055, 165]
    ],
    [[1060, 208], [1059, 210], [1059, 205]]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [955, 196]}],
   "box": [851, 182, 1060, 228],
   "polygons": [
    [
     [1060, 226],
     [1059, 228],
     [1056, 205],
     [1047, 203],
     [1022, 203],
     [1021, 205],
     [1002, 206],
     [974, 204],
     [962, 207],
     [941, 207],
     [932, 205],
     [911, 206],
     [910, 208],
     [851, 207],
     [851, 188],
     [856, 188],
     [857, 190],
     [869, 190],
     [883, 187],
     [929, 188],
     [948, 185],
     [967, 185],
     [968, 187],
     [983, 187],
     [994, 184],
     [1021, 183],
     [1024, 185],
     [1042, 185],
     [1050, 182],
     [1055, 182],
     [1060, 217]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [955, 215]}],
   "box": [851, 203, 1060, 244],
   "polygons": [
    [
     [1060, 244],
     [1058, 242],
     [1057, 227],
     [1049, 222],
     [995, 221],
     [993, 223],
     [973, 224],
     [959, 222],
     [938, 222],
     [937, 224],
     [928, 225],
     [896, 223],
     [884, 224],
     [882, 226],
     [857, 226],
     [851, 224],
     [851, 208],
     [902, 209],
     [911, 207],
     [930, 206],
     [939, 206],
     [941, 208],
     [959, 208],
     [967, 205],
     [1013, 206],
     [1022, 204],
     [1055, 203],
     [1060, 234]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [955, 234]}],
   "box": [851, 220, 1060, 262],
   "polygons": [
    [
     [1060, 260],
     [1059, 262],
     [1056, 243],
     [1049, 240],
     [1025, 241],
     [1021, 243],
     [968, 242],
     [966, 244],
     [944, 245],
     [932, 243],
     [911, 243],
     [910, 245],
     [895, 246],
     [886, 246],
     [878, 244],
     [851, 244],
     [851, 225],
     [856, 225],
     [857, 227],
     [875, 227],
     [894, 224],
     [911, 224],
     [917, 226],
     [938, 223],
     [986, 224],
     [994, 222],
     [1012, 221],
     [1022, 221],
     [1025, 223],
     [1042, 223],
     [1055, 220],
     [1057, 236],
     [1060, 246]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [955, 252]}],
   "box": [851, 240, 1060, 278],
   "polygons": [
    [
     [1060, 271],
     [1059, 273],
     [1057, 270],
     [1057, 262],
     [1047, 260],
     [1023, 260],
     [1015, 258],
     [997, 259],
     [992, 261],
     [967, 261],
     [953, 259],
     [939, 260],
     [938, 262],
     [883, 261],
     [882, 263],
     [857, 263],
     [851, 261],
     [851, 245],
     [884, 245],
     [887, 247],
     [911, 244],
     [959, 245],
     [967, 243],
     [986, 242],
     [995, 242],
     [997, 244],
     [1013, 244],
     [1031, 241],
     [1052, 240],
     [1056, 242]
    ],
    [[1060, 278], [1058, 277], [1058, 275]]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [955, 271]}],
   "box": [851, 258, 1060, 295],
   "polygons": [
    [
     [1060, 293],
     [1059, 295],
     [1056, 280],
     [1047, 278],
     [1023, 278],
     [1022, 280],
     [1017, 281],
     [971, 279],
     [966, 282],
     [941, 282],
     [933, 280],
     [911, 281],
     [910, 283],
     [886, 283],
     [877, 281],
     [853, 282],
     [851, 281],
     [851, 262],
     [856, 262],
     [857, 264],
     [875, 264],
     [883, 262],
     [907, 261],
     [911, 261],
     [913, 263],
     [929, 263],
     [950, 260],
     [967, 260],
     [969, 262],
     [977, 262],
     [995, 259],
     [1023, 259],
     [1025, 261],
     [1041, 261],
     [1050, 258],
     [1055, 258],
     [1060, 281]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [955, 290]}],
   "box": [851, 278, 1059, 310],
   "polygons": [
    [
     [1059, 308],
     [1058, 310],
     [1057, 300],
     [1047, 298],
     [1024, 298],
     [1015, 296],
     [995, 296],
     [993, 298],
     [978, 299],
     [968, 299],
     [959, 297],
     [939, 297],
     [937, 299],
     [921, 300],
     [885, 298],
     [882, 300],
     [858, 301],
     [851, 298],
     [851, 282],
     [884, 282],
     [887, 284],
     [901, 284],
     [919, 281],
     [940, 281],
     [941, 283],
     [959, 283],
     [967, 280],
     [995, 280],
     [997, 282],
     [1003, 282],
     [1022, 279],
     [1055, 278],
     [1059, 297]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [955, 309]}],
   "box": [851, 295, 1060, 328],
   "polygons": [
    [
     [1060, 319],
     [1058, 328],
     [1056, 318],
     [1047, 316],
     [1023, 316],
     [1022, 318],
     [998, 319],
     [987, 317],
     [967, 317],
     [966, 319],
     [911, 318],
     [910, 320],
     [851, 319],
     [851, 299],
     [857, 301],
     [875, 301],
     [884, 299],
     [985, 299],
     [994, 297],
     [1022, 296],
     [1023, 298],
     [1041, 298],
     [1054, 295],
     [1056, 298]
    ]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [955, 327]}],
   "box": [851, 316, 1059, 348],
   "polygons": [
    [
     [1059, 348],
     [1056, 336],
     [1047, 335],
     [995, 334],
     [993, 336],
     [968, 336],
     [957, 334],
     [939, 335],
     [937, 337],
     [885, 335],
     [879, 338],
     [857, 338],
     [851, 336],
     [851, 320],
     [885, 319],
     [887, 321],
     [903, 321],
     [911, 319],
     [938, 318],
     [941, 320],
     [959, 320],
     [967, 318],
     [993, 317],
     [999, 319],
     [1013, 319],
     [1022, 317],
     [1055, 316],
     [1059, 333]
    ]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [955, 346]}],
   "box": [851, 333, 1060, 368],
   "polygons": [
    [
     [1060, 367],
     [1059, 368],
     [1058, 366],
     [1056, 356],
     [1049, 354],
     [1023, 354],
     [1021, 356],
     [996, 356],
     [981, 354],
     [970, 355],
     [965, 357],
     [911, 355],
     [910, 357],
     [899, 358],
     [886, 358],
     [877, 356],
     [851, 356],
     [851, 337],
     [856, 337],
     [857, 339],
     [875, 339],
     [885, 336],
     [911, 336],
     [913, 338],
     [929, 338],
     [943, 335],
     [967, 335],
     [969, 337],
     [985, 337],
     [1002, 334],
     [1022, 334],
     [1023, 336],
     [1041, 336],
     [1055, 333],
     [1060, 350]
    ]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [955, 365]}],
   "box": [851, 354, 1060, 381],
   "polygons": [
    [
     [1060, 381],
     [1058, 381],
     [1056, 374],
     [1023, 373],
     [1015, 371],
     [995, 371],
     [991, 374],
     [939, 372],
     [938, 374],
     [883, 373],
     [882, 375],
     [857, 375],
     [851, 373],
     [851, 357],
     [903, 358],
     [911, 356],
     [940, 356],
     [941, 358],
     [955, 358],
     [967, 355],
     [995, 355],
     [997, 357],
     [1015, 357],
     [1032, 354],
     [1055, 354],
     [1058, 364]
    ]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [955, 384]}],
   "box": [851, 371, 1060, 401],
   "polygons": [
    [
     [1060, 397],
     [1059, 401],
     [1056, 392],
     [1047, 391],
     [1033, 391],
     [1021, 394],
     [996, 394],
     [987, 392],
     [967, 392],
     [966, 394],
     [941, 394],
     [933, 392],
     [911, 393],
     [910, 395],
     [851, 393],
     [851, 374],
     [856, 374],
     [857, 376],
     [875, 376],
     [883, 374],
     [909, 373],
     [913, 375],
     [929, 375],
     [938, 373],
     [985, 374],
     [994, 372],
     [1022, 372],
     [1023, 374],
     [1041, 374],
     [1054, 371],
     [1056, 373],
     [1059, 384]
    ]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [955, 402]}],
   "box": [851, 392, 1060, 417],
   "polygons": [
    [
     [1060, 413],
     [1059, 417],
     [1057, 412],
     [1025, 411],
     [1015, 409],
     [995, 409],
     [993, 411],
     [967, 411],
     [957, 409],
     [938, 410],
     [937, 412],
     [912, 412],
     [903, 410],
     [885, 410],
     [882, 412],
     [857, 412],
     [851, 410],
     [851, 394],
     [885, 394],
     [887, 396],
     [903, 396],
     [927, 393],
     [940, 393],
     [941, 395],
     [959, 395],
     [967, 393],
     [995, 393],
     [995, 395],
     [999, 395],
     [1022, 392],
     [1055, 392],
     [1060, 404]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [955, 421]}],
   "box": [851, 409, 1060, 437],
   "polygons": [
    [
     [1060, 433],
     [1059, 437],
     [1057, 431],
     [1047, 429],
     [1023, 429],
     [1021, 431],
     [967, 430],
     [966, 432],
     [941, 432],
     [933, 430],
     [911, 430],
     [910, 432],
     [886, 432],
     [877, 430],
     [855, 431],
     [851, 430],
     [851, 411],
     [856, 411],
     [857, 413],
     [875, 413],
     [884, 411],
     [911, 411],
     [913, 413],
     [921, 413],
     [939, 410],
     [966, 410],
     [967, 412],
     [985, 412],
     [994, 410],
     [1042, 411],
     [1055, 409],
     [1060, 420]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [955, 440]}],
   "box": [851, 429, 1060, 453],
   "polygons": [
    [
     [1060, 444],
     [1059, 453],
     [1049, 448],
     [1023, 449],
     [1015, 447],
     [995, 447],
     [991, 449],
     [968, 449],
     [959, 447],
     [939, 447],
     [937, 449],
     [912, 449],
     [903, 447],
     [885, 447],
     [883, 449],
     [857, 449],
     [851, 447],
     [851, 431],
     [885, 431],
     [887, 433],
     [903, 433],
     [911, 431],
     [940, 431],
     [941, 433],
     [959, 433],
     [967, 431],
     [987, 430],
     [995, 430],
     [997, 432],
     [1013, 432],
     [1022, 430],
     [1055, 429],
     [1060, 440]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [955, 458]}],
   "box": [851, 447, 1060, 471],
   "polygons": [
    [
     [1060, 465],
     [1059, 471],
     [1056, 468],
     [1047, 467],
     [1023, 467],
     [1021, 469],
     [996, 469],
     [987, 467],
     [967, 467],
     [966, 469],
     [941, 469],
     [933, 467],
     [921, 467],
     [901, 470],
     [886, 470],
     [877, 468],
     [851, 468],
     [851, 448],
     [855, 448],
     [857, 450],
     [875, 450],
     [883, 448],
     [911, 448],
     [913, 450],
     [929, 450],
     [938, 448],
     [967, 448],
     [971, 450],
     [1000, 447],
     [1022, 447],
     [1025, 449],
     [1041, 449],
     [1055, 447],
     [1060, 457]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [955, 477]}],
   "box": [851, 467, 1060, 487],
   "polygons": [
    [
     [1060, 483],
     [1059, 487],
     [1023, 486],
     [1015, 484],
     [995, 484],
     [993, 486],
     [968, 486],
     [959, 484],
     [949, 484],
     [936, 487],
     [912, 487],
     [903, 485],
     [885, 485],
     [883, 487],
     [857, 487],
     [851, 485],
     [851, 469],
     [903, 470],
     [911, 468],
     [940, 468],
     [941, 470],
     [959, 470],
     [967, 468],
     [995, 468],
     [997, 470],
     [1013, 470],
     [1022, 468],
     [1050, 467],
     [1055, 468],
     [1059, 472]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [956, 496]}],
   "box": [851, 485, 1060, 507],
   "polygons": [
    [
     [1060, 504],
     [1059, 507],
     [1049, 505],
     [1025, 505],
     [1021, 507],
     [1001, 507],
     [987, 505],
     [967, 505],
     [966, 507],
     [940, 507],
     [931, 505],
     [911, 505],
     [910, 507],
     [851, 505],
     [851, 486],
     [856, 486],
     [857, 488],
     [875, 488],
     [884, 486],
     [931, 487],
     [939, 485],
     [967, 485],
     [969, 487],
     [985, 487],
     [994, 485],
     [1023, 485],
     [1025, 487],
     [1041, 487],
     [1055, 485],
     [1060, 492]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [956, 515]}],
   "box": [851, 505, 1060, 524],
   "polygons": [
    [
     [1060, 523],
     [1023, 524],
     [1015, 522],
     [995, 522],
     [993, 524],
     [967, 524],
     [959, 522],
     [939, 522],
     [938, 524],
     [912, 524],
     [903, 522],
     [883, 522],
     [882, 524],
     [857, 524],
     [851, 522],
     [851, 506],
     [885, 506],
     [887, 508],
     [903, 508],
     [911, 506],
     [940, 506],
     [941, 508],
     [953, 508],
     [982, 505],
     [997, 507],
     [1015, 507],
     [1023, 505],
     [1054, 505],
     [1060, 508]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [955, 533]}],
   "box": [851, 523, 1060, 544],
   "polygons": [
    [
     [1060, 535],
     [1059, 543],
     [1023, 542],
     [1021, 544],
     [996, 544],
     [987, 542],
     [967, 542],
     [966, 544],
     [941, 544],
     [933, 542],
     [911, 542],
     [910, 544],
     [886, 544],
     [877, 542],
     [851, 542],
     [851, 523],
     [856, 523],
     [857, 525],
     [875, 525],
     [883, 523],
     [911, 523],
     [913, 525],
     [931, 525],
     [939, 523],
     [966, 523],
     [967, 525],
     [985, 525],
     [994, 523],
     [1022, 523],
     [1023, 525],
     [1033, 525],
     [1058, 523],
     [1060, 524]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [955, 552]}],
   "box": [851, 543, 1060, 562],
   "polygons": [
    [
     [1060, 555],
     [1057, 559],
     [1047, 562],
     [1023, 562],
     [1017, 560],
     [995, 560],
     [991, 562],
     [980, 562],
     [959, 559],
     [939, 559],
     [938, 561],
     [931, 562],
     [912, 561],
     [903, 559],
     [883, 559],
     [882, 561],
     [857, 561],
     [851, 559],
     [851, 543],
     [885, 543],
     [887, 545],
     [903, 545],
     [911, 543],
     [940, 543],
     [941, 545],
     [959, 545],
     [967, 543],
     [995, 543],
     [997, 545],
     [1013, 545],
     [1022, 543],
     [1058, 543],
     [1060, 548]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [955, 571]}],
   "box": [851, 558, 1060, 582],
   "polygons": [
    [
     [1060, 570],
     [1059, 577],
     [1055, 580],
     [1024, 580],
     [1021, 582],
     [996, 582],
     [987, 580],
     [967, 580],
     [966, 582],
     [941, 582],
     [933, 580],
     [911, 580],
     [910, 582],
     [887, 582],
     [879, 579],
     [851, 579],
     [851, 560],
     [856, 560],
     [857, 562],
     [875, 562],
     [883, 560],
     [911, 560],
     [913, 562],
     [929, 562],
     [938, 560],
     [966, 560],
     [967, 562],
     [985, 563],
     [994, 560],
     [1020, 560],
     [1026, 562],
     [1042, 562],
     [1051, 561],
     [1059, 558]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [955, 590]}],
   "box": [851, 578, 1060, 600],
   "polygons": [
    [
     [1060, 590],
     [1056, 597],
     [1047, 600],
     [1033, 600],
     [1015, 597],
     [995, 597],
     [990, 600],
     [967, 599],
     [959, 597],
     [939, 597],
     [938, 599],
     [912, 599],
     [903, 597],
     [862, 599],
     [851, 596],
     [851, 580],
     [884, 580],
     [887, 583],
     [914, 580],
     [940, 581],
     [941, 583],
     [959, 583],
     [967, 581],
     [995, 581],
     [997, 583],
     [1013, 583],
     [1022, 581],
     [1051, 581],
     [1059, 578]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [955, 608]}],
   "box": [851, 593, 1060, 620],
   "polygons": [
    [
     [1060, 610],
     [1056, 618],
     [1023, 618],
     [1021, 620],
     [996, 620],
     [979, 617],
     [967, 617],
     [966, 619],
     [958, 620],
     [942, 620],
     [933, 617],
     [911, 617],
     [910, 619],
     [851, 617],
     [851, 597],
     [856, 597],
     [857, 599],
     [865, 600],
     [883, 599],
     [883, 597],
     [911, 598],
     [913, 600],
     [931, 600],
     [939, 598],
     [967, 598],
     [969, 600],
     [985, 600],
     [994, 598],
     [1022, 598],
     [1023, 600],
     [1041, 601],
     [1057, 597],
     [1059, 593]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [955, 627]}],
   "box": [851, 615, 1060, 638],
   "polygons": [
    [
     [1060, 628],
     [1055, 635],
     [1047, 638],
     [1023, 637],
     [1015, 635],
     [995, 635],
     [993, 637],
     [968, 637],
     [959, 634],
     [939, 634],
     [936, 637],
     [922, 637],
     [903, 634],
     [885, 634],
     [882, 636],
     [857, 636],
     [851, 634],
     [851, 618],
     [884, 618],
     [887, 620],
     [903, 620],
     [911, 618],
     [940, 618],
     [941, 620],
     [959, 620],
     [967, 618],
     [994, 618],
     [997, 621],
     [1013, 621],
     [1022, 618],
     [1049, 619],
     [1059, 615]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [955, 646]}],
   "box": [851, 631, 1060, 658],
   "polygons": [
    [
     [1060, 644],
     [1055, 656],
     [1027, 655],
     [1018, 658],
     [997, 658],
     [987, 655],
     [967, 655],
     [966, 657],
     [941, 657],
     [935, 655],
     [911, 654],
     [910, 656],
     [905, 657],
     [886, 657],
     [877, 654],
     [851, 654],
     [851, 635],
     [856, 635],
     [857, 637],
     [875, 637],
     [884, 635],
     [911, 635],
     [913, 637],
     [930, 637],
     [938, 635],
     [967, 635],
     [971, 638],
     [985, 638],
     [1000, 635],
     [1022, 636],
     [1023, 638],
     [1043, 638],
     [1056, 635],
     [1059, 631]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [955, 665]}],
   "box": [851, 647, 1060, 676],
   "polygons": [
    [
     [1060, 662],
     [1055, 673],
     [1047, 676],
     [997, 672],
     [991, 675],
     [975, 675],
     [959, 672],
     [939, 672],
     [938, 674],
     [931, 675],
     [914, 674],
     [903, 671],
     [885, 671],
     [880, 674],
     [857, 673],
     [851, 671],
     [851, 655],
     [885, 655],
     [885, 657],
     [887, 658],
     [911, 655],
     [938, 655],
     [941, 658],
     [959, 658],
     [967, 656],
     [995, 656],
     [1001, 658],
     [1013, 658],
     [1023, 656],
     [1047, 657],
     [1056, 655],
     [1059, 647]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [955, 683]}],
   "box": [851, 663, 1060, 696],
   "polygons": [
    [
     [1060, 671],
     [1058, 685],
     [1054, 694],
     [1023, 693],
     [1021, 695],
     [999, 696],
     [996, 694],
     [967, 692],
     [965, 695],
     [941, 695],
     [933, 692],
     [911, 692],
     [910, 694],
     [886, 694],
     [877, 691],
     [851, 691],
     [851, 672],
     [856, 672],
     [857, 674],
     [871, 675],
     [884, 672],
     [911, 672],
     [915, 675],
     [929, 675],
     [938, 673],
     [966, 673],
     [969, 675],
     [983, 676],
     [993, 675],
     [994, 673],
     [1021, 673],
     [1023, 676],
     [1042, 676],
     [1056, 673],
     [1058, 663]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [955, 702]}],
   "box": [851, 684, 1060, 714],
   "polygons": [
    [
     [1060, 695],
     [1055, 711],
     [1047, 714],
     [1027, 713],
     [1015, 710],
     [995, 710],
     [990, 713],
     [983, 713],
     [949, 709],
     [939, 709],
     [936, 712],
     [921, 712],
     [903, 709],
     [883, 709],
     [882, 711],
     [864, 711],
     [851, 708],
     [851, 692],
     [885, 692],
     [887, 695],
     [903, 695],
     [911, 693],
     [940, 693],
     [941, 696],
     [961, 695],
     [967, 693],
     [988, 693],
     [995, 694],
     [997, 696],
     [1013, 696],
     [1023, 694],
     [1056, 693],
     [1059, 684]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [955, 721]}],
   "box": [851, 701, 1060, 734],
   "polygons": [
    [
     [1060, 712],
     [1055, 732],
     [1023, 731],
     [1021, 733],
     [999, 734],
     [987, 730],
     [967, 730],
     [966, 732],
     [943, 733],
     [941, 731],
     [933, 729],
     [911, 729],
     [910, 731],
     [886, 732],
     [879, 729],
     [871, 728],
     [851, 728],
     [851, 709],
     [856, 709],
     [857, 711],
     [865, 712],
     [883, 711],
     [884, 709],
     [895, 709],
     [927, 713],
     [938, 710],
     [967, 710],
     [969, 713],
     [991, 713],
     [994, 711],
     [1022, 711],
     [1023, 713],
     [1041, 714],
     [1056, 711],
     [1059, 701]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [955, 740]}],
   "box": [851, 718, 1060, 752],
   "polygons": [
    [
     [1060, 729],
     [1055, 749],
     [1047, 752],
     [1026, 751],
     [1015, 748],
     [995, 748],
     [991, 751],
     [987, 751],
     [970, 750],
     [959, 747],
     [939, 747],
     [937, 749],
     [932, 750],
     [915, 749],
     [903, 746],
     [883, 746],
     [882, 748],
     [874, 749],
     [859, 748],
     [851, 745],
     [851, 729],
     [877, 729],
     [885, 730],
     [887, 733],
     [911, 730],
     [940, 730],
     [941, 733],
     [959, 733],
     [967, 731],
     [995, 731],
     [995, 733],
     [997, 734],
     [1013, 734],
     [1022, 732],
     [1056, 731],
     [1059, 718]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [955, 758]}],
   "box": [851, 737, 1060, 769],
   "polygons": [
    [
     [1060, 739],
     [1055, 769],
     [942, 769],
     [933, 767],
     [851, 766],
     [851, 746],
     [856, 746],
     [859, 749],
     [881, 749],
     [883, 747],
     [911, 747],
     [915, 750],
     [938, 749],
     [940, 747],
     [967, 748],
     [973, 751],
     [985, 751],
     [994, 748],
     [1002, 748],
     [1023, 749],
     [1025, 751],
     [1041, 752],
     [1049, 751],
     [1050, 749],
     [1057, 747],
     [1058, 738],
     [1059, 737]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [955, 777]}],
   "box": [851, 754, 1060, 789],
   "polygons": [
    [
     [1060, 764],
     [1055, 786],
     [1048, 789],
     [1024, 788],
     [1022, 786],
     [1013, 785],
     [995, 785],
     [991, 788],
     [980, 788],
     [967, 787],
     [959, 784],
     [939, 784],
     [936, 787],
     [924, 787],
     [912, 786],
     [903, 783],
     [883, 783],
     [880, 786],
     [859, 785],
     [851, 783],
     [851, 767],
     [858, 766],
     [887, 768],
     [920, 767],
     [941, 769],
     [978, 768],
     [997, 770],
     [1047, 770],
     [1056, 769],
     [1059, 754]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [955, 795]}],
   "box": [851, 768, 1060, 807],
   "polygons": [
    [
     [1060, 773],
     [1055, 806],
     [1024, 805],
     [1018, 807],
     [997, 807],
     [988, 804],
     [942, 806],
     [941, 804],
     [914, 802],
     [911, 802],
     [910, 804],
     [887, 805],
     [872, 801],
     [851, 801],
     [851, 784],
     [853, 783],
     [860, 786],
     [872, 787],
     [883, 784],
     [911, 784],
     [914, 787],
     [926, 788],
     [938, 785],
     [966, 785],
     [968, 788],
     [983, 789],
     [992, 788],
     [994, 786],
     [1021, 786],
     [1025, 789],
     [1042, 790],
     [1056, 785],
     [1059, 768]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [955, 814]}],
   "box": [851, 789, 1060, 827],
   "polygons": [
    [
     [1060, 798],
     [1055, 824],
     [1047, 827],
     [1027, 826],
     [1015, 823],
     [995, 823],
     [990, 826],
     [984, 826],
     [959, 822],
     [946, 822],
     [932, 825],
     [890, 820],
     [884, 820],
     [881, 823],
     [858, 822],
     [851, 820],
     [851, 802],
     [876, 802],
     [885, 803],
     [887, 806],
     [911, 803],
     [940, 804],
     [944, 807],
     [967, 804],
     [995, 805],
     [998, 808],
     [1022, 805],
     [1049, 806],
     [1056, 803],
     [1059, 789]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [955, 833]}],
   "box": [851, 804, 1060, 845],
   "polygons": [
    [
     [1060, 814],
     [1055, 844],
     [1022, 842],
     [1021, 844],
     [998, 845],
     [997, 843],
     [980, 841],
     [942, 844],
     [932, 840],
     [911, 840],
     [907, 842],
     [888, 843],
     [878, 839],
     [851, 839],
     [851, 821],
     [872, 824],
     [883, 821],
     [901, 821],
     [923, 825],
     [938, 824],
     [938, 822],
     [951, 822],
     [967, 823],
     [968, 825],
     [977, 826],
     [986, 826],
     [994, 824],
     [1022, 824],
     [1028, 827],
     [1041, 827],
     [1056, 824],
     [1059, 804]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [955, 851]}],
   "box": [851, 823, 1059, 865],
   "polygons": [
    [
     [1059, 838],
     [1055, 862],
     [1046, 865],
     [998, 860],
     [995, 860],
     [989, 864],
     [969, 862],
     [959, 859],
     [938, 859],
     [935, 862],
     [928, 862],
     [904, 858],
     [887, 858],
     [876, 861],
     [857, 859],
     [851, 857],
     [851, 840],
     [858, 839],
     [885, 840],
     [885, 842],
     [887, 843],
     [911, 841],
     [940, 841],
     [941, 844],
     [993, 842],
     [998, 846],
     [1022, 843],
     [1048, 844],
     [1056, 842],
     [1059, 823]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [955, 870]}],
   "box": [851, 841, 1060, 883],
   "polygons": [
    [
     [1060, 854],
     [1056, 869],
     [1056, 881],
     [1025, 880],
     [1022, 882],
     [997, 883],
     [989, 879],
     [941, 881],
     [941, 879],
     [935, 878],
     [911, 877],
     [910, 879],
     [887, 880],
     [878, 876],
     [851, 876],
     [851, 858],
     [866, 861],
     [883, 860],
     [883, 858],
     [911, 859],
     [917, 862],
     [929, 863],
     [938, 860],
     [966, 860],
     [971, 863],
     [980, 864],
     [994, 863],
     [994, 861],
     [1008, 861],
     [1022, 862],
     [1031, 865],
     [1041, 865],
     [1051, 863],
     [1056, 860],
     [1059, 841]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [955, 889]}],
   "box": [851, 856, 1060, 903],
   "polygons": [
    [
     [1060, 866],
     [1055, 900],
     [1047, 903],
     [1007, 898],
     [995, 898],
     [990, 902],
     [959, 897],
     [938, 896],
     [938, 898],
     [935, 900],
     [931, 900],
     [912, 898],
     [904, 895],
     [884, 895],
     [880, 898],
     [870, 898],
     [851, 894],
     [851, 877],
     [885, 877],
     [887, 881],
     [911, 878],
     [932, 878],
     [940, 879],
     [941, 882],
     [953, 882],
     [974, 879],
     [995, 880],
     [996, 882],
     [1015, 883],
     [1023, 881],
     [1047, 881],
     [1056, 878],
     [1059, 856]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [966, 907]}],
   "box": [851, 873, 1060, 921],
   "polygons": [
    [
     [1060, 884],
     [1055, 919],
     [1023, 918],
     [1021, 920],
     [998, 921],
     [991, 917],
     [979, 916],
     [967, 916],
     [966, 918],
     [960, 918],
     [957, 910],
     [953, 909],
     [891, 907],
     [879, 908],
     [852, 906],
     [851, 904],
     [851, 895],
     [875, 899],
     [883, 896],
     [910, 896],
     [915, 899],
     [923, 900],
     [938, 899],
     [938, 897],
     [949, 897],
     [967, 898],
     [983, 902],
     [987, 902],
     [994, 899],
     [1022, 899],
     [1023, 901],
     [1027, 902],
     [1041, 903],
     [1056, 899],
     [1059, 873]
    ],
    [[910, 916], [886, 917], [877, 913], [871, 913], [871, 911], [906, 912], [910, 913]],
    [[955, 917], [953, 919], [942, 919], [933, 915], [919, 915], [917, 913], [953, 913]]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [949, 925]}],
   "box": [870, 898, 1060, 939],
   "polygons": [
    [
     [1060, 901],
     [1057, 922],
     [1055, 927],
     [997, 925],
     [992, 930],
     [989, 938],
     [986, 939],
     [967, 937],
     [962, 935],
     [961, 932],
     [960, 919],
     [967, 917],
     [989, 917],
     [995, 918],
     [999, 921],
     [1015, 921],
     [1022, 918],
     [1049, 919],
     [1056, 912],
     [1059, 898]
    ],
    [
     [912, 930],
     [910, 933],
     [883, 932],
     [879, 936],
     [872, 935],
     [870, 933],
     [871, 914],
     [881, 914],
     [885, 915],
     [885, 917],
     [887, 918],
     [911, 917]
    ],
    [
     [954, 922],
     [948, 934],
     [937, 934],
     [937, 936],
     [934, 938],
     [916, 935],
     [918, 915],
     [940, 916],
     [943, 920],
     [951, 919]
    ]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [959, 942]}],
   "box": [880, 908, 1060, 956],
   "polygons": [
    [[1060, 919], [1059, 925], [1057, 926], [1059, 908]],
    [[911, 934], [909, 938], [904, 939], [887, 938], [880, 936], [883, 933]],
    [[948, 937], [947, 939], [937, 938], [938, 935], [947, 935]],
    [
     [987, 943],
     [986, 951],
     [983, 954],
     [969, 954],
     [961, 956],
     [960, 935],
     [966, 935],
     [987, 940]
    ],
    [[926, 938], [918, 939], [917, 937]],
    [[931, 939], [928, 939]]
   ]
  },
  {
   "color": "#13d238ff",
   "pois": [{"id": "centroid", "point": [971, 964]}],
   "box": [961, 955, 982, 976],
   "polygons": [
    [[982, 966], [979, 967], [978, 976], [961, 972], [961, 957], [967, 955], [981, 955]]
   ]
  },
  {
   "color": "#13ce50ff",
   "pois": [{"id": "centroid", "point": [968, 983]}],
   "box": [960, 973, 978, 993],
   "polygons": [
    [[978, 983], [976, 991], [967, 991], [966, 993], [960, 993], [960, 973], [967, 973], [976, 976]]
   ]
  },
  {
   "color": "#13ca68ff",
   "pois": [{"id": "centroid", "point": [969, 1002]}],
   "box": [961, 992, 978, 1013],
   "polygons": [
    [[978, 1007], [977, 1013], [968, 1012], [962, 1009], [961, 995], [967, 992], [975, 992]]
   ]
  },
  {
   "color": "#13c680ff",
   "pois": [{"id": "centroid", "point": [968, 1021]}],
   "box": [961, 1010, 978, 1031],
   "polygons": [[[978, 1021], [975, 1029], [961, 1031], [961, 1010], [977, 1014]]]
  }
 ],
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [956, 61]}],
   "box": [848, 17, 1065, 121],
   "polygons": [
    [
     [1065, 107],
     [1064, 121],
     [1062, 120],
     [1051, 92],
     [1049, 89],
     [1041, 86],
     [1015, 88],
     [1014, 90],
     [1006, 91],
     [967, 91],
     [961, 92],
     [959, 94],
     [935, 96],
     [927, 94],
     [909, 95],
     [905, 98],
     [891, 99],
     [848, 99],
     [848, 48],
     [865, 47],
     [866, 31],
     [868, 30],
     [914, 26],
     [933, 27],
     [949, 23],
     [985, 20],
     [998, 21],
     [1033, 17],
     [1037, 19],
     [1040, 27],
     [1049, 33],
     [1062, 66],
     [1064, 73]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [956, 103]}],
   "box": [848, 86, 1064, 136],
   "polygons": [
    [
     [1064, 136],
     [1062, 136],
     [1057, 126],
     [1052, 111],
     [1049, 108],
     [1040, 106],
     [998, 106],
     [989, 107],
     [983, 110],
     [938, 110],
     [932, 113],
     [896, 113],
     [881, 114],
     [877, 117],
     [854, 118],
     [848, 116],
     [848, 100],
     [879, 98],
     [881, 100],
     [899, 99],
     [906, 98],
     [909, 96],
     [941, 96],
     [955, 95],
     [964, 92],
     [980, 91],
     [1001, 92],
     [1022, 88],
     [1048, 86],
     [1053, 99],
     [1064, 120]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [956, 122]}],
   "box": [848, 103, 1064, 155],
   "polygons": [
    [
     [1064, 155],
     [1061, 152],
     [1049, 126],
     [1040, 124],
     [990, 130],
     [977, 128],
     [962, 129],
     [957, 132],
     [914, 132],
     [905, 135],
     [893, 136],
     [848, 136],
     [848, 117],
     [853, 117],
     [854, 119],
     [872, 118],
     [879, 115],
     [914, 115],
     [952, 110],
     [974, 111],
     [988, 108],
     [1011, 106],
     [1015, 106],
     [1016, 108],
     [1037, 107],
     [1045, 103],
     [1048, 103],
     [1064, 137]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [956, 141]}],
   "box": [848, 124, 1064, 172],
   "polygons": [
    [
     [1064, 169],
     [1063, 172],
     [1051, 147],
     [1042, 144],
     [995, 144],
     [977, 148],
     [961, 149],
     [947, 147],
     [935, 148],
     [927, 151],
     [907, 152],
     [896, 150],
     [881, 151],
     [878, 152],
     [877, 154],
     [854, 155],
     [848, 153],
     [848, 137],
     [880, 135],
     [881, 137],
     [887, 137],
     [927, 132],
     [934, 132],
     [935, 134],
     [953, 133],
     [960, 130],
     [988, 128],
     [995, 130],
     [1048, 124],
     [1064, 156]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [956, 159]}],
   "box": [848, 141, 1064, 190],
   "polygons": [
    [
     [1064, 190],
     [1060, 186],
     [1049, 165],
     [1040, 163],
     [1025, 163],
     [1002, 166],
     [968, 166],
     [947, 169],
     [917, 169],
     [895, 172],
     [848, 173],
     [848, 154],
     [853, 154],
     [854, 156],
     [871, 155],
     [880, 152],
     [896, 151],
     [923, 152],
     [933, 149],
     [968, 149],
     [1010, 144],
     [1022, 146],
     [1037, 145],
     [1048, 141],
     [1064, 173]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [956, 178]}],
   "box": [848, 162, 1064, 206],
   "polygons": [
    [
     [1064, 206],
     [1062, 206],
     [1054, 192],
     [1051, 183],
     [1017, 183],
     [1007, 181],
     [989, 182],
     [985, 185],
     [935, 185],
     [932, 188],
     [881, 188],
     [878, 191],
     [854, 192],
     [848, 190],
     [848, 174],
     [887, 173],
     [934, 169],
     [941, 170],
     [986, 166],
     [1010, 166],
     [1048, 162],
     [1064, 192]
    ]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [956, 197]}],
   "box": [848, 180, 1064, 226],
   "polygons": [
    [
     [1064, 225],
     [1062, 226],
     [1060, 223],
     [1049, 201],
     [1022, 201],
     [1014, 204],
     [996, 205],
     [965, 204],
     [955, 207],
     [938, 208],
     [923, 206],
     [908, 207],
     [899, 210],
     [848, 210],
     [848, 191],
     [872, 192],
     [879, 191],
     [880, 189],
     [926, 189],
     [933, 188],
     [934, 186],
     [977, 186],
     [986, 185],
     [988, 183],
     [1005, 182],
     [1034, 183],
     [1043, 182],
     [1043, 180],
     [1048, 180],
     [1064, 208]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [956, 216]}],
   "box": [848, 200, 1064, 242],
   "polygons": [
    [
     [1064, 240],
     [1063, 242],
     [1059, 237],
     [1051, 222],
     [1042, 220],
     [1016, 221],
     [1007, 219],
     [989, 220],
     [981, 223],
     [944, 222],
     [935, 223],
     [932, 225],
     [884, 225],
     [878, 228],
     [854, 229],
     [848, 227],
     [848, 211],
     [896, 211],
     [906, 208],
     [950, 208],
     [963, 205],
     [980, 204],
     [1006, 205],
     [1015, 202],
     [1048, 200],
     [1058, 219],
     [1064, 226]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [956, 234]}],
   "box": [848, 217, 1065, 261],
   "polygons": [
    [
     [1065, 248],
     [1064, 260],
     [1062, 261],
     [1054, 249],
     [1054, 246],
     [1051, 244],
     [1049, 240],
     [1041, 239],
     [1019, 239],
     [1012, 242],
     [989, 243],
     [974, 241],
     [948, 245],
     [908, 244],
     [903, 247],
     [848, 247],
     [848, 228],
     [872, 229],
     [882, 226],
     [902, 225],
     [911, 227],
     [942, 223],
     [974, 224],
     [987, 221],
     [1001, 220],
     [1034, 221],
     [1048, 217],
     [1064, 242]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [956, 253]}],
   "box": [848, 238, 1065, 278],
   "polygons": [
    [
     [1065, 266],
     [1064, 278],
     [1051, 261],
     [1042, 258],
     [1016, 259],
     [998, 257],
     [975, 261],
     [961, 261],
     [953, 259],
     [935, 260],
     [927, 263],
     [884, 262],
     [878, 265],
     [854, 266],
     [848, 264],
     [848, 248],
     [898, 248],
     [907, 245],
     [934, 244],
     [935, 246],
     [938, 246],
     [970, 242],
     [1004, 243],
     [1013, 242],
     [1015, 240],
     [1048, 238],
     [1064, 262]
    ]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [956, 272]}],
   "box": [848, 256, 1065, 296],
   "polygons": [
    [
     [1065, 281],
     [1064, 296],
     [1062, 296],
     [1051, 280],
     [1041, 276],
     [1017, 277],
     [1014, 280],
     [961, 279],
     [959, 280],
     [959, 282],
     [911, 281],
     [905, 284],
     [848, 284],
     [848, 265],
     [871, 266],
     [884, 263],
     [906, 262],
     [907, 264],
     [919, 264],
     [929, 263],
     [933, 261],
     [952, 260],
     [960, 260],
     [961, 262],
     [965, 262],
     [1015, 257],
     [1019, 259],
     [1035, 259],
     [1043, 256],
     [1048, 256],
     [1058, 271],
     [1064, 277]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [956, 290]}],
   "box": [848, 276, 1064, 312],
   "polygons": [
    [
     [1064, 312],
     [1054, 302],
     [1054, 300],
     [1051, 297],
     [1042, 296],
     [1018, 297],
     [1010, 295],
     [992, 295],
     [986, 298],
     [961, 299],
     [953, 297],
     [941, 297],
     [932, 300],
     [909, 301],
     [899, 299],
     [887, 299],
     [878, 302],
     [854, 303],
     [848, 301],
     [848, 285],
     [899, 285],
     [911, 282],
     [934, 281],
     [935, 283],
     [952, 283],
     [960, 280],
     [981, 279],
     [988, 279],
     [989, 281],
     [998, 281],
     [1016, 278],
     [1048, 276],
     [1063, 296]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [956, 309]}],
   "box": [848, 294, 1065, 330],
   "polygons": [
    [
     [1065, 318],
     [1064, 330],
     [1062, 330],
     [1050, 316],
     [1041, 315],
     [1017, 315],
     [1015, 317],
     [1002, 318],
     [990, 318],
     [979, 316],
     [961, 317],
     [959, 319],
     [946, 320],
     [919, 318],
     [907, 319],
     [905, 321],
     [885, 322],
     [873, 320],
     [848, 321],
     [848, 302],
     [852, 301],
     [855, 303],
     [871, 303],
     [886, 300],
     [927, 301],
     [936, 298],
     [981, 299],
     [990, 296],
     [1015, 295],
     [1017, 297],
     [1035, 297],
     [1043, 294],
     [1049, 294],
     [1064, 313]
    ]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [956, 328]}],
   "box": [848, 315, 1064, 347],
   "polygons": [
    [
     [1064, 347],
     [1062, 347],
     [1054, 339],
     [1053, 336],
     [1042, 334],
     [987, 333],
     [986, 335],
     [981, 336],
     [933, 335],
     [932, 337],
     [916, 338],
     [889, 336],
     [879, 337],
     [878, 339],
     [854, 339],
     [848, 337],
     [848, 322],
     [897, 322],
     [918, 319],
     [951, 320],
     [974, 317],
     [1007, 318],
     [1026, 315],
     [1048, 315],
     [1064, 332]
    ]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [956, 346]}],
   "box": [848, 332, 1064, 367],
   "polygons": [
    [
     [1064, 367],
     [1049, 354],
     [1040, 353],
     [1017, 353],
     [1014, 355],
     [998, 356],
     [967, 354],
     [959, 357],
     [907, 356],
     [905, 358],
     [888, 359],
     [873, 357],
     [848, 358],
     [848, 338],
     [853, 338],
     [855, 340],
     [871, 340],
     [889, 337],
     [906, 337],
     [907, 339],
     [909, 339],
     [950, 335],
     [960, 335],
     [961, 337],
     [969, 337],
     [987, 334],
     [1008, 333],
     [1015, 333],
     [1017, 335],
     [1035, 335],
     [1043, 332],
     [1048, 332],
     [1064, 348]
    ]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [956, 365]}],
   "box": [848, 352, 1064, 383],
   "polygons": [
    [
     [1064, 382],
     [1063, 383],
     [1051, 373],
     [1042, 372],
     [987, 371],
     [986, 373],
     [965, 374],
     [953, 372],
     [933, 372],
     [932, 374],
     [926, 375],
     [907, 375],
     [893, 373],
     [879, 374],
     [878, 376],
     [854, 376],
     [848, 374],
     [848, 359],
     [897, 359],
     [906, 357],
     [928, 356],
     [934, 356],
     [935, 358],
     [949, 358],
     [961, 355],
     [1007, 356],
     [1026, 353],
     [1047, 352],
     [1063, 366]
    ]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [956, 384]}],
   "box": [848, 370, 1064, 401],
   "polygons": [
    [
     [1064, 401],
     [1049, 392],
     [1041, 391],
     [1017, 391],
     [1015, 393],
     [961, 392],
     [959, 394],
     [940, 395],
     [927, 393],
     [907, 393],
     [905, 395],
     [893, 396],
     [881, 396],
     [873, 394],
     [854, 395],
     [848, 394],
     [848, 375],
     [853, 375],
     [855, 377],
     [871, 377],
     [893, 374],
     [919, 376],
     [933, 373],
     [979, 374],
     [999, 371],
     [1035, 373],
     [1048, 370],
     [1064, 384]
    ]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [956, 402]}],
   "box": [848, 391, 1064, 418],
   "polygons": [
    [
     [1064, 418],
     [1051, 410],
     [1017, 410],
     [1009, 408],
     [987, 409],
     [986, 411],
     [961, 411],
     [951, 409],
     [933, 410],
     [932, 412],
     [879, 411],
     [878, 413],
     [854, 413],
     [848, 411],
     [848, 395],
     [880, 395],
     [881, 397],
     [885, 397],
     [906, 394],
     [953, 395],
     [961, 393],
     [981, 392],
     [989, 392],
     [991, 394],
     [1007, 394],
     [1022, 391],
     [1049, 391],
     [1064, 403]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [956, 421]}],
   "box": [848, 408, 1064, 437],
   "polygons": [
    [
     [1064, 437],
     [1051, 430],
     [1033, 428],
     [1015, 429],
     [1013, 431],
     [989, 431],
     [973, 429],
     [961, 430],
     [959, 432],
     [935, 432],
     [927, 430],
     [911, 430],
     [902, 433],
     [881, 433],
     [873, 431],
     [854, 432],
     [848, 431],
     [848, 412],
     [853, 412],
     [855, 414],
     [871, 414],
     [879, 412],
     [899, 411],
     [906, 411],
     [907, 413],
     [925, 413],
     [945, 410],
     [960, 410],
     [961, 412],
     [979, 412],
     [989, 409],
     [1015, 409],
     [1017, 411],
     [1035, 411],
     [1043, 408],
     [1048, 408],
     [1064, 419]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [956, 440]}],
   "box": [848, 429, 1065, 453],
   "polygons": [
    [
     [1065, 452],
     [1062, 453],
     [1059, 450],
     [1045, 446],
     [1043, 446],
     [1042, 448],
     [989, 446],
     [987, 448],
     [976, 449],
     [933, 447],
     [932, 449],
     [879, 448],
     [878, 450],
     [854, 450],
     [848, 448],
     [848, 432],
     [880, 432],
     [881, 434],
     [895, 434],
     [909, 431],
     [934, 431],
     [935, 433],
     [953, 433],
     [962, 430],
     [989, 430],
     [991, 432],
     [1005, 432],
     [1014, 431],
     [1016, 429],
     [1049, 429],
     [1064, 438]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [956, 459]}],
   "box": [848, 446, 1065, 472],
   "polygons": [
    [
     [1065, 466],
     [1064, 472],
     [1043, 466],
     [1017, 467],
     [1013, 469],
     [989, 469],
     [981, 467],
     [961, 467],
     [960, 469],
     [907, 468],
     [905, 470],
     [848, 468],
     [848, 449],
     [853, 449],
     [855, 451],
     [871, 451],
     [879, 449],
     [925, 450],
     [933, 448],
     [979, 449],
     [987, 447],
     [1016, 447],
     [1017, 449],
     [1035, 449],
     [1046, 446],
     [1064, 453]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [956, 477]}],
   "box": [848, 467, 1065, 488],
   "polygons": [
    [
     [1065, 478],
     [1063, 488],
     [1045, 484],
     [1042, 486],
     [1017, 486],
     [1009, 484],
     [989, 484],
     [986, 486],
     [961, 486],
     [949, 484],
     [933, 485],
     [929, 487],
     [907, 487],
     [899, 485],
     [879, 485],
     [878, 487],
     [854, 487],
     [848, 485],
     [848, 469],
     [880, 469],
     [881, 471],
     [897, 471],
     [906, 469],
     [926, 468],
     [934, 468],
     [935, 470],
     [951, 470],
     [960, 468],
     [1010, 469],
     [1019, 467],
     [1048, 467],
     [1064, 473]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [956, 496]}],
   "box": [848, 484, 1065, 508],
   "polygons": [
    [
     [1065, 505],
     [1063, 508],
     [1049, 505],
     [1033, 504],
     [1017, 504],
     [1012, 507],
     [990, 507],
     [981, 505],
     [961, 505],
     [960, 507],
     [935, 507],
     [927, 505],
     [907, 505],
     [905, 507],
     [848, 505],
     [848, 486],
     [853, 486],
     [855, 488],
     [871, 488],
     [879, 486],
     [906, 486],
     [907, 488],
     [917, 488],
     [934, 485],
     [960, 485],
     [961, 487],
     [1015, 485],
     [1027, 487],
     [1048, 484],
     [1064, 489]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [956, 515]}],
   "box": [848, 505, 1064, 524],
   "polygons": [
    [
     [1064, 523],
     [1043, 522],
     [1042, 524],
     [1016, 524],
     [1007, 522],
     [989, 522],
     [986, 524],
     [961, 524],
     [953, 522],
     [933, 522],
     [932, 524],
     [907, 524],
     [899, 522],
     [879, 522],
     [878, 524],
     [854, 524],
     [848, 522],
     [848, 506],
     [880, 506],
     [881, 508],
     [897, 508],
     [906, 506],
     [934, 506],
     [935, 508],
     [953, 508],
     [966, 505],
     [988, 505],
     [989, 507],
     [1007, 507],
     [1016, 505],
     [1048, 505],
     [1064, 509]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [956, 533]}],
   "box": [848, 522, 1064, 545],
   "polygons": [
    [
     [1064, 542],
     [1015, 543],
     [1011, 545],
     [989, 544],
     [981, 542],
     [961, 542],
     [960, 544],
     [935, 544],
     [927, 542],
     [907, 542],
     [905, 544],
     [848, 542],
     [848, 523],
     [853, 523],
     [855, 525],
     [871, 525],
     [879, 523],
     [906, 523],
     [907, 525],
     [925, 525],
     [933, 523],
     [960, 523],
     [961, 525],
     [979, 525],
     [988, 523],
     [1010, 522],
     [1015, 523],
     [1015, 525],
     [1035, 525],
     [1047, 522],
     [1063, 524]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [956, 552]}],
   "box": [848, 543, 1064, 562],
   "polygons": [
    [
     [1064, 558],
     [1051, 559],
     [1041, 562],
     [1016, 562],
     [1007, 560],
     [967, 562],
     [953, 559],
     [933, 559],
     [932, 561],
     [907, 561],
     [899, 559],
     [879, 559],
     [878, 561],
     [854, 561],
     [848, 559],
     [848, 543],
     [880, 543],
     [881, 545],
     [897, 545],
     [906, 543],
     [934, 543],
     [935, 545],
     [953, 545],
     [961, 543],
     [988, 543],
     [989, 545],
     [1008, 545],
     [1016, 543],
     [1064, 543]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [956, 571]}],
   "box": [848, 558, 1065, 582],
   "polygons": [
    [
     [1065, 576],
     [1064, 578],
     [1052, 580],
     [1017, 580],
     [1015, 582],
     [989, 582],
     [981, 580],
     [961, 580],
     [959, 582],
     [935, 582],
     [909, 579],
     [905, 581],
     [848, 579],
     [848, 560],
     [853, 560],
     [855, 562],
     [871, 562],
     [879, 560],
     [906, 560],
     [907, 562],
     [925, 562],
     [933, 560],
     [960, 560],
     [961, 562],
     [979, 562],
     [987, 560],
     [1015, 560],
     [1021, 562],
     [1035, 562],
     [1063, 558],
     [1065, 564]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [956, 590]}],
   "box": [848, 578, 1065, 600],
   "polygons": [
    [
     [1065, 588],
     [1063, 594],
     [1042, 600],
     [1018, 600],
     [995, 597],
     [975, 600],
     [953, 597],
     [933, 597],
     [932, 599],
     [910, 599],
     [899, 596],
     [879, 596],
     [878, 598],
     [854, 598],
     [848, 596],
     [848, 580],
     [880, 580],
     [881, 582],
     [927, 580],
     [934, 581],
     [935, 583],
     [953, 583],
     [961, 581],
     [989, 581],
     [991, 583],
     [1007, 583],
     [1016, 581],
     [1049, 581],
     [1063, 578],
     [1065, 579]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [956, 608]}],
   "box": [848, 594, 1064, 620],
   "polygons": [
    [
     [1064, 612],
     [1048, 619],
     [1017, 618],
     [1015, 620],
     [989, 620],
     [981, 618],
     [961, 617],
     [960, 619],
     [937, 620],
     [927, 617],
     [907, 617],
     [905, 619],
     [881, 619],
     [873, 616],
     [848, 616],
     [848, 597],
     [853, 597],
     [855, 599],
     [871, 599],
     [879, 597],
     [906, 597],
     [907, 599],
     [911, 600],
     [925, 600],
     [933, 598],
     [960, 598],
     [961, 600],
     [1014, 598],
     [1019, 601],
     [1035, 601],
     [1064, 594]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [956, 627]}],
   "box": [848, 613, 1064, 638],
   "polygons": [
    [
     [1064, 628],
     [1049, 636],
     [1042, 638],
     [1017, 638],
     [1007, 635],
     [989, 635],
     [987, 637],
     [961, 637],
     [953, 634],
     [933, 634],
     [932, 636],
     [925, 637],
     [889, 633],
     [879, 633],
     [875, 636],
     [869, 636],
     [854, 635],
     [848, 633],
     [848, 617],
     [880, 617],
     [881, 620],
     [897, 620],
     [906, 618],
     [934, 618],
     [935, 620],
     [986, 618],
     [991, 621],
     [1007, 621],
     [1016, 619],
     [1045, 619],
     [1063, 613]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [956, 646]}],
   "box": [848, 629, 1064, 658],
   "polygons": [
    [
     [1064, 648],
     [1048, 657],
     [1017, 656],
     [1015, 658],
     [989, 658],
     [988, 656],
     [979, 655],
     [961, 655],
     [960, 657],
     [935, 657],
     [927, 654],
     [907, 654],
     [905, 656],
     [881, 656],
     [873, 653],
     [848, 653],
     [848, 634],
     [869, 637],
     [879, 634],
     [897, 634],
     [906, 635],
     [907, 637],
     [924, 637],
     [933, 635],
     [960, 635],
     [960, 637],
     [963, 638],
     [979, 638],
     [988, 636],
     [1015, 636],
     [1019, 638],
     [1035, 639],
     [1051, 635],
     [1064, 629]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [956, 664]}],
   "box": [848, 648, 1065, 677],
   "polygons": [
    [
     [1065, 649],
     [1064, 664],
     [1048, 674],
     [1034, 677],
     [1017, 676],
     [1007, 673],
     [987, 673],
     [986, 675],
     [971, 675],
     [953, 672],
     [934, 672],
     [930, 674],
     [916, 674],
     [899, 671],
     [879, 670],
     [876, 673],
     [866, 673],
     [848, 670],
     [848, 654],
     [880, 654],
     [881, 657],
     [897, 657],
     [906, 655],
     [934, 655],
     [935, 658],
     [951, 658],
     [960, 656],
     [987, 656],
     [989, 659],
     [1007, 659],
     [1016, 657],
     [1045, 657],
     [1063, 648]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [956, 683]}],
   "box": [848, 664, 1064, 696],
   "polygons": [
    [
     [1064, 683],
     [1047, 695],
     [1017, 694],
     [1014, 696],
     [990, 696],
     [981, 693],
     [935, 695],
     [927, 692],
     [917, 691],
     [907, 691],
     [905, 693],
     [881, 693],
     [873, 690],
     [848, 690],
     [848, 671],
     [867, 674],
     [877, 673],
     [879, 671],
     [889, 671],
     [906, 672],
     [907, 674],
     [919, 675],
     [937, 672],
     [960, 673],
     [961, 675],
     [971, 676],
     [995, 673],
     [1035, 677],
     [1051, 673],
     [1063, 664]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [956, 702]}],
   "box": [848, 683, 1064, 715],
   "polygons": [
    [
     [1064, 699],
     [1048, 712],
     [1041, 715],
     [1018, 714],
     [1016, 712],
     [1007, 711],
     [975, 713],
     [961, 712],
     [958, 710],
     [949, 709],
     [933, 709],
     [932, 711],
     [926, 712],
     [911, 711],
     [899, 708],
     [864, 710],
     [848, 707],
     [848, 691],
     [880, 691],
     [881, 694],
     [925, 692],
     [934, 693],
     [937, 696],
     [960, 693],
     [975, 693],
     [989, 694],
     [989, 697],
     [1007, 697],
     [1016, 695],
     [1044, 695],
     [1051, 693],
     [1063, 683]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [956, 721]}],
   "box": [848, 700, 1065, 734],
   "polygons": [
    [
     [1065, 709],
     [1064, 716],
     [1048, 733],
     [1015, 732],
     [1014, 734],
     [990, 734],
     [989, 732],
     [981, 730],
     [961, 730],
     [959, 732],
     [935, 732],
     [935, 730],
     [907, 728],
     [905, 730],
     [881, 731],
     [873, 727],
     [848, 727],
     [848, 708],
     [865, 711],
     [871, 711],
     [879, 708],
     [906, 709],
     [907, 711],
     [911, 712],
     [925, 712],
     [933, 710],
     [960, 710],
     [961, 713],
     [975, 714],
     [987, 713],
     [988, 711],
     [997, 711],
     [1015, 712],
     [1021, 715],
     [1034, 715],
     [1053, 709],
     [1063, 700]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [956, 739]}],
   "box": [848, 718, 1064, 753],
   "polygons": [
    [
     [1064, 735],
     [1048, 750],
     [1040, 753],
     [1019, 752],
     [1017, 750],
     [995, 748],
     [987, 748],
     [984, 751],
     [976, 751],
     [963, 750],
     [961, 748],
     [937, 746],
     [933, 746],
     [930, 749],
     [908, 748],
     [899, 745],
     [883, 745],
     [873, 748],
     [854, 746],
     [848, 744],
     [848, 728],
     [879, 728],
     [881, 732],
     [906, 729],
     [934, 730],
     [935, 733],
     [984, 731],
     [988, 732],
     [991, 735],
     [1007, 735],
     [1025, 732],
     [1041, 733],
     [1049, 731],
     [1053, 729],
     [1063, 718]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [956, 758]}],
   "box": [848, 735, 1065, 771],
   "polygons": [
    [
     [1065, 736],
     [1063, 754],
     [1048, 771],
     [990, 770],
     [981, 768],
     [935, 768],
     [927, 766],
     [881, 766],
     [873, 764],
     [848, 764],
     [848, 745],
     [863, 748],
     [879, 747],
     [879, 745],
     [906, 746],
     [909, 749],
     [921, 750],
     [931, 749],
     [933, 747],
     [944, 747],
     [960, 748],
     [961, 750],
     [965, 751],
     [977, 752],
     [985, 751],
     [987, 749],
     [1012, 749],
     [1021, 753],
     [1034, 753],
     [1049, 749],
     [1063, 735]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [956, 776]}],
   "box": [848, 754, 1064, 791],
   "polygons": [
    [
     [1064, 768],
     [1049, 788],
     [1039, 791],
     [1017, 789],
     [1010, 786],
     [988, 786],
     [986, 788],
     [981, 789],
     [961, 787],
     [953, 784],
     [941, 784],
     [926, 787],
     [907, 785],
     [907, 783],
     [887, 782],
     [878, 783],
     [874, 785],
     [855, 783],
     [848, 780],
     [848, 765],
     [878, 765],
     [881, 767],
     [934, 767],
     [935, 769],
     [965, 768],
     [989, 769],
     [992, 771],
     [1040, 771],
     [1052, 767],
     [1062, 754],
     [1064, 756]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [956, 795]}],
   "box": [848, 770, 1065, 808],
   "polygons": [
    [
     [1065, 787],
     [1047, 808],
     [1022, 806],
     [1016, 806],
     [1012, 808],
     [991, 808],
     [980, 804],
     [935, 806],
     [926, 802],
     [908, 801],
     [905, 803],
     [881, 803],
     [872, 800],
     [848, 799],
     [848, 781],
     [853, 781],
     [857, 784],
     [866, 785],
     [872, 785],
     [879, 782],
     [886, 782],
     [906, 783],
     [906, 785],
     [908, 786],
     [917, 787],
     [925, 787],
     [933, 784],
     [938, 784],
     [960, 785],
     [962, 788],
     [971, 789],
     [987, 788],
     [987, 786],
     [1015, 787],
     [1019, 790],
     [1034, 791],
     [1050, 788],
     [1051, 784],
     [1054, 782],
     [1060, 772],
     [1064, 770]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [956, 813]}],
   "box": [848, 787, 1065, 829],
   "polygons": [
    [
     [1065, 794],
     [1064, 804],
     [1048, 827],
     [1039, 829],
     [1016, 827],
     [1016, 825],
     [1007, 824],
     [975, 826],
     [941, 821],
     [935, 821],
     [929, 824],
     [924, 824],
     [907, 822],
     [898, 819],
     [881, 819],
     [876, 821],
     [866, 821],
     [848, 818],
     [848, 800],
     [880, 801],
     [881, 804],
     [899, 804],
     [906, 802],
     [934, 803],
     [934, 806],
     [952, 806],
     [960, 804],
     [988, 805],
     [989, 807],
     [995, 809],
     [1017, 806],
     [1040, 808],
     [1049, 806],
     [1055, 800],
     [1060, 790],
     [1064, 787]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [956, 832]}],
   "box": [848, 805, 1065, 846],
   "polygons": [
    [
     [1065, 806],
     [1064, 822],
     [1048, 845],
     [1019, 844],
     [1010, 846],
     [990, 846],
     [989, 843],
     [980, 842],
     [962, 841],
     [959, 843],
     [935, 843],
     [929, 840],
     [920, 839],
     [881, 841],
     [872, 837],
     [848, 836],
     [849, 818],
     [852, 818],
     [857, 821],
     [866, 822],
     [878, 821],
     [880, 819],
     [903, 820],
     [908, 823],
     [917, 824],
     [933, 822],
     [951, 822],
     [977, 827],
     [987, 824],
     [1015, 825],
     [1022, 828],
     [1034, 829],
     [1049, 824],
     [1053, 821],
     [1054, 817],
     [1061, 807],
     [1064, 805]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [956, 851]}],
   "box": [848, 823, 1064, 868],
   "polygons": [
    [
     [1064, 838],
     [1048, 865],
     [1038, 868],
     [1016, 865],
     [1007, 862],
     [989, 861],
     [985, 864],
     [976, 864],
     [962, 862],
     [953, 859],
     [920, 861],
     [893, 856],
     [881, 856],
     [872, 859],
     [857, 857],
     [848, 854],
     [848, 837],
     [880, 838],
     [887, 841],
     [898, 841],
     [906, 839],
     [930, 840],
     [934, 841],
     [935, 844],
     [973, 842],
     [988, 843],
     [989, 845],
     [993, 846],
     [1007, 846],
     [1016, 844],
     [1041, 845],
     [1051, 842],
     [1054, 835],
     [1064, 823]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [956, 869]}],
   "box": [848, 841, 1064, 884],
   "polygons": [
    [
     [1064, 858],
     [1047, 884],
     [1028, 882],
     [1019, 882],
     [1011, 884],
     [990, 884],
     [983, 880],
     [973, 879],
     [935, 881],
     [926, 877],
     [917, 876],
     [908, 876],
     [901, 878],
     [881, 878],
     [872, 874],
     [848, 873],
     [848, 855],
     [853, 855],
     [857, 858],
     [866, 859],
     [871, 859],
     [879, 856],
     [906, 858],
     [908, 860],
     [920, 862],
     [925, 862],
     [933, 859],
     [941, 859],
     [959, 860],
     [962, 863],
     [980, 865],
     [987, 862],
     [1000, 862],
     [1015, 863],
     [1022, 866],
     [1034, 867],
     [1043, 866],
     [1049, 863],
     [1063, 841]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [956, 888]}],
   "box": [848, 859, 1064, 906],
   "polygons": [
    [
     [1064, 874],
     [1048, 902],
     [1038, 906],
     [1017, 903],
     [1010, 900],
     [989, 899],
     [984, 902],
     [978, 902],
     [953, 897],
     [935, 896],
     [929, 899],
     [925, 899],
     [890, 893],
     [881, 893],
     [875, 896],
     [871, 896],
     [857, 894],
     [848, 891],
     [848, 874],
     [880, 875],
     [881, 879],
     [906, 877],
     [934, 878],
     [935, 882],
     [962, 879],
     [982, 880],
     [994, 884],
     [1007, 884],
     [1015, 882],
     [1040, 883],
     [1049, 881],
     [1054, 875],
     [1057, 866],
     [1063, 859]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [966, 906]}],
   "box": [848, 876, 1065, 922],
   "polygons": [
    [
     [1065, 876],
     [1064, 892],
     [1048, 922],
     [1026, 920],
     [991, 922],
     [983, 917],
     [963, 916],
     [959, 918],
     [939, 918],
     [935, 909],
     [931, 908],
     [898, 906],
     [891, 907],
     [856, 904],
     [848, 902],
     [848, 892],
     [853, 892],
     [855, 894],
     [867, 896],
     [879, 894],
     [906, 895],
     [907, 897],
     [919, 899],
     [933, 897],
     [951, 897],
     [979, 903],
     [988, 900],
     [1002, 900],
     [1015, 901],
     [1019, 904],
     [1035, 906],
     [1051, 899],
     [1051, 896],
     [1053, 895],
     [1054, 890],
     [1062, 876]
    ],
    [[858, 910], [853, 910], [857, 908]],
    [[878, 911], [862, 910], [875, 909]],
    [[890, 915], [881, 915], [880, 909], [889, 911]],
    [[909, 911], [905, 915], [898, 915], [898, 910]],
    [[928, 912], [927, 914], [917, 914]],
    [[915, 913], [910, 913]]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [944, 922]}],
   "box": [851, 893, 1065, 937],
   "polygons": [
    [
     [1065, 898],
     [1064, 909],
     [1062, 914],
     [1051, 931],
     [1018, 928],
     [1009, 929],
     [979, 927],
     [969, 928],
     [967, 932],
     [962, 936],
     [942, 934],
     [938, 931],
     [938, 921],
     [940, 919],
     [952, 919],
     [960, 917],
     [986, 918],
     [998, 922],
     [1007, 922],
     [1015, 920],
     [1041, 921],
     [1049, 918],
     [1062, 894],
     [1064, 893]
    ],
    [
     [891, 929],
     [879, 930],
     [875, 933],
     [854, 930],
     [851, 925],
     [853, 911],
     [880, 912],
     [889, 916],
     [891, 919]
    ],
    [[931, 915], [915, 935], [898, 931], [896, 929], [896, 919], [898, 916], [906, 914]],
    [[933, 927], [931, 930], [931, 922], [933, 924]],
    [[928, 934], [926, 937], [921, 936], [921, 934], [925, 930], [927, 930]],
    [[934, 933], [931, 935], [933, 931]]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [950, 937]}],
   "box": [852, 910, 1065, 956],
   "polygons": [
    [[1065, 922], [1064, 928], [1059, 931], [1053, 930], [1062, 911], [1065, 910]],
    [
     [890, 935],
     [853, 935],
     [852, 931],
     [853, 929],
     [857, 932],
     [869, 934],
     [879, 931],
     [889, 931]
    ],
    [[914, 937], [900, 937], [897, 933], [906, 932], [914, 935]],
    [[962, 939], [950, 955], [940, 956], [939, 940], [941, 934], [956, 935], [960, 936]]
   ]
  },
  {
   "color": "#13d238ff",
   "pois": [{"id": "centroid", "point": [942, 962]}],
   "box": [939, 957, 948, 971],
   "polygons": [[[948, 958], [942, 970], [939, 971], [939, 957]]]
  }
 ],
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [956, 62]}],
   "box": [846, 14, 1068, 116],
   "polygons": [
    [
     [1068, 115],
     [1064, 116],
     [1053, 103],
     [1051, 96],
     [1048, 95],
     [1048, 92],
     [1041, 87],
     [1033, 84],
     [1015, 85],
     [1003, 89],
     [984, 91],
     [959, 91],
     [953, 94],
     [938, 96],
     [903, 97],
     [900, 100],
     [891, 101],
     [867, 101],
     [846, 103],
     [846, 52],
     [863, 51],
     [864, 36],
     [866, 33],
     [883, 33],
     [894, 30],
     [911, 29],
     [916, 27],
     [951, 23],
     [957, 24],
     [962, 22],
     [992, 18],
     [1001, 19],
     [1009, 16],
     [1030, 14],
     [1068, 68]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [957, 104]}],
   "box": [846, 83, 1068, 133],
   "polygons": [
    [
     [1068, 133],
     [1062, 130],
     [1056, 122],
     [1056, 120],
     [1053, 119],
     [1051, 114],
     [1048, 112],
     [1046, 108],
     [1044, 108],
     [1043, 105],
     [1001, 104],
     [983, 106],
     [978, 109],
     [968, 109],
     [959, 111],
     [941, 110],
     [932, 111],
     [927, 114],
     [920, 115],
     [893, 115],
     [878, 117],
     [871, 120],
     [851, 121],
     [846, 119],
     [846, 104],
     [874, 101],
     [884, 102],
     [902, 98],
     [921, 96],
     [932, 97],
     [976, 90],
     [1001, 90],
     [1013, 86],
     [1040, 83],
     [1067, 117]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [957, 123]}],
   "box": [845, 100, 1068, 152],
   "polygons": [
    [
     [1068, 151],
     [1064, 152],
     [1054, 138],
     [1051, 136],
     [1049, 132],
     [1044, 129],
     [1041, 124],
     [1033, 122],
     [1010, 124],
     [1006, 127],
     [983, 129],
     [965, 128],
     [956, 129],
     [951, 132],
     [931, 134],
     [914, 133],
     [902, 134],
     [900, 137],
     [881, 139],
     [863, 138],
     [847, 140],
     [845, 129],
     [846, 120],
     [850, 120],
     [851, 122],
     [869, 121],
     [883, 117],
     [917, 116],
     [930, 112],
     [974, 110],
     [981, 107],
     [999, 105],
     [1019, 106],
     [1028, 105],
     [1040, 100],
     [1068, 135]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [957, 141]}],
   "box": [845, 121, 1068, 169],
   "polygons": [
    [
     [1068, 167],
     [1067, 169],
     [1065, 168],
     [1056, 159],
     [1053, 153],
     [1048, 149],
     [1048, 147],
     [1042, 143],
     [1034, 142],
     [1009, 144],
     [1001, 142],
     [989, 143],
     [976, 147],
     [965, 147],
     [955, 149],
     [947, 147],
     [935, 148],
     [921, 152],
     [881, 153],
     [875, 156],
     [851, 158],
     [845, 156],
     [846, 140],
     [873, 138],
     [887, 139],
     [902, 135],
     [924, 133],
     [937, 134],
     [955, 130],
     [974, 128],
     [1001, 128],
     [1008, 125],
     [1040, 121],
     [1068, 154]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [957, 160]}],
   "box": [846, 139, 1068, 188],
   "polygons": [
    [
     [1068, 185],
     [1067, 188],
     [1065, 188],
     [1051, 173],
     [1051, 171],
     [1048, 170],
     [1048, 168],
     [1045, 165],
     [1041, 162],
     [1033, 160],
     [990, 165],
     [962, 166],
     [947, 169],
     [905, 171],
     [901, 173],
     [890, 174],
     [846, 176],
     [846, 157],
     [863, 158],
     [892, 153],
     [919, 153],
     [928, 150],
     [945, 148],
     [974, 148],
     [981, 145],
     [1007, 142],
     [1010, 144],
     [1028, 143],
     [1035, 139],
     [1040, 139],
     [1058, 160],
     [1068, 169]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [957, 178]}],
   "box": [846, 160, 1068, 204],
   "polygons": [
    [
     [1068, 202],
     [1067, 204],
     [1064, 204],
     [1058, 195],
     [1051, 190],
     [1043, 180],
     [986, 181],
     [972, 185],
     [938, 185],
     [929, 186],
     [924, 189],
     [893, 189],
     [881, 190],
     [875, 193],
     [852, 195],
     [846, 193],
     [846, 177],
     [896, 174],
     [904, 172],
     [1041, 160],
     [1052, 174],
     [1068, 189]
    ]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [957, 197]}],
   "box": [846, 177, 1068, 223],
   "polygons": [
    [
     [1068, 223],
     [1064, 223],
     [1056, 215],
     [1056, 213],
     [1051, 210],
     [1046, 204],
     [1034, 198],
     [1010, 200],
     [1002, 203],
     [956, 204],
     [953, 207],
     [917, 207],
     [905, 208],
     [902, 209],
     [901, 211],
     [878, 213],
     [869, 211],
     [847, 213],
     [846, 194],
     [860, 195],
     [878, 191],
     [891, 190],
     [908, 191],
     [919, 190],
     [928, 187],
     [948, 185],
     [965, 186],
     [984, 182],
     [1006, 180],
     [1010, 182],
     [1022, 182],
     [1028, 181],
     [1036, 177],
     [1040, 177],
     [1061, 199],
     [1068, 204]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [957, 216]}],
   "box": [846, 198, 1068, 240],
   "polygons": [
    [
     [1068, 240],
     [1064, 239],
     [1057, 231],
     [1048, 224],
     [1048, 222],
     [1045, 221],
     [1045, 219],
     [1041, 218],
     [998, 218],
     [986, 219],
     [980, 222],
     [969, 223],
     [955, 224],
     [944, 222],
     [932, 223],
     [927, 226],
     [893, 226],
     [878, 227],
     [875, 230],
     [851, 231],
     [846, 229],
     [846, 213],
     [896, 212],
     [905, 209],
     [917, 208],
     [935, 209],
     [947, 208],
     [954, 205],
     [979, 203],
     [995, 204],
     [1004, 203],
     [1008, 201],
     [1040, 198],
     [1067, 224]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [957, 235]}],
   "box": [846, 215, 1068, 258],
   "polygons": [
    [
     [1068, 258],
     [1065, 258],
     [1059, 254],
     [1055, 249],
     [1053, 249],
     [1041, 238],
     [1032, 237],
     [1010, 238],
     [1004, 241],
     [988, 242],
     [962, 241],
     [953, 244],
     [931, 246],
     [920, 244],
     [905, 245],
     [902, 246],
     [901, 248],
     [888, 249],
     [846, 249],
     [846, 230],
     [854, 232],
     [869, 231],
     [876, 230],
     [878, 228],
     [891, 227],
     [923, 227],
     [930, 224],
     [962, 224],
     [973, 223],
     [982, 220],
     [1005, 218],
     [1010, 220],
     [1016, 220],
     [1035, 218],
     [1038, 215],
     [1068, 241]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [957, 253]}],
   "box": [846, 237, 1068, 275],
   "polygons": [
    [
     [1068, 275],
     [1065, 275],
     [1056, 268],
     [1054, 264],
     [1045, 259],
     [1043, 256],
     [1013, 258],
     [998, 256],
     [983, 257],
     [980, 258],
     [979, 260],
     [935, 260],
     [929, 261],
     [927, 263],
     [917, 264],
     [890, 263],
     [878, 264],
     [874, 267],
     [851, 268],
     [846, 266],
     [846, 250],
     [881, 250],
     [896, 249],
     [905, 246],
     [938, 246],
     [973, 241],
     [998, 242],
     [1008, 239],
     [1041, 237],
     [1060, 254],
     [1068, 259]
    ]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [957, 272]}],
   "box": [846, 254, 1068, 295],
   "polygons": [
    [
     [1068, 295],
     [1059, 290],
     [1051, 282],
     [1042, 276],
     [1036, 275],
     [1010, 276],
     [1004, 279],
     [987, 280],
     [974, 278],
     [956, 279],
     [951, 282],
     [937, 283],
     [908, 282],
     [902, 283],
     [901, 285],
     [889, 286],
     [846, 286],
     [846, 267],
     [869, 268],
     [876, 265],
     [911, 265],
     [935, 261],
     [968, 261],
     [981, 258],
     [1005, 256],
     [1012, 258],
     [1028, 257],
     [1035, 254],
     [1040, 254],
     [1068, 276]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [957, 291]}],
   "box": [845, 275, 1068, 311],
   "polygons": [
    [
     [1068, 311],
     [1059, 307],
     [1056, 303],
     [1043, 294],
     [1016, 296],
     [995, 294],
     [980, 295],
     [979, 297],
     [973, 298],
     [957, 299],
     [941, 297],
     [929, 298],
     [927, 300],
     [921, 301],
     [903, 302],
     [890, 300],
     [878, 301],
     [875, 303],
     [868, 304],
     [851, 305],
     [845, 302],
     [846, 287],
     [874, 285],
     [878, 287],
     [884, 287],
     [907, 283],
     [923, 282],
     [928, 282],
     [929, 284],
     [932, 284],
     [947, 283],
     [956, 280],
     [973, 279],
     [998, 280],
     [1008, 277],
     [1040, 275],
     [1068, 296]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [957, 309]}],
   "box": [846, 292, 1068, 330],
   "polygons": [
    [
     [1068, 330],
     [1062, 328],
     [1048, 318],
     [1033, 313],
     [1009, 314],
     [1007, 317],
     [983, 318],
     [975, 316],
     [965, 316],
     [955, 317],
     [953, 319],
     [946, 320],
     [909, 319],
     [903, 320],
     [901, 322],
     [887, 323],
     [848, 323],
     [846, 322],
     [846, 304],
     [850, 303],
     [852, 305],
     [861, 305],
     [890, 301],
     [913, 302],
     [939, 298],
     [965, 299],
     [980, 296],
     [1008, 294],
     [1011, 296],
     [1017, 296],
     [1040, 292],
     [1068, 312]
    ]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [954, 328]}],
   "box": [845, 313, 1068, 341],
   "polygons": [
    [
     [1068, 334],
     [1066, 336],
     [1059, 338],
     [1056, 341],
     [1043, 332],
     [1009, 334],
     [993, 332],
     [968, 336],
     [929, 335],
     [926, 338],
     [905, 339],
     [887, 337],
     [865, 341],
     [851, 341],
     [845, 338],
     [846, 323],
     [893, 323],
     [909, 320],
     [928, 319],
     [929, 321],
     [935, 321],
     [964, 317],
     [981, 316],
     [985, 318],
     [991, 318],
     [1008, 315],
     [1036, 313],
     [1040, 313],
     [1060, 327],
     [1067, 330]
    ]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [951, 346]}],
   "box": [845, 331, 1059, 360],
   "polygons": [
    [
     [1059, 352],
     [1058, 356],
     [1053, 359],
     [1041, 352],
     [1029, 351],
     [1009, 352],
     [1007, 354],
     [997, 355],
     [959, 354],
     [953, 357],
     [932, 358],
     [913, 356],
     [903, 357],
     [901, 359],
     [885, 360],
     [869, 358],
     [846, 359],
     [845, 353],
     [846, 340],
     [855, 342],
     [887, 338],
     [919, 339],
     [928, 336],
     [949, 335],
     [961, 337],
     [1007, 332],
     [1011, 334],
     [1025, 334],
     [1033, 333],
     [1035, 331],
     [1040, 331],
     [1055, 340]
    ]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [942, 365]}],
   "box": [846, 351, 1051, 378],
   "polygons": [
    [
     [1051, 358],
     [1034, 364],
     [1032, 371],
     [1010, 372],
     [993, 370],
     [981, 371],
     [980, 373],
     [963, 374],
     [935, 372],
     [926, 375],
     [902, 376],
     [887, 374],
     [877, 375],
     [875, 377],
     [858, 378],
     [851, 378],
     [846, 376],
     [846, 360],
     [893, 360],
     [912, 357],
     [945, 358],
     [954, 357],
     [956, 355],
     [982, 354],
     [983, 356],
     [989, 356],
     [1008, 353],
     [1039, 351]
    ]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [940, 384]}],
   "box": [846, 371, 1053, 397],
   "polygons": [
    [
     [1033, 383],
     [1030, 390],
     [1015, 390],
     [1007, 391],
     [1004, 393],
     [955, 392],
     [953, 394],
     [940, 395],
     [917, 393],
     [885, 397],
     [869, 395],
     [846, 396],
     [846, 377],
     [867, 378],
     [886, 375],
     [919, 376],
     [935, 373],
     [971, 374],
     [983, 371],
     [1008, 371],
     [1031, 372]
    ],
    [[1053, 380], [1052, 383], [1049, 383], [1042, 377], [1037, 377], [1044, 374]],
    [[1042, 383], [1040, 386], [1041, 380]]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [939, 403]}],
   "box": [846, 390, 1033, 414],
   "polygons": [
    [
     [1033, 403],
     [1032, 408],
     [1026, 410],
     [981, 408],
     [980, 411],
     [929, 410],
     [927, 412],
     [911, 413],
     [885, 411],
     [877, 412],
     [875, 414],
     [851, 414],
     [846, 412],
     [846, 397],
     [855, 396],
     [893, 397],
     [916, 394],
     [928, 394],
     [929, 396],
     [972, 392],
     [981, 392],
     [983, 394],
     [995, 394],
     [1008, 391],
     [1033, 390]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [939, 421]}],
   "box": [845, 408, 1033, 434],
   "polygons": [
    [
     [1033, 422],
     [1030, 428],
     [1011, 428],
     [1000, 431],
     [982, 431],
     [967, 429],
     [955, 430],
     [952, 432],
     [903, 431],
     [901, 433],
     [880, 434],
     [871, 432],
     [846, 432],
     [845, 414],
     [850, 413],
     [851, 415],
     [867, 415],
     [884, 412],
     [901, 412],
     [903, 414],
     [945, 410],
     [963, 412],
     [981, 409],
     [1008, 408],
     [1011, 410],
     [1033, 410]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [939, 440]}],
   "box": [846, 428, 1032, 451],
   "polygons": [
    [
     [1032, 446],
     [1026, 448],
     [981, 446],
     [980, 448],
     [969, 449],
     [929, 447],
     [926, 449],
     [917, 450],
     [881, 448],
     [874, 451],
     [852, 451],
     [846, 449],
     [846, 433],
     [893, 434],
     [902, 432],
     [921, 431],
     [928, 431],
     [929, 433],
     [945, 433],
     [958, 430],
     [999, 431],
     [1008, 429],
     [1031, 428]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [939, 459]}],
   "box": [845, 446, 1034, 470],
   "polygons": [
    [
     [1034, 460],
     [1032, 466],
     [1009, 466],
     [1007, 468],
     [998, 469],
     [955, 467],
     [953, 469],
     [931, 470],
     [923, 468],
     [903, 468],
     [901, 470],
     [877, 470],
     [846, 469],
     [845, 455],
     [846, 450],
     [850, 450],
     [853, 452],
     [872, 451],
     [878, 449],
     [901, 449],
     [903, 451],
     [909, 451],
     [928, 448],
     [973, 449],
     [981, 447],
     [1004, 446],
     [1010, 448],
     [1032, 448]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [939, 477]}],
   "box": [846, 467, 1034, 488],
   "polygons": [
    [
     [1034, 480],
     [1031, 486],
     [981, 484],
     [979, 486],
     [929, 485],
     [927, 487],
     [902, 487],
     [893, 485],
     [877, 485],
     [875, 487],
     [851, 488],
     [846, 486],
     [846, 470],
     [875, 469],
     [877, 471],
     [893, 471],
     [902, 469],
     [945, 470],
     [954, 468],
     [999, 469],
     [1008, 467],
     [1031, 467]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [939, 496]}],
   "box": [846, 484, 1034, 507],
   "polygons": [
    [
     [1034, 503],
     [1009, 504],
     [1007, 506],
     [1001, 507],
     [982, 507],
     [973, 505],
     [955, 505],
     [953, 507],
     [929, 507],
     [921, 505],
     [903, 505],
     [901, 507],
     [877, 507],
     [869, 505],
     [846, 506],
     [846, 487],
     [867, 488],
     [876, 486],
     [901, 486],
     [903, 488],
     [919, 488],
     [943, 485],
     [954, 485],
     [955, 487],
     [971, 487],
     [980, 485],
     [1006, 484],
     [1010, 486],
     [1033, 486],
     [1034, 488],
     [1032, 492]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [939, 515]}],
   "box": [846, 505, 1034, 524],
   "polygons": [
    [
     [1034, 507],
     [1033, 523],
     [1029, 524],
     [1012, 524],
     [1002, 522],
     [981, 522],
     [979, 524],
     [955, 524],
     [947, 522],
     [929, 522],
     [927, 524],
     [902, 524],
     [893, 522],
     [877, 522],
     [875, 524],
     [851, 524],
     [846, 522],
     [847, 506],
     [876, 506],
     [877, 508],
     [893, 508],
     [902, 506],
     [928, 506],
     [929, 508],
     [945, 508],
     [963, 505],
     [981, 505],
     [983, 507],
     [1031, 505]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [939, 533]}],
   "box": [846, 522, 1034, 545],
   "polygons": [
    [
     [1034, 533],
     [1031, 542],
     [1007, 543],
     [1007, 545],
     [988, 545],
     [973, 542],
     [955, 542],
     [953, 544],
     [929, 544],
     [921, 542],
     [903, 542],
     [901, 544],
     [877, 544],
     [869, 542],
     [846, 542],
     [846, 523],
     [850, 523],
     [851, 525],
     [867, 525],
     [876, 523],
     [901, 523],
     [903, 525],
     [919, 525],
     [928, 523],
     [954, 523],
     [955, 525],
     [973, 525],
     [981, 523],
     [1007, 522],
     [1011, 524],
     [1031, 525],
     [1033, 526]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [939, 552]}],
   "box": [845, 543, 1035, 562],
   "polygons": [
    [
     [1035, 555],
     [1031, 562],
     [1010, 562],
     [1001, 560],
     [956, 562],
     [947, 559],
     [929, 559],
     [927, 561],
     [902, 561],
     [893, 559],
     [877, 559],
     [875, 561],
     [851, 561],
     [846, 559],
     [845, 546],
     [846, 543],
     [876, 543],
     [877, 545],
     [893, 545],
     [902, 543],
     [928, 543],
     [929, 545],
     [945, 545],
     [954, 543],
     [982, 543],
     [983, 545],
     [1032, 543]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [939, 571]}],
   "box": [846, 560, 1034, 583],
   "polygons": [
    [
     [1034, 575],
     [1033, 579],
     [1026, 581],
     [1007, 581],
     [990, 583],
     [975, 580],
     [955, 580],
     [953, 582],
     [929, 582],
     [921, 579],
     [903, 579],
     [901, 581],
     [846, 579],
     [846, 560],
     [850, 560],
     [851, 562],
     [867, 562],
     [876, 560],
     [901, 560],
     [903, 562],
     [919, 562],
     [928, 560],
     [954, 560],
     [955, 562],
     [974, 562],
     [981, 560],
     [1004, 560],
     [1011, 563],
     [1032, 562]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [939, 590]}],
   "box": [846, 580, 1034, 601],
   "polygons": [
    [
     [1034, 590],
     [1029, 600],
     [1027, 601],
     [1010, 600],
     [1002, 598],
     [980, 598],
     [979, 600],
     [970, 600],
     [947, 597],
     [929, 597],
     [926, 599],
     [914, 599],
     [893, 596],
     [877, 596],
     [875, 598],
     [851, 598],
     [846, 596],
     [846, 580],
     [876, 580],
     [877, 582],
     [893, 582],
     [902, 580],
     [928, 580],
     [929, 583],
     [945, 583],
     [954, 581],
     [981, 581],
     [983, 583],
     [1030, 581]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [939, 608]}],
   "box": [845, 596, 1033, 621],
   "polygons": [
    [
     [1033, 614],
     [1032, 618],
     [1028, 619],
     [1007, 619],
     [1007, 621],
     [988, 621],
     [973, 618],
     [929, 619],
     [907, 616],
     [903, 616],
     [901, 618],
     [847, 616],
     [845, 611],
     [847, 596],
     [857, 599],
     [867, 599],
     [876, 597],
     [901, 597],
     [901, 599],
     [915, 600],
     [928, 598],
     [954, 598],
     [955, 600],
     [1007, 598],
     [1011, 601],
     [1029, 601],
     [1032, 603],
     [1033, 606]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [939, 627]}],
   "box": [846, 616, 1034, 639],
   "polygons": [
    [
     [1034, 637],
     [1031, 639],
     [1022, 639],
     [1008, 638],
     [1003, 636],
     [981, 635],
     [979, 637],
     [972, 638],
     [955, 637],
     [947, 634],
     [907, 636],
     [893, 633],
     [858, 635],
     [846, 632],
     [846, 616],
     [876, 617],
     [877, 619],
     [914, 617],
     [928, 618],
     [929, 620],
     [979, 618],
     [983, 621],
     [1030, 619],
     [1032, 621]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [939, 646]}],
   "box": [846, 633, 1034, 659],
   "polygons": [
    [
     [1034, 648],
     [1029, 657],
     [1013, 657],
     [1006, 659],
     [985, 659],
     [967, 655],
     [955, 655],
     [953, 657],
     [929, 657],
     [921, 654],
     [877, 655],
     [863, 652],
     [846, 652],
     [846, 633],
     [861, 636],
     [876, 634],
     [901, 634],
     [907, 637],
     [919, 637],
     [928, 635],
     [954, 635],
     [954, 637],
     [957, 638],
     [1002, 636],
     [1011, 639],
     [1029, 639]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [939, 665]}],
   "box": [846, 653, 1033, 677],
   "polygons": [
    [
     [1033, 666],
     [1032, 674],
     [1030, 677],
     [1017, 677],
     [991, 673],
     [981, 673],
     [979, 675],
     [974, 676],
     [931, 671],
     [923, 674],
     [904, 673],
     [893, 670],
     [876, 670],
     [874, 672],
     [862, 672],
     [851, 671],
     [846, 669],
     [846, 653],
     [874, 653],
     [877, 656],
     [893, 656],
     [902, 654],
     [928, 655],
     [929, 658],
     [945, 658],
     [954, 656],
     [981, 656],
     [983, 659],
     [1026, 657],
     [1032, 659]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [939, 683]}],
   "box": [845, 670, 1035, 697],
   "polygons": [
    [
     [1035, 681],
     [1031, 695],
     [1007, 695],
     [1004, 697],
     [986, 697],
     [982, 696],
     [982, 694],
     [973, 693],
     [932, 695],
     [929, 694],
     [929, 692],
     [921, 691],
     [877, 692],
     [869, 689],
     [846, 689],
     [845, 681],
     [846, 670],
     [850, 670],
     [851, 672],
     [863, 673],
     [877, 670],
     [901, 671],
     [901, 673],
     [905, 674],
     [939, 672],
     [954, 673],
     [955, 675],
     [963, 676],
     [980, 674],
     [1008, 674],
     [1013, 677],
     [1032, 678]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [939, 702]}],
   "box": [845, 690, 1034, 716],
   "polygons": [
    [
     [1034, 706],
     [1031, 715],
     [1028, 716],
     [989, 711],
     [983, 711],
     [975, 714],
     [955, 712],
     [953, 710],
     [945, 709],
     [929, 709],
     [926, 711],
     [914, 711],
     [903, 710],
     [902, 708],
     [893, 707],
     [879, 707],
     [873, 709],
     [851, 708],
     [846, 705],
     [845, 690],
     [875, 690],
     [877, 693],
     [893, 693],
     [902, 691],
     [928, 692],
     [929, 695],
     [945, 695],
     [954, 693],
     [981, 694],
     [983, 697],
     [1023, 695],
     [1032, 696]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [939, 721]}],
   "box": [846, 706, 1034, 735],
   "polygons": [
    [
     [1034, 733],
     [1009, 733],
     [1000, 735],
     [987, 735],
     [982, 734],
     [982, 732],
     [957, 730],
     [953, 732],
     [929, 732],
     [929, 730],
     [921, 728],
     [905, 728],
     [901, 730],
     [877, 730],
     [869, 726],
     [846, 725],
     [846, 706],
     [849, 706],
     [853, 709],
     [865, 710],
     [875, 709],
     [876, 707],
     [901, 708],
     [903, 711],
     [915, 712],
     [927, 711],
     [928, 709],
     [954, 710],
     [955, 713],
     [967, 714],
     [980, 711],
     [1005, 712],
     [1019, 716],
     [1032, 717],
     [1034, 723]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [939, 740]}],
   "box": [846, 726, 1033, 754],
   "polygons": [
    [
     [1033, 745],
     [1029, 754],
     [1012, 753],
     [1006, 750],
     [997, 749],
     [983, 749],
     [974, 752],
     [937, 746],
     [929, 746],
     [927, 748],
     [922, 749],
     [902, 747],
     [893, 744],
     [864, 746],
     [846, 742],
     [846, 726],
     [876, 727],
     [876, 729],
     [887, 731],
     [893, 731],
     [902, 728],
     [924, 729],
     [928, 730],
     [929, 733],
     [968, 731],
     [981, 732],
     [983, 735],
     [1001, 735],
     [1010, 733],
     [1032, 734]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [939, 758]}],
   "box": [846, 743, 1033, 772],
   "polygons": [
    [
     [1033, 761],
     [1032, 772],
     [983, 771],
     [967, 768],
     [929, 768],
     [915, 765],
     [877, 765],
     [869, 763],
     [846, 762],
     [846, 743],
     [865, 747],
     [876, 744],
     [901, 745],
     [901, 747],
     [903, 748],
     [911, 749],
     [944, 747],
     [954, 748],
     [957, 751],
     [967, 752],
     [980, 751],
     [980, 749],
     [1005, 750],
     [1015, 754],
     [1031, 755],
     [1033, 757]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [939, 777]}],
   "box": [846, 763, 1034, 793],
   "polygons": [
    [
     [1034, 791],
     [1030, 793],
     [997, 787],
     [979, 787],
     [977, 789],
     [969, 789],
     [947, 784],
     [933, 783],
     [927, 783],
     [926, 785],
     [918, 786],
     [903, 784],
     [902, 782],
     [883, 780],
     [877, 780],
     [871, 783],
     [851, 781],
     [846, 778],
     [846, 763],
     [865, 763],
     [876, 764],
     [879, 766],
     [920, 766],
     [937, 769],
     [959, 768],
     [975, 769],
     [981, 771],
     [1030, 772],
     [1032, 773]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [939, 795]}],
   "box": [845, 779, 1034, 809],
   "polygons": [
    [
     [1034, 795],
     [1031, 808],
     [1009, 807],
     [999, 809],
     [984, 809],
     [969, 804],
     [929, 805],
     [925, 802],
     [917, 801],
     [877, 802],
     [869, 798],
     [846, 797],
     [845, 781],
     [846, 779],
     [849, 779],
     [851, 782],
     [861, 783],
     [890, 781],
     [901, 782],
     [903, 785],
     [919, 787],
     [927, 784],
     [940, 784],
     [954, 785],
     [957, 788],
     [965, 789],
     [995, 787],
     [1007, 788],
     [1015, 792],
     [1032, 793]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [939, 813]}],
   "box": [846, 798, 1033, 831],
   "polygons": [
    [
     [1033, 821],
     [1032, 830],
     [1029, 831],
     [1003, 826],
     [983, 824],
     [972, 827],
     [949, 822],
     [933, 821],
     [918, 823],
     [881, 817],
     [869, 820],
     [846, 815],
     [846, 798],
     [876, 799],
     [877, 803],
     [893, 803],
     [908, 801],
     [924, 802],
     [928, 803],
     [929, 806],
     [945, 806],
     [958, 804],
     [981, 806],
     [983, 809],
     [1032, 809]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [939, 832]}],
   "box": [845, 816, 1034, 847],
   "polygons": [
    [
     [1034, 835],
     [1031, 847],
     [983, 847],
     [975, 842],
     [955, 841],
     [953, 843],
     [930, 843],
     [919, 838],
     [877, 839],
     [869, 835],
     [848, 834],
     [846, 833],
     [845, 829],
     [846, 816],
     [850, 816],
     [851, 818],
     [863, 820],
     [876, 818],
     [901, 819],
     [901, 821],
     [911, 823],
     [925, 823],
     [927, 821],
     [947, 822],
     [965, 827],
     [980, 825],
     [1007, 826],
     [1023, 831],
     [1033, 830]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [939, 851]}],
   "box": [845, 834, 1033, 869],
   "polygons": [
    [
     [1033, 853],
     [1032, 868],
     [1025, 869],
     [999, 863],
     [981, 862],
     [979, 864],
     [974, 865],
     [956, 862],
     [955, 860],
     [931, 858],
     [921, 861],
     [903, 858],
     [902, 856],
     [881, 854],
     [869, 857],
     [846, 852],
     [845, 846],
     [846, 834],
     [854, 834],
     [876, 836],
     [877, 840],
     [893, 840],
     [907, 838],
     [928, 840],
     [929, 843],
     [976, 843],
     [982, 844],
     [982, 847],
     [1030, 847]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [939, 870]}],
   "box": [846, 853, 1034, 885],
   "polygons": [
    [
     [1034, 876],
     [1031, 883],
     [1027, 885],
     [1009, 883],
     [1007, 885],
     [983, 885],
     [975, 880],
     [929, 880],
     [921, 876],
     [911, 875],
     [877, 876],
     [867, 871],
     [846, 870],
     [846, 853],
     [863, 857],
     [876, 855],
     [901, 856],
     [903, 859],
     [915, 861],
     [927, 858],
     [954, 860],
     [956, 863],
     [967, 865],
     [981, 863],
     [1006, 864],
     [1023, 869],
     [1032, 869]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [939, 888]}],
   "box": [845, 871, 1034, 908],
   "polygons": [
    [
     [1034, 888],
     [1033, 905],
     [1032, 907],
     [1027, 908],
     [989, 900],
     [983, 900],
     [975, 903],
     [941, 896],
     [927, 895],
     [926, 897],
     [918, 898],
     [893, 892],
     [881, 891],
     [869, 894],
     [853, 891],
     [846, 888],
     [845, 881],
     [846, 871],
     [856, 871],
     [876, 873],
     [877, 877],
     [893, 877],
     [905, 875],
     [928, 877],
     [929, 881],
     [945, 881],
     [954, 879],
     [981, 881],
     [982, 885],
     [1019, 884],
     [1029, 885]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [947, 907]}],
   "box": [846, 889, 1034, 923],
   "polygons": [
    [
     [1034, 923],
     [984, 923],
     [974, 918],
     [956, 916],
     [953, 918],
     [929, 918],
     [919, 913],
     [914, 907],
     [883, 904],
     [878, 905],
     [869, 903],
     [866, 904],
     [846, 901],
     [846, 889],
     [866, 894],
     [875, 893],
     [876, 891],
     [900, 893],
     [908, 897],
     [920, 899],
     [927, 896],
     [949, 897],
     [968, 903],
     [980, 902],
     [980, 900],
     [1007, 902],
     [1012, 905],
     [1029, 908],
     [1033, 915]
    ],
    [[901, 913], [878, 913], [877, 911], [879, 909], [887, 909], [898, 910]]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [925, 923]}],
   "box": [846, 908, 1033, 936],
   "polygons": [
    [[872, 919], [870, 931], [846, 925], [846, 908], [869, 909]],
    [[904, 916], [889, 928], [880, 928], [877, 926], [878, 914], [895, 914], [904, 912]],
    [
     [1033, 927],
     [1029, 933],
     [1018, 933],
     [1000, 930],
     [999, 931],
     [953, 926],
     [947, 928],
     [942, 933],
     [926, 933],
     [922, 936],
     [918, 934],
     [919, 914],
     [926, 914],
     [928, 915],
     [928, 917],
     [935, 919],
     [962, 917],
     [982, 919],
     [983, 923],
     [1027, 923],
     [1033, 924]
    ],
    [
     [914, 932],
     [911, 934],
     [905, 932],
     [905, 923],
     [910, 918],
     [912, 918],
     [913, 920],
     [912, 923]
    ],
    [[901, 930], [895, 929], [898, 927]]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [899, 935]}],
   "box": [846, 926, 939, 950],
   "polygons": [
    [[869, 933], [863, 934], [846, 932], [846, 926], [868, 931]],
    [[886, 930], [882, 934], [878, 935], [877, 932], [879, 928]],
    [[901, 936], [895, 937], [892, 935], [894, 930], [900, 930]],
    [[912, 935], [911, 938], [904, 934], [905, 933]],
    [[939, 934], [930, 945], [919, 950], [917, 943], [918, 936], [927, 933]]
   ]
  }
 ],
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [957, 62]}],
   "box": [845, 11, 1071, 113],
   "polygons": [
    [
     [1071, 112],
     [1066, 113],
     [1058, 106],
     [1058, 104],
     [1055, 103],
     [1046, 94],
     [1037, 88],
     [1033, 84],
     [1033, 82],
     [1013, 82],
     [1009, 84],
     [1003, 84],
     [999, 87],
     [981, 90],
     [961, 90],
     [949, 92],
     [947, 94],
     [938, 96],
     [924, 98],
     [913, 97],
     [899, 99],
     [897, 102],
     [876, 105],
     [865, 104],
     [845, 107],
     [845, 56],
     [863, 54],
     [864, 39],
     [866, 37],
     [1019, 11],
     [1025, 13],
     [1051, 38],
     [1053, 47],
     [1070, 63]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [958, 104]}],
   "box": [844, 81, 1071, 129],
   "polygons": [
    [
     [1071, 120],
     [1070, 129],
     [1066, 129],
     [1058, 122],
     [1058, 120],
     [1054, 119],
     [1054, 117],
     [1034, 102],
     [1018, 102],
     [1005, 104],
     [989, 103],
     [977, 105],
     [967, 109],
     [926, 112],
     [923, 113],
     [922, 115],
     [917, 116],
     [903, 118],
     [884, 118],
     [872, 122],
     [854, 125],
     [850, 125],
     [845, 123],
     [844, 121],
     [845, 108],
     [848, 107],
     [871, 104],
     [887, 104],
     [898, 100],
     [919, 97],
     [935, 97],
     [948, 93],
     [973, 89],
     [989, 89],
     [1000, 85],
     [1025, 81],
     [1031, 81],
     [1057, 104],
     [1070, 113]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [959, 123]}],
   "box": [844, 98, 1071, 149],
   "polygons": [
    [
     [1071, 148],
     [1067, 149],
     [1042, 130],
     [1039, 126],
     [1024, 120],
     [1004, 122],
     [999, 125],
     [978, 128],
     [968, 127],
     [950, 129],
     [947, 132],
     [941, 133],
     [925, 135],
     [905, 135],
     [899, 136],
     [896, 139],
     [889, 140],
     [863, 141],
     [851, 143],
     [845, 143],
     [844, 126],
     [845, 124],
     [857, 125],
     [897, 117],
     [899, 119],
     [914, 117],
     [926, 113],
     [947, 110],
     [965, 110],
     [975, 106],
     [994, 103],
     [1016, 103],
     [1031, 98],
     [1070, 130]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [958, 141]}],
   "box": [844, 118, 1071, 166],
   "polygons": [
    [
     [1071, 154],
     [1070, 166],
     [1039, 144],
     [1037, 141],
     [1025, 139],
     [1004, 142],
     [983, 142],
     [974, 144],
     [972, 146],
     [957, 148],
     [935, 148],
     [923, 150],
     [922, 152],
     [918, 153],
     [902, 155],
     [881, 155],
     [875, 156],
     [872, 159],
     [857, 161],
     [850, 161],
     [845, 159],
     [844, 148],
     [845, 144],
     [849, 143],
     [867, 141],
     [878, 142],
     [905, 136],
     [920, 134],
     [938, 134],
     [949, 130],
     [973, 127],
     [980, 128],
     [1030, 118],
     [1032, 121],
     [1070, 150]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [959, 160]}],
   "box": [845, 136, 1071, 185],
   "polygons": [
    [
     [1071, 184],
     [1067, 185],
     [1058, 179],
     [1058, 177],
     [1054, 174],
     [1050, 173],
     [1050, 171],
     [1046, 170],
     [1046, 168],
     [1042, 167],
     [1037, 162],
     [1033, 161],
     [1033, 159],
     [1025, 158],
     [995, 163],
     [956, 166],
     [944, 169],
     [914, 171],
     [894, 175],
     [854, 179],
     [845, 179],
     [845, 160],
     [860, 161],
     [874, 157],
     [897, 154],
     [914, 154],
     [935, 149],
     [961, 148],
     [995, 141],
     [1018, 141],
     [1031, 136],
     [1070, 167]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [957, 179]}],
   "box": [844, 157, 1071, 201],
   "polygons": [
    [
     [1071, 200],
     [1067, 201],
     [1033, 178],
     [1010, 180],
     [980, 180],
     [974, 181],
     [971, 184],
     [953, 186],
     [938, 185],
     [923, 187],
     [922, 189],
     [919, 190],
     [902, 192],
     [881, 192],
     [869, 196],
     [860, 197],
     [845, 196],
     [844, 189],
     [845, 180],
     [890, 176],
     [962, 166],
     [997, 163],
     [1031, 157],
     [1037, 163],
     [1070, 185]
    ]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [959, 197]}],
   "box": [845, 175, 1071, 220],
   "polygons": [
    [
     [1071, 220],
     [1066, 220],
     [1062, 216],
     [1058, 215],
     [1052, 210],
     [1037, 202],
     [1033, 198],
     [1025, 196],
     [1001, 199],
     [996, 202],
     [978, 204],
     [959, 203],
     [939, 208],
     [914, 208],
     [899, 210],
     [893, 213],
     [884, 213],
     [876, 215],
     [860, 214],
     [847, 216],
     [845, 215],
     [845, 197],
     [865, 197],
     [886, 192],
     [914, 191],
     [923, 188],
     [947, 185],
     [965, 185],
     [973, 182],
     [998, 179],
     [1019, 179],
     [1031, 175],
     [1070, 202]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [958, 216]}],
   "box": [844, 196, 1071, 237],
   "polygons": [
    [
     [1071, 237],
     [1067, 237],
     [1034, 216],
     [974, 219],
     [971, 222],
     [951, 224],
     [923, 224],
     [921, 227],
     [901, 229],
     [884, 228],
     [872, 232],
     [853, 234],
     [845, 232],
     [844, 224],
     [845, 216],
     [889, 214],
     [905, 210],
     [935, 209],
     [951, 205],
     [968, 203],
     [991, 203],
     [1004, 199],
     [1030, 196],
     [1070, 221]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [958, 235]}],
   "box": [844, 213, 1071, 256],
   "polygons": [
    [
     [1071, 248],
     [1070, 256],
     [1066, 256],
     [1034, 237],
     [1022, 235],
     [1001, 237],
     [996, 240],
     [977, 242],
     [956, 241],
     [944, 245],
     [917, 245],
     [899, 247],
     [897, 249],
     [892, 250],
     [866, 250],
     [845, 252],
     [844, 234],
     [868, 233],
     [877, 230],
     [895, 228],
     [908, 229],
     [923, 225],
     [942, 223],
     [956, 224],
     [973, 220],
     [998, 217],
     [1001, 219],
     [1019, 218],
     [1030, 213],
     [1069, 237]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [958, 253]}],
   "box": [845, 234, 1071, 273],
   "polygons": [
    [
     [1071, 270],
     [1070, 273],
     [1066, 272],
     [1041, 258],
     [1034, 255],
     [1025, 254],
     [1001, 257],
     [980, 256],
     [968, 260],
     [926, 261],
     [923, 262],
     [922, 264],
     [901, 266],
     [884, 265],
     [875, 266],
     [869, 269],
     [850, 270],
     [845, 268],
     [845, 253],
     [872, 250],
     [889, 251],
     [898, 248],
     [915, 246],
     [941, 246],
     [948, 243],
     [965, 241],
     [991, 241],
     [1000, 238],
     [1031, 234],
     [1036, 239],
     [1070, 257]
    ]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [958, 272]}],
   "box": [845, 252, 1071, 292],
   "polygons": [
    [
     [1071, 291],
     [1066, 292],
     [1046, 282],
     [1041, 278],
     [1034, 276],
     [1033, 274],
     [1025, 273],
     [1001, 275],
     [999, 277],
     [987, 279],
     [963, 278],
     [937, 283],
     [905, 283],
     [890, 287],
     [845, 288],
     [845, 269],
     [863, 270],
     [880, 266],
     [917, 265],
     [925, 262],
     [935, 261],
     [965, 261],
     [976, 257],
     [986, 256],
     [1011, 257],
     [1026, 252],
     [1030, 252],
     [1071, 274]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [958, 291]}],
   "box": [844, 273, 1071, 309],
   "polygons": [
    [
     [1071, 306],
     [1067, 309],
     [1064, 306],
     [1033, 293],
     [1000, 295],
     [980, 294],
     [974, 295],
     [972, 297],
     [950, 299],
     [941, 297],
     [923, 299],
     [922, 301],
     [901, 303],
     [890, 301],
     [878, 302],
     [872, 305],
     [852, 307],
     [845, 305],
     [844, 303],
     [845, 289],
     [868, 287],
     [884, 288],
     [916, 283],
     [932, 284],
     [962, 279],
     [983, 280],
     [1000, 276],
     [1031, 273],
     [1069, 292],
     [1071, 294]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [958, 309]}],
   "box": [844, 291, 1071, 328],
   "polygons": [
    [
     [1071, 328],
     [1067, 328],
     [1033, 313],
     [1025, 312],
     [1005, 313],
     [997, 316],
     [984, 317],
     [959, 316],
     [949, 317],
     [947, 319],
     [941, 320],
     [927, 321],
     [903, 320],
     [897, 323],
     [888, 324],
     [865, 323],
     [846, 325],
     [844, 311],
     [846, 305],
     [853, 307],
     [873, 305],
     [875, 303],
     [909, 303],
     [927, 299],
     [959, 299],
     [976, 295],
     [989, 294],
     [1017, 295],
     [1027, 291],
     [1032, 291],
     [1070, 309]
    ]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [950, 327]}],
   "box": [844, 312, 1067, 343],
   "polygons": [
    [
     [1067, 330],
     [1061, 333],
     [1050, 334],
     [1043, 334],
     [1034, 331],
     [1009, 333],
     [991, 331],
     [977, 332],
     [971, 335],
     [931, 335],
     [914, 339],
     [899, 340],
     [889, 338],
     [875, 339],
     [871, 342],
     [850, 343],
     [845, 341],
     [844, 328],
     [845, 325],
     [881, 325],
     [902, 321],
     [917, 320],
     [939, 321],
     [956, 317],
     [991, 317],
     [1010, 313],
     [1032, 312],
     [1065, 326]
    ]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [932, 347]}],
   "box": [844, 329, 1038, 361],
   "polygons": [
    [[1033, 332], [1024, 334], [1017, 338], [1016, 335], [1016, 333], [1028, 329], [1031, 329]],
    [
     [1016, 347],
     [1013, 351],
     [1003, 351],
     [999, 354],
     [953, 354],
     [947, 357],
     [905, 357],
     [897, 358],
     [897, 360],
     [884, 361],
     [846, 361],
     [844, 357],
     [845, 342],
     [865, 343],
     [874, 340],
     [907, 340],
     [930, 336],
     [946, 335],
     [951, 337],
     [989, 332],
     [1014, 333]
    ],
    [[1038, 335], [1032, 335], [1036, 332]]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [920, 366]}],
   "box": [845, 352, 1013, 379],
   "polygons": [
    [
     [1013, 355],
     [1009, 359],
     [998, 359],
     [997, 361],
     [989, 363],
     [987, 369],
     [985, 370],
     [973, 370],
     [971, 373],
     [948, 374],
     [935, 372],
     [923, 373],
     [922, 375],
     [915, 376],
     [883, 375],
     [873, 376],
     [873, 378],
     [861, 379],
     [850, 379],
     [845, 377],
     [845, 361],
     [889, 361],
     [902, 358],
     [919, 357],
     [925, 359],
     [968, 354],
     [993, 355],
     [1002, 352],
     [1013, 352]
    ]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [920, 385]}],
   "box": [844, 370, 1013, 398],
   "polygons": [
    [
     [990, 390],
     [989, 392],
     [981, 393],
     [949, 392],
     [947, 394],
     [940, 395],
     [901, 394],
     [897, 395],
     [897, 397],
     [878, 398],
     [867, 396],
     [845, 397],
     [844, 382],
     [845, 378],
     [865, 379],
     [883, 376],
     [911, 377],
     [923, 374],
     [963, 374],
     [973, 373],
     [974, 371],
     [988, 370],
     [990, 371]
    ],
    [[1013, 381], [1012, 383], [1007, 383], [1001, 378], [1005, 373], [1011, 371], [1013, 375]],
    [[997, 382], [994, 378], [997, 380]],
    [[1001, 381], [999, 382], [999, 380]]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [916, 404]}],
   "box": [845, 392, 990, 416],
   "polygons": [
    [
     [990, 406],
     [986, 408],
     [973, 408],
     [972, 410],
     [963, 411],
     [923, 410],
     [922, 412],
     [918, 413],
     [879, 412],
     [873, 413],
     [871, 415],
     [851, 416],
     [845, 413],
     [845, 398],
     [889, 398],
     [898, 397],
     [900, 395],
     [922, 394],
     [923, 396],
     [929, 396],
     [948, 393],
     [973, 392],
     [989, 393]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [917, 422]}],
   "box": [845, 408, 990, 434],
   "polygons": [
    [
     [990, 428],
     [989, 430],
     [976, 431],
     [967, 429],
     [953, 429],
     [947, 432],
     [899, 431],
     [895, 434],
     [845, 433],
     [845, 414],
     [853, 416],
     [874, 413],
     [897, 412],
     [899, 414],
     [911, 414],
     [923, 411],
     [947, 410],
     [949, 412],
     [955, 412],
     [987, 408],
     [989, 410],
     [988, 419]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [916, 441]}],
   "box": [845, 430, 989, 452],
   "polygons": [
    [
     [989, 435],
     [987, 444],
     [985, 446],
     [975, 446],
     [972, 448],
     [949, 449],
     [941, 447],
     [929, 447],
     [923, 448],
     [922, 450],
     [875, 449],
     [873, 451],
     [863, 452],
     [850, 452],
     [845, 450],
     [845, 434],
     [872, 433],
     [875, 435],
     [889, 435],
     [898, 432],
     [941, 433],
     [951, 430],
     [974, 430],
     [988, 431]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [917, 459]}],
   "box": [844, 447, 990, 471],
   "polygons": [
    [
     [990, 451],
     [988, 468],
     [984, 469],
     [965, 467],
     [949, 467],
     [947, 469],
     [931, 470],
     [899, 468],
     [897, 470],
     [892, 471],
     [845, 469],
     [844, 454],
     [845, 451],
     [848, 450],
     [854, 452],
     [895, 449],
     [898, 449],
     [899, 451],
     [915, 451],
     [928, 448],
     [965, 449],
     [973, 447],
     [989, 447]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [916, 478]}],
   "box": [845, 468, 991, 488],
   "polygons": [
    [
     [991, 472],
     [990, 483],
     [976, 484],
     [972, 486],
     [923, 485],
     [922, 487],
     [898, 487],
     [887, 485],
     [875, 486],
     [872, 488],
     [850, 488],
     [845, 486],
     [845, 470],
     [889, 471],
     [898, 469],
     [939, 470],
     [948, 468],
     [973, 468],
     [988, 469]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [917, 496]}],
   "box": [845, 484, 990, 508],
   "polygons": [
    [
     [990, 500],
     [988, 506],
     [982, 507],
     [965, 505],
     [949, 505],
     [947, 507],
     [899, 505],
     [897, 507],
     [877, 508],
     [869, 506],
     [845, 506],
     [845, 487],
     [849, 487],
     [851, 489],
     [861, 489],
     [877, 486],
     [898, 486],
     [899, 488],
     [915, 488],
     [923, 486],
     [947, 485],
     [949, 487],
     [965, 487],
     [986, 484]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [916, 515]}],
   "box": [845, 505, 990, 524],
   "polygons": [
    [
     [990, 518],
     [986, 522],
     [973, 522],
     [972, 524],
     [948, 524],
     [939, 522],
     [923, 522],
     [922, 524],
     [899, 524],
     [891, 522],
     [875, 522],
     [873, 524],
     [850, 524],
     [845, 523],
     [845, 507],
     [873, 506],
     [875, 508],
     [889, 508],
     [898, 506],
     [922, 506],
     [923, 508],
     [939, 508],
     [948, 506],
     [972, 505],
     [973, 507],
     [990, 507],
     [988, 509]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [917, 533]}],
   "box": [844, 522, 991, 545],
   "polygons": [
    [
     [991, 528],
     [988, 545],
     [979, 545],
     [965, 542],
     [949, 542],
     [947, 544],
     [923, 544],
     [915, 542],
     [899, 542],
     [897, 544],
     [874, 544],
     [865, 542],
     [845, 542],
     [844, 537],
     [846, 523],
     [849, 523],
     [851, 525],
     [865, 525],
     [873, 523],
     [897, 523],
     [899, 525],
     [915, 525],
     [923, 523],
     [948, 523],
     [949, 525],
     [965, 525],
     [978, 522],
     [989, 522]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [916, 552]}],
   "box": [844, 543, 989, 562],
   "polygons": [
    [
     [989, 551],
     [986, 560],
     [973, 560],
     [972, 562],
     [954, 562],
     [941, 559],
     [923, 559],
     [922, 561],
     [899, 561],
     [891, 559],
     [873, 559],
     [872, 561],
     [851, 561],
     [844, 558],
     [845, 543],
     [873, 543],
     [875, 545],
     [889, 545],
     [898, 543],
     [922, 543],
     [923, 545],
     [973, 543],
     [973, 545],
     [986, 545],
     [988, 546]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [918, 571]}],
   "box": [845, 560, 990, 583],
   "polygons": [
    [
     [990, 580],
     [985, 583],
     [965, 580],
     [949, 580],
     [947, 582],
     [924, 582],
     [915, 579],
     [899, 579],
     [897, 581],
     [846, 579],
     [845, 560],
     [849, 560],
     [851, 562],
     [865, 562],
     [873, 560],
     [898, 560],
     [899, 562],
     [915, 562],
     [923, 560],
     [948, 560],
     [949, 562],
     [965, 562],
     [974, 560],
     [985, 560],
     [989, 561]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [915, 589]}],
   "box": [844, 579, 989, 600],
   "polygons": [
    [
     [989, 596],
     [983, 598],
     [972, 598],
     [970, 600],
     [948, 599],
     [939, 597],
     [915, 599],
     [891, 596],
     [859, 598],
     [845, 595],
     [844, 586],
     [845, 579],
     [870, 579],
     [875, 582],
     [889, 582],
     [898, 580],
     [922, 580],
     [925, 583],
     [939, 583],
     [948, 581],
     [973, 581],
     [973, 583],
     [989, 583]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [917, 608]}],
   "box": [845, 596, 990, 621],
   "polygons": [
    [
     [990, 619],
     [988, 621],
     [974, 621],
     [965, 618],
     [949, 617],
     [947, 619],
     [923, 619],
     [915, 616],
     [899, 616],
     [897, 618],
     [874, 618],
     [865, 615],
     [845, 615],
     [845, 596],
     [849, 596],
     [851, 598],
     [865, 598],
     [873, 596],
     [898, 597],
     [899, 599],
     [929, 597],
     [947, 598],
     [949, 600],
     [986, 598],
     [990, 600]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [916, 626]}],
   "box": [845, 616, 989, 638],
   "polygons": [
    [
     [989, 635],
     [979, 635],
     [964, 638],
     [949, 637],
     [941, 634],
     [923, 634],
     [921, 636],
     [898, 635],
     [881, 632],
     [875, 632],
     [873, 634],
     [852, 634],
     [845, 631],
     [845, 616],
     [873, 616],
     [875, 619],
     [889, 619],
     [898, 617],
     [920, 617],
     [923, 620],
     [939, 620],
     [948, 618],
     [973, 619],
     [973, 621],
     [988, 622]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [917, 645]}],
   "box": [844, 632, 991, 659],
   "polygons": [
    [
     [991, 643],
     [989, 645],
     [989, 654],
     [987, 659],
     [977, 659],
     [961, 655],
     [949, 655],
     [947, 657],
     [924, 657],
     [917, 654],
     [909, 653],
     [899, 653],
     [897, 655],
     [874, 655],
     [865, 652],
     [845, 651],
     [844, 636],
     [845, 632],
     [857, 635],
     [889, 633],
     [909, 637],
     [923, 635],
     [947, 635],
     [949, 638],
     [965, 639],
     [973, 636],
     [988, 636],
     [990, 637]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [916, 664]}],
   "box": [844, 652, 990, 676],
   "polygons": [
    [
     [990, 665],
     [987, 674],
     [975, 673],
     [972, 675],
     [965, 676],
     [931, 671],
     [923, 671],
     [922, 673],
     [907, 673],
     [885, 669],
     [877, 669],
     [873, 671],
     [850, 670],
     [845, 667],
     [844, 663],
     [845, 652],
     [860, 652],
     [873, 653],
     [873, 655],
     [875, 656],
     [916, 654],
     [922, 655],
     [923, 657],
     [927, 658],
     [968, 656],
     [988, 660]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [918, 682]}],
   "box": [845, 668, 990, 697],
   "polygons": [
    [
     [990, 693],
     [989, 697],
     [974, 697],
     [970, 694],
     [961, 693],
     [923, 694],
     [923, 692],
     [905, 690],
     [899, 690],
     [897, 692],
     [874, 692],
     [865, 688],
     [847, 688],
     [845, 687],
     [845, 668],
     [861, 672],
     [874, 671],
     [875, 669],
     [892, 670],
     [898, 671],
     [899, 673],
     [907, 674],
     [923, 672],
     [939, 672],
     [955, 676],
     [983, 674],
     [988, 675],
     [989, 677]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [915, 701]}],
   "box": [844, 688, 990, 714],
   "polygons": [
    [
     [990, 704],
     [987, 712],
     [973, 711],
     [972, 713],
     [968, 714],
     [949, 712],
     [948, 710],
     [923, 708],
     [922, 710],
     [915, 711],
     [899, 709],
     [891, 706],
     [877, 706],
     [864, 708],
     [854, 707],
     [845, 704],
     [844, 694],
     [845, 688],
     [873, 689],
     [873, 691],
     [875, 692],
     [883, 693],
     [913, 691],
     [922, 692],
     [923, 695],
     [939, 695],
     [948, 693],
     [974, 694],
     [975, 698],
     [988, 698]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [918, 720]}],
   "box": [844, 705, 991, 735],
   "polygons": [
    [
     [991, 716],
     [989, 724],
     [990, 735],
     [977, 735],
     [974, 734],
     [974, 732],
     [951, 730],
     [947, 732],
     [925, 732],
     [917, 728],
     [905, 727],
     [899, 727],
     [897, 729],
     [885, 729],
     [874, 728],
     [861, 724],
     [844, 723],
     [845, 705],
     [849, 705],
     [849, 707],
     [865, 709],
     [873, 706],
     [880, 706],
     [897, 707],
     [899, 710],
     [907, 711],
     [923, 709],
     [947, 710],
     [949, 713],
     [959, 714],
     [985, 712]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [916, 738]}],
   "box": [845, 724, 991, 752],
   "polygons": [
    [
     [991, 749],
     [973, 749],
     [972, 751],
     [965, 752],
     [950, 750],
     [949, 748],
     [937, 746],
     [912, 748],
     [899, 746],
     [899, 744],
     [891, 743],
     [875, 742],
     [873, 744],
     [867, 745],
     [850, 743],
     [845, 740],
     [845, 724],
     [868, 725],
     [873, 726],
     [873, 729],
     [922, 729],
     [923, 732],
     [929, 733],
     [939, 733],
     [948, 731],
     [973, 732],
     [979, 735],
     [986, 736],
     [991, 742]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [917, 756]}],
   "box": [844, 741, 991, 771],
   "polygons": [
    [
     [991, 758],
     [990, 767],
     [986, 771],
     [974, 771],
     [959, 768],
     [923, 767],
     [905, 764],
     [883, 764],
     [845, 760],
     [844, 745],
     [845, 741],
     [848, 741],
     [849, 743],
     [861, 745],
     [874, 743],
     [898, 744],
     [899, 747],
     [913, 749],
     [923, 746],
     [942, 747],
     [959, 752],
     [973, 750],
     [988, 750]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [916, 775]}],
   "box": [844, 761, 991, 789],
   "polygons": [
    [
     [991, 783],
     [987, 787],
     [961, 789],
     [933, 783],
     [912, 785],
     [883, 779],
     [862, 781],
     [850, 779],
     [845, 776],
     [844, 765],
     [845, 761],
     [859, 761],
     [873, 762],
     [875, 764],
     [911, 765],
     [931, 768],
     [973, 770],
     [975, 772],
     [987, 773]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [918, 793]}],
   "box": [844, 777, 991, 809],
   "polygons": [
    [
     [991, 804],
     [987, 809],
     [975, 809],
     [967, 805],
     [959, 804],
     [929, 805],
     [913, 800],
     [874, 800],
     [865, 796],
     [846, 795],
     [844, 783],
     [846, 777],
     [851, 780],
     [857, 781],
     [871, 781],
     [874, 779],
     [898, 781],
     [901, 784],
     [907, 785],
     [921, 785],
     [923, 783],
     [940, 784],
     [961, 790],
     [973, 789],
     [973, 787],
     [988, 788],
     [991, 797]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [915, 811]}],
   "box": [845, 795, 989, 827],
   "polygons": [
    [
     [989, 821],
     [987, 825],
     [964, 827],
     [949, 824],
     [947, 822],
     [925, 820],
     [919, 822],
     [912, 822],
     [901, 820],
     [899, 818],
     [883, 816],
     [873, 816],
     [866, 818],
     [845, 813],
     [845, 795],
     [871, 797],
     [873, 798],
     [873, 801],
     [905, 800],
     [922, 802],
     [923, 805],
     [939, 806],
     [951, 804],
     [973, 806],
     [978, 809],
     [987, 810]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [918, 830]}],
   "box": [844, 813, 990, 848],
   "polygons": [
    [
     [990, 842],
     [988, 847],
     [984, 848],
     [974, 847],
     [961, 842],
     [923, 842],
     [913, 837],
     [874, 837],
     [863, 832],
     [845, 831],
     [844, 819],
     [845, 814],
     [848, 813],
     [851, 816],
     [861, 818],
     [880, 816],
     [898, 818],
     [901, 821],
     [913, 823],
     [923, 820],
     [945, 822],
     [953, 826],
     [959, 827],
     [971, 827],
     [973, 825],
     [987, 826]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [915, 848]}],
   "box": [845, 832, 990, 865],
   "polygons": [
    [
     [990, 856],
     [986, 864],
     [973, 862],
     [969, 865],
     [963, 865],
     [935, 858],
     [921, 858],
     [916, 860],
     [901, 857],
     [895, 854],
     [873, 852],
     [870, 854],
     [863, 854],
     [845, 849],
     [845, 832],
     [873, 834],
     [873, 837],
     [875, 838],
     [915, 838],
     [922, 839],
     [923, 843],
     [956, 842],
     [973, 844],
     [973, 847],
     [975, 848],
     [988, 848]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [918, 867]}],
   "box": [844, 850, 991, 886],
   "polygons": [
    [
     [991, 884],
     [983, 886],
     [974, 885],
     [965, 880],
     [923, 879],
     [913, 874],
     [874, 874],
     [865, 869],
     [845, 867],
     [844, 853],
     [845, 850],
     [849, 850],
     [857, 854],
     [863, 855],
     [873, 853],
     [893, 854],
     [898, 855],
     [901, 858],
     [911, 860],
     [930, 858],
     [948, 860],
     [949, 863],
     [961, 865],
     [973, 863],
     [988, 864],
     [990, 867]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [916, 886]}],
   "box": [845, 868, 991, 903],
   "polygons": [
    [
     [991, 900],
     [990, 902],
     [977, 901],
     [963, 903],
     [941, 896],
     [929, 895],
     [914, 897],
     [891, 890],
     [859, 890],
     [845, 885],
     [845, 868],
     [871, 870],
     [873, 871],
     [873, 875],
     [906, 874],
     [922, 876],
     [923, 880],
     [939, 881],
     [950, 879],
     [973, 882],
     [973, 885],
     [975, 886],
     [986, 886],
     [991, 889]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [923, 905]}],
   "box": [844, 886, 991, 924],
   "polygons": [
    [
     [991, 905],
     [990, 922],
     [986, 924],
     [979, 924],
     [974, 923],
     [969, 919],
     [965, 918],
     [950, 916],
     [923, 917],
     [917, 912],
     [899, 910],
     [883, 904],
     [878, 905],
     [849, 900],
     [845, 898],
     [844, 889],
     [845, 886],
     [866, 892],
     [876, 889],
     [898, 892],
     [902, 895],
     [911, 897],
     [928, 895],
     [945, 897],
     [953, 901],
     [965, 904],
     [973, 901],
     [980, 901],
     [987, 902]
    ],
    [[886, 910], [874, 911], [875, 909]]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [913, 920]}],
   "box": [845, 905, 989, 933],
   "polygons": [
    [[853, 923], [851, 924], [845, 922], [846, 905], [851, 905], [852, 907]],
    [[883, 914], [865, 922], [862, 926], [860, 925], [859, 907], [871, 907], [882, 912]],
    [
     [989, 927],
     [987, 933],
     [973, 930],
     [931, 926],
     [925, 926],
     [918, 930],
     [917, 933],
     [909, 933],
     [901, 931],
     [898, 928],
     [897, 912],
     [905, 911],
     [921, 913],
     [923, 918],
     [954, 917],
     [972, 919],
     [973, 923],
     [987, 924]
    ],
    [[893, 927], [882, 926], [880, 920], [891, 914], [893, 917]],
    [[877, 923], [876, 925], [867, 928], [868, 925]]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [880, 931]}],
   "box": [844, 922, 912, 942],
   "polygons": [
    [[854, 926], [853, 930], [844, 928], [846, 922]],
    [[877, 929], [875, 934], [865, 933], [863, 930], [874, 926], [876, 926]],
    [[893, 932], [892, 935], [889, 936], [881, 935], [880, 932], [882, 927], [892, 928]],
    [[912, 934], [900, 942], [897, 933], [898, 930]]
   ]
  }
 ],
 [
  {
   "color": "#1489d0ff",
   "pois": [{"id": "centroid", "point": [958, 62]}],
   "box": [845, 9, 1072, 110],
   "polygons": [
    [
     [1072, 110],
     [1067, 109],
     [1061, 104],
     [1057, 103],
     [1056, 100],
     [1046, 96],
     [1040, 92],
     [1040, 90],
     [1035, 89],
     [1033, 86],
     [1023, 81],
     [1014, 80],
     [993, 83],
     [990, 86],
     [979, 87],
     [969, 90],
     [959, 89],
     [943, 92],
     [941, 95],
     [926, 98],
     [901, 100],
     [895, 102],
     [894, 104],
     [884, 106],
     [845, 110],
     [845, 61],
     [863, 57],
     [866, 41],
     [1011, 9],
     [1024, 15],
     [1053, 36],
     [1052, 37],
     [1054, 47],
     [1072, 60]
    ]
   ]
  },
  {
   "color": "#1485e8ff",
   "pois": [{"id": "centroid", "point": [959, 104]}],
   "box": [845, 78, 1072, 129],
   "polygons": [
    [
     [1072, 126],
     [1068, 126],
     [1061, 120],
     [1050, 115],
     [1025, 99],
     [1013, 99],
     [999, 102],
     [983, 102],
     [971, 104],
     [965, 108],
     [949, 111],
     [935, 111],
     [923, 113],
     [915, 117],
     [898, 120],
     [884, 120],
     [872, 122],
     [871, 125],
     [851, 129],
     [845, 127],
     [845, 111],
     [869, 107],
     [887, 106],
     [896, 102],
     [917, 98],
     [935, 97],
     [943, 93],
     [964, 89],
     [983, 88],
     [994, 83],
     [1021, 78],
     [1031, 86],
     [1072, 111]
    ]
   ]
  },
  {
   "color": "#148200ff",
   "pois": [{"id": "centroid", "point": [960, 123]}],
   "box": [845, 95, 1072, 147],
   "polygons": [
    [
     [1072, 146],
     [1066, 145],
     [1025, 120],
     [1015, 117],
     [1011, 119],
     [992, 121],
     [990, 124],
     [967, 128],
     [959, 127],
     [947, 129],
     [933, 134],
     [921, 136],
     [911, 135],
     [896, 138],
     [888, 142],
     [863, 144],
     [847, 147],
     [845, 146],
     [845, 129],
     [847, 127],
     [863, 127],
     [872, 123],
     [889, 120],
     [908, 119],
     [918, 115],
     [938, 111],
     [946, 112],
     [959, 110],
     [966, 106],
     [985, 102],
     [995, 103],
     [1010, 101],
     [1019, 95],
     [1027, 101],
     [1072, 127]
    ]
   ]
  },
  {
   "color": "#147e18ff",
   "pois": [{"id": "centroid", "point": [959, 141]}],
   "box": [845, 116, 1072, 165],
   "polygons": [
    [
     [1072, 163],
     [1030, 141],
     [1028, 138],
     [1016, 135],
     [1015, 138],
     [994, 141],
     [977, 141],
     [965, 144],
     [965, 146],
     [945, 149],
     [929, 149],
     [920, 151],
     [915, 154],
     [897, 157],
     [875, 158],
     [869, 162],
     [852, 165],
     [845, 162],
     [845, 147],
     [884, 143],
     [895, 139],
     [917, 135],
     [929, 135],
     [957, 128],
     [983, 126],
     [991, 122],
     [1020, 116],
     [1038, 128],
     [1072, 147]
    ]
   ]
  },
  {
   "color": "#147a30ff",
   "pois": [{"id": "centroid", "point": [960, 160]}],
   "box": [845, 134, 1072, 183],
   "polygons": [
    [
     [1072, 181],
     [1067, 181],
     [1023, 158],
     [1015, 156],
     [995, 159],
     [987, 162],
     [956, 165],
     [929, 171],
     [905, 173],
     [879, 179],
     [869, 179],
     [847, 183],
     [845, 182],
     [845, 166],
     [846, 163],
     [865, 163],
     [875, 159],
     [893, 156],
     [899, 157],
     [941, 148],
     [956, 148],
     [966, 144],
     [987, 140],
     [1007, 140],
     [1020, 134],
     [1071, 163]
    ]
   ]
  },
  {
   "color": "#147648ff",
   "pois": [{"id": "centroid", "point": [958, 179]}],
   "box": [845, 156, 1072, 201],
   "polygons": [
    [
     [1072, 199],
     [1023, 175],
     [1007, 178],
     [998, 178],
     [992, 180],
     [983, 178],
     [949, 186],
     [932, 186],
     [903, 193],
     [887, 193],
     [872, 195],
     [870, 198],
     [851, 201],
     [845, 199],
     [845, 183],
     [875, 180],
     [898, 175],
     [925, 172],
     [961, 165],
     [985, 163],
     [1003, 158],
     [1022, 156],
     [1060, 177],
     [1072, 182]
    ]
   ]
  },
  {
   "color": "#147260ff",
   "pois": [{"id": "centroid", "point": [959, 197]}],
   "box": [845, 173, 1072, 218],
   "polygons": [
    [
     [1072, 218],
     [1068, 218],
     [1034, 201],
     [1015, 195],
     [992, 198],
     [988, 201],
     [980, 201],
     [975, 203],
     [953, 203],
     [938, 208],
     [923, 210],
     [905, 210],
     [889, 215],
     [874, 217],
     [863, 216],
     [854, 218],
     [845, 217],
     [845, 200],
     [860, 200],
     [876, 195],
     [890, 193],
     [899, 194],
     [937, 186],
     [944, 187],
     [959, 185],
     [970, 181],
     [988, 178],
     [1008, 178],
     [1020, 173],
     [1070, 198],
     [1072, 201]
    ]
   ]
  },
  {
   "color": "#146e78ff",
   "pois": [{"id": "centroid", "point": [959, 216]}],
   "box": [845, 194, 1072, 237],
   "polygons": [
    [
     [1072, 234],
     [1068, 234],
     [1024, 214],
     [991, 218],
     [977, 217],
     [962, 222],
     [947, 224],
     [926, 224],
     [920, 225],
     [916, 228],
     [875, 231],
     [872, 232],
     [871, 234],
     [857, 235],
     [851, 237],
     [845, 234],
     [845, 219],
     [870, 216],
     [878, 217],
     [896, 212],
     [935, 209],
     [946, 205],
     [959, 203],
     [977, 203],
     [994, 198],
     [1020, 194],
     [1070, 218],
     [1072, 220]
    ]
   ]
  },
  {
   "color": "#146a90ff",
   "pois": [{"id": "centroid", "point": [959, 235]}],
   "box": [845, 211, 1072, 255],
   "polygons": [
    [
     [1072, 253],
     [1067, 254],
     [1066, 252],
     [1051, 247],
     [1045, 243],
     [1025, 236],
     [1023, 234],
     [1013, 233],
     [992, 236],
     [988, 239],
     [981, 240],
     [959, 240],
     [944, 242],
     [941, 245],
     [924, 247],
     [905, 247],
     [896, 248],
     [892, 251],
     [885, 252],
     [863, 252],
     [845, 255],
     [845, 235],
     [860, 236],
     [873, 232],
     [888, 230],
     [905, 230],
     [918, 226],
     [939, 223],
     [950, 224],
     [976, 218],
     [1004, 217],
     [1010, 216],
     [1019, 211],
     [1038, 221],
     [1072, 235]
    ]
   ]
  },
  {
   "color": "#1466a8ff",
   "pois": [{"id": "centroid", "point": [959, 253]}],
   "box": [845, 233, 1072, 273],
   "polygons": [
    [
     [1072, 270],
     [1067, 270],
     [1024, 253],
     [997, 256],
     [971, 256],
     [965, 259],
     [920, 262],
     [916, 265],
     [900, 267],
     [878, 267],
     [872, 270],
     [852, 273],
     [846, 271],
     [845, 269],
     [845, 256],
     [869, 252],
     [887, 252],
     [896, 249],
     [911, 247],
     [929, 247],
     [943, 243],
     [965, 240],
     [968, 242],
     [1002, 235],
     [1021, 233],
     [1072, 255]
    ]
   ]
  },
  {
   "color": "#1462c0ff",
   "pois": [{"id": "centroid", "point": [960, 272]}],
   "box": [845, 250, 1072, 291],
   "polygons": [
    [
     [1072, 290],
     [1068, 290],
     [1022, 273],
     [1013, 272],
     [995, 274],
     [984, 278],
     [947, 279],
     [936, 283],
     [911, 283],
     [896, 285],
     [890, 288],
     [881, 288],
     [874, 290],
     [866, 288],
     [847, 291],
     [845, 290],
     [845, 271],
     [849, 271],
     [851, 273],
     [863, 272],
     [877, 268],
     [893, 266],
     [911, 266],
     [918, 263],
     [935, 261],
     [953, 261],
     [983, 255],
     [1001, 256],
     [1020, 250],
     [1051, 264],
     [1072, 271]
    ]
   ]
  },
  {
   "color": "#145ed8ff",
   "pois": [{"id": "centroid", "point": [959, 291]}],
   "box": [845, 271, 1072, 308],
   "polygons": [
    [
     [1072, 306],
     [1067, 306],
     [1025, 291],
     [1015, 291],
     [1012, 293],
     [998, 294],
     [971, 294],
     [965, 297],
     [945, 299],
     [929, 298],
     [920, 299],
     [917, 302],
     [898, 304],
     [872, 304],
     [871, 307],
     [850, 308],
     [845, 307],
     [845, 291],
     [865, 289],
     [886, 289],
     [895, 286],
     [910, 284],
     [932, 284],
     [947, 280],
     [964, 278],
     [968, 280],
     [982, 278],
     [991, 275],
     [1020, 271],
     [1027, 275],
     [1070, 290],
     [1072, 292]
    ]
   ]
  },
  {
   "color": "#145af0ff",
   "pois": [{"id": "centroid", "point": [959, 309]}],
   "box": [845, 290, 1072, 327],
   "polygons": [
    [
     [1072, 320],
     [1066, 324],
     [1061, 324],
     [1015, 310],
     [1010, 312],
     [997, 312],
     [991, 313],
     [989, 315],
     [973, 317],
     [953, 316],
     [943, 317],
     [940, 320],
     [901, 321],
     [888, 325],
     [855, 325],
     [845, 327],
     [845, 308],
     [861, 308],
     [872, 305],
     [890, 303],
     [911, 303],
     [919, 300],
     [939, 298],
     [951, 299],
     [967, 295],
     [985, 293],
     [999, 294],
     [1016, 290],
     [1022, 290],
     [1051, 301],
     [1072, 307]
    ],
    [[1072, 323], [1070, 325], [1068, 324]]
   ]
  },
  {
   "color": "#145708ff",
   "pois": [{"id": "centroid", "point": [945, 327]}],
   "box": [845, 310, 1068, 345],
   "polygons": [
    [
     [1060, 325],
     [1049, 330],
     [1028, 330],
     [1013, 328],
     [1012, 331],
     [1005, 332],
     [967, 332],
     [964, 335],
     [932, 335],
     [907, 340],
     [875, 340],
     [871, 343],
     [852, 345],
     [845, 342],
     [846, 327],
     [870, 325],
     [878, 326],
     [910, 321],
     [935, 321],
     [942, 318],
     [962, 316],
     [977, 317],
     [992, 313],
     [1020, 310],
     [1034, 316],
     [1060, 323]
    ],
    [[1068, 326], [1066, 328], [1064, 325]]
   ]
  },
  {
   "color": "#145320ff",
   "pois": [{"id": "centroid", "point": [909, 349]}],
   "box": [845, 331, 991, 362],
   "polygons": [
    [[991, 335], [987, 333], [989, 331]],
    [[985, 333], [981, 334], [982, 332]],
    [
     [974, 341],
     [972, 346],
     [973, 355],
     [951, 354],
     [933, 358],
     [896, 358],
     [893, 361],
     [882, 362],
     [845, 362],
     [845, 343],
     [853, 345],
     [872, 343],
     [874, 341],
     [903, 341],
     [920, 337],
     [957, 336],
     [966, 333],
     [973, 334]
    ]
   ]
  },
  {
   "color": "#144f38ff",
   "pois": [{"id": "centroid", "point": [897, 368]}],
   "box": [845, 354, 966, 381],
   "polygons": [
    [
     [963, 355],
     [961, 360],
     [947, 358],
     [947, 365],
     [944, 374],
     [923, 373],
     [917, 376],
     [906, 377],
     [881, 376],
     [872, 377],
     [871, 379],
     [852, 381],
     [845, 378],
     [845, 364],
     [847, 363],
     [870, 361],
     [875, 363],
     [895, 361],
     [896, 359],
     [929, 359],
     [948, 355]
    ],
    [[966, 355], [964, 357], [965, 354]],
    [[965, 372], [954, 373], [953, 371], [963, 370]]
   ]
  },
  {
   "color": "#144b50ff",
   "pois": [{"id": "centroid", "point": [899, 386]}],
   "box": [845, 371, 973, 399],
   "polygons": [
    [[973, 381], [968, 385], [961, 385], [960, 379], [962, 374], [967, 371], [970, 371]],
    [
     [947, 386],
     [945, 392],
     [936, 395],
     [920, 396],
     [907, 394],
     [895, 395],
     [894, 397],
     [889, 398],
     [875, 399],
     [845, 398],
     [845, 379],
     [851, 381],
     [880, 377],
     [899, 378],
     [922, 374],
     [941, 373],
     [945, 377]
    ],
    [[960, 374], [956, 376]],
    [[953, 377], [952, 380], [952, 376]],
    [[957, 380], [955, 381], [954, 379], [955, 378]]
   ]
  },
  {
   "color": "#144768ff",
   "pois": [{"id": "centroid", "point": [894, 405]}],
   "box": [845, 393, 946, 416],
   "polygons": [
    [
     [946, 404],
     [945, 411],
     [925, 410],
     [917, 413],
     [904, 414],
     [873, 413],
     [867, 416],
     [845, 415],
     [845, 399],
     [854, 398],
     [883, 399],
     [895, 396],
     [929, 396],
     [944, 393]
    ]
   ]
  },
  {
   "color": "#144380ff",
   "pois": [{"id": "centroid", "point": [895, 423]}],
   "box": [845, 411, 945, 435],
   "polygons": [
    [
     [945, 428],
     [941, 432],
     [925, 433],
     [905, 431],
     [883, 435],
     [845, 434],
     [845, 417],
     [849, 415],
     [849, 417],
     [851, 417],
     [866, 416],
     [873, 414],
     [894, 413],
     [895, 415],
     [897, 415],
     [918, 413],
     [924, 411],
     [941, 411],
     [945, 414]
    ]
   ]
  },
  {
   "color": "#143f98ff",
   "pois": [{"id": "centroid", "point": [894, 442]}],
   "box": [845, 431, 947, 453],
   "polygons": [
    [
     [947, 438],
     [946, 448],
     [943, 449],
     [933, 447],
     [902, 451],
     [883, 449],
     [873, 450],
     [870, 452],
     [854, 453],
     [850, 453],
     [845, 450],
     [845, 435],
     [870, 434],
     [873, 436],
     [905, 432],
     [933, 433],
     [945, 431]
    ]
   ]
  },
  {
   "color": "#143bb0ff",
   "pois": [{"id": "centroid", "point": [895, 460]}],
   "box": [845, 448, 946, 472],
   "polygons": [
    [
     [946, 464],
     [941, 469],
     [931, 470],
     [918, 470],
     [909, 468],
     [895, 469],
     [894, 471],
     [874, 472],
     [865, 470],
     [845, 470],
     [845, 451],
     [861, 453],
     [878, 450],
     [909, 451],
     [918, 449],
     [941, 448],
     [946, 452]
    ]
   ]
  },
  {
   "color": "#1437c8ff",
   "pois": [{"id": "centroid", "point": [894, 479]}],
   "box": [845, 468, 946, 489],
   "polygons": [
    [
     [946, 485],
     [919, 485],
     [917, 487],
     [873, 486],
     [871, 488],
     [852, 489],
     [845, 487],
     [845, 471],
     [870, 470],
     [871, 472],
     [885, 472],
     [900, 469],
     [917, 469],
     [919, 471],
     [923, 471],
     [943, 468],
     [946, 474]
    ]
   ]
  },
  {
   "color": "#1433e0ff",
   "pois": [{"id": "centroid", "point": [895, 497]}],
   "box": [845, 485, 946, 508],
   "polygons": [
    [
     [946, 489],
     [944, 501],
     [945, 503],
     [944, 505],
     [939, 507],
     [918, 507],
     [901, 505],
     [889, 508],
     [872, 508],
     [863, 506],
     [845, 506],
     [846, 487],
     [849, 487],
     [851, 489],
     [863, 489],
     [889, 486],
     [894, 486],
     [895, 488],
     [909, 488],
     [918, 486],
     [941, 485]
    ]
   ]
  },
  {
   "color": "#142ff8ff",
   "pois": [{"id": "centroid", "point": [893, 515]}],
   "box": [845, 506, 946, 525],
   "polygons": [
    [
     [946, 510],
     [943, 512],
     [944, 521],
     [942, 524],
     [933, 522],
     [919, 522],
     [917, 524],
     [895, 524],
     [885, 522],
     [867, 525],
     [851, 525],
     [845, 523],
     [846, 507],
     [887, 508],
     [895, 506],
     [917, 506],
     [919, 508],
     [933, 508],
     [946, 506]
    ]
   ]
  },
  {
   "color": "#142c10ff",
   "pois": [{"id": "centroid", "point": [895, 534]}],
   "box": [845, 523, 946, 544],
   "polygons": [
    [
     [946, 542],
     [941, 544],
     [918, 544],
     [909, 542],
     [895, 542],
     [894, 544],
     [871, 544],
     [863, 542],
     [845, 542],
     [845, 524],
     [849, 523],
     [850, 525],
     [863, 525],
     [872, 523],
     [894, 523],
     [895, 525],
     [909, 525],
     [918, 523],
     [941, 523],
     [945, 526]
    ]
   ]
  },
  {
   "color": "#142828ff",
   "pois": [{"id": "centroid", "point": [893, 552]}],
   "box": [845, 543, 946, 561],
   "polygons": [
    [
     [946, 552],
     [944, 561],
     [933, 559],
     [919, 559],
     [917, 561],
     [895, 561],
     [887, 559],
     [873, 559],
     [871, 561],
     [851, 561],
     [845, 559],
     [845, 543],
     [871, 543],
     [873, 545],
     [887, 545],
     [895, 543],
     [917, 543],
     [919, 545],
     [933, 545],
     [942, 543]
    ]
   ]
  },
  {
   "color": "#142440ff",
   "pois": [{"id": "centroid", "point": [896, 570]}],
   "box": [845, 559, 947, 582],
   "polygons": [
    [
     [947, 580],
     [941, 582],
     [918, 582],
     [909, 579],
     [895, 579],
     [894, 581],
     [871, 581],
     [855, 578],
     [848, 579],
     [845, 578],
     [845, 560],
     [846, 559],
     [850, 561],
     [866, 561],
     [872, 559],
     [894, 560],
     [895, 562],
     [940, 560],
     [944, 562]
    ]
   ]
  },
  {
   "color": "#142058ff",
   "pois": [{"id": "centroid", "point": [893, 589]}],
   "box": [845, 579, 946, 599],
   "polygons": [
    [
     [946, 585],
     [944, 587],
     [945, 596],
     [942, 599],
     [921, 596],
     [917, 598],
     [895, 598],
     [881, 595],
     [873, 595],
     [872, 597],
     [851, 597],
     [845, 595],
     [845, 579],
     [870, 579],
     [870, 581],
     [881, 582],
     [895, 580],
     [917, 580],
     [919, 582],
     [933, 583],
     [943, 581],
     [946, 583]
    ]
   ]
  },
  {
   "color": "#141c70ff",
   "pois": [{"id": "centroid", "point": [896, 607]}],
   "box": [845, 595, 947, 619],
   "polygons": [
    [
     [947, 615],
     [941, 619],
     [918, 619],
     [909, 616],
     [897, 616],
     [894, 618],
     [889, 618],
     [846, 614],
     [845, 596],
     [849, 595],
     [849, 597],
     [855, 598],
     [872, 596],
     [891, 596],
     [895, 599],
     [909, 599],
     [918, 597],
     [941, 598],
     [945, 600]
    ]
   ]
  },
  {
   "color": "#141888ff",
   "pois": [{"id": "centroid", "point": [893, 625]}],
   "box": [845, 615, 946, 636],
   "polygons": [
    [
     [946, 636],
     [933, 634],
     [909, 636],
     [887, 632],
     [873, 632],
     [868, 634],
     [851, 633],
     [845, 631],
     [845, 615],
     [867, 615],
     [873, 618],
     [887, 618],
     [895, 616],
     [917, 617],
     [917, 619],
     [919, 620],
     [933, 620],
     [942, 618],
     [945, 620]
    ]
   ]
  },
  {
   "color": "#1414a0ff",
   "pois": [{"id": "centroid", "point": [896, 644]}],
   "box": [845, 631, 947, 657],
   "polygons": [
    [
     [947, 648],
     [945, 654],
     [941, 657],
     [918, 656],
     [918, 654],
     [909, 653],
     [871, 654],
     [863, 651],
     [846, 650],
     [845, 632],
     [847, 631],
     [850, 633],
     [863, 635],
     [874, 632],
     [894, 633],
     [895, 635],
     [909, 637],
     [918, 634],
     [942, 635]
    ]
   ]
  },
  {
   "color": "#1410b8ff",
   "pois": [{"id": "centroid", "point": [893, 662]}],
   "box": [845, 651, 944, 674],
   "polygons": [
    [
     [944, 673],
     [943, 674],
     [939, 672],
     [929, 671],
     [908, 673],
     [898, 672],
     [895, 670],
     [873, 668],
     [871, 670],
     [851, 669],
     [846, 667],
     [845, 665],
     [845, 651],
     [870, 652],
     [871, 655],
     [887, 655],
     [900, 653],
     [917, 654],
     [917, 657],
     [942, 656],
     [944, 662]
    ]
   ]
  },
  {
   "color": "#140cd0ff",
   "pois": [{"id": "centroid", "point": [896, 681]}],
   "box": [845, 667, 946, 694],
   "polygons": [
    [
     [946, 691],
     [941, 694],
     [918, 694],
     [909, 690],
     [873, 691],
     [865, 687],
     [845, 686],
     [845, 667],
     [857, 671],
     [884, 669],
     [894, 670],
     [895, 672],
     [899, 673],
     [917, 673],
     [918, 671],
     [937, 672],
     [944, 675],
     [946, 679]
    ]
   ]
  },
  {
   "color": "#1408e8ff",
   "pois": [{"id": "centroid", "point": [893, 699]}],
   "box": [845, 687, 946, 712],
   "polygons": [
    [
     [946, 694],
     [944, 712],
     [943, 710],
     [923, 708],
     [919, 708],
     [916, 710],
     [907, 710],
     [895, 708],
     [893, 706],
     [883, 705],
     [865, 707],
     [850, 705],
     [845, 701],
     [845, 687],
     [870, 688],
     [871, 691],
     [877, 692],
     [887, 692],
     [895, 690],
     [917, 691],
     [919, 695],
     [933, 695],
     [942, 693]
    ]
   ]
  },
  {
   "color": "#140500ff",
   "pois": [{"id": "centroid", "point": [895, 718]}],
   "box": [845, 703, 945, 732],
   "polygons": [
    [
     [945, 728],
     [941, 732],
     [935, 732],
     [918, 731],
     [918, 728],
     [909, 727],
     [895, 726],
     [894, 728],
     [883, 728],
     [871, 727],
     [863, 723],
     [848, 723],
     [845, 722],
     [845, 703],
     [850, 704],
     [851, 706],
     [859, 707],
     [872, 705],
     [891, 706],
     [895, 709],
     [907, 711],
     [918, 708],
     [941, 710],
     [944, 713],
     [943, 717]
    ]
   ]
  },
  {
   "color": "#140118ff",
   "pois": [{"id": "centroid", "point": [893, 736]}],
   "box": [845, 723, 945, 748],
   "polygons": [
    [
     [945, 742],
     [944, 748],
     [921, 745],
     [917, 747],
     [906, 747],
     [887, 742],
     [875, 741],
     [869, 743],
     [854, 742],
     [845, 738],
     [845, 723],
     [867, 724],
     [870, 725],
     [871, 728],
     [917, 728],
     [917, 731],
     [919, 732],
     [933, 732],
     [942, 730],
     [945, 732]
    ]
   ]
  },
  {
   "color": "#13fd30ff",
   "pois": [{"id": "centroid", "point": [895, 754]}],
   "box": [845, 739, 946, 767],
   "polygons": [
    [
     [946, 763],
     [945, 766],
     [942, 767],
     [925, 767],
     [901, 763],
     [871, 762],
     [863, 759],
     [849, 759],
     [845, 758],
     [845, 739],
     [849, 739],
     [851, 742],
     [861, 744],
     [871, 743],
     [872, 741],
     [894, 743],
     [894, 745],
     [907, 748],
     [918, 746],
     [940, 747],
     [946, 753]
    ]
   ]
  },
  {
   "color": "#13f948ff",
   "pois": [{"id": "centroid", "point": [894, 772]}],
   "box": [845, 759, 946, 787],
   "polygons": [
    [
     [946, 787],
     [939, 784],
     [921, 782],
     [916, 784],
     [907, 784],
     [887, 778],
     [873, 777],
     [871, 779],
     [860, 779],
     [852, 777],
     [845, 774],
     [845, 759],
     [865, 760],
     [873, 763],
     [895, 763],
     [916, 765],
     [925, 768],
     [945, 768],
     [944, 771]
    ]
   ]
  },
  {
   "color": "#13f560ff",
   "pois": [{"id": "centroid", "point": [896, 790]}],
   "box": [845, 775, 947, 805],
   "polygons": [
    [
     [947, 796],
     [941, 805],
     [918, 804],
     [909, 799],
     [899, 798],
     [875, 799],
     [863, 794],
     [846, 792],
     [845, 775],
     [849, 775],
     [853, 778],
     [861, 780],
     [872, 779],
     [872, 777],
     [889, 779],
     [907, 785],
     [918, 782],
     [938, 784],
     [941, 785],
     [946, 792]
    ]
   ]
  },
  {
   "color": "#13f178ff",
   "pois": [{"id": "centroid", "point": [893, 808]}],
   "box": [845, 793, 946, 824],
   "polygons": [
    [
     [946, 817],
     [943, 824],
     [931, 820],
     [908, 821],
     [895, 818],
     [893, 816],
     [877, 814],
     [864, 816],
     [851, 813],
     [845, 810],
     [845, 793],
     [867, 795],
     [870, 796],
     [871, 799],
     [879, 800],
     [911, 800],
     [917, 801],
     [917, 805],
     [943, 805]
    ]
   ]
  },
  {
   "color": "#13ed90ff",
   "pois": [{"id": "centroid", "point": [896, 827]}],
   "box": [845, 811, 946, 842],
   "polygons": [
    [
     [946, 839],
     [941, 842],
     [918, 841],
     [909, 836],
     [871, 835],
     [863, 830],
     [845, 829],
     [845, 811],
     [849, 811],
     [852, 814],
     [861, 816],
     [872, 814],
     [892, 816],
     [903, 821],
     [925, 820],
     [940, 822],
     [945, 826]
    ]
   ]
  },
  {
   "color": "#13e9a8ff",
   "pois": [{"id": "centroid", "point": [893, 845]}],
   "box": [845, 829, 945, 862],
   "polygons": [
    [
     [945, 862],
     [935, 858],
     [917, 857],
     [911, 859],
     [886, 852],
     [858, 851],
     [845, 846],
     [845, 830],
     [851, 829],
     [870, 832],
     [871, 836],
     [887, 837],
     [897, 835],
     [917, 838],
     [917, 841],
     [931, 843],
     [945, 842]
    ]
   ]
  },
  {
   "color": "#13e5c0ff",
   "pois": [{"id": "centroid", "point": [896, 864]}],
   "box": [845, 847, 946, 879],
   "polygons": [
    [
     [946, 870],
     [944, 876],
     [941, 879],
     [923, 879],
     [918, 878],
     [909, 873],
     [901, 872],
     [875, 872],
     [861, 866],
     [846, 865],
     [845, 847],
     [863, 853],
     [872, 850],
     [894, 853],
     [899, 857],
     [907, 859],
     [918, 857],
     [938, 859],
     [941, 860],
     [944, 864]
    ]
   ]
  },
  {
   "color": "#13e1d8ff",
   "pois": [{"id": "centroid", "point": [893, 882]}],
   "box": [845, 865, 945, 899],
   "polygons": [
    [
     [945, 890],
     [943, 899],
     [937, 896],
     [923, 894],
     [910, 896],
     [887, 888],
     [871, 887],
     [864, 889],
     [845, 882],
     [845, 865],
     [869, 868],
     [871, 872],
     [875, 873],
     [897, 872],
     [917, 875],
     [917, 877],
     [923, 880],
     [944, 879]
    ]
   ]
  },
  {
   "color": "#13ddf0ff",
   "pois": [{"id": "centroid", "point": [897, 900]}],
   "box": [845, 883, 945, 916],
   "polygons": [
    [
     [945, 916],
     [918, 916],
     [909, 910],
     [895, 908],
     [892, 910],
     [880, 909],
     [872, 903],
     [847, 899],
     [845, 898],
     [845, 883],
     [849, 883],
     [861, 889],
     [877, 887],
     [894, 890],
     [895, 892],
     [907, 896],
     [922, 894],
     [941, 897],
     [943, 900]
    ]
   ]
  },
  {
   "color": "#13da08ff",
   "pois": [{"id": "centroid", "point": [895, 918]}],
   "box": [845, 903, 946, 929],
   "polygons": [
    [[864, 908], [859, 912], [847, 916], [845, 915], [845, 903], [861, 906]],
    [
     [946, 922],
     [944, 929],
     [935, 929],
     [916, 925],
     [901, 925],
     [895, 928],
     [878, 923],
     [878, 910],
     [900, 909],
     [917, 912],
     [917, 917],
     [933, 918],
     [943, 916]
    ],
    [[874, 915], [873, 920], [870, 924], [859, 924], [858, 920], [864, 914], [874, 911]]
   ]
  },
  {
   "color": "#13d620ff",
   "pois": [{"id": "centroid", "point": [870, 928]}],
   "box": [845, 921, 894, 936],
   "polygons": [
    [
     [854, 926],
     [853, 930],
     [848, 929],
     [845, 927],
     [845, 923],
     [846, 921],
     [850, 921],
     [853, 923]
    ],
    [[873, 930], [872, 933], [859, 932], [857, 925], [869, 925], [872, 923]],
    [[894, 929], [893, 931], [881, 936], [878, 933], [878, 924], [892, 926], [894, 927]]
   ]
  }
 ]
]