// import { useCallback, useEffect } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import RCSESaveButton from './RCSESaveButton'
import RCSECancelButton from './RCSECancelButton'
import RCGSegments from './RCGSegments'
import RCGPoints from './RCGPoints'
import RCGPolygons from './RCGPolygons'
import RCGPointsOfInterest from './RCGPointsOfInterest'

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    margin: '5px 0px',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around'
  }
}))

const RCGArea = () => {
  const classes = useStyles()
  // const dispatch = useDispatch()
  return (
    <div>
      <div className={classes.buttonsWrapper}>
        <RCSESaveButton />
        <RCSESaveButton back />
      </div>
      <div>
        <RCGSegments />
        <RCGPointsOfInterest />
        <RCGPolygons />
        <RCGPoints />
      </div>
      <RCSECancelButton />
    </div>
  )
}
export default RCGArea
