import React, { useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  getGeneratedStepSegments,
  getGeneratedAllSegmentsBoundingBox
} from '../../modules/SegmentGenerator/reducerSegmentGenerator'
import {
  GRAPHIC_ELEMENT,
  isGraphicElementVisible,
  getLineWidth,
  getPointLineWidth,
  getPointLength,
  getDotPointWidth,
  showEditorSelector
} from '../../modules/SegmentGenerator/reducerSGVisualization'
import { clickAtPoint } from '../../modules/SegmentGenerator/reducerSGEditorActions'
import {
  getEditorCurrentSegment,
  getEditorCurrentSegmentDrawData
} from '../../modules/SegmentGenerator/reducerSGEditorSelectors'
import {
  boundsSelector,
  scaleSelector,
  getImageOffset,
  globalPointToImagePoint,
  stepSelector
} from '../../modules/reducerRotator'
import { currentGroupSelector } from '../../modules/reducerGroups'
import {
  drawCross,
  drawPolygon,
  drawDotPoint,
  drawAsterisk,
  drawCenterText,
  drawShape
} from '../../utils/draw'

const useStyles = makeStyles((theme) => ({
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 4,
    cursor: 'auto'
  }
}))

const BORDER_COLOR = 'blue'
const POINT_COLOR = 'cyan'
const EDITOR_POINT_BORDER_SELECTED_COLOR = 'cyan'
const EDITOR_POINT_BORDER_UNSELECTED_COLOR = 'BlueViolet'
const EDITOR_POINT_SELECTED_COLOR = 'red'

function RCGDrawCanvas() {
  const drawCanvasRef = useRef(null)
  const currentStep = useSelector(stepSelector)
  const currentGroup = useSelector(currentGroupSelector)
  const isVisible = useSelector(isGraphicElementVisible)
  const gGeneratedStepSegments = useSelector(getGeneratedStepSegments)
  const editorSegment = useSelector(getEditorCurrentSegment)
  const scale = useSelector(scaleSelector)
  const gGeneratedAllSegmentsBoundingBox = useSelector(
    getGeneratedAllSegmentsBoundingBox
  )
  const currentSegmentDrawData = useSelector(getEditorCurrentSegmentDrawData)
  const bounds = useSelector(boundsSelector)
  const imageOffset = useSelector(getImageOffset)
  const transformGlobalPointToImagePoint = useSelector(globalPointToImagePoint)
  const lineWidth = useSelector(getLineWidth)
  const pointLineWidth = useSelector(getPointLineWidth)
  const pointLength = useSelector(getPointLength)
  const dotPointWidth = useSelector(getDotPointWidth)
  const showEditor = useSelector(showEditorSelector)
  const dispatch = useDispatch()
  const classes = useStyles()

  //Segment en editor
  const drawEditor = useCallback(
    (ctx) => {
      if (!currentSegmentDrawData) {
        return
      }

      ctx.lineWidth = lineWidth
      ctx.lineJoin = 'round'
      ctx.beginPath()
      for (let polygon of currentSegmentDrawData.polygons) {
        if (polygon.polygon.length > 0) {
          ctx.moveTo(polygon.polygon[0][0], polygon.polygon[0][1])
          for (let i = 1; i < polygon.polygon.length; i++) {
            ctx.lineTo(polygon.polygon[i][0], polygon.polygon[i][1])
          }
          ctx.closePath()
        }
      }
      ctx.fillStyle = currentSegmentDrawData.color.slice(0, -2) + '80'
      ctx.fill('evenodd')
      ctx.strokeStyle = BORDER_COLOR
      ctx.stroke()

      for (let polygon of currentSegmentDrawData.polygons) {
        for (let point of polygon.points) {
          drawDotPoint(
            ctx,
            point.x,
            point.y,
            dotPointWidth,
            point.selected
              ? EDITOR_POINT_SELECTED_COLOR
              : polygon.selected
              ? EDITOR_POINT_BORDER_SELECTED_COLOR
              : EDITOR_POINT_BORDER_UNSELECTED_COLOR
          )
        }
      }
      //Pois
      for (let poi of currentSegmentDrawData.pois) {
        drawAsterisk(
          ctx,
          poi.x,
          poi.y,
          pointLength,
          pointLineWidth,
          poi.selected ? EDITOR_POINT_SELECTED_COLOR : POINT_COLOR
        )
        drawCenterText(ctx, poi.x, poi.y - 5, poi.name, 5, 'black')
      }
    },
    [
      currentSegmentDrawData,
      dotPointWidth,
      lineWidth,
      pointLineWidth,
      pointLength
    ]
  )

  const drawSegment = useCallback(
    (ctx, segment) => {
      if (!segment) {
        return
      }
      //Polygons
      if (isVisible(GRAPHIC_ELEMENT.BORDER)) {
        drawShape(
          ctx,
          segment.polygons,
          true,
          segment.color.slice(0, -2) + '80',
          true,
          BORDER_COLOR,
          lineWidth
        )
      }
      if (isVisible(GRAPHIC_ELEMENT.POINT)) {
        for (let polygon of segment.polygons) {
          for (let i = 0; i < polygon.length; i++) {
            drawCross(
              ctx,
              polygon[i][0],
              polygon[i][1],
              pointLength,
              pointLineWidth,
              POINT_COLOR
            )
          }
        }
      }
      //Pois
      if (isVisible(GRAPHIC_ELEMENT.POIS)) {
        for (let poi of segment.pois) {
          drawAsterisk(
            ctx,
            poi.point[0],
            poi.point[1],
            pointLength,
            pointLineWidth,
            POINT_COLOR
          )
          drawCenterText(
            ctx,
            poi.point[0],
            poi.point[1] - 5,
            poi.id,
            5,
            'black'
          )
        }
      }
      //Box
      if (isVisible(GRAPHIC_ELEMENT.BOUNDING_BOX)) {
        ctx.beginPath()
        ctx.rect(
          segment.box[0],
          segment.box[1],
          segment.box[2] - segment.box[0],
          segment.box[3] - segment.box[1]
        )
        ctx.stroke()
      }
    },
    [isVisible, lineWidth, pointLineWidth, pointLength]
  )

  //Draw
  useEffect(() => {
    const canvas = drawCanvasRef.current
    if (!canvas || !bounds) {
      return
    }
    const ctx = canvas.getContext('2d')
    canvas.width = bounds.width
    canvas.height = bounds.height
    ctx.setTransform(scale, 0, 0, scale, imageOffset.x, imageOffset.y)

    if (showEditor) {
      drawEditor(ctx)
    } else {
      const segments = gGeneratedStepSegments(currentStep)
      for (let segment of segments) {
        drawSegment(ctx, segment)
      }

      if (isVisible(GRAPHIC_ELEMENT.ALL_BOUNDING_BOX)) {
        const allBox = gGeneratedAllSegmentsBoundingBox(currentStep)
        ctx.beginPath()
        ctx.rect(
          allBox[0],
          allBox[1],
          allBox[2] - allBox[0],
          allBox[3] - allBox[1]
        )
        ctx.stroke()
      }
    }
  }, [
    drawSegment,
    drawEditor,
    gGeneratedStepSegments,
    currentStep,
    editorSegment,
    isVisible,
    scale,
    currentGroup,
    gGeneratedAllSegmentsBoundingBox,
    bounds,
    imageOffset,
    showEditor
  ])

  const handleClick = useCallback(
    (e) => {
      if (e.button === 0) {
        //Boton izquierdo
        const pos = transformGlobalPointToImagePoint(e.pageX, e.pageY)
        dispatch(clickAtPoint(pos.x, pos.y))
      }
    },
    [dispatch, transformGlobalPointToImagePoint]
  )

  return (
    <canvas
      ref={drawCanvasRef}
      className={classes.canvas}
      onClick={handleClick}
    />
  )
}
export default RCGDrawCanvas
