import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  getParameter,
  setParam
} from '../../../../modules/SegmentGenerator/reducerSGParameters'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid black',
    padding: '5px 10px',
    margin: '5px 0px'
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  input: { width: '70px' }
}))

const RSGParameter = ({ paramId }) => {
  const gParameter = useSelector(getParameter)
  const parameter = gParameter(paramId)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleParamChange = useCallback(
    (paramId, value) => {
      dispatch(setParam(paramId, value))
    },
    [dispatch]
  )

  return parameter ? (
    <div key={parameter.id} className={classes.inputWrapper}>
      {parameter.type === 'number' ? (
        <>
          <label for={parameter.id}>{parameter.name}</label>
          <input
            className={classes.input}
            name={parameter.name}
            id={parameter.id}
            type={parameter.type}
            min={parameter.min}
            max={parameter.max}
            step={'any'}
            value={parseFloat(parameter.value)}
            onChange={(e) => handleParamChange(parameter.id, e.target.value)}
          />
        </>
      ) : null}
    </div>
  ) : null
}
export default RSGParameter
//
