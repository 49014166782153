import {
  vectorFromPoints,
  scaleVector,
  boxCenter,
  addVectors,
  rangeValue
} from '../utils/mathFunctions'

export const globalPointToLocalPoint = (globalPoint, scale, childTopLeft) => {
  return {
    x: Math.round((globalPoint.x - childTopLeft.x) / scale),
    y: Math.round((globalPoint.y - childTopLeft.y) / scale)
  }
}

export const localPointToGlobalPoint = (localPoint, scale, childTopLeft) => {
  return {
    x: childTopLeft.x + localPoint.x * scale,
    y: childTopLeft.y + localPoint.y * scale
  }
}

export const localPointToGlobalPoint2 = (localPoint, scale, offset, bounds) => {
  const topLeft = { x: offset.x + bounds.x, y: offset.y + bounds.y }
  return localPointToGlobalPoint(localPoint, scale, topLeft)
}

export const localPointToGlobalPoint3 = (
  localPoint,
  scale,
  translation,
  childSize,
  bounds
) => {
  const offset = getChildOffset(scale, translation, childSize, bounds)
  return localPointToGlobalPoint2(localPoint, scale, offset, bounds)
}

export const getChildOffset = (scale, translation, childSize, parentBounds) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  return {
    x: translation.x + (parentBounds.width - childSize.width * scale) / 2,
    y: translation.y + (parentBounds.height - childSize.height * scale) / 2
  }
}

export const getChildTopLeft = (
  scale,
  translation,
  childSize,
  parentBounds
) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  const offset = getChildOffset(scale, translation, childSize, parentBounds)
  return getChildTopLeft2(offset, parentBounds)
}

export const getChildTopLeft2 = (offset, parentBounds) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  return {
    x: parentBounds.left + offset.x,
    y: parentBounds.top + offset.y
  }
}

export const getChildCenter = (parentBounds, translation) => {
  return parentBounds
    ? addVectors(
        boxCenter(
          parentBounds.top,
          parentBounds.left,
          parentBounds.width,
          parentBounds.height
        ),
        translation
      )
    : { x: 0, y: 0 }
}

export const getFitChildScale = (childSize, parentBounds) => {
  if (childSize.width === 0 || childSize.height === 0 || !parentBounds) {
    return 1
  }
  return Math.min(
    parentBounds.width / childSize.width,
    parentBounds.height / childSize.height
  )
}

export const zoomChildAtGlobalPoint = (
  currentScale,
  scaleRange,
  currentTranslation,
  factor,
  globalPoint,
  parentBounds
) => {
  //Nueva escala
  const newScale = rangeValue(
    currentScale * factor,
    scaleRange[0],
    scaleRange[1]
  )

  if (newScale === currentScale || !parentBounds) {
    //Nada que hacer
    return { scale: newScale, translation: currentTranslation }
  }

  const imageGlobalCenter = getChildCenter(parentBounds, currentTranslation)
  //Punto con respecto centro del child
  const zoomPoint = vectorFromPoints(imageGlobalCenter, globalPoint)
  //Quita escalado
  const zoomPointOriginal = scaleVector(zoomPoint, 1 / currentScale)
  //Coordenadas del punto con la nueva escala
  const newZoomPoint = scaleVector(zoomPointOriginal, newScale)
  //Incremento entre los dos puntos
  let inc = vectorFromPoints(zoomPoint, newZoomPoint)
  //Normaliza
  let newTranslation = vectorFromPoints(inc, currentTranslation)
  return { scale: newScale, translation: newTranslation }
}

export const centerAtLocalPoint = (
  localPoint,
  currentTranslation,
  scale,
  childSize,
  parentBounds
) => {
  const parentCenter = boxCenter(
    parentBounds.top,
    parentBounds.left,
    parentBounds.width,
    parentBounds.height
  )
  const centerPoint = localPointToGlobalPoint3(
    localPoint,
    scale,
    currentTranslation,
    childSize,
    parentBounds
  )
  const inc = vectorFromPoints(centerPoint, parentCenter)
  return addVectors(currentTranslation, inc)
}

export const setLocalBoxVisible = (
  box, //[x0,y0,x1,y1]
  currentTranslation,
  scale,
  scaleRange,
  childSize,
  parentBounds
) => {
  const boxWidth = box[2] - box[0] + 1
  const boxHeight = box[3] - box[1] + 1
  const bCenter = boxCenter(box[1], box[0], boxWidth, boxHeight)
  const translation = centerAtLocalPoint(
    bCenter,
    currentTranslation,
    scale,
    childSize,
    parentBounds
  )
  const newScale = getFitChildScale(
    { width: boxWidth, height: boxHeight },
    parentBounds
  )
  return zoomChildAtGlobalPoint(
    scale,
    scaleRange,
    translation,
    newScale / scale,
    boxCenter(
      parentBounds.top,
      parentBounds.left,
      parentBounds.width,
      parentBounds.height
    ),
    parentBounds
  )
}
