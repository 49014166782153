import { createSelector } from 'reselect'
import { getBoundingBox } from '../../utils/mathFunctions'

export const currentSegmentSelector = (state) => state.areaEditor.currentSegment
export const currentPolygonSelector = (state) => state.areaEditor.currentPolygon
export const selectedPointsSelector = (state) => state.areaEditor.selectedPoints
export const segmentsSelector = (state) => state.areaEditor.segments
export const predefinedPoisNamesSelector = (state) =>
  state.areaEditor.predefinedPoisNames

export const getSegment = createSelector(
  [segmentsSelector],
  (segments) => (segmentId) => {
    return segments.find((segment) => segment.id === segmentId)
  }
)

export const getCurrentSegment = createSelector(
  [getSegment, currentSegmentSelector],
  (gSegment, currentSegment) => {
    return gSegment(currentSegment)
  }
)

export const getSegmentPoint = createSelector(
  [getSegment],
  (gSegment) => (segmentId, pointId) => {
    const segment = gSegment(segmentId)
    return segment ? segment.points.find((point) => point.id === pointId) : null
  }
)

export const getCurrentSegmentPoint = createSelector(
  [getCurrentSegment],
  (currentSegment) => (pointId) => {
    return currentSegment
      ? currentSegment.points.find((point) => point.id === pointId)
      : null
  }
)

export const getCurrentSegmentPolygon = createSelector(
  [getCurrentSegment, getCurrentSegmentPoint],
  (currentSegment, gCurrentSegmentPoint) => (polygonId) => {
    if (currentSegment) {
      const polygon = currentSegment.polygons.find(
        (poly) => poly.id === polygonId
      )
      if (polygon) {
        return {
          polygon,
          points: polygon.points.reduce((acc, pointId) => {
            const p = gCurrentSegmentPoint(pointId)
            if (p) {
              acc.push(p)
            }
            return acc
          }, [])
        }
      }
    }
    return { polygon: null, points: [] }
  }
)

export const getCurrentSegmentPoi = createSelector(
  [getCurrentSegment],
  (currentSegment) => (pointId) => {
    if (currentSegment) {
      return currentSegment.pois.find((poi) => poi.pointId === pointId)
    }
    return null
  }
)

export const getSegmentPoiByName = createSelector(
  [getSegment],
  (gSegment) => (segmentId, name) => {
    const segment = gSegment(segmentId)
    console.log(segment, segmentId, name)
    if (segment) {
      return segment.pois.find((poi) => poi.name === name)
    }
    return null
  }
)

export const getCurrentSegmentPoiByName = createSelector(
  [getCurrentSegment],
  (currentSegment) => (name) => {
    if (currentSegment) {
      return currentSegment.pois.find((poi) => poi.name === name)
    }
    return null
  }
)

export const getSegmentCentroid = createSelector(
  [getSegmentPoint, getSegmentPoiByName],
  (gSegmentPoint, gSegmentPoiByName) => (segmentId) => {
    const poi = gSegmentPoiByName(segmentId, 'centroid')
    if (poi) {
      const point = gSegmentPoint(segmentId, poi.pointId)
      if (point) {
        return { x: point.x, y: point.y }
      }
    }
    return { x: 0, y: 0 }
  }
)

export const getCurrentSegmentCentroid = createSelector(
  [getCurrentSegmentPoint, getCurrentSegmentPoiByName],
  (gCurrentSegmentPoint, gCurrentSegmentPoiByName) => {
    const poi = gCurrentSegmentPoiByName('centroid')
    if (poi) {
      const point = gCurrentSegmentPoint(poi.pointId)
      if (point) {
        return { x: point.x, y: point.y }
      }
    }
    return { x: 0, y: 0 }
  }
)

export const getSegmentBox = createSelector(
  [getSegment],
  (gSegment) => (segmentId) => {
    const segment = gSegment(segmentId)
    if (segment) {
      const points = segment.points.map((point) => [point.x, point.y])
      return getBoundingBox(points)
    }
    return [0, 0, 0, 0]
  }
)

export const isPointSelected = createSelector(
  [selectedPointsSelector],
  (selection) => (pointId) => {
    return selection.indexOf(pointId) >= 0
  }
)

export const isPoiSelected = createSelector(
  [isPointSelected],
  (gIsPointSelected) => (poiId) => {
    return gIsPointSelected(poiId)
  }
)

export const getCurrentSegmentSelectedPoints = createSelector(
  [getCurrentSegment, selectedPointsSelector],
  (currentSegment, selection) => {
    if (currentSegment) {
      return selection.filter(
        (pointId) =>
          currentSegment.points.findIndex((point) => point.id === pointId) >= 0
      )
    }
    return []
  }
)

export const getCurrentSegmentSelectedPolygonsPoints = createSelector(
  [getCurrentSegment, selectedPointsSelector],
  (currentSegment, selection) => {
    if (currentSegment) {
      return selection.filter((pointId) => {
        if (
          currentSegment.pois.findIndex((poi) => poi.pointId === pointId) >= 0
        ) {
          return false
        }
        return (
          currentSegment.points.findIndex((point) => point.id === pointId) >= 0
        )
      })
    }
    return []
  }
)

export const getCurrentSegmentSelectedPois = createSelector(
  [getCurrentSegment, getCurrentSegmentSelectedPoints],
  (currentSegment, currentSegmentSelectedPoints) => {
    if (currentSegment) {
      return currentSegmentSelectedPoints.filter(
        (pointId) =>
          currentSegment.pois.findIndex((poi) => poi.pointId === pointId) >= 0
      )
    }
    return []
  }
)

//Posicion del punto en el poligono
export const getCurrentSegmentPointIndex = createSelector(
  [getCurrentSegmentPolygon],
  (gCurrentSegmentPolygon) => (polygonId, pointId) => {
    const { polygon } = gCurrentSegmentPolygon(polygonId)
    return polygon ? polygon.points.findIndex((point) => point === pointId) : -1
  }
)

//Ajusta informacion en el editor para que pueda ser usada fuera
export const getEditorSegmentData = createSelector(
  [getSegment, getSegmentPoint],
  (gSegment, gSegmentPoint) => (segmentId) => {
    const segment = gSegment(segmentId)
    if (!segment || segment.points.length === 0) {
      return null
    }
    const segmentData = {
      color: segmentId,
      box: [0, 0, 0, 0],
      polygons: [],
      pois: []
    }
    //Poligonos
    for (let polygon of segment.polygons) {
      const polygonPoints = polygon.points.reduce((acc, pointId) => {
        const point = gSegmentPoint(segmentId, pointId)
        if (point) {
          acc.push([point.x, point.y])
        }
        return acc
      }, [])
      segmentData.polygons.push(polygonPoints)
    }
    //Puntos de interes
    segmentData.pois = segment.pois.reduce((acc, poi) => {
      const point = gSegmentPoint(segmentId, poi.pointId)
      if (point) {
        acc.push({ id: poi.name, point: [point.x, point.y] })
      }
      return acc
    }, [])
    //Bounding box
    const corners = []
    let box = [0, 0, 0, 0]
    for (let polygon of segmentData.polygons) {
      box = getBoundingBox(polygon)
      corners.push([box[0], box[1]])
      corners.push([box[2], box[3]])
    }
    if (corners.length > 2) {
      box = getBoundingBox(corners)
    }
    segmentData.box = box
    return segmentData
  }
)

//Ajusta informacion en el editor para que pueda ser usada fuera
export const getEditorCurrentSegmentDrawData = createSelector(
  [
    getCurrentSegment,
    getCurrentSegmentPoint,
    selectedPointsSelector,
    currentPolygonSelector
  ],
  (currentSegment, gCurrentSegmentPoint, selectedPoints, currentPolygon) => {
    if (!currentSegment || currentSegment.points.length === 0) {
      return null
    }
    const segmentData = {
      color: currentSegment.id,
      polygons: [],
      pois: []
    }
    //Poligonos
    for (let poly of currentSegment.polygons) {
      const points = poly.points.reduce((acc, pointId) => {
        const point = gCurrentSegmentPoint(pointId)
        if (point) {
          acc.push({
            id: point.id,
            selected: selectedPoints.indexOf(point.id) >= 0,
            x: point.x,
            y: point.y
          })
        }
        return acc
      }, [])
      const polygon = poly.points.reduce((acc, pointId) => {
        const point = gCurrentSegmentPoint(pointId)
        if (point) {
          acc.push([point.x, point.y])
        }
        return acc
      }, [])
      segmentData.polygons.push({
        selected: currentPolygon === poly.id,
        points,
        polygon
      })
    }
    //Puntos de interes
    segmentData.pois = currentSegment.pois.reduce((acc, poi) => {
      const point = gCurrentSegmentPoint(poi.pointId)
      if (point) {
        acc.push({
          name: poi.name,
          selected: selectedPoints.indexOf(point.id) >= 0,
          x: point.x,
          y: point.y
        })
      }
      return acc
    }, [])
    return segmentData
  }
)

export const getEditorCurrentSegment = createSelector(
  [currentSegmentSelector, getEditorSegmentData],
  (currentSegment, gEditorSegmentData) => {
    return gEditorSegmentData(currentSegment)
  }
)

export const getEditorSegmentsData = createSelector(
  [segmentsSelector, getEditorSegmentData],
  (segments, gEditorSegmentData) => {
    return segments.reduce((acc, segment) => {
      const segmentData = gEditorSegmentData(segment.id)
      if (segmentData) {
        acc.push(segmentData)
      }
      return acc
    }, [])
  }
)

export const getCurrentSegmentNotUsedPredefinedPoisNames = createSelector(
  [predefinedPoisNamesSelector, getCurrentSegment],
  (predefinedPoisNames, currentSegment) => {
    if (!currentSegment) {
      return predefinedPoisNames
    }
    return predefinedPoisNames.filter(
      (poiName) =>
        currentSegment.pois.findIndex((poi) => poi.name === poiName.name) < 0
    )
  }
)
