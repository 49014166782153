// import { useCallback } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import RCGGroups from './RCGGroups'
import RSGConfiguration from './RSGConfiguration'
import RCGSaveButton from './RCGSaveButton'
import RSGEditorButton from './RSGEditorButton'
import RCGSteps from './RCGSteps'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white', //'rgb(241, 234, 207)',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    overflow: 'auto',

    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'strentch'

    // padding: '10px'
  }
}))

const RSGGenerator = () => {
  const classes = useStyles()
  // const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      <RCGSaveButton />
      <RCGGroups />
      <RSGConfiguration />
      <RCGSteps />
      <RSGEditorButton />
    </div>
  )
}
export default RSGGenerator
