import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { setEditorVisible } from '../../../../modules/SegmentGenerator/reducerSGVisualization'

const useStyles = makeStyles((theme) => ({
  button: { width: '100%' }
}))

const RCSECancelButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setEditorVisible(false))
  }, [dispatch])

  return (
    <Button
      variant="contained"
      color="primary"
      disableFocusRipple
      disableRipple
      className={classes.button}
      onClick={handleClick}
    >
      Cancel
    </Button>
  )
}
export default RCSECancelButton
//
