import { group_g2_segments } from './group_g2_segments'
const generateImages = () => {
  let images = []
  for (let i = 1; i <= 3; ++i) {
    images.push(
      './images/GiroCasa/base2/render-' + `${i}`.padStart(4, 0) + '.jpg'
    )
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 1; i <= 3; ++i) {
    images.push(
      './images/GiroCasa/mascara2/mapping-' + `${i}`.padStart(4, 0) + '.png'
    )
  }
  return images
}
export const group2 = {
  id: 'g2',
  name: 'Edificio 1',
  imageSize: { width: 1920, height: 1080 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: ['#057e40ff', '#05a168ff'],
  segments: group_g2_segments
}
