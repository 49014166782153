import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import RBarButton from './RBarButton'
import {
  lateralVisibleSelector,
  setLateralVisible,
  barVisibleSelector
} from '../../../modules/reducerMaster'

import { modeSelector, MODE } from '../../../modules/reducerRotator'

const RMenuButton = (props) => {
  const dispatch = useDispatch()
  const mode = useSelector(modeSelector)
  const lateralVisible = useSelector(lateralVisibleSelector)
  const barVisible = useSelector(barVisibleSelector)
  const handleClick = useCallback(() => {
    dispatch(setLateralVisible(!lateralVisible))
  }, [dispatch, lateralVisible])

  return (
    <RBarButton
      {...props}
      tooltip="Menu"
      tooltipEnabled={barVisible}
      checked={lateralVisible}
      onClick={handleClick}
      disabled={
        mode === MODE.PANNING ||
        mode === MODE.ROT_MOUSE ||
        mode === MODE.ROT_AUTOMATIC
      }
    >
      <MenuIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RMenuButton
