import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { setStepData } from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'
import { stepSelector } from '../../../../modules/reducerRotator'
import { setEditorVisible } from '../../../../modules/SegmentGenerator/reducerSGVisualization'
import { getEditorSegmentsData } from '../../../../modules/SegmentGenerator/reducerSGEditorSelectors'

const useStyles = makeStyles((theme) => ({
  button: { width: '100%', padding: '12px 0px', margin: '2px' }
}))

const RCSESaveButton = ({ back }) => {
  const currentStep = useSelector(stepSelector)
  const stepData = useSelector(getEditorSegmentsData)
  const classes = useStyles()
  const dispatch = useDispatch()

  //Guarda area en reducerSegmentGenerator
  const handleSave = useCallback(() => {
    dispatch(setStepData(stepData, currentStep))
    if (back) {
      dispatch(setEditorVisible(false))
    }
  }, [dispatch, currentStep, stepData, back])

  return (
    <Button
      variant="contained"
      color="primary"
      disableFocusRipple
      disableRipple
      className={classes.button}
      onClick={handleSave}
    >
      {back ? 'Save & Back' : 'Save'}
    </Button>
  )
}
export default RCSESaveButton
//
