import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import {
  currentMethodSelector,
  methodsSelector,
  setMethod
} from '../../../../modules/SegmentGenerator/reducerSGParameters'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '10px'
  },
  title: { paddingBottom: '10px' },
  methodsWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start'
  },
  methodButton: { width: '50%', minWidth: '0px', padding: '0px' }
}))

const RSGMethods = () => {
  const classes = useStyles()
  const currentMethod = useSelector(currentMethodSelector)
  const methods = useSelector(methodsSelector)
  const dispatch = useDispatch()

  const handleMethodClick = useCallback(
    (methodId) => {
      dispatch(setMethod(methodId))
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <div className={classes.title}>Method</div>
      <div className={classes.methodsWrapper}>
        {methods.map((method, index) => (
          <Button
            key={method.id}
            className={classes.methodButton}
            variant="contained"
            color={currentMethod === method.id ? 'secondary' : 'default'}
            disableFocusRipple
            onClick={(event) => handleMethodClick(method.id)}
          >
            {method.name}
          </Button>
        ))}
      </div>
    </div>
  )
}
export default RSGMethods
